<div class="d-flex flex-column position-relative" style="width: 95%; z-index: 110;">
    <div class="search-filter d-flex cursor">
        <input type="text" *ngIf="!selectedItem" (click)="openModal()" [placeholder]="labelSearchFilter" [formControl]="searTerm" aria-describedby="basic-addon2">
        <input type="text" *ngIf="selectedItem" (click)="removeSelected()" value="{{selectedItem.immatriculation}}" aria-describedby="basic-addon2">
        <span class="input-group-text filter-button" *ngIf="!isDropDown" id="basic-addon2" (click)="openModal()">
            <i-bs name="filter"></i-bs>
        </span>
        <span class="input-group-text filter-button" *ngIf="isDropDown" id="basic-addon2" (click)="closeDropDown()">
            <i-bs name="x-circle"></i-bs>
        </span>
    </div>
    <div class="row drop-down" *ngIf="isDropDown">
        <ng-container [ngTemplateOutlet]="template"></ng-container>
    </div>
</div>
<div class="modal-bg-click" *ngIf="isDropDown" (click)="closeDropDown()">
</div>

<ng-template #template>
    <div class="modal-content-drop">
        <ng-content></ng-content>
    </div>
</ng-template>