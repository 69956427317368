<li *ngIf="menu && !resized" class="nav-item">
  <a
    *ngIf="!menu.children"
    class="nav-link align-items-center"
    [routerLink]="menu.url"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{
      exact: menu.routerLinkActiveOptions ? true : false
    }"
    (click)="setSelectedLink()"
  >
    <i-bs [name]="menu.icon" class="align-text-bottom align-middle"></i-bs>
    <span class="px-2" *ngIf="!resized">{{ menu.title }}</span>
  </a>
  <button
    *ngIf="menu.children"
    class="btn btn-link nav-link w-100 d-flex justify-content-between collapsed  {{
      isOpen ? 'open text-white' : ''
    }}"
    style="text-align:left;"
    (click)="setSelectedLink()"
    [attr.aria-expanded]="isOpen"
    aria-controls="'#' + menu.collapsedId"
  >
    <span>
      <i-bs [name]="menu.icon" class="align-text-bottom align-middle"></i-bs>
      <span class="px-2" *ngIf="!resized">{{ menu.title }}</span>
    </span>
    <i-bs
      [name]="isOpen ? 'chevron-down' : 'chevron-right'"
      class="align-text-bottom align-middle fw-bold"
    ></i-bs>
  </button>
  <div
    *ngIf="menu.children"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="!isOpen"
    class="collapse"
  >
    <ul class="btn-toggle-nav list-unstyled">
      <ng-container *ngFor="let item of menu.children; let i = index">
        <li *ngIf="canViewMenu(item)">
          <a
            *ngIf="!item.children"
            class="nav-link align-items-center ps-4"
            [routerLink]="item.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: item.routerLinkActiveOptions ? true : false
            }"
          >
            <i-bs
              [name]="item.icon"
              class="align-text-bottom align-middle"
            ></i-bs>
            {{ item.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</li>
<li
  *ngIf="menu && resized"
  ngbDropdown="resized ? true: false"
  class="nav-item dropdown"
  container="body"
>
  <a
    *ngIf="!menu.children"
    class="nav-link align-items-center"
    [routerLink]="menu.url"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{
      exact: menu.routerLinkActiveOptions ? true : false
    }"
    (click)="setSelectedLink()"
  >
    <i-bs [name]="menu.icon" class="align-text-bottom align-middle"></i-bs>
    <span class="px-2" *ngIf="!resized">{{ menu.title }}</span>
  </a>
  <button
    id="navbarDropdown"
    *ngIf="menu.children"
    class="btn btn-link nav-link w-100 d-flex justify-content-between collapsed dropdown-toggle {{
      isOpen ? 'open text-white' : ''
    }}"
    (click)="setSelectedLink()"
    [attr.aria-expanded]="isOpen"
    aria-controls="'#' + menu.collapsedId"
    id="dropdownBasic1"
    ngbDropdownToggle
  >
    <span>
      <i-bs [name]="menu.icon" class="align-text-bottom align-middle"></i-bs>
      <span class="px-2" *ngIf="!resized">{{ menu.title }}</span>
    </span>
    <i-bs
      *ngIf="!resized"
      [name]="isOpen ? 'chevron-down' : 'chevron-right'"
      class="align-text-bottom align-middle fw-bold"
    ></i-bs>
  </button>
  <div
    *ngIf="menu.children && !resized"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="!isOpen"
    class="collapse"
  >
    <ul class="btn-toggle-nav list-unstyled">
      <ng-container *ngFor="let item of menu.children; let i = index">
        <li>
          <a
            *ngIf="!item.children"
            class="nav-link align-items-center ps-5"
            [routerLink]="item.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: item.routerLinkActiveOptions ? true : false
            }"
          >
            <i-bs
              [name]="item.icon"
              class="align-text-bottom align-middle"
            ></i-bs>
            {{ item.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <ul
    class="dropdown-menu mx-5"
    ngbDropdownMenu
    aria-labelledby="dropdownBasic1"
    *ngIf="resized"
  >
    <ng-container *ngFor="let item of menu.children; let i = index">
      <li>
        <a
          ngbDropdownItem
          *ngIf="!item.children"
          class="nav-link align-items-center dropdown-item ps-1 p-2"
          [routerLink]="item.url"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{
            exact: item.routerLinkActiveOptions ? true : false
          }"
        >
          <i-bs
            [name]="item.icon"
            class="align-text-bottom align-middle"
          ></i-bs>
          {{ item.title }}
        </a>
      </li>
    </ng-container>
  </ul>
</li>
