import { ETransactionType } from '../utils';

export interface ITransactionType {
  label: string;
  value: ETransactionType;
}

export interface ITransactionFilter {
  date?: string;
  after?: string;
}

export interface IAccountingFilter {
  date?: string;
  after?: string;
  page: number;
  limit: number;
}

export interface IGroupedSellerTransaction {
  amount: number;
  business_user_mobile: string;
  currency: string;
  date: string;
  fee: number;
  libelle: string;
  libelle_fee: string;
  libelle_versement: string;
}
