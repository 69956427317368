import { ELogisticTourStatus } from "../models/logistic-tour";
import { PointOfDeliveryDto } from "../models/point-of-delivery";
import { PaginationInterface } from "./pagination-interface";
import { IIntervalOfDate } from "./stock-movement-interface";

export class LogisticToursPaginationInterface implements PaginationInterface {
  page: number = 1;
  limit: number = 15;
  next?: number | null;
  last?: number | null;
  pages?: number | null;
  status?: ELogisticTourStatus[];
  conveyor?: number[];
  driver?: number[];
  road_tractor?: number[];
  carrier_truck?: number[];
  semi_trailer?: number[];
  interval_of_date?: IRangeOfDate;
  week?: number;
  year?: number;
}

export class SearchFiltersLogisticToursDto {
  status?: ELogisticTourStatus[];
  conveyor?: number[];
  driver?: number[];
  semi_trailer?: number[];
  road_tractor?: number[];
  carrier_truck?: number[];
  interval_of_date?: IRangeOfDate;
}

export interface IRangeOfDate {
  start_date: string;
  end_date: string;
}

export enum EActionTourLogistic {
  MODIFY = "MODIFY",
  CANCEL = "CANCEL",
  START_TOUR = "START_TOUR",
}

export interface IDaysDateInWeek {
  day: number;
  date: number;
  monthLabel: string;
  monthNumber: number;
  days: number;
  year: number;
}

export class CreateTourLogisticDto {
  start_date!: string;
  end_date!: string;
  driver!: number;
  origin!: number;
  conveyor!: number;
  point_of_deliveries!: PointOfDeliveryDto[];
  carrier_truck?: number;
  road_tractor?: number;
  semi_trailer?: number;
}

export class UpdateTourLogisticDto {
  start_date?: string;
  end_date?: string;
  origin?: number;
  status?: ELogisticTourStatus;
  driver?: number;
  conveyor?: number;
  point_of_deliveries?: PointOfDeliveryDto[];
  carrier_truck?: number;
  road_tractor?: number;
  semi_trailer?: number;
}

export class UpdateLogisticTourItemDto {
  driver?: number | string;
  conveyor?: number | string;
  carrier_truck?: number | string;
  road_tractor?: number | string;
  semi_trailer?: number | string;
}

export class CreateLogisticTourItemDto {
  logistic_tour_id!: number 
  driver?: number | string;
  conveyor?: number | string;
  carrier_truck?: number | string;
  road_tractor?: number | string;
  semi_trailer?: number | string;
}


export interface IActionDropDown {
  label: string;
  action: EActionTourLogistic;
}
