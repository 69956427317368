<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <div class="d-flex">
    <app-go-back [marginClass]="''"></app-go-back>
    <h1 class="h2 text-info mx-4">Gestion des clusters</h1>
  </div>
  <!-- <app-is-guarded [permissions]="['CAN_CREATE_CLUSTER']">
    <a class="btn btn-info btn-sm text-sm text-white fw-bold mx-3" (click)="openCreateModal()">Nouveau clusteur</a>
  </app-is-guarded> -->
</div>

<div>
  <app-table *ngIf="clusters$ | async as clusters" [columnDefs]="columnDefs" [tableData]="clusters"
    (actionInvoqueFunctionEvent)="tableActionsFunction($event)"></app-table>
  <ng-template [ngIf]="!(clusters$ | async)?.length">
    <app-empty-card [icon]="'exclamation-circle'" [message]="'Aucun cluster trouvé'"></app-empty-card>
  </ng-template>
  <app-pagination
    *ngIf="(clusters$ | async)?.length" 
    [pagination]="pagination" 
    (paginationEvent)="paginate($event)">
  </app-pagination>
</div>

<modal #nGmodal [modalConfig]="modalConfig" class="modal-dialog-centered">
  <form *ngIf="modalFormType === 'CREATE' || modalFormType === 'UPDATE'" [formGroup]="modalForm">
    <div class="mb-3">
      <label for="country_id" class="form-label fw-bold text-secondary">Pays:
      </label>
      <select formControlName="country_id" class="form-control form-control-sm" aria-label="Choisir un pays">
        <option [ngValue]="undefined" selected>Choisir un pays</option>
        <option *ngFor="let country of countries$ | async" [ngValue]="country.id">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="libelle" class="form-label fw-bold text-secondary">Libelle:
      </label>
      <input type="text" id="libelle" class="form-control" formControlName="cluster" />
    </div>
    <div class="mb-3">
      <label for="libelle" class="form-label fw-bold text-secondary">Libelle Anglais:
      </label>
      <input type="text" id="libelle" class="form-control" formControlName="cluster_en" />
    </div>
    <div class="mb-3">
      <label for="libelle" class="form-label fw-bold text-secondary">Passage:
      </label>
      <input type="number" [min]="1" id="libelle" class="form-control" formControlName="passage" />
    </div>
    <div class="d-flex mb-3">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault" formControlName="activate" />
        <label class="form-check-label fw-bold text-secondary" for="flexCheckDefault">
          Statut
        </label>
      </div>
    </div>
  </form>
  <p></p>
  <p *ngIf="modalFormType === 'DELETE'">
    <strong>Voulez-vous vraiment supprimer ce cluster
      <span class="text-danger">"{{ currentCluster.cluster }}"</span>?</strong>
  </p>
  <p class="mt-4" *ngIf="!modalFormDisplay && modalFormStatusActionMessage">
    {{ modalFormStatusActionMessage }}
  </p>
</modal>