import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegionCommercialGoalService } from 'src/app/services/region-commercial-goal.service';
import { SectorService } from 'src/app/services/sector.service';
import formatCurrency from 'src/app/utils/format-currency';
import { isDirty } from 'src/app/utils/helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sec-goals-list',
  templateUrl: './sec-goals-list.component.html',
  styleUrls: ['./sec-goals-list.component.scss'],
})
export class SecGoalsListComponent implements OnInit {
  /* Route props */
  rc_id?: number;
  rc_label?: string;
  month!: number;
  year?: number;
  ca?: number;
  return_rate?: number;
  myForm: FormGroup;
  ca_total: number = 0;
  raw_data: any = [];
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sectorService: SectorService,
    private regionCommercialGoalService: RegionCommercialGoalService,
    private spinner: NgxSpinnerService
  ) {
    this.myForm = this.fb.group({
      goals: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.rc_id = params.rc_id;
      this.rc_label = params.rc_label;
      this.month = params.month;
      this.year = params.year;
      this.ca = params.ca;
      this.return_rate = params.return_rate;
    });

    this.myForm.valueChanges.subscribe((value) => {
      this.ca_total = 0;
      for (let i = 0; i < value.goals.length; i++) {
        this.ca_total += parseInt(
          value.goals[i].ca.toString().replace(/\s/g, '')
        );
      }
    });

    if (!this.rc_id !== undefined) {
      this.getSectorAndGoals({
        id: this.rc_id,
        month: this.month,
        year: this.year,
      });
    }
  }

  createGroup(data: any) {
    let d = {
      id: data.id,
      label: { value: data.label, disabled: true },
      month: this.month,
      year: this.year,
      ca: formatCurrency(data.ca),
      return_rate: this.return_rate,
    };
    this.raw_data.push(d);
    return this.fb.group(d);
  }

  addItem(data: any) {
    (this.myForm.get('goals') as FormArray).push(this.createGroup(data));
  }

  getControls() {
    return (this.myForm.get('goals') as FormArray).controls;
  }

  showSpinner() {
    if (this.loading) this.spinner.show();
    else this.spinner.hide();
  }

  getSectorAndGoals(data: any) {
    this.loading = true;
    this.showSpinner();
    this.sectorService.getSectorAndGoals(data).subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          this.addItem(response.data[i]);
        }
        this.loading = false;
        this.showSpinner();
      },
      (error: any) => {
        this.loading = false;
        this.showSpinner();
      }
    );
  }

  onSubmit() {
    Swal.fire({
      title:
        'Attention êtes vous sûre de vouloir enregistrer ces modifications?',
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const data = {
          goals: this.myForm.value.goals.map((goal: any) => {
            return {
              id: goal.id,
              ca: goal.ca.replace(/\s/g, ''),
              month: goal.month,
              year: goal.year,
              return_rate: goal.return_rate,
            };
          }),
        };

        this.loading = true;
        this.showSpinner();
        this.regionCommercialGoalService
          .save_sectors_goals(this.rc_id!, data)
          .subscribe(
            (response: any) => {
              Swal.fire({
                title: 'Success!',
                text: response.message,
                icon: 'success',
              });
              this.loading = false;
              this.showSpinner();
            },
            (error: any) => {
              Swal.fire({
                title: 'Erreur!',
                text: error,
                icon: 'error',
              });
              this.loading = false;
              this.showSpinner();
            }
          );
      }
    });
  }

  checkIsFormDirty() {
    return isDirty(this.raw_data, this.myForm.value.goals, 'id', 'ca');
  }
}
