import { buildParams } from './../utils/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OrderProduct } from '../models/order-product';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getOrdersProduct(point_of_sale_id: Number, date: any): Observable<any> {
    return this.http.get<OrderProduct[]>(`${Config.baseUrl}/orders/${point_of_sale_id}?date=${date}`)
  }


  getAllOrderProducts(params: any): Observable<any> {
    return this.http.get<any>(`${Config.baseUrl}/mt/order_products${buildParams(params)}`)
  }


  createPurchaseOrder(orderPructs: any): Observable<any> {
    return this.http.post<OrderProduct[]>(`${Config.baseUrl}/order/create_purchase_order`, orderPructs)
  }

  createForecast(params: any): Observable<any> {
    return this.http.get<any>(`${Config.baseUrl}/mt/forecast/create${buildParams(params)}`)
  }

  changePreorderToOrder(order_id: number): Observable<any> {
    return this.http.get<any>(`${Config.baseUrl}/orders/${order_id}/change_order_to_preorder`)
  }

  deleteOrder(order_id: any): Observable<any> {
     return this.http.delete<any>(`${Config.baseUrl}/orders/${order_id}`)
  }
}
