import { PointOfSalePerformance } from './../../../models/point_of_sale_performance';
import { CustomDateParserFormatter, CustomAdapter } from 'src/app/utils/datepicker-adapter';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { PerformanceService } from 'src/app/services/performance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PointOfSaleService } from 'src/app/services/point-of-sale.service';
import { SectorService } from 'src/app/services/sector.service';

@Component({
  selector: 'app-view-performances',
  templateUrl: './view-performances.component.html',
  styleUrls: ['./view-performances.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ViewPerformancesComponent implements OnInit {
  loaded: boolean = false;
  loading: boolean = false;
  startDate!: string;
  endDate!: string;
  performances!: PointOfSalePerformance[];
  point_of_sale_id!: number
  point_of_sale_name: string = ""

  constructor(
    private performanceService: PerformanceService,
    private pointOfSaleService: PointOfSaleService,
    private sectorService: SectorService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        this.point_of_sale_name =  params['name']
        this.point_of_sale_id = params['id']
        this.startDate = params['start_date']
        this.endDate = params['end_date']

        this.getPointOfSalePerformance()

      });
    this.startDate = this.startDate || this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
    this.endDate = this.endDate || this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }

  onDateChange(date: any): void { 
    this.getPointOfSalePerformance()

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { start_date: this.startDate, end_date: this.endDate }, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  getPointOfSalePerformance() {
    this.loading = true
    this.performanceService.getPointOfSalePerformances(this.point_of_sale_id, {start_date: this.startDate, end_date: this.endDate})
      .subscribe({
        next: (pointOfSalesPerformances) => {
          this.loaded  = true
          this.loading = false
          this.performances = pointOfSalesPerformances
        },
        error: (e: any) => {
          this.loading = false
        }
      })
  }

}
