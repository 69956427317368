import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Observable,
  OperatorFunction,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginationInterface } from 'src/app/interfaces/pagination-interface';
import { OtherStore } from 'src/app/stores/other.store';
import { ModalConfig, Other } from 'src/app/types';
import { ActionEventType, ColumnDef, EColumnType } from 'src/app/utils';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

@Component({
  selector: 'app-others-list',
  templateUrl: './others-list.component.html',
  styleUrls: ['./others-list.component.scss'],
})
export class OthersListComponent implements OnInit {
  others$: Observable<Other[]> | undefined;
  types$: Observable<string[]> | undefined;
  loading$: Observable<boolean> | undefined;
  error$: Observable<string> | undefined;
  modalState$: Observable<string> | undefined;

  currentOther: any = null;
  types: string[] = [];

  /*  Inputs  */
  @ViewChild('nGmodal') private modalComponent!: ModalComponent;
  @ViewChild('instance', { static: true }) private instance!: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  /* Column definitions */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Id',
      name: 'id',
      positionClass: ['fw-normal'],
    }),
    new ColumnDef({
      label: 'Libelle',
      name: 'libelle',
    }),
    new ColumnDef({
      label: 'Statut',
      name: 'activate',
      type: EColumnType.SWITCH,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
    }),
    new ColumnDef({
      label: 'Actions',
      type: EColumnType.ACTION,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
      actions: [
        {
          baseUrl: '',
          text: 'Modifier',
          functionToInvoque: 'openUpdateModal',
          actionToogleableIndex: 0,
          class: 'btn btn-warning btn-sm',
          permissions: ['CAN_UPDATE_OTHER'],
        },
        {
          baseUrl: '',
          text: 'Supprimer',
          functionToInvoque: 'delete',
          actionToogleableIndex: 0,
          class: 'btn btn-danger btn-sm',
          permissions: ['CAN_DELETE_OTHER'],
        },
      ],
    }),
  ];

  /* Table configuration */
  tableActionsFunction(event: ActionEventType) {
    switch (event.action.functionToInvoque) {
      case 'openUpdateModal':
        this.openUpdateModal(event);
        break;
      case 'delete':
        this.openDeleteModal(event);
        break;
      default:
        break;
    }
  }

  /* Modal configuration */
  modalConfig!: ModalConfig;
  modalForm!: FormGroup;
  modalFormType: string = EModalFormType.CREATE;
  modalFormDisplay: boolean = false;
  modalFormStatusActionMessage: string = '';
  modalState: string = 'closed';

  /* Filter and pagination props */
  pagination!: PaginationInterface;

  constructor(
    private formBuilder: FormBuilder,
    private otherStore: OtherStore,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.others$ = this.otherStore.select((state) => state.others);
    this.types$ = this.others$.pipe(
      map((objects) => objects.map((obj) => obj.types)),
      map((types) => types.filter((type) => type !== null)),
      map((types) => [...new Set(types)])
    );

    this.types$.subscribe((types) => (this.types = types));
    this.loading$ = this.otherStore.select((state) => state.loading);
    this.otherStore
      .select((state) => state.pagination)
      .subscribe((pagination) => {
        if (pagination === undefined) return;
        this.pagination = pagination;
      });

    this.loading$.subscribe((loading) => {
      if (loading) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    });

    this.error$ = this.otherStore.select((state) => state.error);
    this.modalState$ = this.otherStore.select((state) => state.modalState);
    this.modalState$.subscribe((m) => {
      this.modalState = m;
      if (m === 'closed') {
        // this.modalForm.reset();
        this.closeModal();
      }
    });
    this.initForm();
    this.loadOthers();
  }

  initForm() {
    this.modalForm = this.formBuilder.group({
      id: [''],
      libelle: ['', Validators.required],
      activate: [true],
    });
  }

  paginate(pagination: any) {
    this.otherStore.setInitial();
    this.otherStore.getOthers({
      limit: pagination.items!,
      page: pagination.page,
    });
  }

  loadOthers(): void {
    this.otherStore.getOthers(this.pagination);
  }

  openCreateModal() {
    this.openModal();
    this.modalFormType = EModalFormType.CREATE;
    this.modalConfig = {
      modalTitle: 'Ajouter un autre produit',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-info text-white',
      cancelButtonClass: 'btn-secondary',
      submitButtonClass: 'btn-primary text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.create(),
    };

    this.modalForm = this.formBuilder.group({
      id: [''],
      libelle: ['', Validators.required],
      activate: [true],
    });
  }

  openUpdateModal(event: ActionEventType) {
    this.openModal();
    this.modalConfig = {
      modalTitle: 'Modifier un autre produit',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-warning text-white',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-warning text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.update(),
    };
    this.modalFormType = EModalFormType.UPDATE;
    this.currentOther = event.rowData;
    this.modalForm = this.formBuilder.group({
      id: [this.currentOther.id, Validators.required],
      libelle: [this.currentOther.libelle, Validators.required],
      activate: [this.currentOther.activate],
    });
  }

  openDeleteModal(event: ActionEventType) {
    this.currentOther = event.rowData;
    this.modalFormType = EModalFormType.DELETE;
    this.modalConfig = {
      modalTitle: 'Supprimer un autre produit',
      submitButtonLabel: 'Supprimer',
      cancelButtonLabel: 'Annuler',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-danger text-white',
      headerClass: 'bg-danger text-white',
      onSubmit: () => this.delete(this.currentOther),
    };
    this.openModal();
  }

  async openModal() {
    return await this.modalComponent.open();
  }

  async closeModal() {
    return await this.modalComponent.cancel();
  }

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.types
          : this.types.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )
    );
  };

  create() {
    this.otherStore.addOther(this.modalForm.value);
  }

  update() {
    this.otherStore.updateOther(this.modalForm.value);
  }

  delete(other: Other) {
    this.otherStore.deleteOther(other);
  }
}
