import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { NgxBootstrapIconsModule, eye, pencil } from 'ngx-bootstrap-icons';
import { RouterModule } from '@angular/router';
import { CellComponent } from './cell/cell.component';
import { IsGuardedModule } from '../is-guarded/is-guarded.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

const icons = {
  eye,
  pencil,
};

@NgModule({
  declarations: [TableComponent, CellComponent],
  imports: [
    CommonModule,
    NgxBootstrapIconsModule.pick(icons),
    RouterModule,
    IsGuardedModule,
    NgbTooltipModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  exports: [TableComponent],
})
export class TableModule {}
