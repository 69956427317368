import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { OthersCountryListComponent } from './others-country-list/others-country-list.component';
import { OthersListComponent } from './others-list/others-list.component';
import { OthersTypesComponent } from './others-types/others-types.component';

const routes: Routes = [
  { path: '', component: OthersListComponent },
  { path: 'others-types', component: OthersTypesComponent },
  { path: 'others-countries', component: OthersCountryListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OthersRoutingModule {}
