import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { AuthenticationGuardService } from 'src/app/services/authentication-guard.service';
import { ToursComponent } from './tours.component';
import { PlanningToursComponent } from './planning-tours/planning-tours.component';
import { RoleGuard } from 'src/app/guards/role.guard';
import { ListComponent } from './list/list.component';
import { NewLogisticTourComponent } from './new-logistic-tour/new-logistic-tour.component';
import { DetailComponent } from './detail/detail.component';
import { UpdateLogisticTourComponent } from './update-logistic-tour/update-logistic-tour.component';
import { EUserRole } from 'src/app/utils';
import { StockReceiptComponent } from './stock-receipt/stock-receipt.component';
import { ReceiptOfProductsComponent } from './receipt-of-products/receipt-of-products.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: ToursComponent,
        children: [
          {
            path: 'new-logistic-tour',
            component: NewLogisticTourComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.LOGISTIC_MANAGER, EUserRole.PLANNER],
            },
          },
          {
            path: 'list-tour-logistic/:id/update',
            component: UpdateLogisticTourComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.LOGISTIC_MANAGER, EUserRole.PLANNER],
            },
          },
          {
            path: 'list-tour-logistic/:id',
            component: DetailComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.LOGISTIC_MANAGER, EUserRole.PLANNER],
            },
          },
          {
            path: 'plannings',
            component: PlanningToursComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.LOGISTIC_MANAGER, EUserRole.PLANNER],
            },
          },
          {
            path: 'list-tour-logistic',
            component: ListComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.LOGISTIC_MANAGER, EUserRole.PLANNER],
            },
          },
          {
            path: 'tours/stock-receipt-list',
            component: StockReceiptComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.SHIFT_SUPERVISOR],
            },
          },
          {
            path: 'tours/receipt-of-products/:pointOfDeliveryId',
            component: ReceiptOfProductsComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: [EUserRole.ADMIN, EUserRole.SHIFT_SUPERVISOR],
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToursRoutingModule { }
