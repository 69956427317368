<div class="table-responsive">
  <table *ngIf="tableData.length" class="table align-middle table-hover">
    <thead class="table-light">
      <tr class="rounded">
        <th
          scope="col"
          *ngFor="let column of columnDefs"
          [class]="column.hearderPositionClass"
        >
          {{ column.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of tableData">
        <td
          *ngFor="let column of columnDefs"
          [ngClass]="[getCellClass(column)]"
        >
          <app-cell
            [column]="column"
            [rowData]="row"
            (actionInvoqueFunctionEvent)="invoqueActionsFunction($event)"
            (onClickCellValueEvent)="clickCellValueEvent($event)"
          ></app-cell>
        </td>
      </tr>
    </tbody>
  </table>
</div>
