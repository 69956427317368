import { OtherCountry, OtherCountryState } from '../types';
import { Store } from './store';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { OtherCountryListDTO } from '../dtos/other-country.dtos';
import { OtherCountryService } from '../services/other-country.service';

const initialState: OtherCountryState = {
  loading: false,
  otherCountries: [],
  error: '',
  pagination: {
    page: 1,
    limit: 15,
    next: 1,
    last: 1,
    pages: 0,
  },
};

@Injectable({ providedIn: 'root' })
export class OtherCountryStore extends Store<OtherCountryState> {
  private loaded = false;

  constructor(
    private otherCountryService: OtherCountryService,
    private toastService: ToastService
  ) {
    super(initialState);
  }

  getOtherCountries(params: any) {
    if (!this.loaded) {
      this.loaded = true;
      this.setState((state) => ({ ...state, loading: true }));
      this.otherCountryService.getOtherCountries(params).subscribe({
        next: (response: OtherCountryListDTO) => {
          this.setState((state) => ({
            loading: false,
            otherCountries: response.data,
            pagination: response.metadata,
          }));
        },
        error: (error: any) => {
          this.setState((state) => ({ loading: false, error }));
        },
      });
    }
  }

  addOtherCountry(otherCountry: any) {
    this.setState((state) => ({ ...state, loading: true }));
    this.otherCountryService.addOtherCountry(otherCountry).subscribe({
      next: (otherCountry: any) => {
        this.setState((state) => ({
          loading: false,
          otherCountries: [otherCountry, ...state.otherCountries],
        }));
        this.toastService.show('Autre produit ajouté', {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
      },
      error: (error: any) => {
        const errorMessage = error.includes('already exist for this country')
          ? 'Cet autre avec cette catégorie existe déjà pour ce pays.'
          : "Une erreur est survenu lors de l'ajout de l'autre produit. Merci de réessayer";
        this.setState((state) => ({ loading: false, error }));
        this.toastService.show(errorMessage, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });
      },
    });
  }

  updateOtherCountry(otherCountry: any) {
    this.setState((state) => ({ ...state, loading: true }));
    this.otherCountryService.updateOtherCountry(otherCountry).subscribe({
      next: (otherCountry: any) => {
        this.setState((state) => ({
          loading: false,
          otherCountries: state.otherCountries.map((o) =>
            o.id === otherCountry.id ? otherCountry : o
          ),
        }));
        this.toastService.show("L'autre produit a été modifié avec succès", {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(
          "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer",
          {
            classname: 'bg-danger text-light toast-container',
            delay: 5000,
          }
        );
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  deleteOtherCountry(otherCountry: OtherCountry) {
    this.setState((state) => ({ ...state, loading: true }));
    this.otherCountryService.deleteOtherCountry(otherCountry).subscribe({
      next: (o: any) => {
        this.setState((state) => ({
          loading: false,
          otherCountries: state.otherCountries.filter(
            (o) => o.id !== otherCountry.id
          ),
        }));
        this.toastService.show("L'autre produit a été supprimé avec succès", {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(
          "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer",
          {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container',
            delay: 5000,
          }
        );
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  setInitial() {
    this.loaded = false;
  }
}
