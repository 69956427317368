export interface HomeCardItemInterface {
  title: string;
  image: string;
  text1: string;
  text2: string;
  link: string;
  linkClass: string[];
  authorizedRoles: string[];
  sections: ESection[];
}

export enum ESection {
  TRADI = 'TTRADI',
  MT = 'MT',
  ADMIN = 'ADMIN',
  LOGISTIQUE = 'LOGISTIQUE'
}
