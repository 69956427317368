import { NgSelectModule } from '@ng-select/ng-select';
import { GoBackModule } from './../../components/go-back/go-back.module';
import { NgxBootstrapIconsModule, calendar3, eye } from 'ngx-bootstrap-icons';
import { HomePerformancesComponent } from './home-performances/home-performances.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerformancesRoutingModule } from './performances-routing.module';
import { PerformancesComponent } from './performances.component';
import { ErrorInterceptorService } from './../../services/error-interceptor.service';
import { JwtInterceptorService } from './../../services/jwt-interceptor.service';
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ViewPerformancesComponent } from './view-performances/view-performances.component';
import { GlobalPerformancesComponent } from './global-performances/global-performances.component';

const icons = {
    calendar3, 
    eye,
  };

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PerformancesRoutingModule,
    NgxBootstrapIconsModule.pick(icons),
    GoBackModule,
  ],
  declarations: [
    PerformancesComponent,
    HomePerformancesComponent,
    ViewPerformancesComponent,
    GlobalPerformancesComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [PerformancesComponent]
})
export class PerformancesModule { }
