import { PointOfSale } from 'src/app/models/point-of-sale';
import { PointOfSaleService } from 'src/app/services/point-of-sale.service';
import { Product } from './../../../models/product';
import { OrderService } from './../../../services/order.service';
import { OrderProduct } from './../../../models/order-product';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ProductService } from 'src/app/services/product.service';
import OrderProductInterface from 'src/app/interfaces/order-product-interface';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent implements OnInit {
  orderProducts: OrderProduct[] = [];
  products: Product[] = [];
  newOrderForm!: UntypedFormGroup;
  order: OrderProductInterface[] = [];
  rawOrder: OrderProductInterface[] = [];
  orderId!: number;
  sector!: number;
  point_of_sale!: PointOfSale;
  point_of_sale_id!: number;
  point_of_sale_name: string = '';
  date: string | null = '';
  loadingProduct: boolean = false;
  loadingPointOfSale: boolean = false;
  error: string = '';
  loadingOrders: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private orderService: OrderService,
    private productService: ProductService,
    private pointOfSaleService: PointOfSaleService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.point_of_sale_name = params['name'];
      this.date = this.route.snapshot.queryParamMap.get('date');
    });

    this.getProducts();

    this.newOrderForm = this.formBuilder.group({
      pointOfSaleId: ['', Validators.required],
      orderProducts: new UntypedFormArray([]),
    });
  }

  get f() {
    return this.newOrderForm.controls;
  }
  get t() {
    return this.f['orderProducts'] as UntypedFormArray;
  }

  getPointOfSaleOrderProducts(): void {
    this.loadingOrders = true;
    this.point_of_sale_id = Number(
      this.route.snapshot.paramMap.get('point-of-sale-id')
    );
    this.date = this.route.snapshot.queryParamMap.get('date');

    this.orderService
      .getOrdersProduct(this.point_of_sale_id, this.date)
      .subscribe({
        next: (order) => {
          this.orderId = order.id;
          this.sector = order.sector_id;
          this.orderProducts = order.order_products;
          order.order_products.forEach(
            (value: OrderProductInterface, index: any, array: any) => {
              this.addQuantity(
                value.id,
                this.getProductPrice(value.product_id),
                value.quantity_ordered || value.quantity_proposed,
                value.product_id,
                value.order_id,
                value.created_at,
                value.updated_at,
                value.date
              );
            }
          );
          this.order.sort(function (a, b) {
            return a.quantity_proposed! > b.quantity_proposed! ? 1 : -1;
          });
          this.loadingOrders = false;
          this.showSpinner();
        },
        error: (error) => {
          this.error = `Une erreur est survenue lors de la récupération des commandes. Merci de réessayer;`;
          this.loadingOrders = false;
          this.showSpinner();
        },
      });
  }

  getProducts(): void {
    this.point_of_sale_id = Number(
      this.route.snapshot.paramMap.get('point-of-sale-id')
    );
    this.loadingPointOfSale = true;
    this.loadingProduct = true;
    this.showSpinner();
    this.pointOfSaleService.getPointOfSale(this.point_of_sale_id).subscribe({
      next: (response) => {
        this.point_of_sale = response;
        this.loadingPointOfSale = false;
        this.showSpinner();
        this.productService.getProducts().subscribe({
          next: (products) => {
            this.products = products.filter(
              (el) =>
                el.is_accessorie !== true &&
                el?.catalog_id === this.point_of_sale.catalog_id
            );
            this.loadingProduct = false;
            this.showSpinner();
            this.getPointOfSaleOrderProducts();
          },
          error: (error) => {
            this.error = `Une erreur est survenue lors de la récupération des produits. Merci de réessayer.`;
            this.loadingProduct = false;
            this.showSpinner();
          },
        });
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération du point de vente. Merci de réessayer.`;
        this.loadingPointOfSale = false;
        this.showSpinner();
      },
    });
  }

  getProposed(productId?: Number): number {
    const orderProduct = this.orderProducts.find(
      (el) => el.product_id == productId
    );
    if (orderProduct !== undefined) {
      return orderProduct.quantity_proposed || 0;
    }
    return 0;
  }

  getOrderProductId(productId?: Number): Number {
    const orderEdit = this.order.find((el) => el.product_id == productId);
    return orderEdit?.id || 0;
  }

  getProductPrice(productId?: Number): Number {
    const orderEdit = this.products.find((el) => el.id == productId);
    return orderEdit?.price || 0;
  }

  getQuantityOrdered(productId?: Number): Number {
    const orderEdit = this.order.find((el) => el.product_id == productId);
    return orderEdit?.quantity_ordered || 0;
  }

  getSumQuantityOrdered(): any {
    return this.order.reduce((acc, { quantity_ordered }) => {
      return acc + quantity_ordered;
    }, 0);
  }

  onKey(event: any, id: any, price: any, productId: any, orderId: any) {
    this.addQuantity(
      id,
      price,
      event.target.value,
      productId,
      orderId,
      this.getTodayDatetime(),
      this.getTodayDatetime(),
      this.date
    );
  }

  addQuantity(
    id: any,
    price: any,
    quantity: any,
    productId: any,
    orderId: any,
    created_at: any,
    updated_at: any,
    date: any
  ) {
    const orderEdit = this.order.find((el) => el.product_id == productId);
    if (orderEdit === undefined) {
      const o = {
        id: id,
        price: price,
        product_id: productId,
        order_id: orderId,
        quantity_ordered: parseInt(quantity) || 0,
        created_at: this.getTodayDatetime(),
        updated_at: this.getTodayDatetime(),
        date: this.date,
      };
      this.order.push(o);
      this.rawOrder.push(o);
    } else {
      this.order = this.order.map((obj) => {
        if (obj.product_id == productId) {
          return { ...obj, quantity_ordered: parseInt(quantity) || 0 };
        }
        return obj;
      });
    }
  }

  incrementQuantity(productId: any, price: any) {
    const orderEdit = this.order.find((el) => el.product_id == productId);

    if (orderEdit === undefined) {
      const o = {
        id: null,
        price: price,
        product_id: productId,
        quantity_ordered: 1,
        order_id: this.orderId,
        created_at: this.getTodayDatetime(),
        updated_at: this.getTodayDatetime(),
        date: this.date,
      };
      this.order.push(o);
      this.rawOrder.push(o);
    } else {
      this.order = this.order.map((obj) => {
        if (obj.product_id == productId) {
          return { ...obj, quantity_ordered: orderEdit.quantity_ordered + 1 };
        }
        return obj;
      });
    }
  }

  decrementQuantity(productId: any, price: any) {
    const orderEdit = this.order.find((el) => el.product_id == productId);

    if (orderEdit === undefined) {
      const o = {
        id: null,
        price: price,
        product_id: productId,
        quantity_ordered: 0,
        order_id: this.orderId,
        created_at: this.getTodayDatetime(),
        updated_at: this.getTodayDatetime(),
        date: this.date,
      };
      this.order.push(o);
      this.rawOrder.push(o);
    } else {
      if (orderEdit.quantity_ordered > 0) {
        this.order = this.order.map((obj) => {
          if (obj.product_id == productId) {
            return { ...obj, quantity_ordered: orderEdit.quantity_ordered - 1 };
          }
          return obj;
        });
      }
    }
  }

  onSubmit(): void {
    this.spinner.show();

    const order = {
      id: this.orderId,
      order_products: this.order,
      point_of_sale_id: this.point_of_sale_id,
    };

    this.orderService.createPurchaseOrder(order).subscribe({
      next: (v) => {
        this.router.navigate(['/', 'orders'], {
          queryParams: {
            sector_id: this.point_of_sale.sector_id || this.sector,
            date: this.date,
          },
        });
        Swal.fire({
          title: 'Success!',
          text: v.message,
          icon: 'success',
        });
        this.spinner.hide();
      },
      error: (e: any) => {
        Swal.fire({
          title: 'Erreur!',
          text: e,
          icon: 'error',
        });
        this.spinner.hide();
      },
    });
  }

  getTodayDatetime = () => {
    var d = new Date(),
      dformat =
        [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/') +
        ' ' +
        [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
    return dformat;
  };

  showSpinner() {
    if (this.loadingProduct || this.loadingPointOfSale || this.loadingOrders)
      this.spinner.show();
    else this.spinner.hide();
  }
}
