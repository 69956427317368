<div class="card-calendar cursor">
  <div class="card-tag">
    <span [ngClass]="{
        'status-cancelled': planning.status === EPlanningStatus.CANCELED,
        'status-finish': planning.status === EPlanningStatus.FINISH,
        'status-in-progress': planning.status === EPlanningStatus.IN_PROGRESS,
        'status-new': planning.status === EPlanningStatus.SCHEDULED
      }">{{ translatELogisticTourStatus(planning.status) }}</span>
  </div>
  <div class="card-infos">
    <div class="start-pt">
      <span class="circle-tag" [ngClass]="{
          'status-cancelled': planning.status === EPlanningStatus.CANCELED,
          'status-finish': planning.status === EPlanningStatus.FINISH,
          'status-in-progress': planning.status === EPlanningStatus.IN_PROGRESS,
          'status-new': planning.status === EPlanningStatus.SCHEDULED
        }"></span>
      <span>
        {{ planning.point_of_deliveries[0].departure_point.name }}
      </span>
    </div>
    <div class="time-line" [ngClass]="{
        'status-cancelled': planning.status === EPlanningStatus.CANCELED,
        'status-finish': planning.status === EPlanningStatus.FINISH,
        'status-in-progress': planning.status === EPlanningStatus.IN_PROGRESS,
        'status-new': planning.status === EPlanningStatus.SCHEDULED
      }">
    </div>
    <div class="end-pt">
      <span class="circle-tag" [ngClass]="{
          'status-cancelled': planning.status === EPlanningStatus.CANCELED,
          'status-finish': planning.status === EPlanningStatus.FINISH,
          'status-in-progress': planning.status === EPlanningStatus.IN_PROGRESS,
          'status-new': planning.status === EPlanningStatus.SCHEDULED
        }"></span>

      <span class="deposits" [ngClass]="{
        'status-cancelled': planning.status === EPlanningStatus.CANCELED,
        'status-finish': planning.status === EPlanningStatus.FINISH,
        'status-in-progress': planning.status === EPlanningStatus.IN_PROGRESS,
        'status-new': planning.status === EPlanningStatus.SCHEDULED
      }"> {{ planning.point_of_deliveries.length }} dépôts </span>
    </div>
  </div>
  <div class="" *ngIf="planning.point_of_deliveries.length">
    <div class="d-flex justify-content-end fw-bold footer-mat">
      <span *ngIf="getLastDeliveryPointVehicleAndUserActors(planning).carrier_truck === null 
        && getLastDeliveryPointVehicleAndUserActors(planning).road_tractor === null 
        && getLastDeliveryPointVehicleAndUserActors(planning).semi_trailer === null">
        ----
      </span>
      <span *ngIf="getLastDeliveryPointVehicleAndUserActors(planning).carrier_truck !== null">
        {{getLastDeliveryPointVehicleAndUserActors(planning).carrier_truck.immatriculation}}
      </span>
      <span *ngIf="getLastDeliveryPointVehicleAndUserActors(planning).road_tractor !== null">
        {{getLastDeliveryPointVehicleAndUserActors(planning).road_tractor.immatriculation +" - "+
        getLastDeliveryPointVehicleAndUserActors(planning).semi_trailer.immatriculation}}
      </span>
    </div>
  </div>
</div>