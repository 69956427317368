import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pagination: any;
  @Output() paginationEvent = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  next() {
    this.paginationEvent.emit({
      ...this.pagination,
      page: this.pagination.next,
    });
  }

  previous() {
    this.paginationEvent.emit({
      ...this.pagination,
      page: this.pagination.page - 1,
    });
  }

  displayNextButton() {
    return (
      this.pagination.page >= 0 && this.pagination.page <= this.pagination.pages
    );
  }
}
