import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ISellerReference,
  SellerReferencePaginationInterface,
} from '../interfaces/seller-reference-interface';
import { SellerReference } from '../models/seller-references';
import { buildParams } from '../utils';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class SellerReferencesService {
  constructor(private http: HttpClient) {}

  create(data: ISellerReference) {
    return this.http.post<any>(`${Config.baseUrlV2}/seller_references`, data);
  }

  getSellerReferences(params: SellerReferencePaginationInterface) {
    const url = 'seller_references' + buildParams(params);
    return this.http.get<{
      seller_references: SellerReference[];
      metadata: any;
    }>(`${Config.baseUrlV2}/${url}`);
  }

  update(id: number, data: ISellerReference){
    return this.http.patch<any>(`${Config.baseUrlV2}/seller_references/${id}`, data);
  }
}
