import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MustMatch } from 'src/app/utils/helpers';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  message: string = '';
  loading: boolean = false;
  token: string = '';
  strongPassword: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: MustMatch,
      }
    );

    this.token = this.route.snapshot.queryParams['token'];

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  ngOnInit(): void {}

  onSubmit() {
    const val = this.form.value;
    this.loading = true;
    this.authenticationService
      .resetPassword(this.token, val.password, val.confirmPassword)
      .subscribe({
        next: () => {
          this.message = 'Password reset successful, you can now login';
          this.router.navigate(['../login'], { relativeTo: this.route });
        },
        error: (error: any) => {
          this.message = error;
          this.loading = false;
        },
      });
  }
}
