import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-logistic-tour-point',
  templateUrl: './modal-logistic-tour-point.component.html',
  styleUrls: ['./modal-logistic-tour-point.component.scss']
})
export class ModalLogisticTourPointComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<boolean>()

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.isOpen = false
    this.close.emit(this.isOpen);
  }
}
