<!-- Point initial -->
<div *ngIf="!point_of_delivery_detail" class="row d-flex flex-nowrap justify-content-center align-items-center">
    <div *ngIf="indexPointLine !== 0" class="col-3 d-flex flex-column align-items-end cursor"
        (click)="openDetailPoint(point_of_delivery, indexPointLine)">
        <span *ngIf="planned_departure">
            {{
            ( planned_departure | date: 'dd/MM/YYYY')
            }}
        </span>
        <span *ngIf="planned_departure_time" class="fw-bold">
            {{
            ( planned_departure_time)
            }}
        </span>
    </div>
    <div *ngIf="indexPointLine === 0" class="col-3 d-flex flex-column align-items-end">

        <span *ngIf="!planned_departure">
            --/--/----
            <!-- {{
            ( today | date: 'dd/MM/YYYY')
            }} -->
        </span>
        <span *ngIf="planned_departure">
            {{
                ( planned_departure | date: 'dd/MM/YYYY')
            }}
        </span>
        <span *ngIf="!planned_departure_time" class="fw-bold">
            --:--
            <!-- {{
            ( today | date: 'HH:mm')
            }} -->
        </span>
        <span *ngIf="planned_departure_time" class="fw-bold">
            {{
            ( planned_departure_time)
            }}
        </span>
    </div>
    <div *ngIf="indexPointLine === 0" class="col-1 point-and-line d-flex flex-column align-items-center">
        <div class="rounded-circle d-flex align-items-center justify-content-center bg-info text-white"
            style="width: 35px;height: 35px;">
            <i-bs name="geo-alt-fill"></i-bs>
        </div>
    </div>
    <div *ngIf="indexPointLine !== 0 " class="col-1 point-and-line d-flex flex-column align-items-center cursor"
        (click)="openDetailPoint(point_of_delivery, indexPointLine)">
        <div class="rounded-circle d-flex align-items-center justify-content-center bg-info text-white"
            style="width: 35px;height: 35px;">
            <i class="fa-solid fa-truck-fast"></i>
        </div>
    </div>
    <div *ngIf="indexPointLine === 0"
        class="col-2 d-flex align-items-center justify-content-center text-white p-2 bg-secondary rounded-2">
        <span *ngIf="point_of_delivery_name" class="fw-bold text-center">
            {{point_of_delivery_name}}
        </span>
        <span *ngIf="!point_of_delivery_name" class="fw-bold text-center">
            {{"Non défini"}}
        </span>
    </div>
    <div *ngIf="indexPointLine !== 0"
        class="col-2 d-flex align-items-center justify-content-center text-white p-2 bg-info rounded-2 cursor"
        (click)="openDetailPoint(point_of_delivery, indexPointLine)">
        <span *ngIf="point_of_delivery_name" class="fw-bold text-center">
            {{point_of_delivery_name}}
        </span>
        <span *ngIf="!point_of_delivery_name" class="fw-bold text-center">
            {{"Non défini"}}
        </span>
    </div>
    <div class="col-1 d-flex-align-item-center justify-content-center">
        <span *ngIf="indexPointLine !== 0" (click)="removePoint(indexPointLine)" class="text-danger d-block cursor">
            <i-bs name="x-circle-fill"></i-bs>
        </span>
    </div>
</div>

<div *ngIf="!point_of_delivery_detail" class="row d-flex justify-content-center align-items-center flex-column">
    <div class="col-3 bg-white" style="height: 60px; width: 8px; padding: 0;">
    </div>
    <div *ngIf="indexPointLine === pointLinesLength - 1" (click)="addPoint()"
        class="d-flex bg-info rounded-circle cursor align-items-center justify-content-center"
        style="width: 35px;height: 35px; opacity: .6;">
        <i-bs name="plus-square-fill" class="text-white"></i-bs>
    </div>
</div>


<!-- Tour detail time line -->
<div *ngIf="point_of_delivery_detail && indexPointLine === 0"
    class="row d-flex flex-nowrap justify-content-center align-items-center">
    <div class="col-2 d-flex flex-column align-items-end">
        <span>
            {{
            (start_date | date:
            'dd/MM/YYY')
            }}
        </span>
        <span class="fw-bold">
            {{
            (start_date | date:
            'HH:mm')
            }}
        </span>
    </div>
    <div class="col-1 point-and-line d-flex flex-column align-items-center">
        <div class="rounded-circle d-flex align-items-center justify-content-center bg-info text-white"
            style="width: 35px;height: 35px;">
            <i-bs name="geo-alt-fill"></i-bs>
        </div>
    </div>
    <div class="col-2 bg-secondary d-flex align-items-center justify-content-center text-white p-2 rounded-2">
        <span class="fw-bold text-center">
            {{point_of_delivery_detail.departure_point.name}}
        </span>
    </div>
</div>
<div *ngIf="indexPointLine === 0 && point_of_delivery_detail"
    class="row d-flex justify-content-center align-items-center flex-column">
    <div class="col-4 bg-white" style="height: 60px; width: 8px; padding: 0;">
    </div>
</div>


<div *ngIf="point_of_delivery_detail" class="row d-flex flex-nowrap justify-content-center align-items-center cursor">
    <div (click)="openDetailPoint(point_of_delivery_detail, indexPointLine)"
        class="col-2 d-flex flex-column align-items-end">
        <span>
            {{
            (point_of_delivery_detail.planned_departure | date:
            'dd/MM/YYY')
            }}
        </span>
        <span class="fw-bold">
            {{
            (point_of_delivery_detail.planned_departure | date:
            'HH:mm')
            }}
        </span>
    </div>
    <div class="col-1 point-and-line d-flex flex-column align-items-center cursor"
        (click)="openDetailPoint(point_of_delivery_detail, indexPointLine)">
        <div class="rounded-circle d-flex align-items-center justify-content-center bg-light"
            style="width: 35px;height: 35px;">
            <i class="fa-solid fa-truck-fast" [ngClass]="{'status-cancelled': point_of_delivery_detail.status === EPointOfDeliveryStatus.CANCELED,
            'status-received': point_of_delivery_detail.status === EPointOfDeliveryStatus.RECEIVED,
            'status-sended': point_of_delivery_detail.status === EPointOfDeliveryStatus.SENDED,
            'status-to-send': point_of_delivery_detail.status === EPointOfDeliveryStatus.TO_SEND}"></i>

        </div>
    </div>
    <div (click)="openDetailPoint(point_of_delivery_detail, indexPointLine)"
        class="col-2 d-flex align-items-center justify-content-center p-2 rounded-2 cursor" [ngClass]="
    {'bg-status-cancelled': point_of_delivery_detail.status === EPointOfDeliveryStatus.CANCELED,
    'bg-status-received': point_of_delivery_detail.status === EPointOfDeliveryStatus.RECEIVED,
    'bg-status-sended text-dark': point_of_delivery_detail.status === EPointOfDeliveryStatus.SENDED,
    'bg-status-to-send': point_of_delivery_detail.status === EPointOfDeliveryStatus.TO_SEND}">
        <span class="fw-bold text-center">
            {{point_of_delivery_detail.arrival_point.name}}
        </span>
    </div>
</div>
<div *ngIf="indexPointLine !== pointLinesLength - 1 && point_of_delivery_detail"
    class="row d-flex justify-content-center align-items-center flex-column">
    <div class="col-4 bg-white" style="height: 60px; width: 8px; padding: 0;">
    </div>
</div>