import { Performance } from './../../../models/performance';
import { CustomDateParserFormatter, CustomAdapter } from 'src/app/utils/datepicker-adapter';
import { PerformanceService } from '../../../services/performance.service';
import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PointOfSale } from 'src/app/models/point-of-sale';
import { Sector } from 'src/app/models/sector';
import { PointOfSaleService } from 'src/app/services/point-of-sale.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SectorService } from 'src/app/services/sector.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'home-performances',
  templateUrl: './home-performances.component.html',
  styleUrls: ['./home-performances.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class HomePerformancesComponent implements OnInit {

  pointOfSales: PointOfSale[] = [];
  performances: Performance[] = [];
  sectors: Sector[] = [];
  sector: undefined | number;
  loading: boolean = false;
  startDate!: string;
  endDate!: string;
  currentWeekDay!: string
  minDate: any;
  maxDate: any;


  onStartDateChange(date: any): void {
  }

  onEndDateChange(date: any): void {
  }

  constructor(
    private performanceService: PerformanceService,
    private pointOfSaleService: PointOfSaleService,
    private sectorService: SectorService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.route
      .queryParams
      .subscribe(params => {
        this.startDate = params['start_date']
        this.endDate = params['end_date']
        this.dateAdapter.fromModel(this.startDate)
      });

    this.startDate = this.startDate || this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
    this.endDate = this.endDate || this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
    this.minDate = this.dateAdapter.fromModel(this.startDate);
    this.maxDate = this.dateAdapter.fromModel(this.endDate);
    // this.getPointOfSales()
    this.getSectors()
  }

  getPointOfSales(): void {
    const params: any = {}

    if (this.sector !== undefined) {
      params['sector_id'] = this.sector
    }

    this.loading = true
    this.pointOfSaleService.getPointOfSalesSimple(Object.keys(params).length > 0 ? params : undefined)
      .subscribe({
        next: (pointOfSales) => {
          this.loading = false
          this.pointOfSales = pointOfSales

          this.getPerformances()
        },
        error: (e: any) => {
          this.loading = false
        }
      })

  }

  getSectors(): void {
    this.sectorService.getSectors()
      .subscribe(sectors => {
        this.sectors = sectors
        if (this.route.snapshot.queryParamMap.get('sector_id')) {
          this.sector = Number(this.route.snapshot.queryParamMap.get('sector_id'))
          this.getPointOfSales()
        }
        this.getPointOfSales()

      })
  }

  getPerformances() {
    this.spinner.show()
    const params: any = {}

    if (this.startDate) {
      params['start_date'] = this.startDate
    }

    if (this.endDate) {
      params['end_date'] = this.endDate
    }

    if (this.sector !== undefined) {
      params['sector_id'] = this.sector
    }

    this.loading = true
    this.performanceService.getPerformances(Object.keys(params).length > 0 ? params : undefined)
      .subscribe({
        next: (pointOfSalesPerformances) => {
          this.loading = false
          this.performances = pointOfSalesPerformances
          this.spinner.hide()
        },
        error: (e: any) => {
          this.loading = false
          this.spinner.hide()
        }
      })

  }

  onDateChange(date: any): void {
    this.minDate = this.dateAdapter.fromModel(this.startDate);
    this.maxDate = this.dateAdapter.fromModel(this.endDate);
    this.getPointOfSales()

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { start_date: this.startDate, end_date: this.endDate, sector_id: this.sector },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  onSectorChange(sectorId: any): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { start_date: this.startDate, end_date: this.endDate, sector_id: this.sector },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.getPointOfSales()
  }

  getCA(point_of_sale_id: number) {
    const perf = this.performances.find(perf =>  perf.point_of_sale_id == point_of_sale_id)
    return perf !== undefined ? perf!.sale_total_amount  : 0
  }

  getReturns(point_of_sale_id: number): number {
    const perf = this.performances.find(perf =>  perf.point_of_sale_id == point_of_sale_id)
    return perf?.return_total_amount !== undefined ? perf?.return_total_amount : 0
  }

  getReturnPercentage(point_of_sale_id:  number): number | string {
    const perf = this.performances.find(perf =>  perf.point_of_sale_id == point_of_sale_id)
    return  perf !== undefined ? perf!.sale_total_amount !== 0 ? (perf!.return_total_amount / perf!.sale_total_amount * 100).toFixed(2) : 0.00 : 0.00
  }

  getCATotal() {
    const caTotal = this.performances.reduce((a: any, b: any) => a + (b.sale_total_amount), 0)
    return caTotal
  }

  getTotalReturns(): number {
    const perf = this.performances.reduce((a: any, b: { return_total_amount: any; }) => a + b.return_total_amount, 0)
    return perf || 0
  }

  getReturnPercentageTotal(): number | string {
    return (this.getTotalReturns() / this.getCATotal() * 100).toFixed(2) || 0
  }
}
