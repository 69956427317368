import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollapseService {
  private selectedSubject: BehaviorSubject<String> =
    new BehaviorSubject<String>('');
  public selected!: Observable<String>;

  constructor() {
    this.selected = this.selectedSubject.asObservable();
  }

  setSelected(selected: String) {
    this.selectedSubject.next(selected);
  }

  public get selectedValue() {
    return this.selectedSubject.value;
  }
}
