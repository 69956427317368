import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { AuthenticationGuardService } from 'src/app/services/authentication-guard.service';
import { OrderComponent } from './order.component';
import { ListOrderComponent } from './list-order/list-order.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { RoleGuard } from 'src/app/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'orders',
        component: OrderComponent,
        canActivate: [AuthenticationGuardService],
        children: [
          { path: '', component: ListOrderComponent },
          {
            path: ':point-of-sale-id/new',
            component: NewOrderComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: ['admin', 'Précommandeur'],
            },
          },
          {
            path: ':point-of-sale-id',
            component: ViewOrderComponent,
            canActivate: [AuthenticationGuardService, RoleGuard],
            data: {
              roles: ['admin', 'Précommandeur'],
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class OrderRoutingModule {}
