import { buildParams } from './../utils/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPointOfSaleStatsData, IPointOfSaleStatsDTO, PointOfSale } from '../models/point-of-sale';
import Config from '../utils/config';
import { PointOfSalePaginationInterface } from '../interfaces/point-of-sale-interface';

@Injectable({
  providedIn: 'root',
})
export class PointOfSaleService {
  constructor(private http: HttpClient) {}

  /* Appel de l'enpoint qui permet de lister tous les points de vente ou par secteur(mt) */
  pointOfSalesList(data: any) {
    return this.http.post<PointOfSale[]>(
      `${Config.baseUrl}/mt/point_of_sales_list`,
      data
    );
  }

  getPointOfSale(id: number): Observable<PointOfSale> {
    return this.http.get<PointOfSale>(
      `${Config.baseUrlV2}/point_of_sales/${id}`
    );
  }

  getPointOfSales(params: any = undefined): Observable<PointOfSale[]> {
    const url = 'mt/admin/point_of_sales' + buildParams(params);
    return this.http.get<PointOfSale[]>(`${Config.baseUrl}/${url}`);
  }

  getStatsPointOfSaleById(
    id: number,
    params: IPointOfSaleStatsDTO
  ): Observable<any> {
    return this.http.post<IPointOfSaleStatsData>(
      `${Config.baseUrlV2}/point_of_sale_stats/${id}`,
      params
    );
  }

  getPointOfSalesSimple(params: any = undefined): Observable<PointOfSale[]> {
    const url = '/mt/admin/point-of-sales' + buildParams(params);
    return this.http.get<PointOfSale[]>(`${Config.baseUrl}/${url}`);
  }

  getPointOfSaleWithPaginationAndFilters(
    filter: PointOfSalePaginationInterface
  ) {
    return this.http.post<any>(`${Config.baseUrlV2}/point_of_sales`, filter);
  }

  updateTradeSchedule(data: {
    id: number;
    preorder_day_number: number;
    delivery_day_number: number;
  }) {
    return this.http.put<any>(
      `${Config.baseUrl}/mt/admin/update_trade_schedule/${data.id}`,
      data
    );
  }

  addTradeSchedule(data: {
    preorder_day_number: number;
    delivery_day_number: number;
  }) {
    return this.http.post<any>(
      `${Config.baseUrl}/mt/admin/create_trade_schedule`,
      data
    );
  }

  updatePointOfSale(data: any) {
    return this.http.put<any>(`${Config.baseUrlV2}/point_of_sales`, data);
  }

  importDeliveryDays(
    payload: {
      qr_code: string;
      name: string;
      delivery_days: string[];
      sector: number;
      lat: string | number;
      lng: string | number;
    }[]
  ) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/update_point_of_sales_using_imports`,
      { payload }
    );
  }
}
