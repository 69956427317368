import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LogisticTour } from 'src/app/models/logistic-tour';
import { EPointOfDeliveryStatus, PointOfDelivery, StartLogisticTourDto } from 'src/app/models/point-of-delivery';
import { askedValidatorFactory, isDateTime1AfterDateTime2 } from 'src/app/utils';
import { extractDateAndTime, calculPallet, translatePointOfDeliveryStatus } from 'src/app/utils/helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-start-tour-flow',
  templateUrl: './start-tour-flow.component.html',
  styleUrls: ['./start-tour-flow.component.scss']
})
export class StartTourFlowComponent implements OnInit {
  @Input() logisticTour!: LogisticTour;
  @Input() isOpenModal: boolean = false;
  @Output() closeModalStartTour = new EventEmitter();
  @Output() validatePoints = new EventEmitter<StartLogisticTourDto[]>();

  index: number = 0;
  formPointOfDelivery!: FormGroup;
  selectedPointOfDelivery!: PointOfDelivery;
  point_of_deliveries: StartLogisticTourDto[] = [];
  pointOfDeliveries: PointOfDelivery[] = [];

  error: string = '';
  message: string = '';
  loading: boolean = false;
  today = new Date();


  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initFormLoadProducts();
    Object.assign(this.pointOfDeliveries, this.logisticTour.point_of_deliveries);
    this.selectedPointOfDelivery = this.pointOfDeliveries[this.index];
    this.openModalDetailPoint(this.selectedPointOfDelivery, this.index);
  }

  initFormLoadProducts() {
    this.formPointOfDelivery = this.formBuilder.group({
      planned_departure: ['', Validators.required],
      planned_departure_time: ['', Validators.required],
      departure_point: ['', Validators.required],
      arrival_point: ['', Validators.required],
      planned_arrival: ['', Validators.required],
      planned_arrival_time: ['', Validators.required],
      delivered_products: this.formBuilder.array([]),
    });
  }

  openModalDetailPoint(point_of_delivery: PointOfDelivery, index: number) {
    const dateAndTimeDeparture = this.getDateAndTimeFromLongDate(point_of_delivery.planned_departure);
    const dateAndTimeArrival = this.getDateAndTimeFromLongDate(point_of_delivery.planned_arrival);
    this.formPointOfDelivery = this.formBuilder.group({
      planned_departure: [dateAndTimeDeparture.date, Validators.required],
      planned_departure_time: [dateAndTimeDeparture.time, Validators.required],
      departure_point: [point_of_delivery.departure_point.id, Validators.required],
      arrival_point: [point_of_delivery.arrival_point.id, Validators.required],
      planned_arrival: [dateAndTimeArrival.date, Validators.required],
      planned_arrival_time: [dateAndTimeArrival.time, Validators.required],
      delivered_products: this.formBuilder.array([]),
    });
    const delivered_products = this.getLoadProductsValues()
    for (const product of point_of_delivery.point_of_delivery_items) {
      let productLoaded = this.point_of_deliveries[index]?.point_of_delivery_items.find((products) => products.product_id === product.product.id)
      let value: FormGroup = this.formBuilder.group({
        product_name: new FormControl(product.product.sku_sage, Validators.required),
        product_id: new FormControl(product.product.id, Validators.required),
        asked: new FormControl(product.asked, [Validators.min(0)]),
        loaded: new FormControl(productLoaded?.loaded || product.asked, [Validators.required, Validators.min(1), askedValidatorFactory()]),
        pallet: new FormControl(Math.ceil(product.asked / product.product.pallet), [Validators.min(0)]),
      });
      delivered_products.push(value);
    }
    this.isOpenModal = true;
  }

  getPalletCountForProduct(productLoad: any) {
    let product = this.selectedPointOfDelivery.point_of_delivery_items.find((p) => p.product.id === Number(productLoad.value.product_id)) || undefined;
    let pallet = calculPallet(productLoad.value.loaded, product ? product.product.pallet : 100);
    productLoad.controls['pallet'].setValue(pallet)
  }

  getLoadProductsValues() {
    return this.formPointOfDelivery.get('delivered_products') as FormArray;
  }

  getDateAndTimeFromLongDate(dateTime: string) {
    return extractDateAndTime(dateTime);
  }

  translatePointOfDeliveryStatus(status: EPointOfDeliveryStatus) {
    return translatePointOfDeliveryStatus(status)
  }

  checkValidityPlannedDate(index: number, point_of_delivery: PointOfDelivery) {
    const previousPoint = index > 0 ? this.pointOfDeliveries[index - 1] : null;
    const nextPoint = index < this.pointOfDeliveries.length ? this.pointOfDeliveries[index + 1] : null;
    if (index === 0) {
      if (!isDateTime1AfterDateTime2(point_of_delivery.planned_departure, this.logisticTour.start_date)) {
        return {
          validity: false,
          message: 'La date de départ du point est inférieure à la date de démarrage de la tournée logistique.'
        }
      }
      else if (!isDateTime1AfterDateTime2(point_of_delivery.planned_arrival, point_of_delivery.planned_departure)) {
        return {
          validity: false,
          message: "La date d'arrivée ne doit pas être inférieure à la date de départ"
        }
      } else {
        return {
          validity: true,
          message: ''
        }
      }
    } else if (index > 0) {
      if (previousPoint && !isDateTime1AfterDateTime2(point_of_delivery.planned_departure, previousPoint.planned_arrival)) {
        return {
          validity: false,
          message: "La date départ ne doit pas être inférieure à la date d'arrivée du point précédent."
        }
      }
      if (!isDateTime1AfterDateTime2(point_of_delivery.planned_arrival, point_of_delivery.planned_departure)) {
        return {
          validity: false,
          message: "La date d'arrivée ne doit pas être inférieure à la date départ du point"
        }
      }
      else {
        return {
          validity: true,
          message: ''
        }
      }
    } else {
      return {
        validity: true,
        message: ''
      }
    }
  }

  nextPoint() {
    this.patchValuechangePointStartTour();
    const validityPointOfDelivery = this.checkValidityPlannedDate(this.index, this.selectedPointOfDelivery);
    if (validityPointOfDelivery.validity) {
      this.patchValueStartTour();
      this.index += 1;
      this.selectedPointOfDelivery = this.pointOfDeliveries[this.index];
      this.openModalDetailPoint(this.selectedPointOfDelivery, this.index);
    }
    else Swal.fire({
      title: 'Erreur!',
      text: validityPointOfDelivery.message,
      icon: 'error',
    });
    this.selectedPointOfDelivery = this.pointOfDeliveries[this.index];
  }

  previousPoint() {
    this.patchValueStartTour();
    this.index -= 1;
    this.selectedPointOfDelivery = this.pointOfDeliveries[this.index];
    this.openModalDetailPoint(this.selectedPointOfDelivery, this.index);
  }

  validatePointsOfDelivery() {
    this.patchValuechangePointStartTour();
    const validityPointOfDelivery = this.checkValidityPlannedDate(this.index, this.selectedPointOfDelivery);
    if (validityPointOfDelivery.validity) {
      this.patchValueStartTour();
      this.validatePoints.emit(this.point_of_deliveries)
    }
    else Swal.fire({
      title: 'Erreur!',
      text: validityPointOfDelivery.message,
      icon: 'error',
    });
    this.selectedPointOfDelivery = this.pointOfDeliveries[this.index];
  }

  patchValueStartTour() {
    this.point_of_deliveries[this.index] = {
      id: this.selectedPointOfDelivery.id,
      planned_departure: `${this.formPointOfDelivery.value.planned_departure} ${this.formPointOfDelivery.value.planned_departure_time}:00`,
      planned_arrival: `${this.formPointOfDelivery.value.planned_arrival} ${this.formPointOfDelivery.value.planned_arrival_time}:00`,
      point_of_delivery_items: this.formPointOfDelivery.value.delivered_products.map((product: any) => ({
        product_id: product.product_id,
        loaded: product.loaded,
      }))
    };
  }

  patchValuechangePointStartTour() {
    this.pointOfDeliveries[this.index] = {
      ...this.pointOfDeliveries[this.index],
      planned_departure: `${this.formPointOfDelivery.value.planned_departure} ${this.formPointOfDelivery.value.planned_departure_time}:00`,
      planned_arrival: `${this.formPointOfDelivery.value.planned_arrival} ${this.formPointOfDelivery.value.planned_arrival_time}:00`,
      point_of_delivery_items: this.pointOfDeliveries[this.index].point_of_delivery_items,
    };
    this.selectedPointOfDelivery = this.pointOfDeliveries[this.index]
  }

  closeModal(event: boolean) {
    this.isOpenModal = event;
    this.closeModalStartTour.emit();
  }
}
