import { Forecast } from './../models/forecast';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';
import { buildParams } from '../utils';
import { ILoadingRequestParam } from '../interfaces/forecast-interface';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  constructor(private http: HttpClient) {}

  getForecasts(params: any): Observable<any> {
    return this.http.get<any>(
      `${Config.baseUrl}/mt/forecasts${buildParams(params)}`
    );
  }

  putForecasts(params: any): Observable<any> {
    return this.http.put<any>(`${Config.baseUrl}/forecasts`, params);
  }

  validateForecasts(params: {sector_id: number, date: string, supervisor: number}): Observable<any> {
    return this.http.post<any>(
      `${Config.baseUrl}/forecasts/validate`,
      params
    );
  }

  invalidateForecasts(params: {sector_id: number, date: string, supervisor: number, tournee_id: number}): Observable<any> {
    return this.http.post<any>(
      `${Config.baseUrl}/forecasts/invalidate`,
      params
    );
  }

  getForecastsTradi(filter: any) {
    return this.http.post<any>(
      `${Config.baseUrl}/forecasts_by_sector_tradi`,
      filter
    );
  }

  getLoadingRequestByDepotByDate(params: ILoadingRequestParam) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/forecasts_by_product_by_sector_by_depot`,
      params
    );
  }

  getLoadingLogisticsByDepotByDate(params: ILoadingRequestParam) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/loadings_logistics_by_product_by_sector_by_depot`,
      params
    );
  }

  getAdditionnalLoadingLogisticsByDepotByDate(params: ILoadingRequestParam) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/additionnal_loading_by_product_by_depot_by_date`,
      params
    );
  }

  getZoneSupervisorLoadingRequest(params: any) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/get_loading_request_by_product_by_zone_supervisor`,
      params
    );
  }

  update_loading_request_by_shif_supervisor(
    loadings: {
      sector_id: number;
      asked: number;
      loading_id: number;
    }[]
  ) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/loadings/update_request_loading_shif_supervisor`,
      loadings
    );
  }

  getProductAverageSalesLastTwoWeeksBySectors(payload: {
    sku_sage: string;
    sector_ids: number[];
  }) {
    return this.http.post<{ calculate: any; sector_id: number }[]>(
      `${Config.baseUrl}/product_average_sales_last_two_weeks_by_sectors`,
      payload
    );
  }
}
