import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsGuardedComponent } from './is-guarded.component';
import { IsGrantedDirective } from 'src/app/directives/is-granted.directive';

@NgModule({
  declarations: [IsGrantedDirective, IsGuardedComponent],
  imports: [CommonModule],
  exports: [IsGrantedDirective, IsGuardedComponent],
})
export class IsGuardedModule {}
