import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionEventType, ColumnDef } from 'src/app/utils';
import { trunkText } from 'src/app/utils/helpers';

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
})
export class CellComponent implements OnInit {
  @Input() column!: ColumnDef;
  @Input() rowData!: any;
  cellStyle: any;
  buttonStyle: any;
  @Output() actionInvoqueFunctionEvent = new EventEmitter<ActionEventType>();
  @Output() onClickCellValueEvent = new EventEmitter<
    string | number | boolean
  >();

  constructor() {}

  ngOnInit(): void {
    this.cellStyle =
      this.column.type === 'status'
        ? {
            ...this.column.cellStyle,
            ...(this.rowData.statusColor && {
              'background-color': this.rowData.statusColor,
            }),
            ...(this.rowData.statusTextColor && {
              color: this.rowData.statusTextColor,
            }),
          }
        : { ...this.column.cellStyle };
  }

  clickCellValueEvent(column: any) {
    if (column.clickable) {
      this.onClickCellValueEvent.emit(
        column.parent
          ? this.rowData[column.parent]
            ? this.rowData[column.parent][column.name]
            : ''
          : this.rowData[column.name]
          ? this.rowData[column.name]
          : ''
      );
    }
  }

  invoqueActionsFunction(value: ActionEventType) {
    this.actionInvoqueFunctionEvent.emit(value);
  }

  setActionButtonColor(rowData: any, action: any, index: number) {
    let styleColor: any = {};
    if (action.isToogleButton && action.actionToogleableIndex === index) {
      const value = action.contextActionStyle;
      if (rowData[action.contextualPropertyToCheck]) {
        styleColor = this.setButtonStyle(value['negatifStyle']);
      } else {
        styleColor = this.setButtonStyle(value['positifStyle']);
      }
    }

    return styleColor;
  }

  getContextualButtonText(rowData: any, action: any) {
    if (rowData[action.contextualPropertyToCheck])
      return action.contextActionText.negatif;
    else return action.contextActionText.positif;
  }

  setButtonStyle(value: any) {
    const styles: any = {};
    styles['background-color'] = value.color;
    styles['border-color'] = value.color;
    styles['min-width'] = '25%';
    return styles;
  }

  callRenderTemplate(value: any) {
    return this.column.renderTemplate(value);
  }

  trunkText(text: string, length: number = 15) {
    return trunkText(text, length);
  }
}
