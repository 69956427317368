import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { CollapseService } from 'src/app/services/collapse.service';
import { MenuItem } from 'src/app/utils';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss'],
})
export class NavLinkComponent implements OnInit {
  @Input() menu!: MenuItem;
  @Input() resized: boolean = false;
  public isOpen = false;

  constructor(
    private collapseService: CollapseService,
    private router: Router,
    private currentUser: AuthenticationService,
  ) {
    this.collapseService.selected.subscribe((value) => {
      if (this.menu !== undefined) {
        if (value !== this.menu.collapsedId) {
          this.isOpen = false;
        } else {
          this.isOpen = true;
        }
      }
    });
  }

  ngOnInit(): void {
    this.findActiveRoute();
  }

  public setSelectedLink() {
    if (!this.isOpen) {
      this.collapseService.setSelected(this.menu.collapsedId!);
    } else {
      this.collapseService.setSelected('');
    }
  }

  public get selected() {
    return this.collapseService.selectedValue;
  }

  public get isMenuOpened(): boolean {
    return this.isOpen && this.selected === this.menu.collapsedId;
  }

  public findActiveRoute() {
    if (this.menu.children !== undefined) {
      this.menu.children.forEach((child) => {
        if (child.url !== '') {
          if (child.url === this.router.url.replace('/', '')) {
            this.collapseService.setSelected(this.menu.collapsedId!);
          }
        }
      });
    }
  }

  canViewMenu(menu: MenuItem) {
    return (
      menu.authorizedRole.includes(this.currentUser.currentUserValue.role?.libelle!) ||
      menu.authorizedRole.includes('all')
    );
  }
}
