import { Pipe, PipeTransform } from '@angular/core';
import { Days } from '../../helpers';
import { DAYSOFWEEK } from '../..';

@Pipe({
  name: 'numberToDay'
})
export class NumberToDayPipe implements PipeTransform {
  days: Days[] = DAYSOFWEEK;
  transform(value: number, ...args: unknown[]): String {
    const day = this.days.find(
      (day) => day.id.toString() === value.toString()
    );
    return day ? day.label : value.toString();
  }

}
