import { NgModule } from "@angular/core";
import { ImportCoordinatesComponent } from "./import-coordinates.component";
import { CommonModule } from "@angular/common";
import { ModalComponent } from "../modal/modal.component";
import { NgxBootstrapIconsModule, arrowUp, circleFill, search } from "ngx-bootstrap-icons";

const icons = {
     search,
     circleFill,
     arrowUp,
};
@NgModule({
     declarations: [ImportCoordinatesComponent],
     imports: [
          CommonModule,
          ModalComponent,
          NgxBootstrapIconsModule.pick(icons),
     ],
     exports: [ImportCoordinatesComponent],
})

export class ImportCoordinatesModule {}
   