import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Config from '../utils/config';
import { Observable } from 'rxjs';
import { OtherListDTO } from '../dtos/other.dtos';
import { PaginationInterface } from '../interfaces/pagination-interface';

@Injectable({
  providedIn: 'root',
})
export class OtherService {
  constructor(private http: HttpClient) {}

  getOthers(params: any): Observable<OtherListDTO> {
    return this.http.get<OtherListDTO>(`${Config.baseUrlV2}/others`, {
      params: params,
    });
  }

  addOther(other: any) {
    return this.http.post<any>(`${Config.baseUrlV2}/others`, {
      other: other,
    });
  }

  updateOther(other: any) {
    return this.http.put<any>(`${Config.baseUrlV2}/others/${other['id']}`, {
      other: other,
    });
  }

  deleteOther(other: any) {
    return this.http.delete<any>(`${Config.baseUrlV2}/others/${other['id']}`);
  }
}
