import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import {
  NgxBootstrapIconsModule,
  chevronLeft,
  chevronRight,
} from 'ngx-bootstrap-icons';

const icons = {
  chevronLeft,
  chevronRight,
};
@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, NgxBootstrapIconsModule.pick(icons)],
  exports: [PaginationComponent],
})
export class PaginationModule {}
