<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <app-go-back [marginClass]="''"></app-go-back>
    <h1 class="h3 text-info mx-2 my-auto">Gestion des pays</h1>
  </div>
  <div>
    <a
      class="btn btn-info btn-sm text-sm text-white fw-bold mx-3"
      (click)="openCreateModal()"
    >
      Nouveau Pays
    </a>
    <a
      class="btn btn-info btn-sm text-sm text-white fw-bold mx-3"
      (click)="openImportModal()"
    >
      Importer les coordonnées
    </a>
    <button
      class="btn btn-info btn-sm text-sm text-white fw-bold mx-3"
      (click)="redirectToMap()"
    >
      Voir la carte <i-bs name="map"> </i-bs>
    </button>
  </div>
</div>

<div>
  <app-table
    *ngIf="countries$ | async as countries"
    [columnDefs]="columnDefs"
    [tableData]="countries"
    (actionInvoqueFunctionEvent)="tableActionsFunction($event)"
  >
  </app-table>
  <ng-template [ngIf]="error$ | async">
    <app-empty-card
      [icon]="'exclamation-circle'"
      [message]="'Aucun pays trouvé'"
    ></app-empty-card>
  </ng-template>
  <app-pagination
    *ngIf="(countries$ | async)?.length"
    [pagination]="pagination"
    (paginationEvent)="paginate($event)"
  ></app-pagination>
</div>

<modal #nGmodal [modalConfig]="modalConfig" class="modal-dialog-centered">
  <form
    *ngIf="modalFormType === 'CREATE' || modalFormType === 'UPDATE'"
    [formGroup]="modalForm"
  >
    <div class="mb-4">
      <label for="name" class="form-label fw-bold text-secondary">Pays: </label>
      <select
        (change)="selectPays()"
        id="name"
        formControlName="name"
        class="form-control form-select"
        aria-label="Choisir un pays"
      >
        <option [ngValue]="''" selected>Choisir un pays</option>
        <option *ngFor="let country of allCountries" [ngValue]="country.name">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div class="row">
      <div class="col-6 mb-3">
        <label for="iso" class="form-label fw-bold text-secondary">ISO:</label>
        <input
          type="text"
          id="iso"
          class="form-control border rounded"
          formControlName="iso"
        />
      </div>

      <div class="col-6 mb-3">
        <label for="currency_iso" class="form-label fw-bold text-secondary"
          >Devise Monétaire</label
        >
        <input
          type="text"
          id="currency_iso"
          class="form-control border rounded"
          formControlName="currency_iso"
        />
      </div>
    </div>
  </form>
  <p *ngIf="modalFormType === 'DELETE'">
    <strong>Voulez-vous vraiment supprimer ce pays</strong>
  </p>
  <p class="mt-4" *ngIf="!modalFormDisplay && modalFormStatusActionMessage">
    {{ modalFormStatusActionMessage }}
  </p>
</modal>

<app-import-coordinates
  (dataReadyForImport)="importData($event)"
  [entity]="'COUNTRY'"
>
</app-import-coordinates>
