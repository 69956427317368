import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private http: HttpClient) {}

  getAllCountriesWithoutPagination() {
    return this.http.get<any>(`${Config.baseUrl}/countries`);
  }

  importCoordinatesData(data: any) {
    return this.http.post<any>(`${Config.baseUrlV2}/import_country_coordinates`, data);
  }
}
