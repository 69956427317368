import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged, zip } from 'rxjs';
import {
  IAccountingFilter,
  IGroupedSellerTransaction,
} from 'src/app/interfaces/transaction-interface';
import { SellerReference } from 'src/app/models/seller-references';
import { Transaction } from 'src/app/models/transaction';
import { SellerReferencesService } from 'src/app/services/seller-references.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import {
  ColumnDef,
  EColumnType,
  ETransactionType,
  formatDate,
  sortArray,
} from 'src/app/utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-accounting-entries',
  templateUrl: './accounting-entries.component.html',
  styleUrls: ['./accounting-entries.component.scss'],
})
export class AccountingEntriesComponent implements OnInit {
  /* Globals */
  error: string = '';
  message: string = '';

  /* Account entries */
  loadingAccountEntries: boolean = false;
  transactionNumberWithNotFoundSellerReference: any[] = [];
  allGroupedTransactionBySeller: IGroupedSellerTransaction[] = [];
  groupedTransactionBySellerToDisplay: IGroupedSellerTransaction[] = [];

  /* Filters */
  searchFormControl: FormControl = new FormControl('');
  dateFilterControl!: FormControl;
  filter: IAccountingFilter = { page: 1, limit: 1000 };
  transactionTypeToSelect: ETransactionType[] = [
    ETransactionType.DEPOSIT,
    ETransactionType.COMMERCIALPAYMENT,
  ];

  /* Column definition */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Date',
      name: 'date',
      type: EColumnType.DATE,
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Employé',
      name: 'libelle',
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Téléphone',
      name: 'business_user_mobile',
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Libellé frais',
      name: 'libelle_fee',
      cellStyle: { 'font-weight': '100' },
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Libellé versement',
      name: 'libelle_versement',
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Money',
      name: 'currency',
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Montant',
      name: 'amount',
      type: EColumnType.MONEY,
      hearderPositionClass: ['text-end'],
      positionClass: ['text-end'],
      cellTextStyle: ['text-black'],
    }),
    new ColumnDef({
      label: 'Frais',
      name: 'fee',
      type: EColumnType.MONEY,
      hearderPositionClass: ['text-end'],
      positionClass: ['text-end'],
      cellTextStyle: ['text-black'],
    }),
  ];

  /* Modal */
  @ViewChild('errorModalContent') errorModalContent: any;

  constructor(
    private transactionsService: TransactionsService,
    private sellerReferencesService: SellerReferencesService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    const today = this.ngbCalendar.getToday();
    this.dateFilterControl = new FormControl(this.dateAdapter.toModel(today));
    this.getAccountingEntries(this.filter);
    this.onDateControlChange();
    this.onSearchControlChange();
  }

  onSearchControlChange() {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.searchTransactions(searchTerm);
      });
  }

  searchTransactions(searchTerm: string) {
    if (!searchTerm) {
      this.groupedTransactionBySellerToDisplay =
        this.allGroupedTransactionBySeller;
    } else {
      this.groupedTransactionBySellerToDisplay =
        this.allGroupedTransactionBySeller.filter(
          (transaction) =>
            transaction.business_user_mobile.includes(
              searchTerm.toLocaleLowerCase().trim()
            ) ||
            transaction.libelle
              .toLocaleLowerCase()
              .includes(searchTerm.toLocaleLowerCase().trim())
        );
    }
  }

  onDateControlChange() {
    this.dateFilterControl.valueChanges.subscribe((date) => {
      this.filter = { ...this.filter, date };
      this.getAccountingEntries(this.filter);
    });
  }

  getAccountingEntries(filter: IAccountingFilter) {
    this.loadingAccountEntries = true;
    this.showSpinner();
    const transactions = this.transactionsService.getTransactions({
      ...(filter.date && { date: filter.date }),
    });
    const sellerReferences = this.sellerReferencesService.getSellerReferences({
      page: filter.page,
      limit: filter.limit,
    });
    zip(transactions, sellerReferences).subscribe({
      next: (response) => {
        const data = this.formatAccountingEntriesData(response);
        this.allGroupedTransactionBySeller = data.allGroupedTransactionBySeller;
        this.searchTransactions(this.searchFormControl.value);
        this.transactionNumberWithNotFoundSellerReference =
          data.transactionNumberWithNotFoundSellerReference;
        this.loadingAccountEntries = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des données de transactions. Merci de réessayer`;
        this.loadingAccountEntries = false;
        this.showSpinner();
      },
    });
  }

  formatAccountingEntriesData(accountingEntries: any[]) {
    const transactions = accountingEntries[0].items.filter(
      (transaction: Transaction) =>
        this.transactionTypeToSelect.includes(transaction.type)
    );
    const sellerReferences = accountingEntries[1].seller_references;
    const groupeTransactionsByPhone = transactions.reduce(
      (acc: any, curr: any) => {
        (acc[curr.business_user_mobile] =
          acc[curr.business_user_mobile] || []).push(curr);
        return acc;
      },
      {}
    );
    const formatGroupedTransactionsByPhone = [];
    for (const [key, value] of Object.entries(groupeTransactionsByPhone)) {
      const amount = (value as any[]).reduce(
        (amount: any, value: any) => amount + +value.amount + +value.fee,
        0
      );
      const fee = (value as any[]).reduce(
        (fee: any, value: any) => fee + +value.fee,
        0
      );
      formatGroupedTransactionsByPhone.push({
        currency: (value as any[])[0].currency,
        amount,
        fee,
        business_user_mobile: (value as any[])[0].business_user_mobile,
      });
    }
    const transactionNumberWithNotFoundSellerReference =
      formatGroupedTransactionsByPhone.filter(
        (transaction) =>
          !sellerReferences.some(
            (SR: SellerReference) =>
              SR.phone === transaction.business_user_mobile
          )
      );
    const result = [];
    for (const transaction of formatGroupedTransactionsByPhone) {
      const currentSellerReference = sellerReferences.find(
        (sellerReference: SellerReference) =>
          sellerReference.phone === transaction.business_user_mobile
      );
      if (currentSellerReference) {
        result.push({
          ...transaction,
          libelle_fee: currentSellerReference.libelle_fee,
          libelle_versement: currentSellerReference.libelle_versement,
          libelle: currentSellerReference.libelle,
          date: formatDate(this.dateFilterControl.value),
          sage_x3: currentSellerReference.sage_x3,
        });
      }
    }
    return {
      allGroupedTransactionBySeller: result,
      transactionNumberWithNotFoundSellerReference,
    };
  }

  showSpinner() {
    if (this.loadingAccountEntries) this.spinner.show();
    else this.spinner.hide();
  }

  sortArray(arr: any[], prop: string) {
    return sortArray(arr, prop);
  }

  generateAccountingEntries() {
    if (this.transactionNumberWithNotFoundSellerReference.length) {
      let numbers = '';
      this.transactionNumberWithNotFoundSellerReference.forEach(
        (transaction) => {
          numbers += transaction.business_user_mobile + '\n';
        }
      );
      this.error = `Ces numéro n'existe pas dans le référentiel de vendeurs.\n${numbers}`;
      Swal.fire({
        title: 'Erreur!',
        text: this.error,
        icon: 'error',
      });
    } else {
      const test = this.transactionsService.downloadFile(
        this.allGroupedTransactionBySeller,
        'ec_wave',
        this.dateFilterControl.value
      );
    }
  }
}
