<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="description" content="" />
    <meta
      name="author"
      content="Laiterie du berger"
    />
    <meta name="generator" content="LDB" />
    <title>Connexion - Leuk MT</title>

    <style>
      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }
    </style>
  </head>
  <body class="text-center">

      
    <main class="form-signin">
      <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="message !== ''">
       {{message}}
      </ngb-alert>
      <div class="form-signin-content">
        <form [formGroup]="form" (ngSubmit)="login()">
          <img class="mb-4" src="/assets/images/leukmt.png" alt="Logo Leuk MT" />
          <h1 class="h4 mb-5 fw-normal text-info">Connectez-vous</h1>

          <div class="form-floating">
            <input
            formControlName="username"
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label for="floatingInput">Adresse e-mail</label>
          </div>
          <div class="form-floating">
            <input
            formControlName="password"
              type="{{showPassword? 'text': 'password'}}"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
            />
            <label for="floatingPassword">Mot de passe</label>
          </div>

          <div class="form-check mx-2 my-4">
            <input class="form-check-input" (click)="togglePassword()" type="checkbox" value="showPassword" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              Afficher mot de passe
            </label>
          </div>
          <div *ngIf="loading" class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <button [disabled]="!form.valid || loading" *ngIf="!loading" class="w-100 btn btn-lg btn-primary text-white" type="submit">
            Connexion
          </button>
          <a class="nav-link mt-5 text-info" routerLink="/forgot-password">
            Mot de passe oublié ?
          </a>
          <p class="mt-5 mb-3 text-muted">&copy; 2022 - Laiterie du berger</p>
        </form>
    </div>
    </main>
  </body>
</html>
