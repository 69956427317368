const formatCurrency = (value: string): string => {
  const numberString = value.toString();
  const parts = numberString.split('.');
  const wholeNumberPart = parts[0];
  const decimalPart = parts[1] ? '.' + parts[1] : '';

  let formattedWholeNumber = wholeNumberPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' '
  );

  if (wholeNumberPart.length > 6) {
    // Insert space separator for millions
    formattedWholeNumber = formattedWholeNumber.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ' '
    );
  }

  return formattedWholeNumber + decimalPart;
};

export default formatCurrency;
