<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="description" content="" />
  <meta name="author" content="Laiterie du berger" />
  <meta name="generator" content="LDB" />
  <title>Mot de passe oublié - Leuk MT</title>

  <style>
    .bd-placeholder-img {
      font-size: 1.125rem;
      text-anchor: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }

    @media (min-width: 768px) {
      .bd-placeholder-img-lg {
        font-size: 3.5rem;
      }
    }

  </style>
</head>

<body class="text-center">
  <main class="form-reset">
    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="message !== ''">
      {{message}}
    </ngb-alert>
    <div class="form-reset-content">
      <div class="card">
        <h5 class="card-header">Réinitialisation du mot de passe</h5>
        <div class="card-body">
            <p class="card-text">Entrez l'adresse e-mail que vous avez utilisée lors de votre inscription et nous vous enverrons des instructions pour réinitialiser votre mot de passe.
            </p>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-floating">
              <input formControlName="email" type="email" class="form-control" id="floatingInput"
                placeholder="name@example.com" />
              <label for="floatingInput">Adresse e-mail</label>
            </div>
            <div *ngIf="loading" class="spinner-border text-info mt-5" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <button [disabled]="!form.valid || loading" *ngIf="!loading" class="mt-5 w-100 btn btn-primary text-white"
              type="submit">
              Réinitialiser
            </button>
          </form>
        </div>
      </div>
      <a class="nav-link mt-5 text-info" routerLink="/login">
        Retourner sur la page de connexion
      </a>
      <p class="mt-5 mb-3 text-muted">&copy; 2022 - Laiterie du berger</p>
    </div>
  </main>
</body>

</html>
