import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DepotPaginationInterface,
  IShiftSupervisorDepot,
  IDepotPayload,
} from '../interfaces/depot-interface';
import { Depot } from '../models/depot';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class DepotService {
  constructor(private http: HttpClient) {}

  create(payload: IDepotPayload) {
    return this.http.post<any>(`${Config.baseUrlV2}/depots`, payload);
  }

  getDepotById(id: number) {
    return this.http.get<any>(`${Config.baseUrlV2}/depots/${id}`);
  }

  getAllDepotsWithoutPagination() {
    return this.http.get<any>(`${Config.baseUrlV2}/depots`);
  }

  getDepots(payload: DepotPaginationInterface) {
    return this.http.post<{ depots: Depot[]; metadata: any }>(
      `${Config.baseUrlV2}/depots/list`,
      payload
    );
  }

  getDepotWithZoneSupervisors() {
    return this.http.get<any>(
      `${Config.baseUrlV2}/get_depot_with_zone_supervisors`
    );
  }

  getShiftSupervisorDepots() {
    return this.http.get<any>(`${Config.baseUrlV2}/depot/shif_supervisor`);
  }

  getDepotShifSupervisors(depot_id: number) {
    return this.http.get<any>(
      `${Config.baseUrlV2}/depots/${depot_id}/shift_supervisors`
    );
  }

  update(id: number, payload: IDepotPayload) {
    return this.http.patch<any>(`${Config.baseUrlV2}/depots/${id}`, payload);
  }

  addShiftSupervisorToDepot(payload: IShiftSupervisorDepot) {
    return this.http.post<any>(`${Config.baseUrlV2}/depot_users`, payload);
  }

  removeShiftSupervisorFromDepot(payload: IShiftSupervisorDepot) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/depot_users/users`,
      payload
    );
  }
}
