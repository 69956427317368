import { Pipe, PipeTransform } from '@angular/core';
import { Month, months } from '../../helpers';

@Pipe({
  name: 'numberToMonth',
})
export class NumberToMonthPipe implements PipeTransform {
  months: Month[] = months;
  transform(value: number, ...args: unknown[]): String {
    const month = this.months.find(
      (month) => month.id.toString() === value.toString()
    );
    return month ? month.label : value.toString();
  }
}
