import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class GoalService {
  constructor(private http: HttpClient) {}

  getGoals(data: any): Observable<any> {
    return this.http.post<any>(`${Config.baseUrlV2}/goals_by_sectors`, data);
  }
}
