import { Component, Input, OnInit } from '@angular/core';
import {
  ESection,
  HomeCardItemInterface,
} from 'src/app/interfaces/home-card-item-interface';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-home-button-card',
  templateUrl: './home-button-card.component.html',
  styleUrls: ['./home-button-card.component.scss'],
})
export class HomeButtonCardComponent implements OnInit {
  @Input() currentUser!: User;
  ESection = ESection;
  CARD_ITEMS: HomeCardItemInterface[] = [
    {
      title: 'Bon de commande',
      image: '/assets/images/order.png',
      text1: 'Bon de',
      text2: 'commande',
      link: '/orders',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'Précommandeur'],
      sections: [ESection.MT],
    },
    {
      title: 'Performances',
      image: '/assets/images/performance.png',
      text1: 'Performances',
      text2: 'points de vente',
      link: '/performances',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'Précommandeur'],
      sections: [ESection.MT],
    },
    {
      title: 'Chargement des camions',
      image: '/assets/images/delivery-truck.png',
      text1: 'Chargement des',
      text2: 'camions',
      link: '/loadings',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'Précommandeur'],
      sections: [ESection.MT],
    },
    {
      title: 'Chargement des camions',
      image: '/assets/images/delivery-truck.png',
      text1: 'Chargement des',
      text2: 'camions',
      link: '/forecasts',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'superviseur'],
      sections: [ESection.TRADI],
    },
    {
      title: 'Gestion des utilisateurs',
      image: '/assets/images/team.png',
      text1: 'Gestion des',
      text2: 'utilisateurs',
      link: '/users',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin'],
      sections: [ESection.ADMIN],
    },
    {
      title: 'Gestion des rôles',
      image: '/assets/images/management.png',
      text1: 'Gestion des',
      text2: 'rôles',
      link: '/roles',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin'],
      sections: [ESection.ADMIN],
    },
    {
      title: 'Gestion des permissions',
      image: '/assets/images/permission.png',
      text1: 'Gestion des',
      text2: 'permissions',
      link: '/permissions',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin'],
      sections: [ESection.ADMIN],
    },
    {
      title: 'Demandes de chargements',
      image: '/assets/images/delivery-truck.png',
      text1: 'Demande de',
      text2: 'chargements',
      link: '/request-loadings/loading-requests',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'superviseur', 'chef de quart'],
      sections: [ESection.LOGISTIQUE],
    },
    {
      title: 'Chargements',
      image: '/assets/images/delivery-truck.png',
      text1: 'Gestion des',
      text2: 'chargements',
      link: '/request-loadings/loading-logistics',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'superviseur', 'chef de quart'],
      sections: [ESection.LOGISTIQUE],
    },
    {
      title: 'Chargements',
      image: '/assets/images/delivery-truck.png',
      text1: 'Chargements',
      text2: 'supplémentaire',
      link: '/request-loadings/additionnal-loading-logistics',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', '', 'chef de quart'],
      sections: [ESection.LOGISTIQUE],
    },
    {
      title: 'Gestion des points de vente',
      image: '/assets/images/delivery-truck.png',
      text1: 'Gestion des',
      text2: 'points de vente',
      link: '/point_of_sales_tradi',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'superviseur', 'Business Developper'],
      sections: [ESection.TRADI],
    },
    {
      title: "Gestion du stock d'un dépôt",
      image: '/assets/images/delivery-truck.png',
      text1: 'Suivi du',
      text2: 'stock dépôt',
      link: '/stock-controls',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin', 'chef de quart'],
      sections: [ESection.LOGISTIQUE],
    },
    {
      title: "Administration des camions",
      image: '/assets/images/delivery-truck.png',
      text1: 'Gestion',
      text2: 'des camions',
      link: '/gestion-of-trucks',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin'],
      sections: [ESection.LOGISTIQUE],
    },
    {
      title: "Administration des tournées",
      image: '/assets/images/delivery-truck.png',
      text1: 'Gestion',
      text2: 'des tournées',
      link: '/list-tour-logistic',
      linkClass: ['text-decoration-none'],
      authorizedRoles: ['admin'],
      sections: [ESection.LOGISTIQUE],
    },
  ];
  constructor() { }

  ngOnInit(): void { }

  getSectionAuthorizeRole(authorizedRoles: string[]) {
    return authorizedRoles.includes(this.currentUser.role?.libelle!);
  }

  getCardSectionSection(cardSections: ESection[], currentSection: ESection) {
    return cardSections.includes(currentSection);
  }
}
