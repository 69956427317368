import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationInterface } from '../interfaces/pagination-interface';
import { UserPaginationInterface } from '../interfaces/user-pagination-interface';
import { CreateUserInterface } from '../models/user';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUsers(params: UserPaginationInterface) {
    return this.http.post<any>(`${Config.baseUrlV2}/users/list`, params);
  }

  findOneById(id: number) {
    return this.http.get<any>(`${Config.baseUrlV2}/users/details/${id}`);
  }

  create(data: CreateUserInterface) {
    return this.http.post<any>(`${Config.baseUrlV2}/users`, data);
  }

  update(id: number,data: CreateUserInterface) {
    return this.http.put<any>(`${Config.baseUrlV2}/users/${id}`, data);
  }
}
