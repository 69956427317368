import { OtherType, OtherTypeState } from '../types';
import { Store } from './store';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { PaginationInterface } from '../interfaces/pagination-interface';
import { OtherTypeListDTO } from '../dtos/other-type.dtos';
import { OtherTypeService } from '../services/other-type.service';

const initialState: OtherTypeState = {
  loading: false,
  otherTypes: [],
  error: '',
  pagination: {
    page: 1,
    limit: 15,
    next: 1,
    last: 1,
    pages: 0,
  },
};

@Injectable({ providedIn: 'root' })
export class OtherTypeStore extends Store<OtherTypeState> {
  private loaded = false;

  constructor(
    private otherTypeService: OtherTypeService,
    private toastService: ToastService
  ) {
    super(initialState);
  }

  getOtherTypes(params: any) {
    if (!this.loaded) {
      this.loaded = true;
      this.setState((state) => ({ ...state, loading: true }));
      this.otherTypeService.getOtherTypes(params).subscribe({
        next: (response: OtherTypeListDTO) => {
          this.setState((state) => ({
            loading: false,
            otherTypes: response.data,
            pagination: response.metadata,
          }));
        },
        error: (error: any) => {
          this.setState((state) => ({ loading: false, error }));
        },
      });
    }
  }

  addOtherType(otherType: any) {
    this.setState((state) => ({ ...state, loading: true }));
    const payload = {
      ...otherType,
      libelle:
        otherType.libelle.charAt(0).toUpperCase() + otherType.libelle.slice(1),
    };

    this.otherTypeService.addOtherType(payload).subscribe({
      next: (otherType: any) => {
        this.setState((state) => ({
          loading: false,
          otherTypes: [otherType, ...state.otherTypes],
        }));
        this.toastService.show('Autre produit créé', {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
      },
      error: (error: any) => {
        const errorMessage = error.includes('has already been taken')
          ? 'Cette catégorie exist déjà.'
          : "Une erreur est survenue lors de la création d'une nouvelle catégorie. Merci de réessayer";
        this.setState((state) => ({ loading: false, error }));
        this.toastService.show(errorMessage, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });
      },
    });
  }

  updateOtherType(otherType: any) {
    this.setState((state) => ({ ...state, loading: true }));
    const payload = {
      ...otherType,
      libelle:
        otherType.libelle.charAt(0).toUpperCase() +
        otherType.libelle.slice(1).toLowerCase(),
    };
    this.otherTypeService.updateOtherType(payload).subscribe({
      next: (otherType: any) => {
        this.setState((state) => ({
          loading: false,
          otherTypes: state.otherTypes.map((o) =>
            o.id === otherType.id ? otherType : o
          ),
        }));
        this.toastService.show("L'autre produit a été modifié avec succès", {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        const errorMessage = error.includes('has already been taken')
          ? 'Cette catégorie existe déjà.'
          : "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer";
        this.toastService.show(errorMessage, {
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  deleteOtherType(otherType: OtherType) {
    this.setState((state) => ({ ...state, loading: true }));
    this.otherTypeService.deleteOtherType(otherType).subscribe({
      next: (o: any) => {
        this.setState((state) => ({
          loading: false,
          otherTypes: state.otherTypes.filter((o) => o.id !== otherType.id),
        }));
        this.toastService.show("L'autre produit a été supprimé avec succès", {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(
          "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer",
          {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container',
            delay: 5000,
          }
        );
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  setInitial() {
    this.loaded = false;
  }
}
