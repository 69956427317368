import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fchown } from 'fs';
import { star } from 'ngx-bootstrap-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { CreateTourLogisticDto } from 'src/app/interfaces/logistics-tours';
import { EVehicleType } from 'src/app/interfaces/vehicle-interface';
import { Depot } from 'src/app/models/depot';
import { PointOfDeliveryDto, PointOfDelivery } from 'src/app/models/point-of-delivery';
import { Product } from 'src/app/models/product';
import { User } from 'src/app/models/user';
import { Vehicle } from 'src/app/models/vehicle';
import { DataSharedStorageService } from 'src/app/services/data-shared-storage.service';
import { DepotService } from 'src/app/services/depot.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToursService } from 'src/app/services/tours.service';
import { UsersService } from 'src/app/services/users.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ModalConfig } from 'src/app/types';
import { EFormMode, EModalFrameDeliveryPoint, askedValidatorFactory, dateAdapter, formatDate, isDateTime1AfterDateTime2, translateVehicleType } from 'src/app/utils';
import { extractDateAndTime, calculPallet } from 'src/app/utils/helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-logistic-tour',
  templateUrl: './new-logistic-tour.component.html',
  styleUrls: ['./new-logistic-tour.component.scss', '../custom-table.scss']
})
export class NewLogisticTourComponent implements OnInit {
  @ViewChild('confirmModal') private modalComponent!: ModalComponent;

  /* Global */
  error: string = '';
  message: string = '';
  currentUser!: User;
  today: Date = new Date();

  // modal confirmation
  modalConfig!: ModalConfig;


  /* Modal configuration */
  EFormMode = EFormMode;
  modalFormType: string = EFormMode.CREATE;
  EModalFrameDeliveryPoint = EModalFrameDeliveryPoint
  modalFrameSelected: EModalFrameDeliveryPoint = EModalFrameDeliveryPoint.FRAME_INFOS;

  //forms
  formInfosTour!: FormGroup;
  formLoadProducts!: FormGroup;
  isOpenModal: boolean = false;
  isSubmitButtonLoadProductDisabled: boolean = true;


  //Loaders
  loading: boolean = false;
  loadingDriver: boolean = false;
  loadingConveyor: boolean = false;
  loadingDepots: boolean = false;
  loadingtruck: boolean = false;
  loadingProduct: boolean = false;
  isFirtCreation: boolean = true;
  //Verif empty product filter
  isEmptyListProductPointFilter: boolean = false;

  //Pallet
  loaderPallets!: number;
  vehiclePallets!: number;


  //Depots
  initialStartDepot: string = "Richard-Toll";
  defaultInitialDepot?: Depot;
  depotsList: Depot[] = [];
  depotsIDAlReadySelected: number[] = [];
  arrivalPointIdSelected!: number | null;
  point_of_deliveries: PointOfDeliveryDto[] = [];
  indexSelectedPoint!: number;
  indexCreationPoint: number = 0;
  //fix close modal without create point
  isModalCloseWithoutSiubmit: boolean = false;

  selectedPointOfDelivery!: PointOfDeliveryDto;

  //Vehicles
  othersVehiclesList: Vehicle[] = [];
  semiTrailerVehiclesList: Vehicle[] = [];
  vehicleChoiceFirst?: Vehicle;
  vehicleChoiceSecond?: Vehicle;
  VehicleType = EVehicleType;

  conveyorsUsers: User[] = [];
  driverUsers: User[] = [];

  //Products
  productsList: Product[] = [];
  searchProduct: FormControl<string> = new FormControl();

  dateMin!: string;
  dateNewTourParam: any = '';



  constructor(
    private formBuilder: FormBuilder,
    private depotService: DepotService,
    private usersService: UsersService,
    private vehicleService: VehicleService,
    private productService: ProductService,
    private tourLogisticService: ToursService,
    private spinner: NgxSpinnerService,
    private toastService: ToastService,
    private dataSharedStorageService: DataSharedStorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dateNewTourParam = this.dataSharedStorageService.getItem('planningFilter');
    this.dateMin = dateAdapter(this.today)
    this.initForm();
    this.getDepotList();
    this.getFilteredListVehicles();
    this.getConveyorUsers();
    this.getDriverUsers();
    this.getProducts();
    this.initFormLoadProducts();
    this.onSearchFilterProduct();
  }

  initForm() {
    this.formInfosTour = this.formBuilder.group({
      start_date: [this.dateNewTourParam !== null ? this.dateNewTourParam.date : "", [Validators.required]],
      start_time: ["", [Validators.required]], //`${this.today.getHours()}:${this.today.getMinutes()}`
      initial_point: [this.defaultInitialDepot?.id, [Validators.required]],
      truck_id: [''],
      truck_id_two: [''],
      driver: [''],
      conveyor: ['']
    });
  }


  initFormLoadProducts() {
    this.modalFormType = EFormMode.CREATE;
    this.formLoadProducts = this.formBuilder.group({
      planned_departure: ['', Validators.required],
      planned_departure_time: ['', Validators.required],
      departure_point: ['', Validators.required],
      arrival_point: ['', Validators.required],
      planned_arrival: ['', Validators.required],
      planned_arrival_time: ['', Validators.required],
      arrival: [""],
      arrival_time: [""],
      departure: [""],
      departure_time: [""],
      delivered_products: this.formBuilder.array([]),
    });
    const delivered_products = this.getLoadProductsValues()
    for (const product of this.productsList) {
      const value = this.formBuilder.group({
        product_name: new FormControl(product.sku_sage, Validators.required),
        product_id: new FormControl(product.id, Validators.required),
        asked: new FormControl('', [Validators.min(1), askedValidatorFactory()]),
        pallet: new FormControl('', [Validators.min(0)]),
        isIncludeSearchTerm: new FormControl(false),
      });
      delivered_products.push(value);
    }

  }

  onSearchFilterProduct() {
    this.searchProduct.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      const deliveredProductsArray = this.getLoadProductsValues();
      if (searchTerm != "") {
        deliveredProductsArray.controls.forEach((control) => {
          const productName = control.get('product_name')?.value as string;
          const shouldShow = productName.toLowerCase().includes(searchTerm.toLowerCase());
          shouldShow ? control.get('isIncludeSearchTerm')?.setValue(false) : control.get('isIncludeSearchTerm')?.setValue(true);
        });
        this.isEmptyListProductPointFilter = deliveredProductsArray.controls.every((control) => control.get('isIncludeSearchTerm')?.value === true);
      } else if (searchTerm || searchTerm.trim() === '') {
        deliveredProductsArray.controls.map(control => control.get('isIncludeSearchTerm')?.setValue(false))
        this.isEmptyListProductPointFilter = deliveredProductsArray.controls.every((control) => control.get('isIncludeSearchTerm')?.value === true);
      } else {
      }
    });
  }

  submitButtonState() {
    if (this.searchProduct.value === "") {
      return this.formLoadProducts.value.delivered_products?.every(
        (item: any) => !item.asked
      );
    }
  }

  createPoint() {
    this.isOpenModal = true;
    this.isModalCloseWithoutSiubmit = true;
    this.modalFormType = EFormMode.CREATE;
    this.initFormLoadProducts();
    this.searchProduct.patchValue("");
    if (this.indexCreationPoint === 0) {
      this.getInputFormLoadProductFor('departure_point')?.setValue(this.getIdLastPointOfDeliveryAdded());
      this.getInputFormLoadProductFor('planned_departure')?.setValue((this.getTourFormControlFor('start_date')?.value));
      this.getInputFormLoadProductFor('planned_departure_time')?.setValue((this.getTourFormControlFor('start_time')?.value));
    } else {
      this.getInputFormLoadProductFor('departure_point')?.setValue(this.getIdLastPointOfDeliveryAdded());
      this.getInputFormLoadProductFor('planned_departure')?.setValue(this.getDateAndTimeFromLongDate(this.getLastPointOfDeliveryAdded().planned_arrival)?.date || (this.getTourFormControlFor('start_date')?.value));
      this.getInputFormLoadProductFor('planned_departure_time')?.setValue(this.getDateAndTimeFromLongDate(this.getLastPointOfDeliveryAdded().planned_arrival)?.time || (this.getTourFormControlFor('start_time')?.value));
    }
  }


  openModalDetailPoint(values: { point_of_delivery: PointOfDeliveryDto, index: number }) {
    this.modalFormType = EFormMode.UPDATE;
    this.selectedPointOfDelivery = values.point_of_delivery;
    this.arrivalPointIdSelected = values.point_of_delivery.arrival_point;
    this.depotsIDAlReadySelected = this.depotsIDAlReadySelected.filter((id) => id !== Number(this.arrivalPointIdSelected));
    this.indexSelectedPoint = values.index;
    const dateAndTimeDeparture = this.getDateAndTimeFromLongDate(values.point_of_delivery.planned_departure);
    const dateAndTimeArrival = this.getDateAndTimeFromLongDate(values.point_of_delivery.planned_arrival);
    this.formLoadProducts = this.formBuilder.group({
      planned_departure: [dateAndTimeDeparture.date, Validators.required],
      planned_departure_time: [dateAndTimeDeparture.time, Validators.required],
      departure_point: [values.point_of_delivery.departure_point, Validators.required],
      arrival_point: [values.point_of_delivery.arrival_point, Validators.required],
      planned_arrival: [dateAndTimeArrival.date, Validators.required],
      planned_arrival_time: [dateAndTimeArrival.time, Validators.required],
      arrival: [""],
      arrival_time: [""],
      departure: [""],
      departure_time: [""],
      delivered_products: this.formBuilder.array([]),
    });
    const delivered_products = this.getLoadProductsValues()
    for (const product of this.productsList) {
      let value: FormGroup = this.formBuilder.group({
        product_name: new FormControl(product.sku_sage, Validators.required),
        product_id: new FormControl(product.id, Validators.required),
        asked: new FormControl('', [Validators.min(1), askedValidatorFactory()]),
        pallet: new FormControl('', [Validators.min(0)]),
        isIncludeSearchTerm: new FormControl(false),
      });
      for (const productValues of values.point_of_delivery.delivered_products || []) {
        if (product.id === productValues.product_id) {
          value.patchValue({
            asked: productValues.asked,
            pallet: productValues.pallet,
            // Autres valeurs à patcher si nécessaire
          });
          break;
        }
      }
      delivered_products.push(value);
    }
    this.searchProduct.patchValue("");
    this.isOpenModal = true;
    this.isModalCloseWithoutSiubmit = false;
  }

  isDepotAlreadySelected(depot_id: number) {
    return this.depotsIDAlReadySelected.includes(depot_id)
  }

  closeModal(event: boolean) {
    this.isOpenModal = event;
    if (this.arrivalPointIdSelected &&
      this.arrivalPointIdSelected !== null &&
      !this.isModalCloseWithoutSiubmit &&
      !this.isDepotAlreadySelected(this.arrivalPointIdSelected)) {
      this.depotsIDAlReadySelected.push(this.arrivalPointIdSelected);
    }
  }

  pushPointOfDelivery() {
    const point_of_delivery_to_create = {
      planned_departure: `${this.formLoadProducts.value.planned_departure} ${this.formLoadProducts.value.planned_departure_time}:00`,
      planned_arrival: `${this.formLoadProducts.value.planned_arrival} ${this.formLoadProducts.value.planned_arrival_time}:00`,
      departure_point: Number(this.formLoadProducts.value.departure_point),
      arrival_point: Number(this.formLoadProducts.value.arrival_point),
      delivered_products: this.formLoadProducts.value.delivered_products.filter((item: any) => item.asked > 0).map((item: any) => ({ product_id: item.product_id, asked: Number(item.asked), pallet: Number(item.pallet) })),
    }
    const point_of_delivery_before = this.indexCreationPoint >= 1 ? this.point_of_deliveries[this.indexCreationPoint] : null;
    if (point_of_delivery_before && !isDateTime1AfterDateTime2(point_of_delivery_to_create.planned_departure, point_of_delivery_before.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date départ ne doit pas être inférieur à la date d'arrivée du point précédent",
        icon: 'error',
      });
    }
    else if (!isDateTime1AfterDateTime2(point_of_delivery_to_create.planned_arrival, point_of_delivery_to_create.planned_departure)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être inférieur à la date de départ",
        icon: 'error',
      });
    } else {
      this.point_of_deliveries.push(point_of_delivery_to_create);
      if (this.indexCreationPoint === 0) {
        this.getTourFormControlFor("start_date")?.patchValue(formatDate(this.formLoadProducts.value.planned_departure));
        this.getTourFormControlFor("start_time")?.patchValue((this.formLoadProducts.value.planned_departure_time));
      }
      this.indexCreationPoint += 1;
      this.depotsIDAlReadySelected.push(point_of_delivery_to_create.arrival_point);
      this.arrivalPointIdSelected = null;
      this.isModalCloseWithoutSiubmit = false;
      this.closeModal(false);
    }
    this.loaderPallets = this.getLoadedPalletQuantity();
  }

  updatePointOfDelivery() {
    const point_of_delivery_update = {
      planned_departure: `${this.formLoadProducts.value.planned_departure} ${this.formLoadProducts.value.planned_departure_time}:00`,
      planned_arrival: `${this.formLoadProducts.value.planned_arrival} ${this.formLoadProducts.value.planned_arrival_time}:00`,
      departure_point: Number(this.formLoadProducts.value.departure_point),
      arrival_point: Number(this.formLoadProducts.value.arrival_point),
      delivered_products: this.formLoadProducts.value.delivered_products.filter((item: any) => item.asked > 0).map((item: any) => ({ product_id: item.product_id, asked: Number(item.asked), pallet: Number(item.pallet) })),
    }
    const point_of_delivery_before = this.indexSelectedPoint > 1 ? this.point_of_deliveries[this.indexSelectedPoint - 1] : null;
    const point_of_delivery_after = this.indexSelectedPoint < this.point_of_deliveries.length ? this.point_of_deliveries[this.indexSelectedPoint + 1] : null;
    if (!isDateTime1AfterDateTime2(point_of_delivery_update.planned_arrival, point_of_delivery_update.planned_departure)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être supérieur à la date départ",
        icon: 'error',
      });
    }
    else if (point_of_delivery_after && !isDateTime1AfterDateTime2(point_of_delivery_after.planned_departure, point_of_delivery_update.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être supérieur à la date départ du point suivant",
        icon: 'error',
      });
    }
    else if (point_of_delivery_before && !isDateTime1AfterDateTime2(point_of_delivery_update.planned_departure, point_of_delivery_before.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date départ ne doit pas être inférieur à la date d'arrivée du point précédent",
        icon: 'error',
      });
    }
    else {
      this.point_of_deliveries[this.indexSelectedPoint] = point_of_delivery_update
      if (this.indexSelectedPoint === 1) {
        this.getTourFormControlFor("start_date")?.patchValue(formatDate(this.formLoadProducts.value.planned_departure));
        this.getTourFormControlFor("start_time")?.patchValue((this.formLoadProducts.value.planned_departure_time));
      }
      if (this.indexSelectedPoint < 2) {
        // this.point_of_deliveries[this.indexSelectedPoint - 1].planned_arrival = point_of_delivery_update.planned_arrival;
      }
      if (this.indexSelectedPoint < this.point_of_deliveries.length - 1) {
        this.point_of_deliveries[this.indexSelectedPoint + 1].departure_point = point_of_delivery_update.arrival_point;
        this.point_of_deliveries[this.indexSelectedPoint + 1].planned_departure = point_of_delivery_update.planned_departure;
        this.point_of_deliveries[this.indexSelectedPoint + 1].planned_arrival = point_of_delivery_update.planned_arrival;
      }
      this.arrivalPointIdSelected = null;
      this.depotsIDAlReadySelected.push(point_of_delivery_update.arrival_point);
      this.isModalCloseWithoutSiubmit = false;
      this.closeModal(false);
    }
    this.loaderPallets = this.getLoadedPalletQuantity();
  }

  openConfirmModalRemovePointOfDelivery(indexObjectToRemove: number, point_of_delivery_length: number) {
    this.modalConfig = {
      modalTitle: 'Confirmation',
      submitButtonLabel: 'Oui',
      cancelButtonLabel: 'Non',
      headerClass: 'bg-info d-none text-white',
      cancelButtonClass: 'btn-danger ',
      submitButtonClass: 'btn-primary text-white',
      onSubmit: () => this.confirmRemovePointOfDelivery(indexObjectToRemove, point_of_delivery_length),
    };
    this.openModal();
  }

  confirmRemovePointOfDelivery(indexObjectToRemove: number, point_of_delivery_length: number) {
    const objectToDelete = this.point_of_deliveries.splice(indexObjectToRemove, 1)[0];
    this.arrivalPointIdSelected = null;
    this.depotsIDAlReadySelected = this.depotsIDAlReadySelected.filter((d) => d !== objectToDelete.arrival_point)

    if (indexObjectToRemove === 1 && indexObjectToRemove + 1 !== point_of_delivery_length) {
      this.getTourFormControlFor("start_date")?.patchValue(this.getDateAndTimeFromLongDate(objectToDelete.planned_departure).date);
      this.getTourFormControlFor("start_time")?.patchValue(this.getDateAndTimeFromLongDate(objectToDelete.planned_departure).time);
      if (indexObjectToRemove < this.point_of_deliveries.length) {
        this.point_of_deliveries[indexObjectToRemove].departure_point = objectToDelete.departure_point;
      }
    }
    if (indexObjectToRemove === point_of_delivery_length) {
      this.closeModal(true)
    }
    if (indexObjectToRemove < point_of_delivery_length - 1) {
      this.point_of_deliveries[indexObjectToRemove].departure_point = objectToDelete.departure_point;
      this.point_of_deliveries[indexObjectToRemove].planned_departure = objectToDelete.planned_departure;
      this.point_of_deliveries[indexObjectToRemove].planned_arrival = objectToDelete.planned_arrival;
    }
    this.loaderPallets = this.getLoadedPalletQuantity();
    this.modalComponent.close();
    this.closeModal(false);
    this.indexCreationPoint -= 1;
  }

  getLoadProductsValues() {
    return this.formLoadProducts.get('delivered_products') as FormArray;
  }

  getTourFormControlFor(control: string) {
    return this.formInfosTour.get(control);
  }

  getInputFormLoadProductFor(control: string) {
    return this.formLoadProducts.get(control);
  }

  getDepotLabelById(id?: number) {
    return this.depotsList.find((depot) => depot.id === Number(id))?.name;
  }

  onVehicleChoiceFirstChange() {
    const vehicleSelect = this.getTourFormControlFor('truck_id')?.value
    this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === parseInt(vehicleSelect));
    if (this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.ROAD_TRACTOR) {
      this.vehiclePallets = this.vehicleChoiceFirst!.pallet;
      this.resetInputValueAndValidators('truck_id_two');
    }
    else {
      this.getTourFormControlFor('truck_id_two')?.setValidators(Validators.required);
    }
  }

  onVehicleChoiceSecondChange() {
    const vehicleSelect = this.getTourFormControlFor('truck_id_two')?.value;
    if (vehicleSelect !== "") {
      this.vehicleChoiceSecond = this.semiTrailerVehiclesList.find((vehicle) => vehicle.id === parseInt(vehicleSelect)) || undefined;
      this.vehiclePallets = this.vehicleChoiceSecond ? this.vehicleChoiceSecond.pallet : this.vehicleChoiceFirst!.pallet;
    }
  }

  getPalletCountForProduct(productLoad: any) {
    let product = this.productsList.find((p) => p.id === productLoad.value.product_id) || undefined;
    let pallet = calculPallet(productLoad.value.asked, product ? product.pallet : 0);
    productLoad.controls['pallet'].setValue(pallet)
  }

  getLoadedPalletQuantity() {
    let totalPallet: number = 0;
    for (let i = 0; i < this.point_of_deliveries.length; i++) {
      const element = this.point_of_deliveries[i];
      let palletQuantity = element.delivered_products?.reduce((sum, product) => sum + product.pallet, 0) || 0;
      totalPallet = totalPallet + palletQuantity
    }
    return Math.round(totalPallet * 10) / 10;

  }

  //api services
  createNewLogisticTour() {
    this.loading = true;
    this.showSpinner();
    const payload: CreateTourLogisticDto = {
      start_date: `${this.formInfosTour.value.start_date} ${this.formInfosTour.value.start_time}:00`,
      end_date: this.getLastPointOfDeliveryAdded().planned_arrival,
      origin: this.formInfosTour.value.initial_point,
      driver: this.formInfosTour.value.driver,
      conveyor: this.formInfosTour.value.conveyor,
      ...(
        this.vehicleChoiceFirst?.vehicle_type === EVehicleType.CARRIER_TRUCK && {
          carrier_truck: this.formInfosTour.value.truck_id
        }
      ),
      ...(
        this.vehicleChoiceFirst?.vehicle_type === EVehicleType.ROAD_TRACTOR && {
          road_tractor: this.formInfosTour.value.truck_id,
          semi_trailer: this.formInfosTour.value.truck_id_two
        }
      ),
      point_of_deliveries: this.point_of_deliveries.slice(1)
    }
    this.tourLogisticService.create(payload).subscribe({
      next: (response) => {
        this.loading = false;
        this.showSpinner();
        if (this.dateNewTourParam) {
          this.dataSharedStorageService.setItem('planningFilter', { date: payload.start_date })
          this.router.navigateByUrl('/plannings');
        } else this.router.navigateByUrl('/list-tour-logistic');
        this.error = response.message || "";
        this.message = 'La tournée a été créée avec succès';
        this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la création de la tournée. Merci de réessayer`;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        });
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  getDepotList() {
    this.loadingDepots = true;
    this.showSpinner();
    this.depotService.getDepots({ page: 1, limit: 25 }).subscribe({
      next: (response) => {
        this.depotsList = response.depots;
        this.defaultInitialDepot = this.getDefaultInitialPoint(this.depotsList) || undefined;
        this.depotsIDAlReadySelected = this.defaultInitialDepot !== undefined ? [this.defaultInitialDepot.id || 6] : [];
        this.point_of_deliveries.push({
          planned_departure: this.getTourFormControlFor('start_date')?.value,
          planned_arrival: this.getTourFormControlFor('start_time')?.value,
          departure_point: Number(this.defaultInitialDepot?.id),
          arrival_point: Number(this.defaultInitialDepot?.id)
        })
        this.formInfosTour.patchValue({
          initial_point: this.defaultInitialDepot?.id || ''
        });
        this.loadingDepots = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenu lors de la récupération des dépôts. Merci de réessayer`;
        this.loadingDepots = false;
        this.showSpinner();
      },
    })
  }

  getFilteredListVehicles() {
    this.loadingtruck = true;
    this.showSpinner();
    this.error = '';
    this.vehicleService.getFilteredListTruck({ page: 1, limit: 50 }).subscribe({
      next: (response) => {
        this.othersVehiclesList = response.data.filter((vehicle) => vehicle.vehicle_type !== EVehicleType.SEMI_TRAILER);
        this.semiTrailerVehiclesList = response.data.filter((vehicle) => vehicle.vehicle_type === EVehicleType.SEMI_TRAILER);
        this.loadingtruck = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des véhicules. Merci de réessayer`;
        this.loadingtruck = false;
        this.showSpinner();
      },
    });
  }

  getConveyorUsers() {
    this.loadingConveyor = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [6] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.conveyorsUsers = response.data;
        this.loadingConveyor = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateur. Merci de réessayer`;
        this.loadingConveyor = false;
        this.showSpinner();
      },
    });
  }

  getDriverUsers() {
    this.loadingDriver = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [15] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.driverUsers = response.data;
        this.loadingDriver = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateur. Merci de réessayer`;
        this.loadingDriver = false;
        this.showSpinner();
      },
    });
  }

  getProducts(): void {
    this.loadingProduct = true;
    this.showSpinner();
    this.productService.getAllProducts().subscribe({
      next: (products) => {
        this.productsList = products.filter(
          (el: any) =>
            el?.is_accessorie !== true);
        this.loadingProduct = false;
        this.showSpinner();
        this.initFormLoadProducts();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des produits. Merci de réessayer.`;
        this.loadingProduct = false;
        this.showSpinner();
      },
    });
  }

  getObjectInArrayOneNotInArrayTwo(list: Depot[], selecteds: number[]) {
    return list.filter((item) => !selecteds.includes(item.id));
  }


  async openModal() {
    this.message = "";
    this.error = "";
    return await this.modalComponent.open();
  }

  async closeModalConfirm() {
    return await this.modalComponent.close()
  }


  //Helpers
  getIdLastPointOfDeliveryAdded() {
    const lengthOfPoints = this.point_of_deliveries.length - 1;
    if (lengthOfPoints === 0)
      return this.defaultInitialDepot?.id
    else return this.point_of_deliveries[lengthOfPoints].arrival_point
  }

  getLastPointOfDeliveryAdded() {
    return this.point_of_deliveries[this.point_of_deliveries.length - 1];
  }

  getDefaultInitialPoint(depots: Depot[]) {
    return depots.find((depot) => depot.name === this.initialStartDepot)
  }

  getDateAndTimeFromLongDate(dateTime: string) {
    return extractDateAndTime(dateTime);
  }

  translateVehicleType(type: EVehicleType) {
    return translateVehicleType(type);
  }

  changeModalFrame(frame: EModalFrameDeliveryPoint) {
    this.modalFrameSelected = frame;
  }

  resetInputValueAndValidators(controlName: string) {
    this.getTourFormControlFor(controlName)?.setValue('');
    this.getTourFormControlFor(controlName)?.clearValidators();
    this.getTourFormControlFor(controlName)?.updateValueAndValidity();
  }

  roundPositivePallet(value: number) {
    return Math.ceil(value);
  }

  //loaoder
  showSpinner() {
    if (this.loading || this.loadingConveyor || this.loadingDriver || this.loadingtruck || this.loadingProduct || this.loadingDepots) this.spinner.show();
    else {
      this.spinner.hide()
    };
  }
}
