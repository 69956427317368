import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';
import { CreateLogisticTourItemDto } from '../interfaces/logistics-tours';

@Injectable({
  providedIn: 'root'
})
export class LogisticTourItemService {

  constructor(private http: HttpClient) { }

  create(payload: CreateLogisticTourItemDto) {
    return this.http.post<any>(`${Config.baseUrlV2}/logistic_tour_items`, payload);
  }

  update(id: number, payload: any) {
    return this.http.patch<any>(`${Config.baseUrlV2}/logistic_tour_items/${id}`, payload);
  }
}
