import { Injectable } from '@angular/core';
import { CountryState } from '../types';
import { Store } from './store';
import { CountryService } from '../services/country.service';
import { CountryListDTO, CountryPaginationDTO, NewCountryDTO, UpdateCountryDTO } from '../dtos/country.dto';
import { ToastService } from '../services/toast.service';

const initialState: CountryState = {
  countries: [],
  listCountries: [],
  loading: false,
  formSuccess: false,
  error: '',
  pagination: {
    page: 1,
    limit: 15,
    next: 1,
    last: 1,
    pages: 0,
  },
};

@Injectable({
  providedIn: 'root',
})
export class CountryStore extends Store<CountryState> {
  private loaded = false;

  constructor(private countryService: CountryService, 
    private toastService: ToastService,
  ) { super(initialState); }

  getCountries(params: any) {
    if (!this.loaded) {
      this.loaded = true;
      this.setState((state) => ({ ...state, loading: true }));
      this.countryService.getCountries(params).subscribe({
        next: (response: CountryListDTO) => {
          this.setState((state) => ({
            loading: false,
            countries: response.data,
          }));
        },
        error: (error: any) => {
          this.setState((state) => ({ loading: false, error }));
        },
      });
    }
  }

  getListCountries(params: CountryPaginationDTO) {
    if (!this.loaded) {
      this.loaded = true;
      this.setState((state) => ({ ...state, loading: true }));
      this.countryService.getListCountries(params).subscribe({
        next: (response: CountryListDTO) => {
          const formated = response.data.flatMap(item => {
            item.catalog_length = item.catalogs.length
            return item
          });
          
          this.setState((state) => ({
            loading: false,
            listCountries: formated,
            pagination: response.metadata,
          }));
        },
        error: (error: any) => {
          this.setState((state) => ({ loading: false, error }));
        },
      });
    }
  }

  addCountry(dto: NewCountryDTO) {
    this.setState((state) => ({ ...state, loading: true, formSuccess: false }));
    this.countryService.addCountry(dto).subscribe({
      next: (country: any) => {
        country.catalog_length = country.catalogs.length
        this.setState((state) => ({
          loading: false,
          listCountries: [country, ...state.listCountries],
          formSuccess: true,
        }));

        this.toastService.show('Nouveau pays créé', {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
      },
      error: (error: any) => {
        const message = error;
        this.setState((_) => ({ loading: false, formSuccess: false }));
        this.toastService.show(message, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });
      },
    });
  }

  updateCountry(dto: UpdateCountryDTO, id: number) {
    this.setState((state) => ({ ...state, loading: true, formSuccess: false }));
    this.countryService.updateCountry(dto, id).subscribe({
      next: (country: any) => {
        country.catalog_length = country.catalogs.length
        this.setState((state) => ({
          loading: false,
          listCountries: state.listCountries.map((c) =>
            c.id === country.id ? country : c
          ),
          formSuccess: true
        }));

        this.toastService.show('Le pays a été modifié avec succès', {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(error, {
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });

        this.setState((_) => ({ loading: false, formSuccess: false }));
      },
    });
  }

  setInitial() {
    this.loaded = false;
  }
}
