import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';
import { CreateTourLogisticDto, LogisticToursPaginationInterface, UpdateTourLogisticDto } from '../interfaces/logistics-tours';
import { StartLogisticTourDto } from '../models/point-of-delivery';

@Injectable({
  providedIn: 'root'
})
export class ToursService {
  constructor(private http: HttpClient) {}

  getAllTours() {
    return this.http.get<any>(`${Config.baseUrlV2}/logistic_tours`);
  }

  create(payload: CreateTourLogisticDto) {
    return this.http.post<any>(`${Config.baseUrlV2}/logistic_tours`, payload);
  }

  update(id: number, payload: UpdateTourLogisticDto) {
    return this.http.patch<any>(`${Config.baseUrlV2}/logistic_tours/${id}`, payload);
  }

  getLogisticTourById(id: number) {
    return this.http.get<any>(`${Config.baseUrlV2}/logistic_tours/${id}`);
  }

  getLogisticToursList(payload: LogisticToursPaginationInterface) {
    return this.http.post<any>(`${Config.baseUrlV2}/logistic_tours/list`, payload);
  }

  startLogisticTour(id: number, payload: {point_of_deliveries: StartLogisticTourDto[]}) {
    return this.http.post<any>(`${Config.baseUrlV2}/logistic_tours/start_logistic_tour/${id}`, payload);
  }

  cancel(id: number) {
    return this.http.post<any>(`${Config.baseUrlV2}/logistic_tours/cancel/${id}`, {});
  }

}
