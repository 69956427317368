<div *ngIf=" !loading"
    class="d-flex justify-content-between flex-wrap flex-md-wrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <div class="d-flex align-items-center">
        <app-go-back></app-go-back>
        <h1 class="h2 text-primary">Modification de la tournée</h1>
    </div>
</div>
<div *ngIf="!loading && !loadingUsers && !loadingtruck && !loadingDepot && !loadingProduct"
    class="row d-flex justify-content-around">
    <div class="col-4 rounded-4 d-flex flex-column justify-content-between align-items-between">
        <div class="card rounded-4 mb-3">
            <h6 class="text-center fw-bold mt-3">
                Programmer la tournée
            </h6>
            <form class="form p-4 form-tour-size" [formGroup]="formInfosTour">
                <div class="row mb-3">
                    <div class="col-8">
                        <label class="text-dark m-auto fs-11 fw-lighter" for="start_date">
                            <small>Date de départ</small>
                        </label>
                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                            formControlName="start_date" [min]="dateMin" placeholder="Date de départ">
                    </div>
                    <div class="col-4">
                        <label class="text-dark m-auto fs-11 fw-lighter" for="start_time">
                            <small>Heure de départ</small>
                        </label>
                        <input type="time" class="form-control border-0 bg-gray-light text-dark "
                            formControlName="start_time" placeholder="Heure">
                    </div>
                </div>
                <div class="mb-3">
                    <label class="text-dark m-auto fs-11 fw-lighter" for="initial_point">
                        <small>Point de départ</small>
                    </label>
                    <select class="form-select rounded-2 bg-gray-light text-dark" formControlName="initial_point">
                        <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}</option>
                    </select>
                </div>
            </form>
        </div>
        <div class="d-flex mb-3 justify-content-center" *ngIf="formModified">
            <button (click)="updateLogisticTour()" [disabled]="formInfosTour.invalid || loading"
                class="btn btn-primary fw-bold text-white mx-3">
                Enregistrer les modifications
            </button>
        </div>
        <div class="card bg-gray-light rounded-4 p-4 " style="font-size: .8rem;">
            <div class="row align-items-center bg-white rounded-4 p-1">
                <div class="col-7"> Palettes totales : </div>
                <div class="col-5 rounded-4 bg-info p-1 text-center">
                    <span class="text-white">
                        {{ (vehiclePallets === 0) ? '---' : vehiclePallets +" Palettes"}}
                    </span>
                </div>
            </div>
            <div class="row align-items-center bg-white rounded-4 p-1 mt-3">
                <div class="col-7"> Taux de remplissage : </div>
                <div class="col-5 d-flex justify-content-start rounded-4 bg-gray-light" style="padding: 0;">
                    <div class="rounded-4 py-1 text-center" *ngIf="vehiclePallets > 0" style="min-width: 30%;"
                        [ngStyle]="{ 'width': +calculatePercentagePalletLoaded(loaderPallets, vehiclePallets)+'%'}"
                        [ngClass]="{'bg-primary': vehiclePallets >= loaderPallets,
                         'bg-danger': vehiclePallets < loaderPallets}">
                        <span class="text-white">
                            {{calculatePercentagePalletLoaded(loaderPallets, vehiclePallets) }} %
                        </span>
                    </div>
                    <div class="rounded-4 text-center py-1 bg-danger opacity-75" style="width: 100%;"
                        *ngIf="vehiclePallets <= 0">
                        <span class="text-white">
                            {{ "---" }} % ({{roundPositivePallet(loaderPallets)}} Palettes)
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-7 border rounded-4 bg-gray-light">
        <h6 class="text-center fw-bold m-3">
            Route liste de la tournée
        </h6>
        <div class="py-2" style="max-height: 70vh;overflow-y: auto; overflow-x: hidden;">
            <ng-container *ngFor="let point of point_of_deliveries; let index=index">
                <div *ngIf="index === 0" class="">
                    <app-time-line-point [pointLinesLength]="point_of_deliveries.length" [indexPointLine]="index"
                        [point_of_delivery_name]="defaultInitialDepot?.name"
                        [planned_departure]="getTourFormControlFor('start_date')?.value"
                        [planned_departure_time]="getTourFormControlFor('start_time')?.value">
                    </app-time-line-point>
                </div>
                <div *ngIf="index !== 0" class="">
                    <app-time-line-point [pointLinesLength]="point_of_deliveries.length" [indexPointLine]="index"
                        [point_of_delivery_name]="point.arrival_point.name" [point_of_delivery]="point"
                        [planned_departure]="point.planned_arrival"
                        [planned_departure_time]="getDateAndTimeFromLongDate(point.planned_arrival).time"
                        (removePointInPointsOfDeliveries)="openConfirmModalRemovePointOfDelivery($event, point)"
                        (openPoint)="openModalDetailPoint($event)" (addNewPoint)="createPoint()">
                    </app-time-line-point>
                </div>
            </ng-container>
        </div>
    </div>
</div>



<app-modal-logistic-tour-point [isOpen]="isOpenModal" (close)="closeModal($event)">
    <ng-template [ngIf]="isOpenModal">
        <div [ngSwitch]="modalFormType">
            <ng-template [ngSwitchCase]='EFormMode.UPDATE'>
                <ng-container [ngTemplateOutlet]="loadProduct"></ng-container>
            </ng-template>
            <ng-template [ngSwitchCase]='EFormMode.CREATE'>
                <ng-container [ngTemplateOutlet]="createPointModal"></ng-container>
            </ng-template>
        </div>
    </ng-template>
</app-modal-logistic-tour-point>

<ng-template #loadProduct>
    <div class="">
        <form style="width: 100%;" [formGroup]="formLoadProducts">
            <div class="d-flex">
                <div class="col-2 d-flex bg-gray-light flex-column">
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                            'bg-info opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS
                        }" (click)="changeModalFrame(EModalFrameDeliveryPoint.FRAME_INFOS)">
                        <span class="text-center ">
                            Infos Gen.
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                        'bg-info opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS
                    }" (click)="changeModalFrame(EModalFrameDeliveryPoint.PRODUCTS)">
                        <span class="text-center">
                            Produits
                        </span>
                    </div>
                </div>
                <div class="col-10">
                    <div class="d-flex bg-info flex-row justify-content-between align-items-center p-4 frame-height">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-white m-auto mx-2" for="departure_point">
                                <span class="fs-5 fw-bolder">Point de départ</span>
                                <small class="text-white mx-3 fs-8 fw-lighter"> {{
                                    selectedPointOfDelivery.departure_point.name }} </small>
                            </label>
                            <select hidden class="form-select-sm rounded-2 bg-white text-dark"
                                formControlName="departure_point">
                                <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-white m-auto mx-2" for="arrival_point">
                                <span class="fs-5 fw-bolder">Destination</span>
                            </label>
                            <select class="form-select rounded-2 text-dark ml-2" formControlName="arrival_point">
                                <option value="" selected>Choisir un dépôt</option>
                                <option
                                    *ngFor="let depot of getObjectInArrayOneNotInArrayTwo(depotsList, depotIdsAllReadyUsed)"
                                    [value]="depot.id">{{depot.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS">
                        <h5 class="text-center mt-4">Départ</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_departure" placeholder="Date de dép."
                                            [min]="getDateAndTimeFromLongDate(point_of_deliveries[indexSelectedPoint - 1]?.planned_arrival).date">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="departure" placeholder="Date de dép."
                                            [min]="getDateAndTimeFromLongDate(point_of_deliveries[indexSelectedPoint - 1]?.planned_arrival).date">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-4">Arrivée</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure d'arrivée prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_arrival"
                                            [min]="getInputFormLoadProductFor('planned_departure')?.value">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de d'arrivée réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="arrival"
                                            [min]="getInputFormLoadProductFor('planned_departure')?.value">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-5">Transport</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-4 d-flex flex-column text-dark">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id">
                                    <small class="fw-semibold">Vehicule ({{ vehicleChoiceFirst ?
                                        translateVehicleType(vehicleChoiceFirst.vehicle_type) :
                                        '--'}})</small>
                                </label>
                                <ng-select (ngModelChange)="onVehicleChoiceFirstChange($event)"
                                    formControlName="truck_id" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of othersVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}} <small>({{
                                            translateVehicleType(vehicle.vehicle_type)
                                            }})</small> </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark"
                                *ngIf="vehicleChoiceFirst?.vehicle_type === VehicleType.ROAD_TRACTOR">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id_two">
                                    <small
                                        class="fw-semibold">{{translateVehicleType(VehicleType.SEMI_TRAILER)}}</small>
                                </label>
                                <ng-select (ngModelChange)="onVehicleChoiceSecondChange($event)"
                                    formControlName="truck_id_two" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of semiTrailerVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="driver">
                                    <small class="fw-semibold">Chauffeur</small>
                                </label>
                                <ng-select formControlName="driver" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let user of driverUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="conveyor">
                                    <small class="fw-semibold">Convoyeur</small>
                                </label>
                                <ng-select formControlName="conveyor" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of conveyorsUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>

                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS">
                        <div class="row d-flex my-2 align-items-center ">
                            <div class="input-group flex-nowrap justify-content-center">
                                <input type="search" [formControl]="searchProduct" class="col-4 rounded-start-circle"
                                    placeholder="Rechercher un produit" aria-label="Rechercher un produit"
                                    aria-describedby="addon-wrapping">
                                <span class="bg-info px-4 py-2" style="border-radius: 0 30px 30px 0;"
                                    id="addon-wrapping">
                                    <i-bs name="search" class="text-white"></i-bs>
                                </span>
                            </div>
                        </div>
                        <div class="col-11 m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                            <div class="section-details-item section-details-list-header">
                                <div>Produits</div>
                                <div class="center">Qté à charger</div>
                                <div class="center">Palettes</div>
                                <div class="center">Actions</div>
                            </div>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <div class="section-details-item section-details-list-item" [formGroupName]="index"
                                    *ngIf="!product.value.isIncludeSearchTerm"
                                    [ngClass]="{'bg-danger-light': isProductSelected(product.value.product_id)}">
                                    <div class="">
                                        <span class="text-info bold">
                                            {{ product.value.product_name }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <input type="text" (ngModelChange)="getPalletCountForProduct(product)"
                                            class="text-center border-0 rounded-2" formControlName="asked"
                                            [readOnly]="isProductSelected(product.value.product_id)" [min]="1"
                                            [class.invalid-asked]="getLoadProductsValues().get(index + '.asked')?.hasError('invalidAsked')">
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked > 0 ? product.value.pallet : "" }}
                                        </span>
                                    </div>
                                    <div class="center"
                                        *ngIf="isProductAlreadyLoaded(product.value.product_id, selectedPointOfDelivery.point_of_delivery_items)">
                                        <div *ngIf="product.value.asked > 0"
                                            class="d-flex align-items-center justify-content-center">
                                            <i-bs class="cursor text-danger"
                                                *ngIf="!isProductSelected(product.value.product_id)"
                                                (click)="selectProduitToRemove(product.value)"
                                                name="x-circle-fill"></i-bs>
                                            <i-bs class="cursor text-primary"
                                                *ngIf="isProductSelected(product.value.product_id)"
                                                (click)="selectProduitToRemove(product.value)"
                                                name="check2-circle"></i-bs>
                                        </div>
                                    </div>
                                    <div class="center"
                                        *ngIf="!isProductAlreadyLoaded(product.value.product_id, selectedPointOfDelivery.point_of_delivery_items)">
                                        <div *ngIf="product.value.asked > 0"
                                            class="d-flex align-items-center justify-content-center">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isEmptyListProductPointFilter">
                                <div class="text-center">
                                    Aucun produit correspondant à cette recherche:
                                    <strong>'{{searchProduct.value}}'</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end px-4 border-top">
                        <input type="button" (click)="closeModal(false)" value="Annuler"
                            class="btn btn-danger text-white m-2">
                        <input type="submit" *ngIf="modalFormType === EFormMode.UPDATE"
                            [disabled]="formLoadProducts.invalid || submitButtonState() || searchProduct.value"
                            value="Modifier" class="btn btn-primary text-white m-2"
                            (click)="updatePointOfDeliveryInLogisticTour()">
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #createPointModal>
    <div class="">
        <form style="width: 100%;" [formGroup]="formLoadProducts">
            <div class="d-flex">
                <div class="col-2 d-flex bg-gray-light flex-column">
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                            'bg-info opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS
                        }" (click)="changeModalFrame(EModalFrameDeliveryPoint.FRAME_INFOS)">
                        <span class="text-center ">
                            Infos Gen.
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                        'bg-info opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS
                    }" (click)="changeModalFrame(EModalFrameDeliveryPoint.PRODUCTS)">
                        <span class="text-center">
                            Produits
                        </span>
                    </div>
                </div>
                <div class="col-10">
                    <div
                        class="d-flex bg-info flex-row justify-content-between align-items-center py-4 px-4 frame-height">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-white m-auto mx-2" for="departure_point">
                                <span class="fs-5 fw-bolder">Point de départ</span>
                                <small class="text-white mx-3 fs-8 fw-lighter"> {{
                                    logisticTour.point_of_deliveries[logisticTour.point_of_deliveries.length
                                    -1].arrival_point.name }} </small>
                            </label>
                            <select hidden class="form-select-sm rounded-2 bg-white text-dark"
                                formControlName="departure_point">
                                <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-white m-auto mx-2" for="arrival_point">
                                <span class="fs-5 fw-bolder">Destination</span>
                            </label>
                            <select class="form-select rounded-2 text-dark ml-2" formControlName="arrival_point">
                                <option value="" selected>Choisir un dépôt</option>
                                <option
                                    *ngFor="let depot of getObjectInArrayOneNotInArrayTwo(depotsList, depotIdsAllReadyUsed)"
                                    [value]="depot.id">{{depot.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS">
                        <h5 class="text-center mt-4">Départ</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_departure" placeholder="Date de dép."
                                            [min]="getDateAndTimeFromLongDate(point_of_deliveries[point_of_deliveries.length - 1]?.planned_arrival).date">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="departure" placeholder="Date de dép."
                                            [min]="getDateAndTimeFromLongDate(point_of_deliveries[point_of_deliveries.length - 1]?.planned_arrival).date">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-4">Arrivée</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure d'arrivée prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_arrival"
                                            [min]="getInputFormLoadProductFor('planned_departure')?.value">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="planned_arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de d'arrivée réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="arrival"
                                            [min]="getInputFormLoadProductFor('planned_departure')?.value">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS">
                        <div class="row d-flex my-2 align-items-center ">
                            <div class="input-group flex-nowrap justify-content-center">
                                <input type="search" [formControl]="searchProduct" class="col-4 rounded-start-circle"
                                    placeholder="Rechercher un produit" aria-label="Rechercher un produit"
                                    aria-describedby="addon-wrapping">
                                <span class="bg-info px-4 py-2" style="border-radius: 0 30px 30px 0;"
                                    id="addon-wrapping">
                                    <i-bs name="search" class="text-white"></i-bs>
                                </span>
                            </div>
                        </div>
                        <div class="col-11 m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                            <div class="section-details-item section-details-list-header">
                                <div>Produits</div>
                                <div class="center">Qté à charger</div>
                                <div class="center">Palettes</div>
                            </div>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <div class="section-details-item section-details-list-item" [formGroupName]="index"
                                    *ngIf="!product.value.isIncludeSearchTerm">
                                    <div class="">
                                        <span class="text-info bold">
                                            {{ product.value.product_name }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <input type="text" class="text-center border-0 rounded-2" style="outline: none;"
                                            formControlName="asked" (ngModelChange)="getPalletCountForProduct(product)"
                                            [min]="1"
                                            [class.invalid-asked]="getLoadProductsValues().get(index + '.asked')?.hasError('invalidAsked')">
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked > 0 ? product.value.pallet : "" }}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isEmptyListProductPointFilter">
                                <div class="text-center">
                                    Aucun produit correspondant à cette recherche:
                                    <strong>'{{searchProduct.value}}'</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end px-4 border-top">
                        <input type="button" (click)="closeModal(false)" value="Annuler"
                            class="btn btn-danger text-white m-2">
                        <input type="submit" *ngIf="modalFormType === EFormMode.CREATE"
                            [disabled]="formLoadProducts.invalid || submitButtonState() || searchProduct.value"
                            value="Ajouter un dépôt" class="btn btn-primary text-white m-2"
                            (click)="addNewPointOfDeliveryToLogisticTour()">
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<modal #confirmModal [modalConfig]="modalConfig" class="modal-dialog-centered">
    <div class="h4 fw-bold text-center">
        Confirmation
    </div>
    <p class="text-center">
        {{ modalConfig?.contentText || ""}}
    </p>

</modal>