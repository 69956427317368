import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ModalConfig } from 'src/app/types';

@Component({
  selector: 'app-filter-search-modal',
  templateUrl: './filter-search-modal.component.html',
  styleUrls: ['./filter-search-modal.component.scss']
})
export class FilterSearchModalComponent implements OnInit {
  @Input() labelSearchFilter: string = '';
  @Input() public modalConfig!: ModalConfig;
  @Input() isDropDown: boolean = false;
  @Input() selectedItem?: any;
  @Output() onSearch = new EventEmitter<string>();
  @Output() dropDown = new EventEmitter<boolean>();


  searTerm: FormControl = new FormControl()

  constructor(
  ) { }

  ngOnInit(): void {
    this.onSearchTermControlChange();
  }

  onSearchTermControlChange() {
    this.searTerm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string) => {
      this.onSearch.emit(value);
      if (value.trim() !== "") {
        this.isDropDown = true;
        this.dropDown.emit(this.isDropDown);
      } else {
        this.isDropDown = false;
      }
    })
  }

  removeSelected(){
    this.selectedItem = undefined
  }

  openModal() {
    this.isDropDown = true;
    this.dropDown.emit(this.isDropDown);
  }

  closeDropDown() {
    this.isDropDown = false;
    this.dropDown.emit(this.isDropDown);
  }
}
