import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { OtherService } from 'src/app/services/others.service';
import { OtherStore } from 'src/app/stores/other.store';
import { ModalConfig, Other } from 'src/app/types';
import { ActionEventType, ColumnDef, EColumnType } from 'src/app/utils';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss'],
})
export class OthersComponent implements OnInit {
  ngOnInit(): void {}
}
