import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDaysDateInWeek, LogisticToursPaginationInterface } from 'src/app/interfaces/logistics-tours';
import { LogisticTour } from 'src/app/models/logistic-tour';
import { User } from 'src/app/models/user';
import { DataSharedStorageService } from 'src/app/services/data-shared-storage.service';
import { ToursService } from 'src/app/services/tours.service';
import { DAYSOFWEEK, YEARS } from 'src/app/utils';
import { getDaysFromStartOfYear, getMonthLabel, getMonthsForWeek, getWeekDates, getWeekNumber, months } from 'src/app/utils/helpers';


@Component({
  selector: 'app-planning-tours',
  templateUrl: './planning-tours.component.html',
  styleUrls: ['./planning-tours.component.scss']
})
export class PlanningToursComponent implements OnInit {

  error: string = '';
  message: string = '';
  currentUser!: User;

  loadingPlanning: boolean = false;
  today = new Date();

  weekDay: IDaysDateInWeek[] = [];
  daysLabel = DAYSOFWEEK;

  currentYears: number = this.today.getFullYear();
  yearFormControl = new FormControl(this.currentYears);
  years = YEARS;

  month = months;
  monthsOfWeek: string[] = [];

  currentWeek: number = 0;
  minWeek: number = 1;
  maxWeek: number = 52;
  arrayWeek = Array(52).fill(1);
  weekFormControl = new FormControl();

  // planningsList: Planning[] = PLANNINGS_LIST;
  plannings: LogisticTour[] = [];
  pagination: LogisticToursPaginationInterface = {
    page: 1,
    limit: 15,
    next: 1,
    last: 1,
    pages: 0,
    year: this.currentYears,
  };

  planningFilter: any;

  constructor(
    private toursService: ToursService,
    private router: Router, 
    private dataSharedStorageService: DataSharedStorageService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.planningFilter = this.dataSharedStorageService.getItem('planningFilter');
    this.currentWeek = getWeekNumber(this.today)
    if (this.planningFilter !== null) {
      this.currentWeek = getWeekNumber(this.planningFilter.date);
      this.currentYears = new Date(this.planningFilter.date).getFullYear();
      this.yearFormControl = new FormControl(this.currentYears);
      this.pagination.week = this.currentWeek;
      this.pagination.year = this.currentYears;
    }
    this.dataSharedStorageService.removeItem('planningFilter')
    this.setControlValue(this.currentWeek, this.weekFormControl);
    this.setWeekDatesDays(this.currentYears, this.currentWeek)
    this.pagination.week = this.currentWeek;
    this.getLogisticToursList(this.pagination);
    this.onWeekChange();
    this.onYearChange();
  }

  //ServicesAPI
  getLogisticToursList(pagination: LogisticToursPaginationInterface) {
    this.loadingPlanning = true;
    this.showSpinner()
    this.toursService.getLogisticToursList(pagination).subscribe({
      next: (response) => {
        this.plannings = response.logistic_tours
        this.loadingPlanning = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenu lors de la récupération des tournées. Merci de réessayer`;
        this.loadingPlanning = false;
        this.showSpinner();
      },
    })
  }

  onWeekChange() {
    this.weekFormControl.valueChanges.subscribe((value) => {
      if (!value) {
        delete this.pagination.week;
      } else {
        this.currentWeek = value;
        this.setCalendarDay()
        this.pagination.week = this.currentWeek;
        this.pagination.year = this.currentYears;
        this.pagination.page = 1;
        this.getLogisticToursList(this.pagination);
      }
    });
  }

  isWeekNotPast(weekDay: IDaysDateInWeek[]){
    return !weekDay.some((day) => this.isPossibleToAddTourToDay(day.days))
  }

  onYearChange() {
    this.yearFormControl.valueChanges.subscribe((value) => {
      if (!value) {
        delete this.pagination.year;
      } else {
        this.currentYears = value;
        this.setCalendarDay()
        this.pagination.year = this.currentYears;
        this.pagination.week = this.currentWeek;
      }
      this.pagination.page = 1;
      this.getLogisticToursList(this.pagination);
    });
  }

  previousWeek() {
    if (this.currentWeek <= this.minWeek) {
      this.currentWeek = this.maxWeek;
      this.currentYears -= 1;
      this.setControlValue(this.currentYears, this.yearFormControl);
    } else this.currentWeek -= 1;
    this.setControlValue(this.currentWeek, this.weekFormControl);
    this.setCalendarDay();
  }

  nextWeek() {
    if (this.currentWeek >= this.maxWeek) {
      this.currentWeek = this.minWeek;
      this.currentYears += 1;
      this.setControlValue(this.currentYears, this.yearFormControl);
    } else this.currentWeek += 1;
    this.setControlValue(this.currentWeek, this.weekFormControl);
    this.setCalendarDay();
  }

  setCalendarDay() {
    this.setWeekDatesDays(this.currentYears, this.currentWeek)
  }

  getMonthNumberLabel(number: number) {
    return getMonthLabel(number);
  }

  setControlValue(value: number, formControl: FormControl) {
    return formControl.setValue(value);
  }

  setWeekDatesDays(year: number, week: number) {
    this.weekDay = getWeekDates(this.currentYears, this.currentWeek);
    this.monthsOfWeek = getMonthsForWeek(this.weekDay);
  }

  goToCreateNewTour(day: IDaysDateInWeek) {
    const datePlannedTour = this.getDateFormate(day);
    this.dataSharedStorageService.setItem('planningFilter', { date: datePlannedTour });    
    this.router.navigateByUrl('/new-logistic-tour')
  }

  getDaysOfCurrentDay() {
    const date = this.today.getDate();
    const month = this.today.getMonth();
    const year = this.today.getFullYear();
    return getDaysFromStartOfYear(date, month, year) + 1
  }

  getDateFormate(day: IDaysDateInWeek) {
    return `${day.year}-${(day.monthNumber + 1).toString().padStart(2, '0')}-${day.date.toString().padStart(2, '0')}`
  }

  showSpinner() {
    if (this.loadingPlanning) this.spinner.show();
    else this.spinner.hide();
  }

  isPossibleToAddTourToDay(day: number) {
    if (this.currentYears > this.today.getFullYear()) {
      return true
    }
    else if (this.currentYears === this.today.getFullYear() && day >= this.getDaysOfCurrentDay()) {
      return true
    }
    else return false
  }
}
