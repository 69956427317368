<app-modal-logistic-tour-point [isOpen]="isOpenModal" (close)="closeModal($event)">
    <div *ngIf="isOpenModal">
        <form style="width: 100%;" [formGroup]="formPointOfDelivery">
            <div class="row bg-status-to-send py-2 d-flex justify-content-between">
                <div class="col-2 d-flex align-items-center">
                    <div class="row">
                        <div class="">
                            <label class="text-white m-auto fs-11 fw-lighter" for="departure_point">
                                <small>Point de départ</small>
                            </label>
                            <div class="form-control-sm rounded-2 bg-white text-dark" id="departure_point">
                                {{selectedPointOfDelivery.departure_point.name}}
                            </div>
                        </div>
                        <div class="">
                            <label class="text-white m-auto fs-11 fw-lighter" for="arrival_point">
                                <small>Choisir un dépôt</small>
                            </label>
                            <div class="form-control-sm rounded-2 bg-white text-dark" id="arrival_point">
                                {{selectedPointOfDelivery.arrival_point.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="d-flex">
                        <div class="col-5 d-flex flex-column justify-content-center">
                            <div class="row d-flex justify-content-start">
                                <div class="col-xl-5 col-10 d-flex flex-column">
                                    <label class="text-white fs-11 fw-lighter">
                                        <small>
                                            Date de dép. prévue
                                        </small>
                                    </label>
                                    <input type="date" class="form-control-sm border-0 bg-gray-light text-dark"
                                        
                                        formControlName="planned_departure" placeholder="Date de dép.">
                                </div>
                                <div class="col-xl-5 col-10 mx-1 d-flex flex-column ">
                                    <label class="text-white fs-11 fw-lighter">
                                        <small>
                                            Heure de dép. prévue
                                        </small>
                                    </label>
                                    <input type="time" class="form-control-sm border-0 bg-gray-light text-dark"
                                        formControlName="planned_departure_time" placeholder="Heure">
                                </div>
                                <div class="col-xl-5 col-10 d-flex flex-column">
                                    <label class="text-white fs-11 fw-lighter">
                                        <small>
                                            Date d'arr. prévue
                                        </small>
                                    </label>
                                    <input type="date" class="form-control-sm border-0 bg-gray-light text-dark"
                                        formControlName="planned_arrival" placeholder="Date de d'arrivée">
                                </div>
                                <div class="col-xl-5 col-10 mx-1 d-flex flex-column ">
                                    <label class="text-white fs-11 fw-lighter">
                                        <small>
                                            Heure d'arr. prévue
                                        </small>
                                    </label>
                                    <input type="time" class="form-control-sm border-0 bg-gray-light text-dark"
                                        formControlName="planned_arrival_time" placeholder="Heure">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-start align-items-center">
                            <div class="row">
                                <small class="text-white">
                                    Statut:
                                </small>
                                <span class="bg-white rounded-pill px-1 py-1 fw-semibold">
                                    {{ translatePointOfDeliveryStatus(selectedPointOfDelivery.status) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row bg-white">
                <div class="col-4 d-flex justify-content-center m-2 input-group flex-nowrap">
                </div>
                <div class="col-11 bg-gray-light m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                    <table class="table align-middle ">
                        <thead class="table-light position-relative">
                            <tr class="rounded">
                                <th scope="col">Produit</th>
                                <th scope="col" class="text-center">Qté à charger</th>
                                <th scope="col" class="text-center">Qté chargée</th>
                                <th scope="col" class="text-center">Palettes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <tr [formGroupName]="index">
                                    <th scope="row" class="
                                        fw-bold text-info col-2"> {{ product.value.product_name }}</th>
                                    <td class="align-middle fw-bold text-center text-info col-2">
                                        <input type="number" class="text-center border-0 rounded-2"
                                            formControlName="asked" readonly [min]="0">
                                    </td>
                                    <td class="align-middle fw-bold text-center text-info col-2">
                                        <input type="text" class="text-center border-0 rounded-2"
                                            formControlName="loaded" (ngModelChange)="getPalletCountForProduct(product)"
                                            [min]="1"
                                            [class.invalid-asked]="getLoadProductsValues().get(index + '.loaded')?.hasError('invalidAsked')">
                                    </td>
                                    <td class="align-middle fw-bold text-center text-info col-2">
                                        <input type="number" readonly
                                            class="text-center bg-gray-light border-0 rounded-2"
                                            formControlName="pallet" [min]="0">

                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="col-11 m-2 mx-auto d-flex justify-content-between">
                    <div class="col">
                        <input type="button" value="Fermer" (click)="closeModal(false)" class="btn btn-info text-white">
                    </div>
                    <div class="col d-flex justify-content-end"
                        *ngIf="index !== logisticTour.point_of_deliveries.length -1">
                        <input type="button" [disabled]="formPointOfDelivery.invalid || index === 0" value="Point précédent"
                            class="btn btn-info text-white mx-2" (click)="previousPoint()">
                        <input type="button" [disabled]="formPointOfDelivery.invalid" value="Point suivant"
                            class="btn btn-primary text-white mx-2" (click)="nextPoint()">
                    </div>
                    <div class="col d-flex justify-content-end"
                        *ngIf="index === logisticTour.point_of_deliveries.length -1">
                        <input type="button" [disabled]="formPointOfDelivery.invalid || index === 0" value="Point précédent"
                            class="btn btn-info text-white mx-2" (click)="previousPoint()">
                        <input type="button" [disabled]="formPointOfDelivery.invalid" value="Valider"
                            class="btn btn-primary text-white mx-2" (click)="validatePointsOfDelivery()">
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-modal-logistic-tour-point>