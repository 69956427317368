import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EPointOfDeliveryStatus, PointOfDeliveryDto, PointOfDelivery } from 'src/app/models/point-of-delivery';

@Component({
  selector: 'app-time-line-point',
  templateUrl: './time-line-point.component.html',
  styleUrls: ['./time-line-point.component.scss']
})
export class TimeLinePointComponent implements OnInit {
  @Input() point_of_delivery!: PointOfDeliveryDto;
  @Input() point_of_delivery_name?: string;
  @Input() planned_departure?: string;
  @Input() planned_departure_time?: string;
  @Input() indexPointLine!: number;
  @Input() pointLinesLength!: number;
  @Output() addNewPoint = new EventEmitter();
  @Output() openPoint = new EventEmitter<{point_of_delivery: any
  , index: number}>();
  @Output() removePointInPointsOfDeliveries = new EventEmitter();

  //detail logistic tour
  @Input() point_of_delivery_detail!: PointOfDelivery;
  @Input() start_date?: string;


  EPointOfDeliveryStatus = EPointOfDeliveryStatus;
  today = new Date();
  constructor() { }

  ngOnInit(): void {
  }

  addPoint(){
    this.addNewPoint.emit()
  }

  removePoint(index: number){
    this.removePointInPointsOfDeliveries.emit(index)
  }

  openDetailPoint(point_of_delivery: any, index: number){
    this.openPoint.emit({point_of_delivery, index})
  }
}
