import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSearchModalComponent } from './filter-search-modal.component';
import { NgxBootstrapIconsModule, filter, xCircle } from 'ngx-bootstrap-icons';
import { ModalModule } from '../modal/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const icons = {
  filter,
  xCircle
};
@NgModule({
  declarations: [
    FilterSearchModalComponent
  ],
  exports:[FilterSearchModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    NgxBootstrapIconsModule.pick(icons),
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class FilterSearchModalModule { }
