<div *ngIf="!loading && !loadingDepots && !loadingUsers && !loadingtruck"
    class="d-flex justify-content-between flex-wrap flex-md-wrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <div class="d-flex align-items-center">
        <app-go-back></app-go-back>
        <h1 class="h3 text-primary">Détail de la tournée</h1>
        <span class="mx-2 p-1 rounded-1 fw-bold" [ngClass]="{
        'tour-status-cancelled': logisticTour.status === ELogisticTourStatus.CANCELED,
        'tour-status-finish': logisticTour.status === ELogisticTourStatus.FINISH,
        'tour-status-in-progress': logisticTour.status === ELogisticTourStatus.IN_PROGRESS,
        'tour-status-new': logisticTour.status === ELogisticTourStatus.SCHEDULED}">
            {{ translateLogisticTourStatus(logisticTour.status) }}
        </span>
    </div>
    <div class="d-flex justify-content-end">
        <ng-template
            [ngIf]="logisticTour.status === ELogisticTourStatus.IN_PROGRESS || logisticTour.status === ELogisticTourStatus.SCHEDULED">
            <div class="d-flex justify-content-end">
                <div class="btn-container mb-3" style="flex-direction: row-reverse" (click)="onShowOrCloseTootltip()">
                    <span class="action-label">Actions</span>
                    <div class="custom-tooltip" [ngStyle]="{
                    display: open_tooltip ? 'block' : 'none'
                  }">
                        <div *ngFor="let action of actions" class="custom-tooltip-option"
                            [ngClass]="{'custom-tooltip-option-cancel': action.action === EActionTourLogistic.CANCEL}"
                            (click)="onActionItemClick(action)">
                            <div class="custom-tooltip-option-label">
                                {{ action.label }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<div *ngIf="!loading && !loadingDepots && !loadingUsers && !loadingtruck" class="row d-flex justify-content-around">
    <div class="col-4 p-2 rounded-4 d-flex flex-column justify-content-between align-items-between">
        <div class="card rounded-4 mb-3">
            <div class="row m-3 bg-gray-light rounded-2 py-4">
                <div class="row">
                    <div class="col-5 m-2 justify-content-start">
                        <div class=""><small>Point de départ</small></div>
                        <div
                            class="align-items-center justify-content-center rounded-pill fw-bold text-dark">
                            <small>
                                {{
                                logisticTour.point_of_deliveries[0].departure_point.name || "--" }}
                            </small>
                        </div>
                    </div>
                    <div class="col-5 m-2">
                        <div class=""><small>Date de départ</small></div>
                        <div
                            class=" align-items-center justify-content-center rounded-pill fw-bold text-dark">
                            <small>
                                {{logisticTour.start_date | date:'dd/MM/YYYY'}}
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 m-2">
                        <div class=""><small>Heure de départ</small></div>
                        <div
                            class=" align-items-center justify-content-center rounded-pill fw-bold text-dark">
                            <small>
                                {{logisticTour.start_date | date:'HH:mm'}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-3 mb-3 rounded-2 bg-primary">
                <div class="d-flex flex-column m-2">
                    <small class="text-white">
                        Créer par:
                    </small>
                    <span class="text-white fw-bold">
                        {{logisticTour.created_by.username}}
                    </span>
                </div>
                <div class="d-flex flex-column m-2">
                    <small class="text-white">
                        Date de création:
                    </small>
                    <span class="text-white fw-bold">
                        {{logisticTour.created_at | date: 'dd/MM/YYYY'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="card bg-gray-light rounded-4 p-4 ">
            <div class="row align-items-center bg-white rounded-4 p-1">
                <div class="col-7"> Capacité : </div>
                <div class="col-5 rounded-4 bg-info p-1 text-center">
                    <span class="text-white">
                        {{ vehiclePallets }} Palettes
                    </span>
                </div>
            </div>
            <div class="row align-items-center bg-white rounded-4 p-1 mt-3">
                <div class="col-7"> Taux de remplissage : </div>
                <div class="col-5 d-flex justify-content-start rounded-4 bg-gray-light" style="padding: 0;">
                    <div class="rounded-4 py-1 text-center" *ngIf="vehiclePallets > 0" style="min-width: 30%;"
                        [ngStyle]="{ 'width': +calculatePercentagePalletLoaded(loaderPallets, vehiclePallets)+'%'}"
                        [ngClass]="{'bg-primary': vehiclePallets >= loaderPallets, 'bg-danger': vehiclePallets < loaderPallets}">
                        <span class="text-white" style="position: relative;">
                            {{calculatePercentagePalletLoaded(loaderPallets, vehiclePallets) }} %
                        </span>
                    </div>
                    <div class="rounded-4 text-center py-1 bg-danger opacity-75" style="width: 100%;"
                        *ngIf="vehiclePallets <= 0">
                        <span class="text-white">
                            {{ "---" }} % ({{roundPositivePallet(loaderPallets)}} Palettes)
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-7 m-2 border rounded-4 bg-gray-light">
        <h5 class="text-center fw-bold m-3">
            Route liste de la tournée
        </h5>
        <div class="py-2" style="max-height: 70vh;overflow-y: auto; overflow-x: hidden;">
            <ng-container *ngFor="let point of logisticTour.point_of_deliveries; let index=index">
                <div>
                    <app-time-line-point [pointLinesLength]="logisticTour.point_of_deliveries.length"
                        [start_date]="logisticTour.start_date" [indexPointLine]="index"
                        [point_of_delivery_detail]="point" (openPoint)="openModalDetailPoint($event)"
                        (openPoint)="openModalDetailPoint($event)">
                    </app-time-line-point>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-start-tour-flow [logisticTour]="logisticTour" (closeModalStartTour)="closeModalStartTour(false)"
    (validatePoints)="startLogisticTour($event)" *ngIf="isOpenModalStarTour"
    [isOpenModal]="isOpenModalStarTour"></app-start-tour-flow>

<app-modal-logistic-tour-point [isOpen]="isOpenModal" (close)="closeModal($event)">
    <ng-template [ngIf]="selectedPointOfDelivery">
        <div [ngSwitch]="selectedPointOfDelivery.status">
            <ng-template [ngSwitchCase]="EPointOfDeliveryStatus.SENDED">
                <ng-container [ngTemplateOutlet]="detailPointOfDeliveryWithSendedStatus"></ng-container>
            </ng-template>
            <ng-template [ngSwitchCase]='EPointOfDeliveryStatus.TO_SEND'>
                <ng-container [ngTemplateOutlet]="detailPointOfDeliveryWithToSendStatus"></ng-container>
            </ng-template>
            <ng-template [ngSwitchCase]='EPointOfDeliveryStatus.CANCELED'>
                <ng-container [ngTemplateOutlet]="detailPointOfDeliveryWithCancelledStatus"></ng-container>
            </ng-template>
            <ng-template [ngSwitchCase]='EPointOfDeliveryStatus.RECEIVED'>
                <ng-container [ngTemplateOutlet]="detailPointOfDeliveryWithReceivedStatus"></ng-container>
            </ng-template>
        </div>
    </ng-template>
</app-modal-logistic-tour-point>

<ng-template #detailPointOfDeliveryWithToSendStatus>
    <div *ngIf="isOpenModal">
        <form style="width: 100%;" [formGroup]="formPointOfDelivery">
            <div class="d-flex">
                <div class="col-2 d-flex bg-gray-light flex-column">
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                            'bg-info opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS
                        }" (click)="changeModalFrame(EModalFrameDeliveryPoint.FRAME_INFOS)">
                        <span class="text-center ">
                            Infos Gen.
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                        'bg-info opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS
                    }" (click)="changeModalFrame(EModalFrameDeliveryPoint.PRODUCTS)">
                        <span class="text-center">
                            Produits
                        </span>
                    </div>
                </div>
                <div class="col-10">
                    <div
                        class="d-flex bg-info flex-row justify-content-between align-items-center p-4 mx-auto frame-height">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-white m-auto mx-2" for="departure_point">
                                <span class="fs-5 fw-bolder">Point de départ</span>
                            </label>
                            <small class="text-white mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.departure_point.name}} </small>
                            <select hidden class="form-select-sm rounded-2 bg-white text-dark"
                                formControlName="departure_point">
                                <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-white m-auto mx-2" for="arrival_point">
                                <span class="fs-5 fw-bolder">Destination</span>
                            </label>
                            <small class="text-white mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.arrival_point.name}} </small>

                            <select hidden class="form-select rounded-2 text-dark ml-2" formControlName="arrival_point">
                                <option value="" selected>Choisir un dépôt</option>
                                <option *ngFor="let depot of depotsList" [value]="depot.id">{{depot.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS">
                        <h5 class="text-center mt-4">Départ</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).date"
                                            placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).date"
                                            placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-4">Arrivée</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure d'arrivée prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).date"
                                            placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de d'arrivée réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).date"
                                            placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-5">Transport</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id">
                                    <small class="fw-semibold">Vehicule ({{ vehicleChoiceFirst ?
                                        translateVehicleType(vehicleChoiceFirst.vehicle_type) :
                                        '--'}})</small>
                                </label>
                                <ng-select (ngModelChange)="onVehicleChoiceFirstChange($event)"
                                    formControlName="truck_id" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of othersVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}} <small>({{
                                            translateVehicleType(vehicle.vehicle_type)
                                            }})</small> </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2"
                                *ngIf="vehicleChoiceFirst?.vehicle_type === VehicleType.ROAD_TRACTOR">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id_two">
                                    <small
                                        class="fw-semibold">{{translateVehicleType(VehicleType.SEMI_TRAILER)}}</small>
                                </label>
                                <ng-select (ngModelChange)="onVehicleChoiceSecondChange($event)"
                                    formControlName="truck_id_two" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of semiTrailerVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="driver">
                                    <small class="fw-semibold">Chauffeur</small>
                                </label>
                                <ng-select formControlName="driver" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let user of driverUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="conveyor">
                                    <small class="fw-semibold">Convoyeur</small>
                                </label>
                                <ng-select formControlName="conveyor" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of conveyorsUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>

                    </div>

                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS">
                        <div class="row d-flex my-2 align-items-center ">
                            <div class="input-group flex-nowrap justify-content-center">
                                <input type="search" [formControl]="searchProduct" class="col-4 rounded-start-circle"
                                    placeholder="Rechercher un produit" aria-label="Rechercher un produit"
                                    aria-describedby="addon-wrapping">
                                <span class="bg-info px-4 py-2" style="border-radius: 0 30px 30px 0;"
                                    id="addon-wrapping">
                                    <i-bs name="search" class="text-white"></i-bs>
                                </span>
                            </div>
                        </div>
                        <div class="col-11 m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                            <div class="section-details-item section-details-list-header">
                                <div>Produits</div>
                                <div class="center">Qté à charger</div>
                                <div class="center">Palettes</div>
                            </div>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <div class="section-details-item section-details-list-item" [formGroupName]="index"
                                    *ngIf="!product.value.isIncludeSearchTerm">
                                    <div class="">
                                        <span class="text-info bold">
                                            {{ product.value.product_name }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <input type="text" class="text-center border-0 rounded-2" style="outline: none;"
                                            formControlName="asked" (ngModelChange)="getPalletCountForProduct(product)"
                                            [min]="1"
                                            [class.invalid-asked]="getLoadProductsValues().get(index + '.asked')?.hasError('invalidAsked')">
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked > 0 ? product.value.pallet : "" }}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isEmptyListProductPointFilter">
                                <div class="text-center">
                                    Aucun produit correspondant à cette recherche:
                                    <strong>'{{searchProduct.value}}'</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end border-top px-4">
                        <input type="button" value="Fermer" (click)="closeModal(false)"
                            class="btn m-2 btn-info text-white">
                        <input type="button" (click)="confirmDeletePointOfDelivery()" value="Supprimer"
                            class="btn m-2 text-white btn-danger">
                        <input type="submit" [disabled]="formPointOfDelivery.invalid || searchProduct.value"
                            value="Enregistrer" class="btn btn-primary text-white m-2"
                            (click)="updatePointOfDeliveryNew()">
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #detailPointOfDeliveryWithSendedStatus>
    <div *ngIf="isOpenModal">
        <form style="width: 100%;" [formGroup]="formPointOfDelivery">
            <div class="d-flex">
                <div class="col-2 d-flex bg-gray-light flex-column">
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                            'bg-status-sended  opacity-75': modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS
                        }" (click)="changeModalFrame(EModalFrameDeliveryPoint.FRAME_INFOS)">
                        <span class="text-center text-dark">
                            Infos Gen.
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                        'bg-status-sended  opacity-75 text-dark': modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS
                    }" (click)="changeModalFrame(EModalFrameDeliveryPoint.PRODUCTS)">
                        <span class="text-center text-dark">
                            Produits
                        </span>
                    </div>
                </div>
                <div class="col-10">
                    <div
                        class="d-flex bg-status-sended flex-row justify-content-between align-items-center p-4 mx-auto frame-height">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-dark m-auto mx-2" for="departure_point">
                                <span class="fs-5 fw-bolder">Point de départ</span>
                            </label>
                            <small class="text-dark mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.departure_point.name}} </small>
                            <select hidden class="form-select-sm rounded-2 bg-white text-dark"
                                formControlName="departure_point">
                                <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex flex-row justify-content-between align-items-center">
                            <label class="text-dark m-auto mx-2" for="arrival_point">
                                <span class="fs-5 fw-bolder">Destination</span>
                            </label>
                            <small class="text-dark mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.arrival_point.name}} </small>

                            <select hidden class="form-select rounded-2 text-dark ml-2" formControlName="arrival_point">
                                <option value="" selected>Choisir un dépôt</option>
                                <option *ngFor="let depot of depotsList" [value]="depot.id">{{depot.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS">
                        <h5 class="text-center mt-4">Départ</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).date"
                                            placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="departure" placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-4">Arrivée</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure d'arrivée prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).date"
                                            placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de d'arrivée réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="arrival" placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            formControlName="arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-5">Transport</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id">
                                    <small class="fw-semibold">Vehicule ({{ vehicleChoiceFirst ?
                                        translateVehicleType(vehicleChoiceFirst.vehicle_type) :
                                        '--'}})</small>
                                </label>
                                <ng-select (ngModelChange)="onVehicleChoiceFirstChange($event)"
                                    formControlName="truck_id" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of othersVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}} <small>({{
                                            translateVehicleType(vehicle.vehicle_type)
                                            }})</small> </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2"
                                *ngIf="vehicleChoiceFirst?.vehicle_type === VehicleType.ROAD_TRACTOR">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id_two">
                                    <small
                                        class="fw-semibold">{{translateVehicleType(VehicleType.SEMI_TRAILER)}}</small>
                                </label>
                                <ng-select (ngModelChange)="onVehicleChoiceSecondChange($event)"
                                    formControlName="truck_id_two" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of semiTrailerVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="driver">
                                    <small class="fw-semibold">Chauffeur</small>
                                </label>
                                <ng-select formControlName="driver" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let user of driverUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="conveyor">
                                    <small class="fw-semibold">Convoyeur</small>
                                </label>
                                <ng-select formControlName="conveyor" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of conveyorsUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-start mx-4 my-2">
                            <div class="col-8 d-flex justify-content-start ext-dark" *ngIf="checkChangeFromInfosTourItem">
                                <label class="text-dark my-auto fs-11 fw-lighter" style="margin-right: 1rem;" for="conveyor_data_from_other_points">
                                    <small class="fw-semibold">Cocher pour modifier les points suivants</small>
                                </label>
                                <input type="checkbox" formControlName="update_conveyor_data_from_other_points" id="conveyor_data_from_other_points">
                            </div>
                        </div>
                    </div>

                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS">
                        <div class="row d-flex my-2 align-items-center ">
                            <div class="input-group flex-nowrap justify-content-center">
                                <input type="search" [formControl]="searchProduct" class="col-4 rounded-start-circle"
                                    placeholder="Rechercher un produit" aria-label="Rechercher un produit"
                                    aria-describedby="addon-wrapping">
                                <span class="bg-info px-4 py-2" style="border-radius: 0 30px 30px 0;"
                                    id="addon-wrapping">
                                    <i-bs name="search" class="text-white"></i-bs>
                                </span>
                            </div>
                        </div>
                        <div class="col-11 m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                            <div class="section-details-item section-details-list-header">
                                <div>Produits</div>
                                <div class="center">Qté à charger</div>
                                <div class="center">Qté chargée</div>
                                <div class="center">Palettes</div>
                            </div>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <div class="section-details-item section-details-list-item" [formGroupName]="index"
                                    *ngIf="!product.value.isIncludeSearchTerm">
                                    <div class="">
                                        <span class="text-info bold">
                                            {{ product.value.product_name }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <input class="text-center border-0 rounded-2" type="number"
                                            formControlName="loaded"
                                            (ngModelChange)="getPalletCountForLoadProduct(product)" [min]="0"
                                            [class.invalid-asked]="getLoadProductsValues().get(index + '.loaded')?.hasError('invalidAsked')">
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.loaded > 0 ? product.value.pallet : "" }}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isEmptyListProductPointFilter">
                                <div class="text-center">
                                    Aucun produit correspondant à cette recherche:
                                    <strong>'{{searchProduct.value}}'</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end border-top px-4">
                        <input type="button" value="Fermer" (click)="closeModal(false)"
                            class="btn btn-info text-white m-2">
                        <input type="button" (click)="confirmCancelPointOfDelivery()" value="Annuler"
                            class="btn text-white btn-danger  m-2">
                        <input type="submit" [disabled]="formPointOfDelivery.invalid || searchProduct.value"
                            value="Enregistrer" class="btn btn-primary text-white m-2"
                            (click)="updatePointOfDeliveryInProgress()">
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #detailPointOfDeliveryWithCancelledStatus>
    <div *ngIf="isOpenModal">
        <form style="width: 100%;" [formGroup]="formPointOfDelivery">
            <div class="d-flex">
                <div class="col-2 d-flex bg-gray-light flex-column">
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                            ' bg-status-cancelled opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS
                        }" (click)="changeModalFrame(EModalFrameDeliveryPoint.FRAME_INFOS)">
                        <span class="text-center">
                            Infos Gen.
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                        ' bg-status-cancelled opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS
                    }" (click)="changeModalFrame(EModalFrameDeliveryPoint.PRODUCTS)">
                        <span class="text-center">
                            Produits
                        </span>
                    </div>
                </div>
                <div class="col-10">
                    <div
                        class="d-flex  bg-status-cancelled flex-row justify-content-between align-items-center p-4 mx-auto frame-height">
                        <div class="d-flex text-white flex-row justify-content-between align-items-center">
                            <label class=" m-auto mx-2" for="departure_point">
                                <span class="fs-5 fw-bolder">Point de départ</span>
                            </label>
                            <small class=" mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.departure_point.name}} </small>
                            <select hidden class="form-select-sm rounded-2 bg-white " formControlName="departure_point">
                                <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex text-white flex-row justify-content-between align-items-center">
                            <label class=" m-auto mx-2" for="arrival_point">
                                <span class="fs-5 fw-bolder">Destination</span>
                            </label>
                            <small class=" mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.arrival_point.name}} </small>

                            <select hidden class="form-select rounded-2  ml-2" formControlName="arrival_point">
                                <option value="" selected>Choisir un dépôt</option>
                                <option *ngFor="let depot of depotsList" [value]="depot.id">{{depot.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS">
                        <h5 class="text-center mt-4">Départ</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).date"
                                            placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_departure" placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-4">Arrivée</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure d'arrivée prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).date"
                                            placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de d'arrivée réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_arrival" placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-5">Transport</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id">
                                    <small class="fw-semibold">Vehicule ({{ vehicleChoiceFirst ?
                                        translateVehicleType(vehicleChoiceFirst.vehicle_type) :
                                        '--'}})</small>
                                </label>
                                <ng-select [readonly]="true" (ngModelChange)="onVehicleChoiceFirstChange($event)"
                                    formControlName="truck_id" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of othersVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}} <small>({{
                                            translateVehicleType(vehicle.vehicle_type)
                                            }})</small> </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2"
                                *ngIf="vehicleChoiceFirst?.vehicle_type === VehicleType.ROAD_TRACTOR">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id_two">
                                    <small
                                        class="fw-semibold">{{translateVehicleType(VehicleType.SEMI_TRAILER)}}</small>
                                </label>
                                <ng-select [readonly]="true" (ngModelChange)="onVehicleChoiceSecondChange($event)"
                                    formControlName="truck_id_two" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of semiTrailerVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="driver">
                                    <small class="fw-semibold">Chauffeur</small>
                                </label>
                                <ng-select [readonly]="true" formControlName="driver" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of driverUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="conveyor">
                                    <small class="fw-semibold">Convoyeur</small>
                                </label>
                                <ng-select [readonly]="true" formControlName="conveyor" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of conveyorsUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS">
                        <div class="row d-flex my-2 align-items-center ">
                            <div class="input-group flex-nowrap justify-content-center">
                                <input type="search" [formControl]="searchProduct" class="col-4 rounded-start-circle"
                                    placeholder="Rechercher un produit" aria-label="Rechercher un produit"
                                    aria-describedby="addon-wrapping">
                                <span class="bg-info px-4 py-2" style="border-radius: 0 30px 30px 0;"
                                    id="addon-wrapping">
                                    <i-bs name="search" class="text-white"></i-bs>
                                </span>
                            </div>
                        </div>
                        <div class="col-11 m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                            <div class="section-details-item section-details-list-header">
                                <div>Produits</div>
                                <div class="center">Qté à charger</div>
                                <div class="center"
                                    *ngIf="isDeliveredProductLoaded(selectedPointOfDelivery.point_of_delivery_items)">
                                    Qté chargée</div>
                                <div class="center">Palettes</div>
                            </div>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <div class="section-details-item section-details-list-item" [formGroupName]="index"
                                    *ngIf="!product.value.isIncludeSearchTerm">
                                    <div class="">
                                        <span class="text-info bold">
                                            {{ product.value.product_name }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked }}
                                        </span>
                                    </div>
                                    <div class="center"
                                        *ngIf="isDeliveredProductLoaded(selectedPointOfDelivery.point_of_delivery_items)">
                                        <span class="text-info bold">
                                            {{ product.value.loaded }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked > 0 ? product.value.pallet : "" }}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isEmptyListProductPointFilter">
                                <div class="text-center">
                                    Aucun produit correspondant à cette recherche:
                                    <strong>'{{searchProduct.value}}'</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end px-4 border-top">
                        <input type="button" value="Fermer" (click)="closeModal(false)"
                            class="btn btn-danger text-white m-2">
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #detailPointOfDeliveryWithReceivedStatus>
    <div *ngIf="isOpenModal">
        <form style="width: 100%;" [formGroup]="formPointOfDelivery">
            <div class="d-flex">
                <div class="col-2 d-flex bg-gray-light flex-column">
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                            'bg-status-received opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS
                        }" (click)="changeModalFrame(EModalFrameDeliveryPoint.FRAME_INFOS)">
                        <span class="text-center">
                            Infos Gen.
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center py-4 cursor frame-height" [ngClass]="{
                        'bg-status-received opacity-75 text-white': modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS
                    }" (click)="changeModalFrame(EModalFrameDeliveryPoint.PRODUCTS)">
                        <span class="text-center">
                            Produits
                        </span>
                    </div>
                </div>
                <div class="col-10">
                    <div
                        class="d-flex bg-status-received flex-row justify-content-between align-items-center p-4 mx-auto frame-height">
                        <div class="d-flex text-white flex-row justify-content-between align-items-center">
                            <label class=" m-auto mx-2" for="departure_point">
                                <span class="fs-5 fw-bolder">Point de départ</span>
                            </label>
                            <small class=" mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.departure_point.name}} </small>
                            <select hidden class="form-select-sm rounded-2 bg-white " formControlName="departure_point">
                                <option *ngFor="let depot of depotsList" [value]="depot.id" disabled>{{depot.name}}
                                </option>
                            </select>
                        </div>
                        <div class="d-flex text-white flex-row justify-content-between align-items-center">
                            <label class=" m-auto mx-2" for="arrival_point">
                                <span class="fs-5 fw-bolder">Destination</span>
                            </label>
                            <small class=" mx-3 fs-8 fw-lighter">
                                {{selectedPointOfDelivery.arrival_point.name}} </small>

                            <select hidden class="form-select rounded-2  ml-2" formControlName="arrival_point">
                                <option value="" selected>Choisir un dépôt</option>
                                <option *ngFor="let depot of depotsList" [value]="depot.id">{{depot.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.FRAME_INFOS">
                        <h5 class="text-center mt-4">Départ</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).date"
                                            placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_departure).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>

                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de départ réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_departure" placeholder="Date de dép.">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_departure_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-4">Arrivée</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure d'arrivée prévues
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).date"
                                            placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly
                                            [value]="getDateAndTimeFromLongDate(selectedPointOfDelivery.planned_arrival).time"
                                            placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 d-flex flex-column text-dark">
                                <label class="m-auto fs-11 fw-lighter" for="arrival_point">
                                    <small class="fw-semibold">
                                        Date et heure de d'arrivée réelles
                                    </small>
                                </label>
                                <div class="d-flex justify-content-between">
                                    <div class="col-7">
                                        <input type="date" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_arrival" placeholder="Date de d'arrivée">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control border-0 bg-gray-light text-dark"
                                            readonly formControlName="planned_arrival_time" placeholder="Heure">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 class="text-center mt-5">Transport</h5>
                        <div class="row d-flex justify-content-between mx-4">
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id">
                                    <small class="fw-semibold">Vehicule ({{ vehicleChoiceFirst ?
                                        translateVehicleType(vehicleChoiceFirst.vehicle_type) :
                                        '--'}})</small>
                                </label>
                                <ng-select [readonly]="true" (ngModelChange)="onVehicleChoiceFirstChange($event)"
                                    formControlName="truck_id" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of othersVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}} <small>({{
                                            translateVehicleType(vehicle.vehicle_type)
                                            }})</small> </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2"
                                *ngIf="vehicleChoiceFirst?.vehicle_type === VehicleType.ROAD_TRACTOR">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="truck_id_two">
                                    <small
                                        class="fw-semibold">{{translateVehicleType(VehicleType.SEMI_TRAILER)}}</small>
                                </label>
                                <ng-select [readonly]="true" (ngModelChange)="onVehicleChoiceSecondChange($event)"
                                    formControlName="truck_id_two" [dropdownPosition]="'bottom'" style="width: 100%;">
                                    <ng-option *ngFor="let vehicle of semiTrailerVehiclesList; let index = index"
                                        [value]="vehicle.id">
                                        {{vehicle.immatriculation}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="driver">
                                    <small class="fw-semibold">Chauffeur</small>
                                </label>
                                <ng-select [readonly]="true" formControlName="driver" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of driverUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-4 d-flex flex-column text-dark my-2">
                                <label class="text-dark m-auto fs-11 fw-lighter" for="conveyor">
                                    <small class="fw-semibold">Convoyeur</small>
                                </label>
                                <ng-select [readonly]="true" formControlName="conveyor" [dropdownPosition]="'bottom'"
                                    style="width: 100%;">
                                    <ng-option *ngFor="let user of conveyorsUsers; let index = index" [value]="user.id">
                                        {{ user.username }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="bg-white" style="min-height: 400px;"
                        *ngIf="modalFrameSelected === EModalFrameDeliveryPoint.PRODUCTS">
                        <div class="row d-flex my-2 align-items-center ">
                            <div class="input-group flex-nowrap justify-content-center">
                                <input type="search" [formControl]="searchProduct" class="col-4 rounded-start-circle"
                                    placeholder="Rechercher un produit" aria-label="Rechercher un produit"
                                    aria-describedby="addon-wrapping">
                                <span class="bg-info px-4 py-2" style="border-radius: 0 30px 30px 0;"
                                    id="addon-wrapping">
                                    <i-bs name="search" class="text-white"></i-bs>
                                </span>
                            </div>
                        </div>
                        <div class="col-11 m-2 mx-auto py-2" style="height: 50vh; overflow-y: auto;">
                            <div class="section-details-item section-details-list-header">
                                <div>Produits</div>
                                <div class="center">Qté à charger</div>
                                <div class="center">Qté chargée</div>
                                <div class="center">Qté livrée</div>
                                <div class="center">Palettes</div>
                            </div>
                            <ng-container *ngFor="let product of getLoadProductsValues().controls; let index= index"
                                formArrayName="delivered_products">
                                <div class="section-details-item section-details-list-item" [formGroupName]="index"
                                    *ngIf="!product.value.isIncludeSearchTerm">
                                    <div class="">
                                        <span class="text-info bold">
                                            {{ product.value.product_name }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.asked }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.loaded }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.delivered }}
                                        </span>
                                    </div>
                                    <div class="center">
                                        <span class="text-info bold">
                                            {{ product.value.pallet }}
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isEmptyListProductPointFilter">
                                <div class="text-center">
                                    Aucun produit correspondant à cette recherche:
                                    <strong>'{{searchProduct.value}}'</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end px-4 border-top">
                        <input type="button" value="Fermer" (click)="closeModal(false)"
                            class="btn btn-danger text-white m-2">
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<modal #confirmModal [modalConfig]="modalConfig" class="modal-dialog-centered">
    <div class="h4 fw-bold text-center">
        {{modalConfig.modalTitle}}
    </div>
    <p class="text-center">
        {{modalConfig.contentText}}
    </p>
</modal>