import { PaginationInterface } from '../interfaces/pagination-interface';
import { LogisticTour } from './logistic-tour';
import { Product } from './product';
import { User } from './user';
import { Vehicle } from './vehicle';

export class PointOfDelivery {
  constructor(
    public id: number,
    public logistic_tour_id: number,
    public logistic_tour_item_id: number,
    public code: string,
    public departure: string,
    public arrival: string,
    public planned_departure: string,
    public planned_arrival: string,
    public status: EPointOfDeliveryStatus,
    public point_of_delivery_items: ILogisticDeliveredProducts[],
    public semi_trailer: Vehicle,
    public road_tractor: Vehicle,
    public carrier_truck: Vehicle,
    public conveyor: User,
    public driver: User,
    public departure_point: IPoint,
    public created_by: User,
    public last_updated_by: User,
    public is_deleted: false,
    public created_at: string,
    public updated_at: string,
    public arrival_point: IPoint,
    public logistic_tour: LogisticTour
  ) {}
}

export enum EPointOfDeliveryStatus {
  CANCELED = 'CANCELED',
  TO_SEND = 'TO_SEND',
  SENDED = 'SENDED',
  RECEIVED = 'RECEIVED',
}

export enum EPointOfDeliveryStatusFr {
  CANCELED = 'Annulé',
  TO_SEND = 'Planifié',
  SENDED = 'En attente de réception',
  RECEIVED = 'Reçu',
}

export interface IPoint {
  id: number;
  user_id: number;
  name: string;
  localisation: string;
  created_at: string;
  updated_at: string;
  activate: boolean;
  owner: string;
}

export interface ILogisticDeliveredProducts {
  id: number;
  product: Product;
  point_of_delivery_id: number;
  asked: number;
  loaded: number;
  delivered?: number;
  is_deleted: boolean;
  state: string;
  created_at: string;
  updated_at: string;
}

export interface IDeliveredProducts {
  product_id: number;
  asked: number;
  pallet: number;
}

export interface IRemoveProduct{
  product_id: number;
}

export interface ILoadedProducts {
  product_id: number;
  asked: number;
  loaded: number;
  pallet: number;
}

export class UpdatePointOfDeliveryDto {
  arrival_point?: number;
  departure_point?: number;
  planned_departure?: string;
  planned_arrival?: string;
  delivered_products?: IDeliveredProducts[];
  products_to_remove?: IRemoveProduct[];
  conveyor?: number;  
  driver?: number;  
  carrier_truck?: number;  
  road_tractor?: number;  
  semi_trailer?: number;
  update_conveyor_data_from_other_points?: boolean;
  status?: EPointOfDeliveryStatus;
}

export class PointOfDeliveryDto {
  arrival_point!: number;
  departure_point!: number;
  planned_departure!: string;
  planned_arrival!: string;
  conveyor?: number;  
  driver?: number;  
  carrier_truck?: number;  
  road_tractor?: number;  
  semi_trailer?: number;  
  update_conveyor_data_from_other_points?: boolean;
  delivered_products?: IDeliveredProducts[];
}

export class AddNewPointOfDeliveryToLogisticTourDto {
  logistic_tour_id!: number;
  point_of_deliveries!: PointOfDeliveryDto[];
}

export class StartLogisticTourDto {
  id!: number;
  planned_departure!: string;
  planned_arrival!: string;
  point_of_delivery_items!: ILoadedProducts[];
}

export class PointOfDeliveriessPaginationInterface
  implements PaginationInterface
{
  page: number = 1;
  limit: number = 15;
  next?: number | null;
  last?: number | null;
  pages?: number | null;
  status?: EPointOfDeliveryStatus[];
  date?: string;
  arrival_point?: number;
}

export interface IDeliveredProduct {
  product_id: number;
  delivered: number;
}

export interface IComment {
  content: string;
}

export interface IConfirmStockReceipt {
  point_of_delivery_id: number;
  comments: IComment[];
  end_date: string;
  delivered_products: IDeliveredProduct[];
}
