<div class="pagination d-flex justify-content-end mb-2">
  <button
    *ngIf="pagination.page >= 1"
    type="button"
    class="btn btn-outline-info fw-bold mx-1"
    [disabled]="pagination.page === 1"
    (click)="previous()"
  >
    <i-bs name="chevron-left" width="16" height="16"> </i-bs>
  </button>
  <button type="button" class="btn btn-outline-info fw-bold mx-1">
    {{ pagination.page + " / " + pagination.pages }}
  </button>
  <button
    *ngIf="displayNextButton()"
    type="button"
    class="btn btn-outline-info fw-bold mx-1"
    [disabled]="pagination.page === pagination.pages"
    (click)="next()"
  >
    <i-bs name="chevron-right" width="16" height="16"> </i-bs>
  </button>
</div>
