<div
  class="d-flex justify-content-between flex-column pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <h1 class="h2 text-info">Dépôts et Paiements Marchand</h1>
  </div>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-around justify-content-start b-4">
      <div class="row-cols-sm-auto form-group">
        <label for="inputEmail3" class="mx-2 col-form-label fw-bold text-info"
          >Rechercher par vendeur, téléphone,...
        </label>
        <div class="col">
          <div class="input-group">
            <input
              type="text"
              class="form-control search-input"
              [formControl]="searchFormControl"
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-around b-4 text-center mx-4">
        <div class="row-cols-sm-auto form-group col-12">
          <label for="inputEmail3" class="col-form-label fw-bold text-info">
            Sélectionner un vendeur
          </label>
          <div class="col">
            <div class="input-group">
              <select
                [formControl]="sellerFilterControl"
                class="form-select status-select px-2"
                aria-label="Choisir un type de transaction"
              >
                <option value="">-- Vendeurs --</option>
                <option
                  *ngFor="let item of sortArray(sellerReferences, 'libelle')"
                  [value]="item.phone"
                >
                  {{ item.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="row-cols-sm-auto form-group mx-3">
        <label for="inputEmail3" class="mx-2 col-form-label fw-bold text-info"
          >Choix de la date
        </label>
        <div class="col">
          <div class="input-group">
            <input
              ngb-dp-today
              class="form-control form-control-sm rounded-left"
              placeholder="dd/mm/yyyy"
              [formControl]="dateFilterControl"
              ngbDatepicker
              #d="ngbDatepicker"
            />
            <button
              class="btn btn-info btn-sm btn-outline-secondary calendar text-white"
              (click)="d.toggle()"
              type="button"
            >
              <i-bs name="calendar3"></i-bs>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="sellerFilterControl.value && formatedTransactions.length"
  class="d-flex justify-content-center"
>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card border-success">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div class="text-info">
            <h5>
              {{
                getSellerTotalAmount()
                  | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
              }}
            </h5>
            <p class="mb-0 fw-bold">
              Total versements {{ formatedTransactions[0].seller }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-table
  *ngIf="!loadingTransaction && transactions?.length"
  [columnDefs]="columnDefs"
  [tableData]="sortArray(formatedTransactions, 'seller')"
></app-table>
<app-empty-card
  *ngIf="!loadingTransaction && emptyTransactionMessage"
  [message]="emptyTransactionMessage"
  [icon]="'exclamation-circle'"
  [iconColorClass]="'text-danger'"
></app-empty-card>
