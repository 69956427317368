import { Cluster, ClusterState } from '../types';
import { Store } from './store';
import { ClusterService } from '../services/cluster.service';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { ClusterListDTO, ClusterZoneSupervisorDTO } from '../dtos/cluster.dto';
import { ClusterZoneSupervisorService } from '../services/cluster-zone-supervisor.service';
import { ClusterZoneSupervisor } from '../models/cluster';
import { ClusterPaginationInterface, EClusteringMode } from '../interfaces/clusters-interface';

const initialState: ClusterState = {
  loading: false,
  clusters: [],
  formSuccess: false,
  error: '',
  pagination: {
    page: 1,
    limit: 10,
    next: 1,
    last: 1,
    pages: 1,
    clustering_modes: [EClusteringMode.DEFAULT],
  },
};

@Injectable({ providedIn: 'root' })
export class ClusterStore extends Store<ClusterState> {
  private loaded = false;
  error = "";

  constructor(
    private clusterService: ClusterService,
    private toastService: ToastService
  ) {
    super(initialState);
  }

  getClusters(params: ClusterPaginationInterface) {
    if (!this.loaded) {
      this.loaded = true;
      this.setState((state) => ({ ...state, loading: true }));
      this.clusterService.getClusters(params).subscribe({
        next: (response: ClusterListDTO) => {
          this.setState((state) => ({
            loading: false,
            clusters: response.data,
            pagination: {
              ...params,
              ...response.metadata
            },
          }));
        },
        error: (error: any) => {
          this.setState((state) => ({ loading: false, error }));
        },
      });
    }
  }

  updateCluster(cluster: any) {
    this.setState((state) => ({ ...state, loading: true, formSuccess: false }));
    this.clusterService.updateCluster(cluster).subscribe({
      next: (cluster: any) => {
        this.setState((state) => ({
          loading: false, formSuccess: true,
          clusters: state.clusters.map((o) =>
            o.id === cluster.id ? cluster : o
          ),
        }));
        this.toastService.show('Le cluster a été modifié avec succès', {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(
          'Une erreur est survenu lors de la création du cluster. Merci de réessayer',
          {
            classname: 'bg-danger text-light toast-container',
            delay: 5000,
          }
        );
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  deleteCluster(cluster: Cluster) {
    this.setState((state) => ({ ...state, loading: true, formSuccess: false }));
    this.clusterService.deleteCluster(cluster).subscribe({
      next: (o: any) => {
        this.setState((state) => ({
          loading: false, formSuccess: true,
          clusters: state.clusters.filter((o) => o.id !== cluster.id),
        }));
        this.toastService.show('Le cluster a été supprimé avec succès', {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(
          'Une erreur est survenu lors de la suppression du cluster. Merci de réessayer',
          {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container',
            delay: 5000,
          }
        );
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  setInitial() {
    this.loaded = false;
  }
}
