import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ModalConfig } from 'src/app/types';

export enum EDropDownSearchFilterType {
  SEARCH_INPUT_LIST = 'SEARCH_INPUT_lIST',
  MODAL_INPUTS_FILTER = 'MODAL_INPUTS_FILTER',
}

export interface IDropDownSearchFilter {
  isDropDown: boolean,
  filter_type: EDropDownSearchFilterType
}

@Component({
  selector: 'app-inputs-search-filter-modal',
  templateUrl: './inputs-search-filter-modal.component.html',
  styleUrls: ['./inputs-search-filter-modal.component.scss']
})
export class InputsSearchFilterModalComponent implements OnInit {

  @Input() labelSearchFilter: string = '';
  @Input() public modalConfig!: ModalConfig;
  @Input() isOpenFilterForm: boolean = false;
  @Input() selectedItem?: any;
  @Output() onSearch = new EventEmitter<string>();
  @Output() dropDown = new EventEmitter<IDropDownSearchFilter>();


  searchTerm: FormControl = new FormControl()

  constructor(
  ) { }

  ngOnInit(): void {
    this.onSearchTermControlChange();
  }

  onSearchTermControlChange() {
    this.searchTerm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string) => {
      this.onSearch.emit(value);
      if (value.trim() !== "") {
        this.isOpenFilterForm = true;
        this.dropDown.emit({isDropDown: true, filter_type: EDropDownSearchFilterType.SEARCH_INPUT_LIST});
      } else {
        // this.isOpenFilterForm = false;
        // this.dropDown.emit({isDropDown: false, filter_type: EDropDownSearchFilterType.SEARCH_INPUT_LIST});
      }
    })
  }

  removeSelected() {
    this.searchTerm.patchValue(this.selectedItem.immatriculation)
    this.selectedItem = undefined
  }

  openModal() {
    this.isOpenFilterForm = true;
    this.dropDown.emit({isDropDown: this.isOpenFilterForm, filter_type: EDropDownSearchFilterType.MODAL_INPUTS_FILTER});
  }

  closeDropDown() {
    this.isOpenFilterForm = false;
    this.dropDown.emit({isDropDown: this.isOpenFilterForm, filter_type: EDropDownSearchFilterType.MODAL_INPUTS_FILTER});
  }

  openDropListVehicle(){
    this.isOpenFilterForm = true;
    this.dropDown.emit({isDropDown: this.isOpenFilterForm, filter_type: EDropDownSearchFilterType.SEARCH_INPUT_LIST});
  }

  close() {
    this.isOpenFilterForm = false
  }
}
