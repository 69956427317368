<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <app-go-back></app-go-back>
    <h1 class="h2 text-info">Categories de produits</h1>
  </div>
  <app-is-guarded [permissions]="['CAN_CREATE_OTHER_TYPE']">
    <a
      class="btn btn-info btn-sm text-sm text-white fw-bold mx-3"
      (click)="openCreateModal()"
      >Nouvelle catégorie</a
    >
  </app-is-guarded>
</div>

<div *ngIf="otherTypes$ | async as otherTypes">
  <app-table
    *ngIf="otherTypes.length"
    [columnDefs]="columnDefs"
    [tableData]="otherTypes"
    (actionInvoqueFunctionEvent)="tableActionsFunction($event)"
  ></app-table>
  <ng-template [ngIf]="error$ | async">
    <app-empty-card
      *ngIf="!otherTypes.length"
      [icon]="'exclamation-circle'"
      [message]="'Aucune catégorie trouvée'"
    ></app-empty-card>
  </ng-template>
  <app-pagination
    *ngIf="otherTypes.length"
    [pagination]="pagination"
    (paginationEvent)="paginate($event)"
  ></app-pagination>
</div>

<modal #nGmodal [modalConfig]="modalConfig" class="modal-dialog-centered">
  <form
    *ngIf="modalFormType === 'CREATE' || modalFormType === 'UPDATE'"
    [formGroup]="modalForm"
  >
    <div class="mb-3">
      <label for="libelle" class="form-label fw-bold text-secondary"
        >Libelle:
      </label>
      <input
        type="text"
        id="libelle"
        class="form-control"
        formControlName="libelle"
      />
    </div>
    <div class="d-flex mb-3">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          formControlName="active"
        />
        <label
          class="form-check-label fw-bold text-secondary"
          for="flexCheckDefault"
        >
          Statut
        </label>
      </div>
    </div>
  </form>
  <p></p>
  <p *ngIf="modalFormType === 'DELETE'">
    <strong
      >Voulez-vous vraiment supprimer cette catégorie de produit
      <span class="text-danger">"{{ currentOtherType.libelle }}"</span>?</strong
    >
  </p>
  <p class="mt-4" *ngIf="!modalFormDisplay && modalFormStatusActionMessage">
    {{ modalFormStatusActionMessage }}
  </p>
</modal>
