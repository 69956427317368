import { PaginationInterface } from './pagination-interface';

export class VehiclePaginationInterface implements PaginationInterface {
    page: number = 1;
    limit: number = 15;
    next?: number | null;
    last?: number | null;
    pages?: number | null;
    searchTerm?: string;
    status?: EVehicleStatus[];
    brands?: EVehicleBrand[];
    tonnages?: number[];
}

export enum ETrucksFormType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}   

//values test
export enum EVehicleBrand {
    TOYOTA = "TOYOTA",
    TATA = "TATA",
    SUSUKI = "SUSUKI",
    FORD = "FORD"
}

export enum EVehicleType {
    SEMI_TRAILER = "SEMI_TRAILER",
    ROAD_TRACTOR = "ROAD_TRACTOR",
    CARRIER_TRUCK = "CARRIER_TRUCK",
}

export enum EVehicleTypeFR {
    SEMI_TRAILER = "Semi-remorque",
    ROAD_TRACTOR = "Tracteur routier",
    CARRIER_TRUCK = "Camion porteur",
}

export enum EVehicleStatus {
    BROKEN = "BROKEN",
    AVAILABLE = "AVAILABLE",
    IN_USE = "IN_USE",
    MAINTENANCE = "MAINTENANCE",
}

export enum EVehicleStatusFR {
    BROKEN = "En panne",
    AVAILABLE = "Disponible",
    IN_USE = "En Tournée",
    MAINTENANCE = "En maintenance",
}