<ng-container [ngSwitch]="column.type">
  <ng-container *ngSwitchCase="'text'">
    <span [ngStyle]="{ cursor: column.clickable ? 'pointer' : '' }" (click)="clickCellValueEvent(column)">
      {{
      column.parent
      ? rowData[column.parent]
      ? rowData[column.parent][column.name]
      : ""
      : rowData[column.name]
      ? rowData[column.name]
      : ""
      }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'tooltips'">
    <span [ngStyle]="{ cursor: column.clickable ? 'pointer' : '' }" *ngIf="column.parent"
      [ngbTooltip]="rowData[column.parent][column.name].length > 15 ? rowData[column.parent][column.name] : ''">
      {{
      trunkText(rowData[column.parent][column.name])
      }}
    </span>
    <span [ngStyle]="{ cursor: column.clickable ? 'pointer' : '' }" *ngIf="!column.parent"
      [ngbTooltip]="rowData[column.name].length > 15 ? rowData[column.name] : ''">
      {{
      trunkText(rowData[column.name])
      }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    <ng-template [ngIf]="column.parent" [ngIfElse]="noParentBlock">
      <span class="number-item" [ngStyle]="cellStyle">
        {{ rowData[column.parent][column.name] | number : "" : "fr-FR" }}
      </span>
    </ng-template>
    <ng-template #noParentBlock>
      <span class="number-item" [ngStyle]="cellStyle">
        {{ rowData[column.name] | number : "" : "fr-FR" }}
      </span>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'money'">
    <ng-template [ngIf]="column.parent" [ngIfElse]="noParentBlock">
      {{
      rowData[column.parent][column.name]
      | currency :rowData[column.currency] || 'XOF' : "symbol-narrow" : "" : "fr-FR"
      }}
    </ng-template>
    <ng-template #noParentBlock>
      {{
      rowData[column.name] | currency :rowData[column.currency] || 'XOF' : "symbol-narrow" : "" : "fr-FR"
      }}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'template'">
    <ng-template [ngIf]="column.parent" [ngIfElse]="noParentBlock">
      {{ callRenderTemplate(rowData[column.parent][column.name]) }}
    </ng-template>
    <ng-template #noParentBlock>
      {{ callRenderTemplate(rowData[column.name]) }}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'action'">
    <ng-template ngFor let-action [ngForOf]="column.actions" let-index="index">
      <a class="btn btn-sm {{
          action.class ? action.class : 'btn-info'
        }} text-info text-white mx-2" (click)="invoqueActionsFunction({action, rowData})"
        *appIsGranted="action.permissions" [ngStyle]="setActionButtonColor(rowData, action, index)">
        <i-bs *ngIf="action.icon" [name]="action.icon!"></i-bs>
        <span *ngIf="action.text; else statusActionBlock">{{
          action.text
          }}</span>
        <!-- Il s'agit d'une partie spécial pour prendre en compte un boutton
           qui aua deux état pour permette une activation ou désactivation d'une resource
        -->
        <ng-template #statusActionBlock>
          <span *ngIf="rowData.status && action.actionToogleableIndex === index">{{ getContextualButtonText(rowData,
            action) }}</span>
        </ng-template>
      </a>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <div class="status-container">
      <span class="status-item" [ngStyle]="cellStyle">
        {{
        column.parent
        ? rowData[column.parent][column.name]
        : rowData[column.name]
        }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'switch'">
    <div class="status-container">
      <div class="form-check form-switch" [ngStyle]="cellStyle">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedDisabled" [checked]="
            column.parent
              ? rowData[column.parent][column.name]
              : rowData[column.name]
          " disabled />
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    <ng-template [ngIf]="column.parent" [ngIfElse]="noParentBlock">
      {{ rowData[column.parent][column.name] | date : "short" : "fr" }}
    </ng-template>
    <ng-template #noParentBlock>
      {{ rowData[column.name] | date : "short" : "fr" }}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{
    column.parent ? rowData[column.parent][column.name] : rowData[column.name]
    }}
  </ng-container>
</ng-container>