import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from 'src/app/types';
import { extractDataFromExcel, isValidCoordinate} from 'src/app/utils';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-import-coordinates',
  templateUrl: './import-coordinates.component.html',
  styleUrls: ['./import-coordinates.component.scss']
})
export class ImportCoordinatesComponent implements OnInit {

  /* Modal configuration */
  @ViewChild('nGmodal') modalComponent!: ModalComponent;
  @ViewChild('confirmModal') confirmModalComponent!: ModalComponent;

  @Output() importSuccess = new EventEmitter<void>();
  @Output() dataReadyForImport = new EventEmitter<any>();
  @Input() entity !: string ;

  modalConfig!: ModalConfig;
  confirmModalConfig!: ModalConfig;

  /* Imports */
  fileName: string = '';
  loadedData: any[] = []; 
  loadedDataToDisplay: any[] = [];
  invalidData: string[] = [];
  isLoadingFileData: boolean = false;
  isValidImportedData: boolean = false;
  importingData: boolean = false;
  
  constructor(
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  openImportModal() {
    this.openModal(this.modalComponent);
    this.modalConfig = {
      modalTitle: 'Mise à jour des coordonnées',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-primary-color text-white',
      cancelButtonClass: 'btn-sm btn-danger',
      submitButtonClass: 'btn-sm btn-primary text-white',
      onSubmit: () => this.openConfirmModal(),
      disableSubmitButton: () => !this.isValidImportedData,
    };
  }

  openConfirmModal() {
    this.confirmModalComponent.open();
    this.confirmModalConfig = {
      modalTitle: 'Confirmation',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      cancelButtonClass: 'btn-sm btn-danger',
      submitButtonClass: 'btn-sm btn-primary text-white',
      onSubmit: () =>  this.confirmData(),
      disableSubmitButton: () => !this.isValidImportedData,
    };
  }

  async openModal(modal: any) {
    this.fileName = '';
    this.loadedData = [];
    this.invalidData = [];
    this.loadedDataToDisplay = [];
    this.isValidImportedData = false;
    return await modal.openXl();
  }

  handleFileInput(event: any): void {
    this.loadedData = [];
    this.loadedDataToDisplay = [];
    this.invalidData = [];
    this.isLoadingFileData = true;
    const file = event.target.files[0];
    this.fileName = file ? file.name : '';

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const jsonData = extractDataFromExcel(e.target.result);
      if (this.validateLoadedDataFormat(jsonData, [ this.entity, 'X_COORD', 'Y_COORD'])) {
        this.processValidData(jsonData);
      } else {
        this.toastService.show('Format de fichier invalide ou des champs vide', {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 6000,
        });
      }
      this.isLoadingFileData = false;
    };
    reader.readAsArrayBuffer(file);
    event.target.value = '';
  }

  processValidData(jsonData: any[]): void {
    try {
      this.isValidImportedData = true;
      const processedData: any[] = [];
      this.invalidData = [];

      jsonData.forEach((row) => {
        const value : any = {};
        const normalizedRow = this.normalizeColumns(row);
        value[`${this.entity.toLowerCase()}`] = normalizedRow[this.entity];
        value['x_coord'] = parseFloat(normalizedRow['X_COORD']);
        value['y_coord'] = parseFloat(normalizedRow['Y_COORD']);
        const isValid = isValidCoordinate( value['y_coord'], value['x_coord']);

        value['isValid'] = isValid;
        processedData.push(value);

        if (!isValid) {
          this.invalidData.push(`Invalid coordinates for secteur: ${value[`${this.entity.toLowerCase()}`]}, latitude: ${value['y_coord']}, longitude: ${value['x_coord']}`);
        }
      });

      if (this.invalidData.length > 0) {
        this.isValidImportedData = false;
        this.toastService.show('Certaines données sont invalides. Veuillez vérifier les coordonnées.', {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 6000,
        });
      }

      this.loadedData = processedData;
      this.loadedDataToDisplay = this.loadedData;
    } catch (error) {
      this.toastService.show(`Erreur: ${error}`, {
        header: 'Erreur',
        classname: 'bg-danger text-light toast-container',
        delay: 6000,
      });
    }
  }

  confirmData() {
    this.dataReadyForImport.emit(this.loadedData);
  }

  normalizeColumns(row: any): any {
    const normalizedRow: any = {};
    for (const key in row) {
      const normalizedKey = key.trim().toLowerCase();
      switch (normalizedKey) {
        case 'secteur':
        case 'secteurs':
        case 'sector':
        case 'sectors':
          normalizedRow['Secteur'] = row[key];
          break;
        case 'zone':
        case 'zones':
          normalizedRow['Zone'] = row[key];
          break;
        case 'zone_superviseur':
        case 'zones_superviseur':
        case 'zone_superviseurs':
        case 'zones_superviseurs':
          normalizedRow['Zone_superviseur'] = row[key];
          break;
        case 'region':
        case 'regions':
          normalizedRow['Region'] = row[key];
          break;
        case 'country':
        case 'countries':
          normalizedRow['COUNTRY'] = row[key];
          break;
        case 'x_coord':
        case 'x-coord':
        case 'longitude':
          normalizedRow['X_COORD'] = row[key]; // parseFloat(row[key]) || NaN;
          break;
        case 'y_coord':
        case 'y-coord':
        case 'latitude': 
          normalizedRow['Y_COORD'] = row[key] // parseFloat(row[key]) || NaN;
          break;
        default:
          normalizedRow[key] = row[key];
      }
    }
    return normalizedRow;
  }

  validateLoadedDataFormat(data: any[], requiredHeaders: string[]): boolean {
    const headers = Object.keys(this.normalizeColumns(data[0]));
    return requiredHeaders.every((header) => headers.includes(header));
  }
}
