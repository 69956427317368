import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cluster } from '../models/cluster';
import Config from '../utils/config';
import { Observable } from 'rxjs';
import { ClusterListDTO } from '../dtos/cluster.dto';
import { ClusterPaginationInterface } from '../interfaces/clusters-interface';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  constructor(private http: HttpClient) {}

  getAllClusters() {
    return this.http.get<Cluster[]>(`${Config.baseUrlV2}/clusters`);
  }

  getClusters(payload: ClusterPaginationInterface): Observable<ClusterListDTO> {
    return this.http.post<ClusterListDTO>(`${Config.baseUrlV2}/clusters/list`,
      payload,
    );
  }

  addCluster(cluster: any) {
    return this.http.post<any>(`${Config.baseUrlV2}/clusters`, {
      cluster: cluster,
    });
  }

  updateCluster(cluster: any) {
    return this.http.put<any>(`${Config.baseUrlV2}/clusters/${cluster['id']}`, cluster);
  }

  deleteCluster(cluster: any) {
    return this.http.delete<any>(
      `${Config.baseUrlV2}/clusters/${cluster['id']}`
    );
  }
}
