<div
  class="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
<app-go-back></app-go-back> <h1 class="h3 text-info">Bon de commande - <span class="fs-3 text-secondary">{{ point_of_sale_name }}</span> - <span class="text-info">{{ date }}</span></h1>
</div>
<form [formGroup]="viewOrderForm" (ngSubmit)="onSubmit()">
  <table class="table align-middle mb-5">
    <thead class="table-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Produits</th>
        <th scope="col">Propositions de commande</th>
        <th scope="col">Commande</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of products; index as i" >
        <th scope="row">{{ i + 1 }}</th>
        <td class="fw-bold text-info">
          {{ product.sku_sage }}
        </td>
        <td>
          <span class="fw-bold">{{ getProposed(product.id) }}</span>
        </td>
        <td>
          <span class="fw-bold text-info">{{ getOrdered(product.id) }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</form>
