<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="col-6 d-flex align-items-center">
    <app-go-back [marginClass]="''"></app-go-back>
    <h1 class="h2 text-primary mx-4">
      Réceptions de stock
      <span class="mx-2 rounded-1 fs-5 px-2 text-white bg-info fw-bold">
        {{ currentDepot ? currentDepot.name : "..." }}
      </span>
    </h1>
  </div>
  <div class="col-6 d-flex align-items-around justify-content-end b-4">
    <div class="row-cols-sm-auto form-group mx-3">
      <label for="inputEmail3" class="mx-2 col-form-label fw-bold text-info"
        >Choix de la date
      </label>
      <div class="col">
        <div class="input-group">
          <input
            ngb-dp-today
            class="form-control form-control-sm rounded-left"
            placeholder="dd/mm/yyyy"
            [formControl]="dateFilterControl"
            ngbDatepicker
            #d="ngbDatepicker"
          />
          <button
            class="btn btn-info btn-sm btn-outline-secondary calendar text-white"
            (click)="d.toggle()"
            type="button"
          >
            <i-bs name="calendar3"></i-bs>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table
    *ngIf="pointOfDeliveries.length && !isLoadingPointOfDeliveries && !error"
    class="table align-middle table-hover"
  >
    <thead class="table-light">
      <tr class="rounded">
        <th scope="col">Dépôt d'origin</th>
        <th scope="col">Date d'envoi</th>
        <th scope="col" class="text-center">Heure d'envoie</th>
        <th scope="col" class="text-center">Statut</th>
        <th scope="col" class="text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pointOfDelivery of pointOfDeliveries">
        <td class="fw-bold text-info">
          {{
            pointOfDelivery.logistic_tour.origin
              ? pointOfDelivery.logistic_tour.origin.name
              : "Richard-Toll"
          }}
        </td>
        <td class="fw-bold text-info">
          {{ pointOfDelivery.logistic_tour.start_date | date : "dd/MM/YYYY" }}
        </td>
        <td class="text-info text-center fw-bold">
          {{ pointOfDelivery.logistic_tour.start_date | date : "shortTime" }}
        </td>
        <td class="text-center text-white fw-bold">
          <span
            class="rounded-4 px-4 py-1"
            [ngClass]="{
              'status-cancelled':
                pointOfDelivery.status === EPointOfDeliveryStatus.CANCELED,
              'status-finish':
                pointOfDelivery.status === EPointOfDeliveryStatus.RECEIVED,
              'status-in-progress':
                pointOfDelivery.status === EPointOfDeliveryStatus.SENDED,
              'status-new':
                pointOfDelivery.status === EPointOfDeliveryStatus.TO_SEND
            }"
            >{{ EPointOfDeliveryStatusFr[pointOfDelivery.status] }}</span
          >
        </td>
        <td class="text-center text-info fw-bold">
          <span
            [routerLink]="['/tours/receipt-of-products', pointOfDelivery.id]"
            class="text-center btn btn-sm mx-1 btn-info"
          >
            <i-bs name="eye"></i-bs>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-pagination
  *ngIf="!isLoadingPointOfDeliveries && pointOfDeliveries.length && !error"
  [pagination]="filter"
  (paginationEvent)="paginate($event)"
></app-pagination>

<app-empty-card
  *ngIf="error"
  [message]="error"
  [icon]="'exclamation-circle'"
></app-empty-card>
<app-empty-card
  *ngIf="
    !error &&
    !pointOfDeliveries.length &&
    !isLoadingPointOfDeliveries &&
    !loadingDepot
  "
  [message]="'Pas de stock à réceptionner'"
  [icon]="'exclamation-circle'"
></app-empty-card>
