import { EClusterType } from "../utils";
import { PaginationInterface } from "./pagination-interface";

export class ClusterPaginationInterface implements PaginationInterface {
    page: number = 1;
    limit: number = 15;
    next?: number | null;
    last?: number | null;
    pages?: number | null;
    searchTerm?: string;
    clusters?: EClusterType[];
    clustering_modes?: EClusteringMode[];
    zone_supervisor_ids?: number;
    country_id?: number; 
}


export enum EClusteringMode{
    RTM = "RTM",
    DEFAULT = "DEFAULT",
}