import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Config from '../utils/config';
import { CountryListDTO, CountryPaginationDTO, NewCountryDTO, UpdateCountryDTO } from '../dtos/country.dto';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private http: HttpClient) {}

  getCountries(params: any): Observable<CountryListDTO> {
    return this.http.get<CountryListDTO>(`${Config.baseUrlV2}/countries`);
  }
  
  getListCountries(payload: CountryPaginationDTO): Observable<CountryListDTO> {
    return this.http.post<CountryListDTO>(
      `${Config.baseUrlV2}/countries/list`,
      payload
    );
  }

  addCountry(payload: NewCountryDTO) {
    return this.http.post<any>(`${Config.baseUrlV2}/countries`, payload);
  }

  updateCountry(payload: UpdateCountryDTO, id: number) {
    return this.http.put<any>(`${Config.baseUrlV2}/countries/${id}`, payload);
  }
}
