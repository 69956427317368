import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgxBootstrapIconsModule, calendar3, eye } from 'ngx-bootstrap-icons';

import { CommonModule } from '@angular/common';
import { EmptyCardModule } from 'src/app/components/empty-card/empty-card.module';
import { ErrorInterceptorService } from './../../services/error-interceptor.service';
import { GoBackModule } from './../../components/go-back/go-back.module';
import { GoalsComponent } from './goals.component';
import { GoalsRoutingModule } from './goals-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptorService } from './../../services/jwt-interceptor.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberToMonthPipe } from 'src/app/utils/pipes/number-to-month/number-to-month.pipe';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { RcGoalsListComponent } from './rc-goals-list/rc-goals-list.component';
import { RouterModule } from '@angular/router';
import { SecGoalsListComponent } from './sec-goals-list/sec-goals-list.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TableModule } from '../../components/table/table.module';
import { IsGuardedModule } from 'src/app/components/is-guarded/is-guarded.module';
import { CurrencyFormatDirective } from 'src/app/directives/currency-format.directive';

const icons = {
  calendar3,
  eye,
};

@NgModule({
  declarations: [
    GoalsComponent,
    RcGoalsListComponent,
    SecGoalsListComponent,
    NumberToMonthPipe,
    CurrencyFormatDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [GoalsComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    GoalsRoutingModule,
    NgxBootstrapIconsModule.pick(icons),
    GoBackModule,
    TableModule,
    PaginationModule,
    EmptyCardModule,
    SweetAlert2Module.forChild(),
    ModalComponent,
    IsGuardedModule,
  ],
})
export class GoalsModule {}
