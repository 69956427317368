import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  message: string = ''
  loading: boolean = false

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { 
    this.form = this.fb.group({
      email: ['', Validators.required],
    })
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const val = this.form.value;

    if (val.email) {
      this.loading = true
      this.authenticationService.forgot(val.email)
        .subscribe({
          next: () => {
            this.loading = false
            this.router.navigateByUrl('/login');
          },
          error: (e) => {
            this.loading = false
            this.message = 'E-mail incorrect'
          }}
        );
    }
  }

}
