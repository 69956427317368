import { Component, Input, OnInit } from '@angular/core';
import { IconName, IconNamesEnum } from 'ngx-bootstrap-icons';

@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss']
})
export class EmptyCardComponent implements OnInit {
  @Input() icon!: IconNamesEnum | IconName;
  @Input() iconColorClass: string = "text-info"
  @Input() message: string = '';
  @Input() messageTextColor: string = 'text-info'
  constructor() { }

  ngOnInit(): void {
  }

}
