import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';
import { AddNewPointOfDeliveryToLogisticTourDto, IConfirmStockReceipt, PointOfDeliveriessPaginationInterface, PointOfDeliveryDto, UpdatePointOfDeliveryDto } from '../models/point-of-delivery';

@Injectable({
  providedIn: 'root'
})
export class PointOfDeliveryService {

  constructor(private http: HttpClient) { }

  addNewPointOfDeliiveryToLogisticTour(paylaod: AddNewPointOfDeliveryToLogisticTourDto) {
    return this.http.post<any>(`${Config.baseUrlV2}/point_of_deliveries`, paylaod);
  }

  update(id: number, paylaod: UpdatePointOfDeliveryDto) {
    return this.http.patch<any>(`${Config.baseUrlV2}/point_of_deliveries/${id}`, paylaod);
  }

  delete(id: number) {
    return this.http.delete<any>(`${Config.baseUrlV2}/point_of_deliveries/${id}`);
  }

  list(filter: PointOfDeliveriessPaginationInterface) {
    return this.http.post<any>(`${Config.baseUrlV2}/point_of_deliveries/list`, filter);
  }

  findOne(id: number) {
    return this.http.get<any>(`${Config.baseUrlV2}/point_of_deliveries/${id}`);
  }

  receive_logistic_products(payload: IConfirmStockReceipt) {
    return this.http.post<any>(`${Config.baseUrlV2}/point_of_deliveries/receive_logistic_products`, payload);
  }
}
