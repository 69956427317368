import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToursRoutingModule } from './tours-routing.module';
import { ToursComponent } from './tours.component';
import { RouterModule } from '@angular/router';
import { PlanningToursComponent } from './planning-tours/planning-tours.component';
import { NgxBootstrapIconsModule, plusSquareFill, geoAltFill, check2Circle, caretLeftFill, search, xCircleFill, caretRightFill, plusCircle, pencilSquare, calendar2Check, truck, eye } from 'ngx-bootstrap-icons';
import { NumberToDayPipe } from 'src/app/utils/pipes/number-to-day/number-to-day.pipe';
import { GoBackModule } from 'src/app/components/go-back/go-back.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { ListComponent } from './list/list.component';
import { EmptyCardModule } from 'src/app/components/empty-card/empty-card.module';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { FilterSearchModalModule } from 'src/app/components/filter-search-modal/filter-search-modal.module';
import { CardDailyPlanningComponent } from './components/card-daily-planning/card-daily-planning.component';
import { NewLogisticTourComponent } from './new-logistic-tour/new-logistic-tour.component';
import { TimeLinePointComponent } from './components/time-line-point/time-line-point.component';
import { ModalModule } from 'src/app/components/modal/modal.module';
import { ModalLogisticTourPointComponent } from './components/modal-logistic-tour-point/modal-logistic-tour-point.component';
import { DetailComponent } from './detail/detail.component';
import { UpdateLogisticTourComponent } from './update-logistic-tour/update-logistic-tour.component';
import { StartTourFlowComponent } from './components/start-tour-flow/start-tour-flow.component';
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/utils/datepicker-adapter';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StockReceiptComponent } from './stock-receipt/stock-receipt.component';
import { TableModule } from 'src/app/components/table/table.module';
import { ReceiptOfProductsComponent } from './receipt-of-products/receipt-of-products.component';
import { InputsSearchFilterModalModule } from 'src/app/components/inputs-search-filter-modal/inputs-search-filter-modal.module';

const icons = {
  caretRightFill,
  calendar2Check,
  plusSquareFill,
  caretLeftFill,
  check2Circle,
  pencilSquare,
  plusCircle,
  xCircleFill,
  geoAltFill,
  search,
  truck,
  eye,
};
@NgModule({
  declarations: [
    ToursComponent,
    PlanningToursComponent,
    CardDailyPlanningComponent,
    NumberToDayPipe,
    ListComponent,
    NewLogisticTourComponent,
    TimeLinePointComponent,
    ModalLogisticTourPointComponent,
    DetailComponent,
    UpdateLogisticTourComponent,
    StartTourFlowComponent,
    StockReceiptComponent,
    ReceiptOfProductsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    GoBackModule,
    NgSelectModule,
    NgxBootstrapIconsModule.pick(icons),
    ToursRoutingModule,
    EmptyCardModule,
    PaginationModule,
    FilterSearchModalModule,
    InputsSearchFilterModalModule,
    ModalModule,
    NgbModule,
    TableModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ToursModule { }
