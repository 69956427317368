import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyCardComponent } from './empty-card.component';
import { NgxBootstrapIconsModule, exclamationCircle } from 'ngx-bootstrap-icons';

const icons = {
  exclamationCircle,
};

@NgModule({
  declarations: [EmptyCardComponent],
  imports: [CommonModule, NgxBootstrapIconsModule.pick(icons),],
  exports: [EmptyCardComponent],
})
export class EmptyCardModule {}
