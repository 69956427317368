import { AuthenticationService } from 'src/app/services/authentication.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appIsGranted]',
})
export class IsGrantedDirective {
  private hasView = false;
  @Input('appIsGranted') permissions: string | string[] | undefined = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private AuthenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.AuthenticationService.currentUser.subscribe((user) => {
      this.checkPermissions();
    });
    this.checkPermissions();
  }

  checkPermissions() {
    let havePermissions =
      this.AuthenticationService.isGranted(this.permissions!) ||
      this.permissions!.length == 0;

    if (havePermissions && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!havePermissions && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
