import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  message: string = '';
  loading: boolean = false;
  showPassword: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  login() {
    const val = this.form.value;

    if (val.username && val.password) {
      this.loading = true;
      this.authenticationService.login(val.username, val.password).subscribe({
        next: () => {
          this.loading = false;

          this.router.navigateByUrl('/');
        },
        error: (e) => {
          this.loading = false;
          this.message = 'E-mail ou mot de passe incorrect';
        },
      });
    }
  }
}
