<div class="d-flex justify-content-start flex-wrap flex-md-wrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <div class="d-flex align-items-center">
        <app-go-back></app-go-back>
        <h2 class="h3 text-primary">
            Planification Hebdomadaire <br>
            des tournées
        </h2>
    </div>
    <div class="d-flex align-items-baseline mx-5">
        <div class="filter filter-year mx-3">
            <label for="yearFormControl">Année :</label>
            <div class="input-year mx-3">
                <ng-select [clearable]="false" [dropdownPosition]="'bottom'" [formControl]="yearFormControl">
                    <ng-option *ngFor="let year of years; let index = index" [value]="year.value">
                        {{year.label}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="filter filter-week mx-3">
            <label for="weekFormControl">Semaine :</label>
            <div class="input-week">
                <span class="arrow a-prev cursor" (click)="previousWeek()"> <i-bs name="caret-left-fill"></i-bs>
                </span>
                <div class="col">
                    <div class="input-group">
                        <ng-select [clearable]="false" [formControl]="weekFormControl">
                            <ng-option *ngFor="let week of arrayWeek; let index = index" [value]="index +1"> Semaine
                                {{index +1}} </ng-option>
                        </ng-select>
                    </div>
                </div>
                <span class="arrow a-next cursor" (click)="nextWeek()"> <i-bs name="caret-right-fill"></i-bs>
                </span>
            </div>
            <span>
                <ng-container *ngFor="let month of monthsOfWeek; let index = index">
                    <small>{{ month }}
                        <small *ngIf="index === 0 && monthsOfWeek.length > 1">
                            -
                        </small>
                    </small>
                </ng-container>
            </span>
        </div>
    </div>
</div>
<div class="">
    <div class="row row-header flex-nowrap">
        <ng-container *ngFor="let day of weekDay; let index = index">
            <div class="plannings">
                <div class="cells head-cell"
                    [ngClass]="{'head-cell-current': (day.days === getDaysOfCurrentDay() && day.year === today.getFullYear()) ? '#F2F3F7': '' }">
                    <div class="date-month">
                        <span> {{ day.date }}</span>
                        <span>
                            {{day.monthLabel.toUpperCase() | slice: 0:3 }}
                        </span>
                    </div>
                    <small>{{ day.day | numberToDay }}</small>
                </div>
            </div>
        </ng-container>
    </div>
    <app-empty-card
        *ngIf="!loadingPlanning && !plannings.length && isWeekNotPast(weekDay)"
        [message]="'Pas de tournées pour cette semaine.'" [icon]="'exclamation-circle'">
    </app-empty-card>
    <div class="row row-body flex-nowrap" *ngIf="!loadingPlanning">
        <ng-container *ngFor="let day of weekDay; let index = index">
            <div class="plannings">
                <ng-container *ngFor="let planning of plannings">
                    <div class="cells body-cell" [routerLink]="['/list-tour-logistic', planning.id]"
                        *ngIf="day.days === planning.day && day.year === planning.year">
                        <app-card-daily-planning [planning]="planning"></app-card-daily-planning>
                    </div>
                </ng-container>

                <div class="cells foot-cell cursor" *ngIf="isPossibleToAddTourToDay(day.days)"
                    (click)="goToCreateNewTour(day)">
                    <span>+</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>