import { CommonModule, JsonPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { EmptyCardModule } from 'src/app/components/empty-card/empty-card.module';
import { ErrorInterceptorService } from 'src/app/services/error-interceptor.service';
import { GoBackModule } from 'src/app/components/go-back/go-back.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptorService } from 'src/app/services/jwt-interceptor.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OthersCountryListComponent } from './others-country-list/others-country-list.component';
import { OthersListComponent } from './others-list/others-list.component';
import { OthersRoutingModule } from './others-routing.module';
import { OthersTypesComponent } from './others-types/others-types.component';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { RouterModule } from '@angular/router';
import { TableModule } from 'src/app/components/table/table.module';
import { IsGuardedModule } from 'src/app/components/is-guarded/is-guarded.module';

@NgModule({
  declarations: [
    OthersListComponent,
    OthersCountryListComponent,
    OthersTypesComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  imports: [
    CommonModule,
    EmptyCardModule,
    FormsModule,
    GoBackModule,
    JsonPipe,
    NgbModule,
    NgSelectModule,
    NgbTypeaheadModule,
    NgxSpinnerModule,
    OthersRoutingModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    ModalComponent,
    IsGuardedModule,
  ],
})
export class OthersModule {}
