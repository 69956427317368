import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Forecast } from 'src/app/models/forecast';
import { OrderProduct } from 'src/app/models/order-product';
import { Sector } from 'src/app/models/sector';
import { ForecastService } from 'src/app/services/forecast.service';
import { OrderService } from 'src/app/services/order.service';
import { SectorService } from 'src/app/services/sector.service';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from 'src/app/utils/datepicker-adapter';
import Swal from 'sweetalert2';
import { formatDate } from 'src/app/utils';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models/user';

interface IFilter {
  sector_id?: number;
  date?: string;
}
@Component({
  selector: 'app-loadings',
  templateUrl: './loadings.component.html',
  styleUrls: ['./loadings.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class LoadingsComponent implements OnInit {
  error: string = '';
  orderProducts: OrderProduct[] = [];
  forecasts: Forecast[] = [];
  editedForecasts: Forecast[] = [];
  rawEditedForecasts: Forecast[] = [];
  forecastForm!: UntypedFormGroup;
  sectors: Sector[] = [];
  date!: string;
  dateFilterControl!: FormControl;
  sectorFilterControl: FormControl = new FormControl('');
  sector!: string;
  loadingForecast: boolean = false;
  numberBoxPerTruck: number = 50;
  filter!: IFilter;
  currentUser!: User;

  constructor(
    private route: ActivatedRoute,
    private forecastService: ForecastService,
    private authentificatinService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private orderService: OrderService,
    private sectorService: SectorService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authentificatinService.currentUserValue;
    this.date = this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
    const queryDate = this.route.snapshot.params['date'];
    this.dateFilterControl = new FormControl(queryDate ? queryDate : this.date);
    this.filter = { date: formatDate(this.dateFilterControl.value) };
    this.onSectorChange();
    this.onDateChange();
    this.getSectors();
    this.initForm();
  }

  initForm() {
    this.forecastForm = this.formBuilder.group({
      pointOfSaleId: ['', Validators.required],
      orderProducts: new FormArray([]),
    });
  }

  onSectorChange() {
    this.sectorFilterControl.valueChanges.subscribe((sector_id) => {
      this.filter = { ...this.filter, sector_id: parseInt(sector_id) };
      if (sector_id && this.dateFilterControl.value) {
        this.getForecasts(this.filter);
      }
    });
  }

  onDateChange() {
    this.dateFilterControl.valueChanges.subscribe((date) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      const regex_two = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!regex.test(date) && !regex_two.test(date)) {
        Swal.fire({
          title: 'Erreur!',
          text: 'Format de date incorrecte : ' + date,
          icon: 'error',
        });
      } else {
        this.filter = {
          ...this.filter,
          date: regex_two.test(date) ? this.formatStringDate(date) : date,
        };
        if (date && this.sectorFilterControl.value) {
          this.getForecasts(this.filter);
        }
      }
    });
  }

  formatStringDate(date: string) {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  }

  getForecasts(filter: IFilter) {
    this.spinner.show();
    this.editedForecasts = [];
    this.loadingForecast = true;
    this.forecastService.getForecasts(filter).subscribe({
      next: (forecasts) => {
        this.loadingForecast = false;
        this.forecasts = forecasts;
        this.editedForecasts = this.initEditAndRawForecast(forecasts);
        this.rawEditedForecasts = this.initEditAndRawForecast(forecasts);
        this.spinner.hide();
      },
      error: (error) => {
        this.error = error;
        this.loadingForecast = false;
        this.spinner.hide();
      },
    });
  }

  initEditAndRawForecast(forecasts: Forecast[]) {
    const data = forecasts.filter(
      (forecast) => !forecast.product_is_accessorie
    );
    return data.flatMap((forecast) => ({
      ...forecast,
      asked: parseInt(forecast.asked.toString(), 10),
    }));
  }

  getSectors(): void {
    this.spinner.show();
    this.sectorService.getSectors().subscribe((sectors) => {
      this.sectors = sectors;
      const querySector = this.route.snapshot.queryParams['sector_id'];
      querySector
        ? this.sectorFilterControl.patchValue(querySector ? querySector : '')
        : this.spinner.hide();
    });
  }

  getOrderProducts(): void {
    const params: any = {};

    if (this.date) {
      params['date'] = this.date;
    }

    if (this.sector !== undefined) {
      params['sector_id'] = this.sector;
    }

    this.orderService.getAllOrderProducts(params).subscribe((data) => {});
  }

  getQuantityAsked(forecastId?: Number): Number {
    const editedForecast = this.editedForecasts.find(
      (el) => el.id == forecastId
    );
    return editedForecast?.asked || 0;
  }

  getNeededBoxQuantity() {
    let totalCasier: number = 0;
    let remainingProduct: number = 0;
    let boxQuantity: number = 0;

    this.editedForecasts.forEach((eF) => {
      boxQuantity = eF.product_box_quantity;
      if (boxQuantity != null && boxQuantity != 0) {
        totalCasier += ~~(parseInt(eF.asked.toString(), 10) / boxQuantity);
        remainingProduct +=
          ((parseInt(eF.asked.toString(), 10)! % boxQuantity) * 100) /
          boxQuantity;
      }
    });

    return (totalCasier += Math.ceil(remainingProduct / 100));
  }

  getNeededCoolerQuantity() {
    let totalCasier: number = 0;
    let remainingProduct: number = 0;
    let coolerQuantity: number = 0;

    this.editedForecasts.forEach((eF) => {
      coolerQuantity = eF.product_cooler_quantity;
      if (coolerQuantity != null && coolerQuantity != 0) {
        totalCasier += ~~(parseInt(eF.asked.toString(), 10) / coolerQuantity);
        remainingProduct +=
          ((parseInt(eF.asked.toString(), 10)! % coolerQuantity) * 100) /
          coolerQuantity;
      }
    });

    return (totalCasier += Math.ceil(remainingProduct / 100));
  }

  addQuantity(forecast: Forecast, quantity: any) {
    if (!this.isValidable()) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Vous ne pouvez pas modifier une prévision de chargement déja validée',
        icon: 'error',
      });
    } else {
      this.editedForecasts = this.editedForecasts.map((obj) => {
        if (obj.id == forecast.id) {
          return { ...obj, asked: parseInt(quantity) };
        }
        return obj;
      });
      this.isDirty();
    }
  }

  incrementQuantity(forecast: Forecast) {
    const quantity = forecast.asked + 1;
    this.addQuantity(forecast, quantity);
  }

  decrementQuantity(forecast: Forecast) {
    const quantity = forecast.asked - 1;
    this.addQuantity(forecast, quantity);
  }

  onKey(e: any, forecast: Forecast) {
    this.addQuantity(forecast, parseInt(e.target.value) || 0);
  }

  onChange(e: any, forecast: any) {
    this.addQuantity(forecast, parseInt(e.target.value) || 0);
  }

  onSubmit(): void {
    this.spinner.show();
    const params: any = {
      seller_id: this.currentUser.id,
      forecasts: this.editedForecasts,
    };
    this.forecastService.putForecasts(params).subscribe({
      next: (response) => {
        Swal.fire({
          title: 'Success!',
          text: response.message,
          icon: 'success',
        });
        this.rawEditedForecasts = this.editedForecasts;
        this.spinner.hide();
      },
      error: (error: any) => {
        Swal.fire({
          title: 'Erreur!',
          text: error,
          icon: 'error',
        });
        this.spinner.hide();
      },
    });
  }

  onValidate(): void {
    this.spinner.show();
    const params: any = {
      sector_id: this.filter.sector_id,
      date: this.filter.date,
      supervisor: this.currentUser.id,
    };
    this.forecastService.validateForecasts(params).subscribe({
      next: (response) => {
        this.editedForecasts = this.editedForecasts.map((forecast) => {
          forecast.status = 'Validation_supervisor';
          return forecast;
        });
        this.rawEditedForecasts = this.editedForecasts;
        Swal.fire({
          title: 'Success!',
          text: response.message,
          icon: 'success',
        });
        this.spinner.hide();
        location.reload();
      },
      error: (error: any) => {
        Swal.fire({
          title: 'Erreur!',
          text: error,
          icon: 'error',
        });
        this.spinner.hide();
      },
    });
  }

  onInvalidate(): void {
    this.spinner.show();
    const params: any = {
      ...this.filter,
      supervisor: this.currentUser.id,
      tournee_id: this.editedForecasts[0].tournee_id,
    };

    this.forecastService.invalidateForecasts(params).subscribe({
      next: (response) => {
        this.editedForecasts = this.editedForecasts.map((forecast) => {
          forecast.status = 'Validation_seller';
          return forecast;
        });
        Swal.fire({
          title: 'Success!',
          text: response.message,
          icon: 'success',
        });
        this.spinner.hide();
        location.reload();
      },
      error: (error: any) => {
        Swal.fire({
          title: 'Erreur!',
          text: error,
          icon: 'error',
        });
        this.spinner.hide();
      },
    });
  }

  isValidable(): boolean {
    const vadidables = this.editedForecasts.filter(
      (eF) => eF.status !== 'Validation_supervisor'
    );
    return vadidables.length > 0;
  }

  trackForecast(index: number, forecast: Forecast) {
    return forecast.id;
  }

  isDirty(): boolean {
    const difference = [];
    for (const forecast of this.editedForecasts) {
      const currentRawForecast = this.rawEditedForecasts.find(
        (f) => f.id === forecast.id
      );
      if (currentRawForecast!.asked !== forecast.asked) {
        difference.push(forecast);
      }
    }
    return difference.length > 0;
  }

  isInThePast(): boolean {
    let inPast: boolean = false;
    let today: Date = new Date();
    let dateValue: Date = new Date(this.date);
    today.setHours(0, 0, 0, 0);

    if (dateValue < today) {
      inPast = true;
    }

    return inPast;
  }

  getCoolerOrBoxQuantity(forecast: Forecast) {
    if (
      forecast.product_box_quantity === forecast.product_cooler_quantity &&
      forecast.product_box_quantity === 0
    ) {
      return 0;
    }
    return Math.ceil(
      forecast.product_box_quantity > 0
        ? forecast.asked / forecast.product_box_quantity
        : forecast.asked / forecast.product_cooler_quantity
    );
  }

  getAmountTotal() {
    const caTotal = this.forecasts.reduce(
      (a: any, b: any) => a + b.price * b.asked,
      0
    );
    return caTotal;
  }

  accessoriesColumnText(forecast: Forecast) {
    let text = 'N/A';
    if (
      forecast.product_box_quantity > 0 ||
      forecast.product_cooler_quantity > 0
    ) {
      if (this.getCoolerOrBoxQuantity(forecast) === 1) {
        text = `${
          forecast.product_box_quantity > 0
            ? this.getCoolerOrBoxQuantity(forecast) + ' Casier'
            : this.getCoolerOrBoxQuantity(forecast) + ' Glacière'
        }`;
      } else if (this.getCoolerOrBoxQuantity(forecast) > 1) {
        text = `${
          forecast.product_box_quantity > 0
            ? this.getCoolerOrBoxQuantity(forecast) + ' Casiers'
            : this.getCoolerOrBoxQuantity(forecast) + ' Glacières'
        }`;
      } else {
        text = "Pas d'accessoires";
      }
    }
    if (
      forecast.product_box_quantity === 0 &&
      forecast.product_cooler_quantity === 0
    ) {
      text = "Pas d'accessoires";
    }
    return text;
  }
}
