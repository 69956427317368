import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Depot } from 'src/app/models/depot';
import {
  EPointOfDeliveryStatus,
  EPointOfDeliveryStatusFr,
  PointOfDeliveriessPaginationInterface,
  PointOfDelivery,
} from 'src/app/models/point-of-delivery';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DepotService } from 'src/app/services/depot.service';
import { PointOfDeliveryService } from 'src/app/services/point-of-delivery.service';
import { EUserRole, formatDate } from 'src/app/utils';
import { ToastService } from 'src/app/components/toast/toast.service';

@Component({
  selector: 'app-stock-receipt',
  templateUrl: './stock-receipt.component.html',
  styleUrls: ['./stock-receipt.component.scss'],
})
export class StockReceiptComponent implements OnInit {
  /* Globals */
  error: string = '';
  message: string = '';
  currentUser!: User;

  /* Depot */
  currentDepot!: Depot;
  loadingDepot: boolean = false;

  /* Filters */
  dateFilterControl: FormControl = new FormControl();
  date!: string;
  filter: PointOfDeliveriessPaginationInterface = { page: 1, limit: 15 };

  /* Point of deliveries */
  isLoadingPointOfDeliveries: boolean = false;
  pointOfDeliveries: PointOfDelivery[] = [];
  EPointOfDeliveryStatus = EPointOfDeliveryStatus;
  EPointOfDeliveryStatusFr = EPointOfDeliveryStatusFr;

  constructor(
    private depotService: DepotService,
    private authentificationService: AuthenticationService,
    private pointOfDeliveriesService: PointOfDeliveryService,
    private spinner: NgxSpinnerService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    const today = this.ngbCalendar.getToday();
    this.date = this.dateAdapter.toModel(today)!;
    this.dateFilterControl = new FormControl(this.date);
    this.filter.date = formatDate(this.dateFilterControl.value);
    this.currentUser = this.authentificationService.currentUserValue;
    this.getCurrentDepot();
    this.onDateFilterControlChange();
  }

  /* Date filter change */
  onDateFilterControlChange() {
    this.dateFilterControl.valueChanges.subscribe((date) => {
      this.error = '';
      if (!date) {
        this.error = 'Merci de sélectionner une date';
      } else {
        this.filter.date = date;
        this.filter.page = 1;
        this.getPointOfDeliveriesByDepot(this.filter);
      }
    });
  }

  /* Get point of deliveries by depot */
  getPointOfDeliveriesByDepot(filter: PointOfDeliveriessPaginationInterface) {
    if (this.currentDepot) {
      this.isLoadingPointOfDeliveries = true;
      this.showSpinner();
      this.pointOfDeliveriesService.list(filter).subscribe({
        next: (response) => {
          this.pointOfDeliveries = response.point_of_deliveries;
          this.filter = {
            ...this.filter,
            next: response.metadata.next,
            last: response.metadata.last,
            pages: response.metadata.pages,
          };
          this.isLoadingPointOfDeliveries = false;
          this.showSpinner();
        },
        error: (error) => {
          this.error = error;
          this.isLoadingPointOfDeliveries = false;
          this.showSpinner();
          this.toastService.showDanger(this.error);
        },
      });
    } else {
      this.error = 'Merci de sélectionner un dépôt.';
    }
  }

  /* Paginate */
  paginate(event: PointOfDeliveriessPaginationInterface) {
    this.filter.page = event.page;
    this.getPointOfDeliveriesByDepot(this.filter);
  }

  /* Depot */
  getCurrentDepot() {
    if (this.currentUser.role?.libelle === EUserRole.SHIFT_SUPERVISOR) {
      this.loadingDepot = true;
      this.showSpinner();
      this.depotService.getShiftSupervisorDepots().subscribe({
        next: (response) => {
          this.currentDepot = response[0];
          this.filter.arrival_point = this.currentDepot.id;
          this.getPointOfDeliveriesByDepot(this.filter);
          this.loadingDepot = false;
          this.showSpinner();
        },
        error: (error) => {
          this.error = `Une erreur est survenue lors de la récupération du dépôt du chef de quart merci de réessayer`;
          this.loadingDepot = false;
          this.showSpinner();
          this.toastService.showDanger(this.error);
        },
      });
    } else {
      this.error = 'Seul les chefs de quart ont accès à cette interface';
    }
  }

  /* Helpers */
  showSpinner() {
    if (this.loadingDepot || this.isLoadingPointOfDeliveries)
      this.spinner.show();
    else this.spinner.hide();
  }
}
