<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  <div class="col-6 d-flex align-items-center">
    <app-go-back [marginClass]="''"></app-go-back>
    <h1 class="h2 text-primary mx-4">
      Confirmation de réception de stock
      <span class="mx-2 rounded-1 fs-5 px-2 text-white bg-info fw-bold">
        {{ currentDepot ? currentDepot.name : "..." }}
      </span>
      <span *ngIf="pointOfDelivery" class="mx-2 rounded-1 fs-5 px-2 text-white fw-bold" [ngClass]="{
          'tour-status-cancelled':
            pointOfDelivery.status === EPointOfDeliveryStatus.CANCELED,
          'tour-status-received':
            pointOfDelivery.status === EPointOfDeliveryStatus.RECEIVED,
          'tour-status-sent':
            pointOfDelivery.status === EPointOfDeliveryStatus.SENDED,
          'tour-status-to-send':
            pointOfDelivery.status === EPointOfDeliveryStatus.TO_SEND
        }">
        {{ EPointOfDeliveryStatusFr[pointOfDelivery.status] }}
      </span>
    </h1>
  </div>
</div>
<!-- Table start -->
<table *ngIf="!loadingPointOfDelivery && pointOfDelivery" class="table align-middle mb-5 table-responsive">
  <thead class="table-light">
    <tr class="rounded">
      <th>Produits</th>
      <th class="text-center">Quantités à charger</th>
      <th class="text-center">Quantités chargées</th>
      <th class="text-center">Quantités livrées </th>
      <th class="text-center">Palettes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="
        let pointOfDeliveryItem of pointOfDelivery.point_of_delivery_items
      ">
      <td class="align-middle fw-bold text-info">
        {{ pointOfDeliveryItem.product.sku_sage }}
      </td>
      <td class="text-center fw-bold text-info">
        {{ pointOfDeliveryItem.asked }}
      </td>
      <td class="text-center fw-bold text-info">
        {{ pointOfDeliveryItem.loaded }}
      </td>      <td class="text-center fw-bold text-info">
        {{ pointOfDeliveryItem.delivered }}
      </td>
      <td class="fw-bold text-center">
        <span class="badge rounded-pill bg-primary pallets">
          {{ getProductPallet(pointOfDeliveryItem) }}
        </span>
      </td>
    </tr>
  </tbody>
</table>
<!-- Table end -->
<div *ngIf="
    pointOfDelivery && pointOfDelivery.status === EPointOfDeliveryStatus.SENDED
  " class="bottom-bar shadow-sm fixed-bottom col-md-9 ms-sm-auto col-lg-10 px-md-4">
  <div class="justify-content-end mb-md-0 mx-1 d-flex">
    <div class="d-flex flex-wrap flex-md-nowrap">
      <div class="row">
        <div class="col">
          <div class="mx-auto">
            <div class="vstack gap-9">
              <button type="button" [disabled]="
                  pointOfDelivery.status !== EPointOfDeliveryStatus.SENDED
                " (click)="openConfirm()" class="btn btn-primary text-white">
                Réceptionner
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal #confirmModal [modalConfig]="modalConfig" class="modal-dialog-centered">
  <div class="h2 fw-bold text-center">
    {{ modalConfig.modalTitle }}
  </div>
  <form [formGroup]="receptionForm">
    <div class="row d-flex justify-content-between mb-3">
      <div class="col-6">
        <label for="end_date" class="form-label fw-bold text-secondary">Date de réception:
        </label>
        <div>
          <input type="date" class="form-control border-0 bg-gray-light text-dark" formControlName="end_date" readonly
            placeholder="Date de départ" />
        </div>
      </div>
      <div class="col-6">
        <label for="end_time" class="form-label fw-bold text-secondary">Heure de réception:
        </label>
        <div>
          <input type="time" class="form-control border-0 bg-gray-light text-dark" formControlName="end_time"
           placeholder="Heure" />
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <label for="comment" class="form-label fw-bold text-secondary">Commentaire:
      </label>
      <div>
        <textarea formControlName="comment" class="form-control bg-gray-light text-dark" id="comment" cols="30"
          rows="10"></textarea>
      </div>
    </div>
  </form>
</modal>