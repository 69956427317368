<div class="d-flex justify-content-between flex-wrap flex-md-wrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <div class="d-flex align-items-center">
        <app-go-back></app-go-back>
        <h1 class="h2 text-info m-auto">Liste des tournées</h1>
    </div>
    <div class="d-flex" style="width: 40%;">
        <app-inputs-search-filter-modal [selectedItem]="selectedVehicleFilter" (onSearch)="searchTermFilter($event)"
            [labelSearchFilter]="'Filtrer les tournée par véhicule'" [isOpenFilterForm]="isDropDownFilterList"
            (dropDown)="dropDownFilterList($event)">
            <ng-template [ngIf]="isDropDownFilterList">
                <ng-template [ngIf]="dropDownFilterListType === EDropDownSearchFilterType.MODAL_INPUTS_FILTER">
                    <ng-container [ngTemplateOutlet]="formFilterListLogisticTour"></ng-container>
                </ng-template>
                <ng-template [ngIf]="dropDownFilterListType === EDropDownSearchFilterType.SEARCH_INPUT_LIST">
                    <ng-container [ngTemplateOutlet]="listFilterVehicles"></ng-container>
                </ng-template>
            </ng-template>
        </app-inputs-search-filter-modal>

    </div>
    <div class="d-flex justify-content-end align-items-center">
        <div [routerLink]="'/plannings'"
            class="btn btn-info d-flex justify-content-center align-items-center fw-bold refresh-button mx-3">
            <i class="fa-regular fa-calendar-check mx-1"></i>
            <span> Planning </span>
        </div>
        <div routerLink="/new-logistic-tour"
            class="btn btn-info d-flex justify-content-center align-items-center fw-bold refresh-button mx-3">
            <i class="fa-regular fa-square-plus mx-1"></i>
            <span> Nouvelle Tournée </span>
        </div>
    </div>
</div>
<div class="table-responsive">
    <table *ngIf="logisticTourList!.length" class="table align-middle table-hover">
        <thead class="table-light">
            <tr class="rounded">
                <th scope="col">Plaques I.M</th>
                <th scope="col">Date d'envoi</th>
                <th scope="col" class="text-center">Convoyeur</th>
                <th scope="col" class="text-center">Nombre de dépôts</th>
                <th scope="col" class="text-center">Statut</th>
                <th scope="col" class="text-center">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let tour of logisticTourList">
                <th scope="row" class="fw-bold text-info">
                    <span *ngIf="getLastDeliveryPointVehicleAndUserActors(tour).carrier_truck === null 
                        && getLastDeliveryPointVehicleAndUserActors(tour).road_tractor === null 
                        && getLastDeliveryPointVehicleAndUserActors(tour).semi_trailer === null">
                        ----
                    </span>
                    <span *ngIf="getLastDeliveryPointVehicleAndUserActors(tour).carrier_truck !== null">
                        {{getLastDeliveryPointVehicleAndUserActors(tour).carrier_truck.immatriculation}}
                    </span>
                    <span *ngIf="getLastDeliveryPointVehicleAndUserActors(tour).road_tractor !== null">
                        {{getLastDeliveryPointVehicleAndUserActors(tour).road_tractor.immatriculation +" - "+
                        getLastDeliveryPointVehicleAndUserActors(tour).semi_trailer.immatriculation}}
                    </span>
                </th>
                <td class="fw-bold text-info">
                    {{ tour.start_date | date:"dd/MM/YYYY" }}
                </td>
                <td class="text-info text-center fw-bold">
                    <span>
                        {{getLastDeliveryPointVehicleAndUserActors(tour).conveyor !== null ?
                        getFullName(getLastDeliveryPointVehicleAndUserActors(tour).conveyor) : '----' }}
                    </span>
                </td>
                <td class="text-center fw-bold">
                    <div class="tooltiip">
                        {{ tour.point_of_deliveries.length }}
                        <span class="tooltiiptext">
                            <ng-container *ngFor="let point of tour.point_of_deliveries">
                                <small>
                                    {{point.departure_point.name}} <br>
                                </small>
                            </ng-container>
                        </span>
                    </div>
                </td>
                <td class="text-white text-center fw-bold">
                    <span class="rounded-4 px-4 py-1" [ngClass]="{'status-cancelled': tour.status === ELogisticToursStatus.CANCELED,
                    'status-finish': tour.status === ELogisticToursStatus.FINISH,
                    'status-in-progress': tour.status === ELogisticToursStatus.IN_PROGRESS,
                    'status-new': tour.status === ELogisticToursStatus.SCHEDULED}  ">
                        {{ translateLogisticTourStatus(tour.status) }}
                    </span>
                </td>
                <td class="text-center text-info fw-bold">
                    <button [routerLink]="['/list-tour-logistic', tour.id]"
                        class="text-center justify-content-center align-items-center btn btn-sm mx-1 btn-info">
                        <i class="fa-regular fa-eye"></i>
                    </button>
                    <button *ngIf="tour.status !== ELogisticToursStatus.SCHEDULED" disabled
                        class="text-center justify-content-center align-items-center btn btn-sm btn-info mx-1">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                    <button *ngIf="tour.status === ELogisticToursStatus.SCHEDULED"
                        [routerLink]="['/list-tour-logistic', tour.id, 'update']"
                        class="text-center justify-content-center align-items-center btn btn-sm mx-1 btn-info">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-container *ngIf="
      !loading &&
      !logisticTourList?.length
    " [ngTemplateOutlet]="emptyCard">
</ng-container>
<ng-template #emptyCard>
    <app-empty-card [message]="'Pas de resultats pour cette recherche'" [icon]="'exclamation-circle'"></app-empty-card>
</ng-template>

<app-pagination *ngIf="!loading && logisticTourList.length" [pagination]="pagination"
    (paginationEvent)="paginate($event)"></app-pagination>


<ng-template #formFilterListLogisticTour>
    <form [formGroup]="filterForm" class="filter-form">
        <div class=" d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small>Semi remorque:</small></label>
            <div class="d-flex flex-row with-input">
                <ng-select [multiple]="true" class="custom" [dropdownPosition]="'bottom'" style="width: 100%;"
                    [formControl]="semi_trailer">
                    <ng-option *ngFor="let vehicle of semiTrailerVehiclesList; let index = index" [value]="vehicle.id">
                        {{vehicle.immatriculation}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class=" d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small>Camion porteur :</small></label>
            <div class="d-flex flex-row with-input">
                <ng-select [multiple]="true" class="custom" [dropdownPosition]="'bottom'" style="width: 100%;"
                    [formControl]="carrier_truck">
                    <ng-option *ngFor="let vehicle of carrierTruckVehiclesList; let index = index" [value]="vehicle.id">
                        {{vehicle.immatriculation}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class=" d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small>Tracteur routier :</small></label>
            <div class="d-flex flex-row with-input">
                <ng-select [multiple]="true" class="custom" [dropdownPosition]="'bottom'" style="width: 100%;"
                    [formControl]="road_tractor">
                    <ng-option *ngFor="let vehicle of roadTractorVehiclesList; let index = index" [value]="vehicle.id">
                        {{vehicle.immatriculation}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small> Date d'envoie :</small></label>
            <div class="d-flex justify-content-end with-input">
                <input type="date" class="border-bottom" formControlName="start_date">
                <input type="date" formControlName="end_date" [min]="getFormControlFor('start_date').value"
                    class="border-bottom mx-1">
            </div>
            <span (click)="resetDateFilter()"><i class="fa-solid fa-calendar-xmark"></i></span>
        </div>
        <div class="d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small> Chauffeurs :</small></label>
            <div class="d-flex flex-row with-input">
                <ng-select [multiple]="true" class="custom" [dropdownPosition]="'bottom'" style="width: 100%;"
                    formControlName="driver">
                    <ng-option *ngFor="let driver of driverUsers; let index = index" [value]="driver.id">
                        {{driver.username}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small> Convoyeurs :</small></label>
            <div class="d-flex flex-row with-input">
                <ng-select [multiple]="true" class="custom" [dropdownPosition]="'bottom'" style="width: 100%;"
                    formControlName="conveyor">
                    <ng-option *ngFor="let conveyor of conveyorsUsers; let index = index" [value]="conveyor.id">
                        {{conveyor.username}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center py-1 mx-4">
            <label><small> Statuts :</small></label>
            <div class="d-flex flex-row with-input">
                <ng-select [multiple]="true" class="custom" [dropdownPosition]="'bottom'" style="width: 100%;"
                    formControlName="status">
                    <ng-option *ngFor="let status of logisticTourStatus; let index = index" [value]="status">
                        {{translateLogisticTourStatus(status)}} </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
            <button type="submit" class="btn btn-primary text-white py-1" [disabled]="loading"
                (click)="searchFilter()">Rechercher</button>
        </div>
    </form>
</ng-template>

<ng-template #listFilterVehicles>
    <div class="drop-down">
        <ng-container *ngFor="let vehicle of filterdVehiclesList">
            <span (click)="filterListByVehicle(vehicle)" *ngIf="!isVehicleSelectedInListFilter(vehicle)"
                class="d-block py-2 m-0 cursor item-list-vehicle">
                {{ vehicle.immatriculation }} ({{ translateVehicleType(vehicle.vehicle_type) }})
            </span>
            <span *ngIf="isVehicleSelectedInListFilter(vehicle)" class="d-block text-dark py-2 m-0 cursor">
                {{ vehicle.immatriculation }} ({{ translateVehicleType(vehicle.vehicle_type) }})
            </span>
        </ng-container>
    </div>
</ng-template>