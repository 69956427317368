import { HttpClient } from '@angular/common/http';
import Config from '../utils/config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegionCommercialGoalService {
  constructor(private http: HttpClient) {}

  getAllRegionCommercialGoal(data: any) {
    return this.http.get<any>(`${Config.baseUrlV2}/region_commercial_goals`, {
      params: data,
    });
  }

  create(data: any) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/region_commercial_goals`,
      data
    );
  }

  update(id: number, data: any) {
    return this.http.put<any>(
      `${Config.baseUrlV2}/region_commercial_goals/${id}`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<any>(
      `${Config.baseUrlV2}/region_commercial_goals/${id}`
    );
  }

  save_sectors_goals(id: number, data: any) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/region_commercial_goals/${id}/sectors`,
      data
    );
  }
}
