import { Component, OnInit, ViewChild } from '@angular/core';
import { Goal } from 'src/app/models/goal';
import { ActionEventType, ColumnDef, EColumnType } from 'src/app/utils';
import { RegionCommercialGoalService } from 'src/app/services/region-commercial-goal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationInterface } from 'src/app/interfaces/pagination-interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { RegionCommercialService } from 'src/app/services/region-commercial.service';
import { RegionCommercial } from 'src/app/models/region-commercial';
import { Month, Year, months } from 'src/app/utils/helpers';
import { PerformanceService } from 'src/app/services/performance.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ModalConfig } from 'src/app/types';
import { ToastService } from 'src/app/components/toast/toast.service';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  ASSIGNPERMISSION = 'ASSIGNPERMISSION',
}
@Component({
  selector: 'app-rc-goals-list',
  templateUrl: './rc-goals-list.component.html',
  styleUrls: ['./rc-goals-list.component.scss'],
})
export class RcGoalsListComponent implements OnInit {
  /* Childs */
  @ViewChild('modal') modal!: SwalComponent;
  @ViewChild('nGmodal') private modalComponent!: ModalComponent;

  /* Global props */
  error: string = '';
  loading: boolean = false;
  months: Month[] = months;
  years: Year[] = [];
  year!: number;
  month!: number;

  /* Column definition */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Region commercial',
      name: 'label',
      parent: 'goalable',
      positionClass: ['fw-normal'],
    }),
    new ColumnDef({
      label: 'Mois',
      name: 'month',
      type: EColumnType.TEMPLATE,
      renderTemplate: (month: any) => {
        return months.find((m) => m.id == month)?.label;
      },
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
    }),
    new ColumnDef({
      label: 'Année',
      name: 'year',
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
    }),
    new ColumnDef({
      label: 'C.A',
      name: 'ca',
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center', 'text-success'],
      type: EColumnType.MONEY,
    }),
    new ColumnDef({
      label: 'Taux de retour',
      name: 'return_rate',
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
    }),
    new ColumnDef({
      label: 'Actions',
      type: EColumnType.ACTION,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
      actions: [
        {
          baseUrl: '/goals',
          text: 'Voir',
          functionToInvoque: 'redirectToDetails',
          class: 'btn btn-info btn-sm fw-bold',
          permissions: ['CAN_GOAL_SHOW'],
        },
        {
          baseUrl: '',
          text: 'Modifier',
          functionToInvoque: 'openUpdateModal',
          actionToogleableIndex: 0,
          class: 'btn btn-warning btn-sm fw-bold',
          permissions: ['CAN_GOAL_RC_UPDATE'],
        },
        {
          baseUrl: '',
          text: 'Supprimer',
          functionToInvoque: 'delete',
          actionToogleableIndex: 0,
          class: 'btn btn-danger btn-sm fw-bold',
          permissions: ['CAN_GOAL_RC_DELETE'],
        },
      ],
    }),
  ];

  /* Filter and pagination props */
  pagination: PaginationInterface = {
    page: 1,
    limit: 15,
    next: 1,
    last: 1,
    pages: 1,
  };

  /* RC Props */
  rCs: RegionCommercial[] = [];

  /* Goals Props */
  goals: Goal[] = [];
  loadingRCGoals: boolean = false;

  /* Modal Props */
  modalForm!: FormGroup;
  modalTitle: string = '';
  modalWidth: string = '40%';
  displayFormModal: boolean = false;
  statusActionMessage: string = '';
  enableStatusUpdate: boolean = false;
  modalFormType: string = EModalFormType.CREATE;
  currentGoal: any = null;
  modalConfig!: ModalConfig;
  message: any;

  tableActionsFunction(event: ActionEventType) {
    switch (event.action.functionToInvoque) {
      case 'openUpdateModal':
        this.openUpdateModal(event);
        break;
      case 'redirectToDetails':
        this.redirectToDetails(event);
        break;
      case 'delete':
        this.delete(event);
        break;
      default:
        break;
    }
  }

  constructor(
    private regionCommercialService: RegionCommercialService,
    private regionCommercialGoalService: RegionCommercialGoalService,
    private performanceService: PerformanceService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public swalTargets: SwalPortalTargets,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.month = new Date().getMonth() + 1;
    this.getYears();
    this.getRegionCommercials();
    this.getAllRegionCommercialGoal(this.pagination);
    this.initForm();
  }

  initForm() {
    this.modalForm = this.formBuilder.group({
      id: [''],
      month: [''],
      year: [''],
      ca: [''],
      return_rate: [''],
      goalable_id: [''],
      recalculate: [false],
    });
  }

  showSpinner() {
    if (this.loadingRCGoals) this.spinner.show();
    else this.spinner.hide();
  }

  paginate(pagination: any) {
    this.pagination = { ...this.pagination, page: pagination.page };
    this.getAllRegionCommercialGoal(this.pagination);
  }

  openCreateModal() {
    this.openModal();
    this.modalFormType = EModalFormType.CREATE;
    this.modalConfig = {
      modalTitle: 'Ajouter un objectif',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      onSubmit: () => this.create(),
    };

    this.modalForm = this.formBuilder.group({
      id: [''],
      goalable_id: [''],
      month: [''],
      year: [''],
      ca: [''],
      return_rate: [''],
      recalculate: [false],
    });
  }

  openUpdateModal(event: ActionEventType) {
    this.openModal();
    this.modalConfig = {
      modalTitle: 'Modifier un objectif',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      onSubmit: () => this.update(),
    };
    this.modalFormType = EModalFormType.UPDATE;
    this.currentGoal = event.rowData;
    this.modalForm = this.formBuilder.group({
      id: [this.currentGoal.id],
      goalable_id: [this.currentGoal.goalable_id],
      month: [this.currentGoal.month],
      year: [this.currentGoal.year],
      ca: [this.currentGoal.ca],
      return_rate: [this.currentGoal.return_rate],
      recalculate: [false],
    });
  }

  getYears(): void {
    this.performanceService.getYears().subscribe((years) => {
      this.years = years;
      this.year = new Date().getFullYear();
    });
  }

  onMonthChange(month: any): void {
    this.month = month;
    this.getAllRegionCommercialGoal(this.pagination);
  }

  onYearChange(year: any): void {
    this.year = year;
    this.getAllRegionCommercialGoal(this.pagination);
  }

  getAllRegionCommercialGoal(params: PaginationInterface) {
    this.loadingRCGoals = true;
    this.showSpinner();

    const filters = {
      page: params.page,
      limit: params.limit,
      ...(params.searchTerm && { searchTerm: params.searchTerm }),
      ...(this.month && { month: this.month }),
      ...(this.year && { year: this.year }),
    };

    this.regionCommercialGoalService
      .getAllRegionCommercialGoal(filters)
      .subscribe({
        next: (response) => {
          this.goals = response['data'];
          this.loadingRCGoals = false;
          this.showSpinner();
        },
        error: (err) => {
          this.loadingRCGoals = false;
          this.showSpinner();
          this.error = err;
        },
      });
  }

  getRegionCommercials() {
    this.showSpinner();
    this.regionCommercialService.getAllRegionCommercials().subscribe({
      next: (response) => {
        this.rCs = response;
        this.loadingRCGoals = false;
        this.showSpinner();
      },
      error: (err) => {
        this.loadingRCGoals = false;
        this.showSpinner();
        this.error = err;
      },
    });
  }

  confirmModal() {
    switch (this.modalFormType) {
      case EModalFormType.CREATE:
        this.create();
        break;
      case EModalFormType.UPDATE:
        this.update();
        break;
      default:
        break;
    }
  }

  cancel() {
    this.modalTitle = '';
    this.modalWidth = '40%';
    this.statusActionMessage = '';
    this.currentGoal = null;
    this.enableStatusUpdate = false;
    this.displayFormModal = false;
  }

  create() {
    this.loadingRCGoals = true;
    this.showSpinner();
    this.regionCommercialGoalService
      .create({ goal: this.modalForm.value })
      .subscribe({
        next: (response) => {
          this.goals.push(response);
          this.loadingRCGoals = false;
          this.showSpinner();
          this.toastService.showSuccess('Objectif créé');
        },
        error: (error) => {
          this.toastService.showDanger(
            "Une erreur est survenu lors de la création de l'objectif. Merci de réessayer"
          );
          this.error =
            "Une erreur est survenu lors de la création de l'objectif. Merci de réessayer";

          this.loadingRCGoals = false;
          this.showSpinner();
        },
      });
  }

  update(): void {
    this.statusActionMessage = '';
    this.loadingRCGoals = true;
    this.showSpinner();
    const id = this.currentGoal.id;
    const params = {
      goal: {
        month: this.modalForm.value.month,
        year: this.modalForm.value.year,
        ca: this.modalForm.value.ca,
        return_rate: this.modalForm.value.return_rate,
        goalable_id: this.currentGoal.goalable_id,
        recalculate: this.modalForm.value.recalculate,
      },
    };
    this.regionCommercialGoalService.update(id, params).subscribe({
      next: (response) => {
        this.toastService.show(
          'bg-success text-light',
          'Succès',
          'Objectif mis à jour'
        );
        this.goals = this.goals.map((goal: Goal) => {
          if (goal.id == response.id) {
            goal.id = response.id;
            goal.ca = response.ca;
            goal.month = response.month;
            goal.year = response.year;
            goal.return_rate = response.return_rate;
            goal.goalable_id = response.goalable_id;
            goal.goalable_type = response.goalable_type;
            goal.goalable = response.goalable;
          }
          return goal;
        });
        this.enableStatusUpdate = false;
        this.loadingRCGoals = false;
        this.showSpinner();
      },
      error: (error) => {
        this.toastService.showDanger(
          'Une erreur est survenu lors de la mise à jour du rôle. Merci de réessayer'
        );
        this.error =
          'Une erreur est survenu lors de la mise à jour du rôle. Merci de réessayer';
        this.enableStatusUpdate = false;
        this.loadingRCGoals = false;
        this.showSpinner();
      },
    });
  }

  redirectToDetails(value: ActionEventType) {
    this.route.navigate([`${value.action.baseUrl}/${value.rowData.id}`], {
      queryParams: {
        rc_id: value.rowData.goalable_id,
        rc_label: value.rowData.goalable.label,
        ca: value.rowData.ca,
        return_rate: value.rowData.return_rate,
        month: this.month,
        year: this.year,
      },
      relativeTo: this.activatedRoute,
    });
  }

  delete(value: ActionEventType) {
    Swal.fire({
      title: 'Etes-vous sur de vouloir supprimer cet objectif ?',
      showDenyButton: true,
      confirmButtonText: 'Supprimer',
      denyButtonText: `Annuler`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingRCGoals = true;
        this.showSpinner();
        this.regionCommercialGoalService.delete(value.rowData.id).subscribe({
          next: (response) => {
            let index = this.goals.findIndex(
              (goal: Goal) => goal.id == value.rowData.id
            );
            this.goals.splice(index, 1);
            this.loadingRCGoals = false;
            this.showSpinner();
          },
          error: (error) => {
            this.error =
              "Une erreur est survenu lors de la suppression de l'objectif. Merci de réessayer";
            this.loadingRCGoals = false;
            this.showSpinner();
          },
        });
      }
    });
  }

  async openModal() {
    return await this.modalComponent.open();
  }
}
