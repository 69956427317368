import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import Config from '../utils/config';
import { ConfigKey, formatMapData, IMapFilter } from '../utils/map.util';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private nvaigationParams!: { resource: ConfigKey};
  constructor(private httpClient: HttpClient) {}

  getCoordinates(
    urlSegment: string,
    resource: ConfigKey,
    filter: IMapFilter = {
      sector_ids: [],
      zone_ids: [],
      country_ids: [],
      zone_supervisor_ids: [],
      region_commercial_ids: [],
    }
  ) {
    const coordinates: any = {
      type: 'FeatureCollection',
      name: resource,
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [],
    };
    return this.httpClient
      .post<{ coordinates: any[] }>(`${Config.baseUrlV2}/${urlSegment}`, filter)
      .pipe(
        map((data: { coordinates: any[] }) => formatMapData(data, coordinates))
      );
  }

  getNavigationParams() {
    return localStorage.getItem('mapNavigationParams');
  }

  setNavigationParams(mapNavigationParams: { resource: ConfigKey }) {
    localStorage.setItem('mapNavigationParams', JSON.stringify(mapNavigationParams));
  }

  redirectToMap(router: Router, resource: ConfigKey) {
    const urlTree = router.createUrlTree(['/maps']);
    const url = router.serializeUrl(urlTree);
    this.setNavigationParams({ resource });
    window.open(url, '_blank',);
  }
}
