import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Depot } from 'src/app/models/depot';
import {
  EPointOfDeliveryStatus,
  EPointOfDeliveryStatusFr,
  ILogisticDeliveredProducts,
  PointOfDelivery,
} from 'src/app/models/point-of-delivery';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DepotService } from 'src/app/services/depot.service';
import { PointOfDeliveryService } from 'src/app/services/point-of-delivery.service';
import { ModalConfig } from 'src/app/types';
import { EUserRole, dateAdapter, formatDate } from 'src/app/utils';
import { ToastService } from 'src/app/components/toast/toast.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { extractDateAndTime } from 'src/app/utils/helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receipt-of-products',
  templateUrl: './receipt-of-products.component.html',
  styleUrls: ['./receipt-of-products.component.scss'],
})
export class ReceiptOfProductsComponent implements OnInit {
  /* Globals */
  error: string = '';
  message: string = '';
  currentUser!: User;
  minEndDate!: string;
  maxEndDate!: string;
  today: Date = new Date();

  /* Depot */
  currentDepot!: Depot;
  loadingDepot: boolean = false;

  /* Point of deliveries */
  pointOfDelivery!: PointOfDelivery;
  loadingPointOfDelivery: boolean = false;
  EPointOfDeliveryStatus = EPointOfDeliveryStatus;
  receivingProduct: boolean = false;
  EPointOfDeliveryStatusFr = EPointOfDeliveryStatusFr;

  /* Confirmation modal */
  @ViewChild('confirmModal') private modalComponent!: ModalComponent;
  modalConfig: ModalConfig = {
    modalTitle: 'Confirmation réception de stock',
  };

  /* Form */
  receptionForm!: FormGroup;

  constructor(
    private depotService: DepotService,
    private authentificationService: AuthenticationService,
    private pointOfDeliveriesService: PointOfDeliveryService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.minEndDate = dateAdapter(this.today);
    this.maxEndDate = this.minEndDate;
    this.currentUser = this.authentificationService.currentUserValue;
    const pointOfDeliveryId =
      this.activatedRoute.snapshot.params['pointOfDeliveryId'];
    this.getCurrentDepot();
    this.getPointOfDelivery(pointOfDeliveryId);
    this.initReceptionForm();
  }

  /* Point of deliveries */
  getPointOfDelivery(id: number) {
    this.loadingPointOfDelivery = true;
    this.showSpinner();
    this.pointOfDeliveriesService.findOne(id).subscribe({
      next: (response) => {
        this.pointOfDelivery = response.point_of_delivery;
        // this.minEndDate = dateAdapter(
        //   new Date(this.pointOfDelivery.logistic_tour.start_date)
        // );
        this.loadingPointOfDelivery = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = error;
        this.loadingPointOfDelivery = false;
        this.showSpinner();
      },
    });
  }

  /* Depot */
  getCurrentDepot() {
    if (this.currentUser.role?.libelle === EUserRole.SHIFT_SUPERVISOR) {
      this.loadingDepot = true;
      this.showSpinner();
      this.depotService.getShiftSupervisorDepots().subscribe({
        next: (response) => {
          this.currentDepot = response[0];
          this.loadingDepot = false;
          this.showSpinner();
        },
        error: (error) => {
          this.error = `Une erreur est survenue lors de la récupération du dépôt du chef de quart merci de réessayer`;
          this.loadingDepot = false;
          this.showSpinner();
        },
      });
    } else {
      this.error = 'Seul les chefs de quart ont accès à cette interface';
    }
  }

  /* Form */
  initReceptionForm() {
    this.receptionForm = this.formBuilder.group({
      comment: new FormControl(''),
      end_date: new FormControl(dateAdapter(new Date()), [Validators.required]),
      end_time: new FormControl(' ', [Validators.required]),
    });
  }

  /* Helpers */
  showSpinner() {
    if (
      this.loadingDepot ||
      this.loadingPointOfDelivery ||
      this.receivingProduct
    )
      this.spinner.show();
    else this.spinner.hide();
  }

  getProductPallet(pointOfDeliveryItem: ILogisticDeliveredProducts) {
    if (pointOfDeliveryItem.product.pallet > 0) {
      return Math.floor(
        pointOfDeliveryItem.loaded / pointOfDeliveryItem.product.pallet
      );
    } else return 0;
  }

  openConfirm() {
    this.modalConfig = {
      modalTitle: 'Réception de stock',
      submitButtonLabel: 'Valider',
      cancelButtonLabel: 'Fermer',
      headerClass: 'bg-info d-none text-white display-4',
      cancelButtonClass: 'btn-danger ',
      submitButtonClass: 'btn-primary text-white',
      contentText: 'Etes-vous sûr de vouloir réceptionner ces produits ?',
      disableSubmitButton: () => this.receptionForm.invalid,
      onSubmit: () => this.confirm(),
    };
    this.openModal();
  }

  async openModal() {
    this.message = '';
    this.error = '';
    return await this.modalComponent.open();
  }

  /* Confirm */
  confirm() {
    const end_date = `${this.receptionForm.value.end_date} ${this.receptionForm.value.end_time}:00`;
    if (this.canConfirmReceivedProduct(this.pointOfDelivery.logistic_tour.start_date, end_date)) {
      this.receivingProduct = true;
      this.showSpinner();
      this.modalComponent.close();
      const payload = {
        point_of_delivery_id: this.pointOfDelivery.id,
        end_date,
        comments: this.receptionForm.value.comment
          ? [{ content: this.receptionForm.value.comment }]
          : [],
        delivered_products: this.pointOfDelivery.point_of_delivery_items.flatMap(
          (pointOfDeliveryItem) => ({
            product_id: pointOfDeliveryItem.product.id!,
            delivered: pointOfDeliveryItem.loaded,
          })
        ),
      };
      this.pointOfDeliveriesService.receive_logistic_products(payload).subscribe({
        next: (response) => {
          this.pointOfDelivery.status = EPointOfDeliveryStatus.RECEIVED;
          this.message =
            'Réception effectué avec succès. Votre stock a été mis à jour.';
          this.receivingProduct = false;
          this.showSpinner();
          this.toastService.showSuccess(this.message);
        },
        error: (error) => {
          this.error = error;
          this.receivingProduct = false;
          this.showSpinner();
          this.toastService.showDanger(this.error);
        },
      });
    }
    else Swal.fire({
      title: 'Erreur!',
      text: "Vous ne pouvez pas encore réceptionner ce stock de produit: La date de reception doit être supérieure à la date de début de la tournée logistique.",
      icon: 'error',
    });
  }

  getTimeFromDate(date: Date) {
    return `${date.getHours()}:${date.getMinutes()}`
  }

  canConfirmReceivedProduct(start_date: string, end_date: string) {
    const date_arrival = new Date(end_date).getTime();
    const currentDate = new Date(start_date).getTime();
    return date_arrival >= currentDate;
  }
}
