import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import {
  ITransactionType,
  ITransactionFilter,
} from 'src/app/interfaces/transaction-interface';
import { SellerReference } from 'src/app/models/seller-references';
import { Transaction } from 'src/app/models/transaction';
import { SellerReferencesService } from 'src/app/services/seller-references.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import {
  ColumnDef,
  EColumnType,
  ETransactionType,
  sortArray,
} from 'src/app/utils';

@Component({
  selector: 'app-transactions-for-billing',
  templateUrl: './transactions-for-billing.component.html',
  styleUrls: ['./transactions-for-billing.component.scss'],
})
export class TransactionsForBillingComponent implements OnInit {
  /* Global props */
  error: string = '';
  message: string = '';

  /* Column definition */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Horodatage',
      name: 'timestamp',
      type: EColumnType.DATE,
    }),
    new ColumnDef({ label: 'Vendeur', name: 'seller' }),
    new ColumnDef({ label: 'Téléphone', name: 'phoneNumber' }),
    new ColumnDef({ label: 'Type de transaction', name: 'type' }),
    new ColumnDef({ label: 'Résumé', name: 'summary' }),
    new ColumnDef({
      label: 'Identifiant de transaction',
      name: 'transaction_id',
    }),
    new ColumnDef({
      label: 'Montant',
      name: 'total',
      type: EColumnType.MONEY,
    }),
  ];

  /* Transactions */
  transactions: Transaction[] = [];
  loadingTransaction: boolean = false;
  metadata: any;
  formatedTransactions: any[] = [];
  emptyTransactionMessage: string = '';

  /* Filters */
  searchFormControl: FormControl = new FormControl('');
  sellerFilterControl: FormControl = new FormControl('');
  dateFilterControl!: FormControl;

  /* Seller references */
  sellerReferences: SellerReference[] = [];
  loadingSellerReference: boolean = false;

  constructor(
    private transactionsService: TransactionsService,
    private sellerReferencesService: SellerReferencesService,
    private spinner: NgxSpinnerService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {}

  ngOnInit(): void {
    const today = this.ngbCalendar.getToday();
    this.dateFilterControl = new FormControl(this.dateAdapter.toModel(today));
    this.getTransactions();
    this.getSellerReferences();
    this.onSearchFilterTransactionChange();
    this.onDateFilterChange();
    this.onSellerFilterChange();
  }

  onSearchFilterTransactionChange() {
    this.searchFormControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchTerm) => {
        if (searchTerm) {
          this.sellerFilterControl.reset('');
        } else {
          if (!this.sellerFilterControl.value) {
            this.searchByTerm('');
          }
        }
      });
  }

  searchByTerm(searchTerm: string) {
    this.formatedTransactions = this.formatTransactions(this.transactions);
    this.emptyTransactionMessage = ``;
    if (searchTerm) {
      this.formatedTransactions = this.formatedTransactions.filter(
        (transaction) => {
          const isPresent =
            transaction.seller
              .toLowerCase()
              .includes(searchTerm.toLowerCase().trim()) ||
            transaction.phoneNumber.includes(searchTerm.toLowerCase().trim()) ||
            transaction.transaction_id
              .toLowerCase()
              .includes(searchTerm.toLowerCase().trim());
          if (isPresent) {
            return transaction;
          }
        }
      );
      if (!this.formatedTransactions.length)
        this.emptyTransactionMessage = `Aucune transaction trouvée`;
    }
  }

  onSellerFilterChange() {
    this.sellerFilterControl.valueChanges.subscribe((phone) => {
      this.emptyTransactionMessage = ``;
      if (phone) {
        this.searchFormControl.reset();
        this.filterTransactionsBySellerPhone(phone);
        // this.formatedTransactions = this.formatTransactions(
        //   this.transactions.filter(
        //     (transaction) => transaction.business_user_mobile === phone
        //   )
        // );
        // if (!this.formatedTransactions.length)
        //   this.emptyTransactionMessage = `Aucune transaction trouvée`;
      } else {
        this.searchByTerm(this.searchFormControl.value);
      }
    });
  }

  filterTransactionsBySellerPhone(phone: string) {
    this.formatedTransactions = this.formatTransactions(
      this.transactions.filter(
        (transaction) => transaction.business_user_mobile === phone
      )
    );
    if (!this.formatedTransactions.length)
      this.emptyTransactionMessage = `Aucune transaction trouvée`;
  }

  onDateFilterChange() {
    this.dateFilterControl.valueChanges.subscribe((date) => {
      this.getTransactions({ date: date });
    });
  }

  getSellerReferences() {
    this.loadingSellerReference = true;
    this.showSpinner();
    const params = { page: 1, limit: 1000 };
    this.sellerReferencesService.getSellerReferences(params).subscribe({
      next: (response) => {
        this.sellerReferences = response.seller_references;
        this.loadingSellerReference = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération du référentiel vendeur`;
        this.loadingSellerReference = false;
        this.showSpinner();
      },
    });
  }

  getTransactions(filter?: ITransactionFilter) {
    this.loadingTransaction = true;
    this.showSpinner();
    const params = {
      ...(filter?.date && { date: filter?.date }),
      ...(filter?.after && { after: filter?.after }),
    };
    this.transactionsService.getTransactions(params).subscribe({
      next: (response) => {
        this.loadingTransaction = false;
        this.showSpinner();
        this.transactions = response.items;
        this.metadata = response.page_info;
        this.searchByTerm(this.searchFormControl.value);
        if (this.sellerFilterControl.value)
          this.filterTransactionsBySellerPhone(this.sellerFilterControl.value);
      },
      error: (error) => {
        this.loadingTransaction = false;
        this.showSpinner();
        this.error = `Une erreur est survenue lors de la récupération des transactions.Merci de réessayer.`;
      },
    });
  }

  formatTransactions(transactions: Transaction[]) {
    const sellerTransactions = transactions.filter((transaction) =>
      [ETransactionType.DEPOSIT, ETransactionType.COMMERCIALPAYMENT].includes(
        transaction.type
      )
    );
    const formatedTransaction = sellerTransactions.flatMap((transaction) => ({
      timestamp: transaction.timestamp,
      summary: this.generateTransactionSummery(transaction),
      seller: transaction.business_user_name,
      transaction_id: transaction.transaction_id,
      type: transaction.type,
      total: +transaction.amount + +transaction.fee,
      phoneNumber: transaction.business_user_mobile,
    }));

    return formatedTransaction;
  }

  generateTransactionSummery(transaction: Transaction) {
    let summary: string = '';
    switch (transaction.type) {
      case ETransactionType.DEPOSIT:
        summary = `Dépôt chez ${transaction.counterparty_name}`;
        break;
      case ETransactionType.COMMERCIALPAYMENT:
        summary = `Payé`;
        break;
      default:
        summary = '';
        break;
    }
    if (
      [ETransactionType.DEPOSIT, ETransactionType.COMMERCIALPAYMENT].includes(
        transaction.type
      )
    ) {
      summary += ` par ${transaction.business_user_name} `;
    }
    return summary;
  }

  sortArray(arr: any[], prop: string) {
    return sortArray(arr, prop);
  }

  showSpinner() {
    if (this.loadingTransaction || this.loadingSellerReference)
      this.spinner.show();
    else this.spinner.hide();
  }

  getSellerTotalAmount() {
    const total = this.formatedTransactions.reduce(
      (total: any, value: any) => total + value.total,
      0
    );
    return total;
  }
}
