import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged, of } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UpdateLogisticTourItemDto, UpdateTourLogisticDto } from 'src/app/interfaces/logistics-tours';
import { EVehicleType } from 'src/app/interfaces/vehicle-interface';
import { Depot } from 'src/app/models/depot';
import { ELogisticTourStatus, LogisticTour } from 'src/app/models/logistic-tour';
import { PointOfDelivery, AddNewPointOfDeliveryToLogisticTourDto, ILogisticDeliveredProducts } from 'src/app/models/point-of-delivery';
import { Product } from 'src/app/models/product';
import { User } from 'src/app/models/user';
import { Vehicle } from 'src/app/models/vehicle';
import { DepotService } from 'src/app/services/depot.service';
import { PointOfDeliveryService } from 'src/app/services/point-of-delivery.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToursService } from 'src/app/services/tours.service';
import { UsersService } from 'src/app/services/users.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ModalConfig } from 'src/app/types';
import { EFormMode, EModalFrameDeliveryPoint, askedValidatorFactory, calculatePercentage, dateAdapter, isDateTime1AfterDateTime2, minDateValidator, sortArray, translateVehicleType } from 'src/app/utils';
import { extractDateAndTime, calculPallet, getLastDeliveryPointVehicleAndUserActors, isCarrierTruckChanged, isRoadTractorChanged, isNoChange, isInfosTourItemNotNull } from 'src/app/utils/helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-logistic-tour',
  templateUrl: './update-logistic-tour.component.html',
  styleUrls: ['./update-logistic-tour.component.scss', '../custom-table.scss']
})
export class UpdateLogisticTourComponent implements OnInit {
  @ViewChild('confirmModal') private modalComponent!: ModalComponent;

  /* Global */
  error: string = '';
  message: string = '';
  currentUser!: User;
  today: Date = new Date();

  // modal confirmation
  modalConfig!: ModalConfig;


  /* Modal configuration */
  EFormMode = EFormMode;
  modalFormType: string = EFormMode.CREATE;
  EModalFrameDeliveryPoint = EModalFrameDeliveryPoint
  modalFrameSelected: EModalFrameDeliveryPoint = EModalFrameDeliveryPoint.FRAME_INFOS;

  //forms
  formInfosTour!: FormGroup;
  formLoadProducts!: FormGroup;
  isOpenModal: boolean = false;
  isSubmitButtonLoadProductDisabled: boolean = true;
  isEmptyListProductPointFilter: boolean = false;


  //Loaders
  loading: boolean = false;
  loadingUsers: boolean = false;
  loadingtruck: boolean = false;
  loadingProduct: boolean = false;
  loadingDepot: boolean = false;
  formModified: boolean = false;
  formTourDateModified: boolean = false;
  ignoreFirstChange: boolean = true;
  checkChangeFromInfosTourItem: boolean = false;

  //Pallet
  loaderPallets!: number;
  vehiclePallets!: number;



  //Depots
  initialStartDepot: string = "Richard-Toll"; /** **/
  defaultInitialDepot?: Depot;
  depotsList: Depot[] = [];
  searchProduct: FormControl<string> = new FormControl();

  //Point of delivery
  point_of_deliveries: any[] = [];
  productsToRemove: any[] = [];
  pointOfDelivery: PointOfDelivery[] = [];
  depotIdsAllReadyUsed: number[] = [];
  selectedPointOfDelivery!: PointOfDelivery;
  indexSelectedPoint!: number;

  //Vehicles
  othersVehiclesList: Vehicle[] = [];
  semiTrailerVehiclesList: Vehicle[] = [];
  vehicleChoiceFirst?: Vehicle;
  vehicleChoiceSecond?: Vehicle;
  VehicleType = EVehicleType;

  conveyorsUsers: User[] = [];
  driverUsers: User[] = [];

  //Products
  productsList: Product[] = [];

  //tours 
  logisticTour!: LogisticTour;
  defaultTourInfo!: {
    conveyor: number;
    driver: number;
    initial_point: number;
    start_date: string;
    start_time: string;
    truck_id: number;
    truck_id_two: number;
  };
  ELogisticTourStatus = ELogisticTourStatus;

  dateMin!: string;
  logistic_tour_id!: number;

  constructor(
    private formBuilder: FormBuilder,
    private depotService: DepotService,
    private usersService: UsersService,
    private vehicleService: VehicleService,
    private productService: ProductService,
    private tourLogisticService: ToursService,
    private pointOfDeliveryService: PointOfDeliveryService,
    private spinner: NgxSpinnerService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dateMin = dateAdapter(this.today);
    this.activatedRoute.params.subscribe(params => {
      this.logistic_tour_id = params['id'];
      this.getLogisticTour(this.logistic_tour_id)
    });
    this.getFilteredListVehicles();
    this.getConveyorUsers();
    this.getDriverUsers();
    this.getProducts();
    this.initFormLoadProducts();
    this.onSearchFilterProduct();
  }

  initForm() {
    const dateAndTime = this.getDateAndTimeFromLongDate(this.logisticTour.start_date);
    const yesterday = this.today;
    yesterday.setDate(this.today.getDate() - 1);
    this.formInfosTour = this.formBuilder.group({
      start_date: [dateAndTime.date, [Validators.required, minDateValidator(yesterday)]],
      start_time: [dateAndTime.time, [Validators.required]],
      initial_point: [this.defaultInitialDepot?.id, [Validators.required]],
    })
  }


  initFormLoadProducts() {
    this.modalFormType = EFormMode.CREATE;
    this.formLoadProducts = this.formBuilder.group({
      planned_departure: ['', Validators.required],
      planned_departure_time: ['', Validators.required],
      departure_point: ['', Validators.required],
      arrival_point: ['', Validators.required],
      planned_arrival: ['', Validators.required],
      planned_arrival_time: ['', Validators.required],
      arrival: [""],
      arrival_time: [""],
      departure: [""],
      departure_time: [""],
      delivered_products: this.formBuilder.array([]),
      update_conveyor_data_from_other_points: [''],
    });
    const delivered_products = this.getLoadProductsValues()
    for (const product of this.productsList) {
      const value = this.formBuilder.group({
        product_name: new FormControl(product.sku_sage, Validators.required),
        product_id: new FormControl(product.id, Validators.required),
        asked: new FormControl('', [Validators.min(1), askedValidatorFactory()]),
        pallet: new FormControl('', [Validators.min(0)]),
        isIncludeSearchTerm: new FormControl(false),
      });
      delivered_products.push(value);
    }
  }

  onSearchFilterProduct() {
    this.searchProduct.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      const deliveredProductsArray = this.getLoadProductsValues();
      if (searchTerm != "") {
        deliveredProductsArray.controls.forEach((control) => {
          const productName = control.get('product_name')?.value as string;
          const shouldShow = productName.toLowerCase().includes(searchTerm.toLowerCase());
          shouldShow ? control.get('isIncludeSearchTerm')?.setValue(false) : control.get('isIncludeSearchTerm')?.setValue(true);
        });
        this.isEmptyListProductPointFilter = deliveredProductsArray.controls.every((control) => control.get('isIncludeSearchTerm')?.value === true);
      } else if (searchTerm || searchTerm.trim() === '') {
        deliveredProductsArray.controls.map(control => control.get('isIncludeSearchTerm')?.setValue(false))
        this.isEmptyListProductPointFilter = deliveredProductsArray.controls.every((control) => control.get('isIncludeSearchTerm')?.value === true);
      } else {
      }
    });
  }

  closeModal(event: boolean) {
    this.isOpenModal = event;
    this.productsToRemove = [];
    this.depotIdsAllReadyUsed = this.point_of_deliveries.map((p: any) => p.arrival_point.id || p.arrival_point);
    this.formLoadProducts.reset("");
  }

  onVehicleChoiceFirstChange(value: number) {
    this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === Number(value));
    if (this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.ROAD_TRACTOR) {
      this.resetInputValueAndValidators('truck_id_two');
    }
    else {
      this.getTourFormControlFor('truck_id_two')?.setValidators(Validators.required);
    }
  }

  onVehicleChoiceSecondChange(value: number) {
    const vehicleSelect = this.getTourFormControlFor('truck_id_two')?.value;
    if (vehicleSelect !== "") {
      this.vehicleChoiceSecond = this.semiTrailerVehiclesList.find((vehicle) => vehicle.id === parseInt(vehicleSelect)) || undefined;
    }
  }

  isFormDefault(): boolean {
    const form = this.formInfosTour.value
    return JSON.stringify({ ...form, conveyor: Number(form.conveyor), driver: Number(form.driver), truck_id: Number(form.truck_id), ...(form.truck_id_two !== "" && { truck_id_two: Number(form.truck_id_two) }) }) === JSON.stringify(this.defaultTourInfo);
  }

  createPoint() {
    this.modalFormType = EFormMode.CREATE;
    this.searchProduct.patchValue("");
    this.isOpenModal = true;
    this.initFormLoadProducts();
    this.getInputFormLoadProductFor('departure_point')?.setValue(this.getLastPointOfDeliveryAdded().arrival_point.id);
    this.getInputFormLoadProductFor('planned_departure')?.setValue(this.getDateAndTimeFromLongDate(this.getLastPointOfDeliveryAdded().planned_arrival)?.date);
    this.getInputFormLoadProductFor('planned_departure_time')?.setValue(this.getDateAndTimeFromLongDate(this.getLastPointOfDeliveryAdded().planned_arrival)?.time);
  }

  openModalDetailPoint(values: { point_of_delivery: PointOfDelivery, index: number }) {
    this.modalFormType = EFormMode.UPDATE;
    this.indexSelectedPoint = values.index;
    this.selectedPointOfDelivery = values.point_of_delivery;
    this.depotIdsAllReadyUsed = this.depotIdsAllReadyUsed.filter((d) => d !== this.selectedPointOfDelivery.arrival_point.id)
    const dateAndTimeDeparture = this.getDateAndTimeFromLongDate(values.point_of_delivery.planned_departure);
    const dateAndTimeArrival = this.getDateAndTimeFromLongDate(values.point_of_delivery.planned_arrival);
    this.formLoadProducts = this.formBuilder.group({
      planned_departure: [dateAndTimeDeparture.date, Validators.required],
      planned_departure_time: [dateAndTimeDeparture.time, Validators.required],
      departure_point: [values.point_of_delivery.departure_point.id, Validators.required],
      arrival_point: [values.point_of_delivery.arrival_point.id, Validators.required],
      planned_arrival: [dateAndTimeArrival.date, Validators.required],
      planned_arrival_time: [dateAndTimeArrival.time, Validators.required],
      arrival: [""],
      arrival_time: [""],
      departure: [""],
      departure_time: [""],
      delivered_products: this.formBuilder.array([]),
      truck_id: ['', [Validators.required]],
      truck_id_two: ['', [Validators.required]],
      driver: [this.selectedPointOfDelivery.driver !== null ? this.selectedPointOfDelivery.driver.id : ''],
      conveyor: [this.selectedPointOfDelivery.conveyor !== null ? this.selectedPointOfDelivery.conveyor.id : '', [Validators.required]],
      update_conveyor_data_from_other_points: [''],
    });
    this.checkIfValueInfoTourItemFormChange();
    if (this.selectedPointOfDelivery.carrier_truck !== null) {
      this.resetInputValueAndValidators('truck_id_two');
      this.getInputFormLoadProductFor('truck_id')?.patchValue(this.selectedPointOfDelivery.carrier_truck.id)
      this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === this.selectedPointOfDelivery.carrier_truck.id);
    }
    else if (this.selectedPointOfDelivery.road_tractor !== null) {
      this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === this.selectedPointOfDelivery.road_tractor.id);
      this.getInputFormLoadProductFor('truck_id')?.patchValue(this.selectedPointOfDelivery.road_tractor.id)
      this.getInputFormLoadProductFor('truck_id_two')?.patchValue(this.selectedPointOfDelivery.semi_trailer.id)
    } else { }
    const delivered_products = this.getLoadProductsValues()
    for (const product of this.productsList) {
      let value: FormGroup = this.formBuilder.group({
        product_name: new FormControl(product.sku_sage, Validators.required),
        product_id: new FormControl(product.id, Validators.required),
        asked: new FormControl('', [Validators.min(1), askedValidatorFactory()]),
        pallet: new FormControl('', [Validators.min(0)]),
        isIncludeSearchTerm: new FormControl(false),
      });
      for (const productValues of this.selectedPointOfDelivery.point_of_delivery_items || []) {
        if (product.id === productValues.product.id) {
          value.patchValue({
            asked: productValues.asked,
            pallet: productValues.product.pallet > 0 ? calculPallet(productValues.asked, productValues.product.pallet) : 0
            // Autres valeurs à patcher si nécessaire
          });
          break;
        }
      }
      delivered_products.push(value);
    }
    this.searchProduct.patchValue("");
    this.isOpenModal = true;
  }

  isProductAlreadyLoaded(product_id: number, arrayProductLoad: ILogisticDeliveredProducts[]) {
    const idProdductLoaded = arrayProductLoad.map(product => (product.product.id));
    return idProdductLoaded.includes(product_id)
  }

  getLastVehiclePallet(tour: LogisticTour) {
    const lastLogisticTourItem = this.getLastDeliveryPointVehicleAndUserActors(tour);
    if (lastLogisticTourItem.carrier_truck !== null) {
      this.vehiclePallets = lastLogisticTourItem.carrier_truck.pallet;
    }
    else if (lastLogisticTourItem.semi_trailer !== null) {
      this.vehiclePallets = lastLogisticTourItem.semi_trailer.pallet;
    }
    else {
      this.vehiclePallets = 0;
    }
  }

  getPalletCountForProduct(productLoad: any) {
    let product = this.productsList.find((p) => p.id === productLoad.value.product_id) || undefined;
    let pallet = calculPallet(productLoad.value.asked, product ? product.pallet : 0);
    if (pallet === Infinity) pallet = 0;
    productLoad.controls['pallet'].setValue(pallet)
  }

  getLoadedPalletQuantity(point_of_deliveries: PointOfDelivery[]) {
    let totalPallet: number = 0;
    for (let i = 0; i < point_of_deliveries.length; i++) {
      const element = point_of_deliveries[i];
      let palletQuantity = element.point_of_delivery_items?.
        reduce((sum, product) => sum + calculPallet(product.asked, product.product.pallet), 0) || 0;
      totalPallet = totalPallet + palletQuantity;
    }
    return Math.round(totalPallet * 10) / 10;
  }

  isProductSelected(product_id: number) {
    return this.productsToRemove.includes(product_id)
  }

  selectProduitToRemove(product: any) {
    if (!this.isProductSelected(product.product_id)) {
      if (this.productsToRemove.length === this.selectedPointOfDelivery.point_of_delivery_items.length - 1) {
        Swal.fire({
          title: 'Erreur!',
          text: "Vous ne pouvez pas supprimer tous les produits renseigner.",
          icon: 'error',
        });
      }
      else
        this.productsToRemove.push(product.product_id);
    } else this.productsToRemove = this.productsToRemove.filter((id) => id != product.product_id)
  }

  addNewPointOfDeliveryToLogisticTour() {
    const lastPoint = this.logisticTour.point_of_deliveries[this.logisticTour.point_of_deliveries.length -1]
    const payload: AddNewPointOfDeliveryToLogisticTourDto = {
      point_of_deliveries: [
        {
          planned_departure: `${this.formLoadProducts.value.planned_departure} ${this.formLoadProducts.value.planned_departure_time}:00`,
          planned_arrival: `${this.formLoadProducts.value.planned_arrival} ${this.formLoadProducts.value.planned_arrival_time}:00`,
          departure_point: Number(this.formLoadProducts.value.departure_point),
          arrival_point: Number(this.formLoadProducts.value.arrival_point),
          delivered_products: this.formLoadProducts.value.delivered_products.filter((item: any) => item.asked > 0).map((item: any) => ({ product_id: item.product_id, asked: Number(item.asked) })),
        }
      ],
      logistic_tour_id: this.logistic_tour_id
    }
    if (!isDateTime1AfterDateTime2(lastPoint.planned_arrival, payload.point_of_deliveries[0].planned_departure)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date de départ ne doit pas être inférieure à la date d'arrivée du point précédent",
        icon: 'error',
      });
    }
    else if (isDateTime1AfterDateTime2(payload.point_of_deliveries[0].planned_arrival, payload.point_of_deliveries[0].planned_departure)) {
      this.loading = true;
      this.showSpinner();
      this.pointOfDeliveryService.addNewPointOfDeliiveryToLogisticTour(payload).subscribe({
        next: (response) => {
          this.closeModal(false)
          this.loading = false;
          this.showSpinner();
          this.message = 'Point de livraison ajouté avec succès';
          this.toastService.show(this.message, {
            header: 'Succès',
            classname: 'bg-primary text-light toast-container p-1',
            delay: 5000,
          });
          this.getLogisticTour(this.logistic_tour_id)
        },
        error: (error) => {
          this.error = `Une erreur est survenue lors de l'ajout du point de livraison. Merci de réessayer`;
          this.loading = false;
          this.showSpinner();
          this.toastService.show(this.error, {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container p-1',
            delay: 5000,
          });
        },
      })
    }
    else {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être inférieure à la date départ",
        icon: 'error',
      });
    }
  }

  updatePointOfDeliveryInLogisticTour() {
    const payload: any = {
      planned_departure: `${this.formLoadProducts.value.planned_departure} ${this.formLoadProducts.value.planned_departure_time}:00`,
      planned_arrival: `${this.formLoadProducts.value.planned_arrival} ${this.formLoadProducts.value.planned_arrival_time}:00`,
      departure_point: Number(this.formLoadProducts.value.departure_point),
      arrival_point: Number(this.formLoadProducts.value.arrival_point),
      ...(this.formLoadProducts.value.driver && {driver: Number(this.formLoadProducts.value.driver)}),
      conveyor: Number(this.formLoadProducts.value.conveyor),
      ...(this.vehicleChoiceFirst?.vehicle_type === EVehicleType.CARRIER_TRUCK && {
        carrier_truck: this.formLoadProducts.value.truck_id
      }),
      ...(this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.CARRIER_TRUCK && {
        road_tractor: this.formLoadProducts.value.truck_id,
        semi_trailer: this.formLoadProducts.value.truck_id_two,
      }),
      update_conveyor_data_from_other_points: true,
      delivered_products: this.formLoadProducts.value.delivered_products.filter((item: any) => item.asked > 0).map((item: any) => ({ product_id: item.product_id, asked: Number(item.asked) })),
      products_to_remove: this.productsToRemove.map((id) => ({
        product_id: id
      })),
    };
    const previousPoint = this.indexSelectedPoint > 1 ? this.point_of_deliveries[this.indexSelectedPoint - 1] : null;
    const nextPoint = this.indexSelectedPoint < this.point_of_deliveries.length ? this.point_of_deliveries[this.indexSelectedPoint + 1] : null;
    if (!isDateTime1AfterDateTime2(payload.planned_departure, this.logisticTour.start_date)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date de départ ne doit pas être inférieure à la date de démarrage de la tournée",
        icon: 'error',
      });
    }
    else if (!isDateTime1AfterDateTime2(payload.planned_arrival, payload.planned_departure)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être inférieure à la date départ",
        icon: 'error',
      });
    }
    else if (nextPoint && !isDateTime1AfterDateTime2(nextPoint.planned_departure, payload.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être supérieure à la date départ du point suivant",
        icon: 'error',
      });
    }
    else if (previousPoint && !isDateTime1AfterDateTime2(payload.planned_departure, previousPoint.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date départ ne doit pas être inférieure à la date d'arrivée du point précédent",
        icon: 'error',
      });
    } else {
      this.loading = true;
      this.showSpinner();
      this.pointOfDeliveryService.update(this.selectedPointOfDelivery.id, payload).subscribe({
        next: (response) => {
          this.loading = false;
          this.showSpinner();
          this.closeModal(false)
          this.message = 'Point de livraison modifié avec succès';
          this.productsToRemove = [];
          this.getLogisticTour(this.logistic_tour_id);
          this.toastService.show(this.message, {
            header: 'Succès',
            classname: 'bg-primary text-light toast-container p-1',
            delay: 5000,
          });
        },
        error: (error) => {
          this.loading = false;
          this.showSpinner();
          if (error.includes("is out of range for ActiveModel::Type::Integer with limit 4 bytes")) {
            this.error = `La valeur à chargé est trop grande. Cette valeur ne peut être prise en charge pour le champ demandé`;
          } else {
            this.error = `Une erreur est survenue lors de la modification du point de livraison. Merci de réessayer`;
          }
          this.toastService.show(this.error, {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container p-1',
            delay: 5000,
          });
        },
      })
    }

  }

  openConfirmModalRemovePointOfDelivery(indexObjectToremove: number, point_of_delivery: PointOfDelivery) {
    this.modalConfig = {
      modalTitle: 'Confirmation',
      submitButtonLabel: 'Oui',
      cancelButtonLabel: 'Non',
      headerClass: 'bg-info d-none text-white',
      cancelButtonClass: 'btn-danger ',
      submitButtonClass: 'btn-primary text-white',
    };
    if (indexObjectToremove === 1 && indexObjectToremove === this.point_of_deliveries.length - 1) {
      this.modalConfig.contentText = "Etes-vous sûre de vouloir supprimer ce dépôt de la tournée, cette action supprimera la tournée?"
      this.modalConfig.onSubmit = () => this.confirmRemovePointOfDelivery(point_of_delivery, true);
    }
    else {
      this.modalConfig.contentText = "Etes-vous sûre de vouloir supprimer ce dépôt de la tournée"
      this.modalConfig.onSubmit = () => this.confirmRemovePointOfDelivery(point_of_delivery);
    }
    this.openModalConfirm();
  }

  confirmRemovePointOfDelivery(selectedPointOfDelivery: PointOfDelivery, isLastPoint: boolean = false) {
    this.loading = true;
    this.showSpinner();
    this.pointOfDeliveryService.delete(selectedPointOfDelivery.id).subscribe({
      next: (response) => {
        this.message = response.message;
        this.loading = false;
        this.showSpinner();
        this.closeModalConfirm();
        this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
        if (isLastPoint) {
          this.closeModalConfirm();
          this.router.navigateByUrl('/list-tour-logistic');
        } else {
          this.getLogisticTour(this.logistic_tour_id)
        }
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la suppression du point de livraison. Merci de réessayer`;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        })
        this.loading = false;
        this.showSpinner();
      },
    })

  }

  //api services
  updateLogisticTour() {
    this.loading = true;
    this.showSpinner();
    const valuesTourItem: UpdateLogisticTourItemDto = {
      ...(this.vehicleChoiceFirst?.vehicle_type === EVehicleType.CARRIER_TRUCK && {
        carrier_truck: this.formInfosTour.value.truck_id
      }),
      ...(this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.CARRIER_TRUCK && {
        road_tractor: this.formInfosTour.value.truck_id,
        semi_trailer: this.formInfosTour.value.truck_id_two,
      }),
      conveyor: this.formInfosTour.value.conveyor,
      driver: this.formInfosTour.value.driver
    }
    const updateStartDateTourPromise = this.formTourDateModified ? this.updateStartDateTour() : Promise.resolve();

    Promise.all([updateStartDateTourPromise])
      .then(() => {
        this.loading = false;
        this.showSpinner();
        this.handleSuccess();
      })
      .catch((error) => {
        this.loading = false;
        this.showSpinner();
        this.handleError(error);

      });
  }

  updateStartDateTour() {
    const payload: UpdateTourLogisticDto = {
      start_date: `${this.formInfosTour.value.start_date} ${this.formInfosTour.value.start_time}:00`,
    }
    return new Promise<void>((resolve, reject) => {
      this.tourLogisticService.update(this.logistic_tour_id, payload).subscribe({
        next: (response) => {
          this.message = 'La date de démarrage de la tournée a été modifiée avec succès';
          resolve(response);
          this.toastService.show(this.message, {
            header: 'Succès',
            classname: 'bg-primary text-light toast-container p-1',
            delay: 5000,
          });
        },
        error: (error) => {
          this.error = `Une erreur est survenue lors de la modification de la date de démarrage de la tournée. Merci de réessayer`;
          reject(this.error);
        },
      });
    });
  }


  handleSuccess() {
    this.getLogisticTour(this.logistic_tour_id);
  }

  handleError(error: string) {
    this.toastService.show(error, {
      header: 'Erreur',
      classname: 'bg-danger text-light toast-container p-1',
      delay: 5000,
    });
  }

  calculatePercentagePalletLoaded(loaderPallets: number, vehiclePallets: number) {
    return calculatePercentage(loaderPallets, vehiclePallets);
  }

  getLogisticTour(id: number) {
    this.loading = true;
    this.showSpinner()
    this.ignoreFirstChange = true;
    this.tourLogisticService.getLogisticTourById(id).subscribe({
      next: (response) => {
        this.formModified = false;
        this.formTourDateModified = false;
        this.checkChangeFromInfosTourItem = false;
        this.logisticTour = response.logistic_tour;
        this.logisticTour.point_of_deliveries = sortArray(this.logisticTour.point_of_deliveries, 'created_at');
        this.loaderPallets = this.getLoadedPalletQuantity(this.logisticTour.point_of_deliveries);
        this.getLastVehiclePallet(this.logisticTour);
        this.initForm();
        this.loading = false;
        this.showSpinner();
        this.getDepotList();
        this.formInfosTour.valueChanges.pipe().subscribe((formValues) => {
          if (this.ignoreFirstChange) {
            this.ignoreFirstChange = false;
            this.defaultTourInfo = this.formInfosTour.value;
          } else {
            this.formModified = !this.isFormDefault();
            if (formValues.start_date !== this.defaultTourInfo.start_date ||
              formValues.start_time !== this.defaultTourInfo.start_time) {
              this.formTourDateModified = true;
            }
          }
        });
      },
      error: (error) => {
        this.loading = false;
        this.showSpinner();
        if (error.includes("Couldn't find LogisticTour with 'id'=")) {
          this.error = `Tournée avec l'id ${id} introuvable`;
          this.router.navigateByUrl('/list-tour-logistic');
          this.toastService.show(this.error, {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container p-1',
            delay: 5000,
          });
        } else {
          this.error = `Une erreur est survenue lors de la récupération de la tournée logistique. Merci de réessayer`;
        }
      },
    })
  }

  getDepotList() {
    this.point_of_deliveries = [];
    this.loadingDepot = true;
    this.showSpinner();
    this.depotService.getDepots({ page: 1, limit: 25 }).subscribe({
      next: (response) => {
        this.depotsList = response.depots;
        this.defaultInitialDepot = this.getDefaultInitialPoint(this.depotsList) || undefined;
        this.point_of_deliveries.push({
          planned_departure: this.logisticTour.start_date,
          planned_arrival: this.logisticTour.start_date,
          departure_point: Number(this.defaultInitialDepot?.id),
          arrival_point: Number(this.defaultInitialDepot?.id)
        })
        this.formInfosTour.patchValue({
          initial_point: this.defaultInitialDepot?.id || ''
        });
        this.point_of_deliveries.push(...this.logisticTour.point_of_deliveries);
        this.depotIdsAllReadyUsed = this.point_of_deliveries.map((p: any) => p.arrival_point.id || p.arrival_point);
        this.loadingDepot = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des dépôts. Merci de réessayer`;
        this.loadingDepot = false;
        this.showSpinner();
      },
    })
  }

  getFilteredListVehicles() {
    this.loadingtruck = true;
    this.showSpinner();
    this.error = '';
    this.vehicleService.getFilteredListTruck({ page: 1, limit: 50 }).subscribe({
      next: (response) => {
        this.othersVehiclesList = response.data.filter((vehicle) => vehicle.vehicle_type !== EVehicleType.SEMI_TRAILER);
        this.semiTrailerVehiclesList = response.data.filter((vehicle) => vehicle.vehicle_type === EVehicleType.SEMI_TRAILER);
        this.loadingtruck = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des véhicules. Merci de réessayer`;
        this.loadingtruck = false;
        this.showSpinner();
      },
    });
  }

  getConveyorUsers() {
    this.loadingUsers = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [6] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.conveyorsUsers = response.data;
        this.loadingUsers = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateurs. Merci de réessayer`;
        this.loadingUsers = false;
        this.showSpinner();
      },
    });
  }

  getDriverUsers() {
    this.loadingUsers = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [15] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.driverUsers = response.data;
        this.loadingUsers = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateurs. Merci de réessayer`;
        this.loadingUsers = false;
        this.showSpinner();
      },
    });
  }

  getProducts(): void {
    this.loadingProduct = true;
    this.showSpinner();
    this.productService.getAllProducts().subscribe({
      next: (products) => {
        this.productsList = products.filter(
          (el: any) =>
            el?.is_accessorie !== true);
        this.loadingProduct = false;
        this.showSpinner();
        this.initFormLoadProducts();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des produits. Merci de réessayer.`;
        this.loadingProduct = false;
        this.showSpinner();
      },
    });
  }

  async openModalConfirm() {
    this.message = "";
    this.error = "";
    return await this.modalComponent.open();
  }

  async closeModalConfirm() {
    return await this.modalComponent.close()
  }

  //Helpers
  getIdLastPointOfDeliveryAdded() {
    const lengthOfPoints = this.point_of_deliveries.length - 1;
    if (lengthOfPoints === 0)
      return this.defaultInitialDepot?.id
    else return this.point_of_deliveries[lengthOfPoints].arrival_point
  }

  getObjectInArrayOneNotInArrayTwo(list: Depot[], selecteds: number[]) {
    return list.filter((item) => !selecteds.includes(item.id));
  }

  getDateAndTimeFromLongDate(dateTime: string) {
    return extractDateAndTime(dateTime);
  }

  getLastPointOfDeliveryAdded() {
    return this.point_of_deliveries[this.point_of_deliveries.length - 1];
  }

  getDefaultInitialPoint(depots: Depot[]) {
    return depots.find((depot) => depot.name === this.initialStartDepot)
  }

  translateVehicleType(type: EVehicleType) {
    return translateVehicleType(type);
  }

  submitButtonState() {
    return this.formLoadProducts.value.delivered_products?.every(
      (item: any) => !item.asked
    );
  }
  getLoadProductsValues() {
    return this.formLoadProducts.get('delivered_products') as FormArray;
  }

  getTourFormControlFor(control: string) {
    return this.formInfosTour.get(control);
  }

  getInputFormLoadProductFor(control: string) {
    return this.formLoadProducts.get(control);
  }

  getDepotLabelById(id?: number) {
    return this.depotsList.find((depot) => depot.id === Number(id))?.name;
  }

  resetInputValueAndValidators(controlName: string) {
    this.getInputFormLoadProductFor(controlName)?.setValue('');
    this.getInputFormLoadProductFor(controlName)?.clearValidators();
    this.getInputFormLoadProductFor(controlName)?.updateValueAndValidity();
  }

  roundPositivePallet(value: number) {
    return Math.ceil(value);
  }

  checkIfValueInfoTourItemFormChange() {
    this.formLoadProducts.valueChanges.subscribe((values) => {
      if (this.selectedPointOfDelivery.carrier_truck != null && isCarrierTruckChanged(values, this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = true;
      } else if (this.selectedPointOfDelivery.road_tractor != null && isRoadTractorChanged(values, this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = true;
      } else if (isNoChange(values, this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = false;
      } else if (!isInfosTourItemNotNull(this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = true;
      } else {
      }
    });
  }

  getLastDeliveryPointVehicleAndUserActors(tour: LogisticTour) {
    return getLastDeliveryPointVehicleAndUserActors(tour);
  }

  changeModalFrame(frame: EModalFrameDeliveryPoint) {
    this.modalFrameSelected = frame;
  }

  //loaoder
  showSpinner() {
    if (this.loading || this.loadingUsers || this.loadingtruck || this.loadingDepot || this.loadingProduct) this.spinner.show();
    else this.spinner.hide();
  }
}
