import { Component, OnInit } from '@angular/core';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerState,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { SectorService } from 'src/app/services/sector.service';
import { Sector } from 'src/app/models/sector';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from 'src/app/utils/datepicker-adapter';
import { OrderService } from 'src/app/services/order.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PointOfSale } from 'src/app/models/point-of-sale';
import { PointOfSaleService } from 'src/app/services/point-of-sale.service';

@Component({
  selector: 'app-list-order',
  templateUrl: './list-order.component.html',
  styleUrls: ['./list-order.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ListOrderComponent implements OnInit {
  pointOfSales: PointOfSale[] = [];
  sectors: Sector[] = [];
  date!: string;
  minDate!: NgbDateStruct;
  deliveryDate!: string;
  sector: undefined | number;
  loading: boolean = false;
  weekDay: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  currentWeekDay!: string;

  constructor(
    private orderService: OrderService,
    private pointOfSaleService: PointOfSaleService,
    private sectorService: SectorService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentWeekDay = this.weekDay[new Date().getDay()];
    this.date = this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
    this.deliveryDate = this.dateAdapter.toModel(
      this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 1)
    )!;
    this.minDate = this.ngbCalendar.getToday();
    this.getPointOfSales();
    this.getSectors();
  }

  getPointOfSales(): void {
    const params: any = {};

    if (this.date) {
      params['date'] = this.date;
    }

    if (this.sector !== undefined) {
      params['sector_id'] = this.sector;
    }

    this.loading = true;
    this.pointOfSaleService.getPointOfSales(params).subscribe({
      next: (pointOfSales) => {
        this.loading = false;
        this.pointOfSales = pointOfSales.sort(
          (val1: any, val2: any) =>
            parseInt(val1.sale_id)
              .toString()
              .localeCompare(parseInt(val2.sale_id).toString()) ||
            this.isDeliveryDay(val1) > this.isDeliveryDay(val2) ||
            val2.no_id.toString().localeCompare(val1.no_id)
        );
      },
      error: (e: any) => {
        this.loading = false;
      },
    });
  }

  getSectors(): void {
    this.sectorService.getSectors().subscribe((sectors) => {
      this.sectors = sectors;
      if (this.route.snapshot.queryParamMap.get('sector_id')) {
        this.sector = Number(
          this.route.snapshot.queryParamMap.get('sector_id')
        );
        this.getPointOfSales();
      }
    });
  }

  getHasDeliveryToDay() {
    return this.pointOfSales.filter(
      (el) =>
        el.delivery_day_1 === this.currentWeekDay ||
        el.delivery_day_2 === this.currentWeekDay ||
        el.delivery_day_3 === this.currentWeekDay
    );
  }

  getHasNoDeliveryToDay() {
    return this.pointOfSales.filter(
      (el) =>
        el.delivery_day_1 !== this.currentWeekDay &&
        el.delivery_day_2 !== this.currentWeekDay &&
        el.delivery_day_3 !== this.currentWeekDay
    );
  }

  isDeliveryDay(el: any) {
    return (
      el.delivery_day_1 === this.currentWeekDay ||
      el.delivery_day_2 === this.currentWeekDay ||
      el.delivery_day_3 === this.currentWeekDay
    );
  }

  onSectorChange(sectorId: any): void {
    this.getPointOfSales();
  }

  onDateChange(date: any): void {
    // this.getPointOfSales()
  }

  onDatePreorderChange(date: any): void {
    this.getPointOfSales();
  }

  canForecast(): boolean {
    return (
      this.sector !== undefined &&
      this.pointOfSales.filter((e) => e.is_ordered).length > 0 &&
      this.deliveryDate !== undefined
    );
  }

  createForecast(): void {
    this.spinner.show();
    this.orderService
      .createForecast({
        sector_id: this.sector,
        date: this.deliveryDate,
        preorder_date: this.date,
      })
      .subscribe({
        next: (v) => {
          this.router.navigate(['/', 'loadings'], {
            queryParams: { sector_id: this.sector, date: this.deliveryDate },
          });
          Swal.fire({
            title: 'Success!',
            text: v.message,
            icon: 'success',
          });
          this.spinner.hide();
        },
        error: (e: any) => {
          Swal.fire({
            title: 'Erreur!',
            text: e,
            icon: 'error',
          });
          this.spinner.hide();
        },
      });
  }

  getCA(pointOfSale: PointOfSale, sales: any): number {
    if (sales.length === 0) {
      return 0;
    } else {
      if (pointOfSale.is_ordered) {
        return pointOfSale.total_ordered;
      } else {
        return pointOfSale.total_proposed;
      }
    }
    return 0;
  }

  change_preorder_to_order(order_id: number, sector_id: number) {
    this.spinner.show();
    this.orderService.changePreorderToOrder(order_id).subscribe({
      next: (v) => {
        window.location.href = `/orders?sector_id=${sector_id}&date=${this.date}`;
        Swal.fire({
          title: 'Success!',
          text: v.message,
          icon: 'success',
        });
        this.spinner.hide();
      },
      error: (e: any) => {
        Swal.fire({
          title: 'Erreur!',
          text: e,
          icon: 'error',
        });
        this.spinner.hide();
      },
    });
  }

  delete_order(order_id: number, sector_id: number) {
    Swal.fire({
      title: 'Etes-vous sur de vouloir supprimer cette (pré)commande?',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Supprimer',
      denyButtonText: `Annuler`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        this.orderService.deleteOrder(order_id).subscribe({
          next: (v) => {
            window.location.href = `/orders?sector_id=${sector_id}&date=${this.date}`;

            Swal.fire({
              title: 'Success!',
              text: v.message,
              icon: 'success',
            });
            this.spinner.hide();
          },
          error: (e: any) => {
            Swal.fire({
              title: 'Erreur!',
              text: e,
              icon: 'error',
            });
            this.spinner.hide();
          },
        });
      }
    });
  }

  isInThePast(): boolean {
    let inPast: boolean = false;
    let today: Date = new Date();
    let dateValue: Date = new Date(this.date);
    today.setHours(0, 0, 0, 0);

    if (dateValue < today) {
      inPast = true;
    }

    return inPast;
  }
}
