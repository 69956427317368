import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgxBootstrapIconsModule, calendar3, eye } from 'ngx-bootstrap-icons';

import { CommonModule } from '@angular/common';
import { EmptyCardModule } from 'src/app/components/empty-card/empty-card.module';
import { ErrorInterceptorService } from './../../services/error-interceptor.service';
import { GoBackModule } from './../../components/go-back/go-back.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptorService } from './../../services/jwt-interceptor.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TableModule } from '../../components/table/table.module';
import { ListOrderComponent } from './list-order/list-order.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { OrderRoutingModule } from './order-routing.module';
import { IsGuardedModule } from 'src/app/components/is-guarded/is-guarded.module';
import { OrderComponent } from './order.component';

const icons = {
  calendar3,
  eye,
};

@NgModule({
  declarations: [
    OrderComponent,
    ListOrderComponent,
    NewOrderComponent,
    ViewOrderComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [OrderComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    OrderRoutingModule,
    NgxBootstrapIconsModule.pick(icons),
    GoBackModule,
    TableModule,
    PaginationModule,
    EmptyCardModule,
    SweetAlert2Module.forChild(),
    ModalComponent,
    IsGuardedModule,
  ],
})
export class OrderModule {}
