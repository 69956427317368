import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Config from '../utils/config';
import { Observable } from 'rxjs';
import { OtherCountryListDTO } from '../dtos/other-country.dtos';

@Injectable({
  providedIn: 'root',
})
export class OtherCountryService {
  constructor(private http: HttpClient) {}

  getOtherCountries(params: any): Observable<OtherCountryListDTO> {
    return this.http.get<OtherCountryListDTO>(
      `${Config.baseUrlV2}/country_others`,
      {
        params: params,
      }
    );
  }

  addOtherCountry(countryOther: any) {
    return this.http.post<any>(`${Config.baseUrlV2}/country_others`, {
      country_other: countryOther,
    });
  }

  updateOtherCountry(countryOther: any) {
    return this.http.put<any>(
      `${Config.baseUrlV2}/country_others/${countryOther['id']}`,
      {
        country_other: countryOther,
      }
    );
  }

  deleteOtherCountry(countryOther: any) {
    return this.http.delete<any>(
      `${Config.baseUrlV2}/country_others/${countryOther['id']}`
    );
  }
}
