<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <app-go-back></app-go-back>
    <h1 class="h2 text-info">{{ rc_label }} - sectors</h1>
  </div>
</div>
<div class="row mb-3">
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h4 class="text-info">{{ month | numberToMonth }} {{ year }}</h4>
            <p class="mb-0 fw-bold">Période</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div
      class="card  {{ ca_total >= ca! ? 'border-success' : 'border-danger' }}"
    >
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div class="{{ ca_total > ca! ? 'text-danger' : 'text-info' }}">
            <h5>
              {{ ca | currency : "XOF" : "symbol-narrow" : "" : "fr-FR" }}
            </h5>
            <p class="mb-0 fw-bold">C.A de la région commerciale</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div
      class="card {{
        ca_total >= ca!
          ? 'border-success bg-success'
          : 'border-danger bg-danger'
      }} {{ ca_total > ca! ? 'border-danger' : 'border-success' }}"
    >
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h5 class="text-white">
              {{ ca_total | currency : "XOF" : "symbol-narrow" : "" : "fr-FR" }}
            </h5>
            <p class="mb-0 fw-bold text-white">C.A</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h5 class="text-info">{{ return_rate }}</h5>
            <p class="mb-0 fw-bold">Taux de retour</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="avoid-bottom">
  <table class="table table-responsive">
    <thead>
      <tr class="row">
        <th class="col">Secteur</th>
        <th class="col">CA</th>
        <th class="col">Taux de retour</th>
      </tr>
    </thead>
    <tbody></tbody>
  </table>
  <form [formGroup]="myForm">
    <div formArrayName="goals">
      <div
        *ngFor="let item of getControls(); let i = index"
        [formGroupName]="i"
      >
        <div class="row">
          <div class="form-group col mb-2">
            <!-- <label for="label">Secteur</label> -->
            <input
              type="text"
              class="form-control"
              formControlName="label"
              (disabled)="(true)"
            />
          </div>
          <div class="form-group col mb-2">
            <!-- <label for="label">CA</label> -->
            <input
              class="form-control"
              formControlName="ca"
              appCurrencyFormat
            />
          </div>
          <div class="form-group col mb-2">
            <!-- <label for="label">CA</label> -->
            <input
              type="number"
              class="form-control"
              formControlName="return_rate"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<app-is-guarded [permissions]="['CAN_CREATE_GOAL_SECT']">
  <div
    *ngIf="checkIsFormDirty() && ca_total > 0"
    class="bottom-bar shadow-sm fixed-bottom col-md-9 ms-sm-auto col-lg-10 px-md-4"
  >
    <div class="justify-content-end mb-md-0 mx-1 d-flex">
      <div class="d-flex flex-wrap flex-md-nowrap">
        <div class="mx-2">
          <button
            type="button"
            class="btn btn-sm btn-info fw-bold text-white"
            (click)="onSubmit()"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</app-is-guarded>
