import { Year } from './../utils/helpers';
import { Performance } from './../models/performance';
import { HttpClient } from '@angular/common/http';
import { PointOfSale } from 'src/app/models/point-of-sale';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { buildParams } from '../utils';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  constructor(private http: HttpClient) { }

  getPerformances(params: any = undefined): Observable<Performance[]> {
    const url = 'mt/admin/performances' + buildParams(params)
    return this.http.get<Performance[]>(`${Config.baseUrl}/${url}`);
  }

  getPointOfSalePerformances(point_of_sale_id: number, params: any = undefined): Observable<any> {
    const url = `mt/admin/performances/${point_of_sale_id}` + buildParams(params)
    return this.http.get<any>(`${Config.baseUrl}/${url}`)
  }

  getSectorsPerformances(params: any = undefined): Observable<any> {
    return this.http.get<any>(`${Config.baseUrl}/mt/admin/performance/sectors` + buildParams(params))
  }

  getYears(): Observable<Year[]> {
    return this.http.get<any>(`${Config.baseUrl}/mt/admin/performance/years`);
  }
}
