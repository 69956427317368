import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ImportCoordinatesComponent } from 'src/app/components/import-coordinates/import-coordinates.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginationInterface } from 'src/app/interfaces/pagination-interface';
import { Country } from 'src/app/models/country';
import { CountriesService } from 'src/app/services/countries.service';
import { MapService } from 'src/app/services/map.service';
import { ToastService } from 'src/app/services/toast.service';
import { CountryStore } from 'src/app/stores/country.store';
import { ModalConfig } from 'src/app/types';
import { ActionEventType, ColumnDef, EColumnType} from 'src/app/utils';
import { CountryCurrency, countriesWithCurrency } from 'src/app/utils/helpers';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  /* Observables */
  countries$: Observable<Country[]> | undefined;
  loading$: Observable<boolean> | undefined;
  formSuccess$: Observable<boolean> | undefined;
  error$: Observable<string> | undefined;

  /* vars */
  currentCountry: Country | null = null;
  allCountries: CountryCurrency[] = countriesWithCurrency;

  /* Modal configuration */
  modalConfig!: ModalConfig;
  modalForm!: FormGroup;
  modalFormType: string = EModalFormType.CREATE;
  modalFormDisplay: boolean = false;
  modalFormStatusActionMessage: string = '';

  /*  Inputs  */
  @ViewChild('nGmodal') private modalComponent!: ModalComponent;

  /* Filter and pagination props */
  pagination!: PaginationInterface;
 
   /* Imports */
   fileName: string = '';
   loadedData: { country: string; x_coord: number; y_coord: number;  isValid: boolean }[] = [];
   loadedDataToDisplay: { country: string; x_coord: number; y_coord: number ; isValid: boolean}[] = [];
   isValidImportedData: boolean = false;
   invalidData: string[] = [];
   importingData: boolean = false;
   error: string = '';

   @ViewChild( ImportCoordinatesComponent ) importCoordinateComponent!: ImportCoordinatesComponent;

  /* Column definitions */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'ISO',
      name: 'iso',
      positionClass: ['fw-normal'],
    }),
    new ColumnDef({
      label: 'Nom',
      name: 'name',
    }),
    new ColumnDef({
      label: 'Devise monétaire',
      parent: 'currency',
      name: 'iso',
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
    }),
    new ColumnDef({
      label: 'Catalogues',
      name: 'catalog_length',
      type: EColumnType.NUMBER,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
    }),
    new ColumnDef({
      label: 'Actions',
      type: EColumnType.ACTION,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-center'],
      actions: [
        {
          baseUrl: '',
          text: 'Modifier',
          functionToInvoque: 'openUpdateModal',
          actionToogleableIndex: 0,
          class: 'btn btn-warning btn-sm',
          permissions: ['CAN_UPDATE_CLUSTER'],
        },
      ],
    }),
  ];

  /* Table configuration */
  tableActionsFunction(event: ActionEventType) {
    switch (event.action.functionToInvoque) {
      case 'openUpdateModal':
        this.openUpdateModal(event);     
        break;
      default:
        break;
    }
  }

  constructor(
    private countryStore: CountryStore,
    private spinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastService: ToastService,
    private countriesService: CountriesService,
    private mapService: MapService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.countries$ = this.countryStore.select((state) => state.listCountries);
    this.loading$ = this.countryStore.select((state) => state.loading);
    this.formSuccess$ = this.countryStore.select((state) => state.formSuccess);
    this.error$ = this.countryStore.select((state) => state.error);

    this.countryStore
      .select((state) => state.pagination)
      .subscribe((pagination) => {
        if (pagination === undefined) return;
        this.pagination = pagination;
      });

    this.loading$.subscribe((loading) => {
      if (loading) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    });

    this.loading$.subscribe((formSuccess) => {
      if (formSuccess) {
        this.closeModal();
      }
    });

    this.getListCountries();
    this.initForm();
  }

  paginate(pagination: any) {
    this.countryStore.setInitial();
    this.countryStore.getListCountries({
      limit: pagination.items!,
      page: pagination.page,
    });
  }

  getListCountries() {
    this.countryStore.setInitial();
    this.countryStore.getListCountries(this.pagination);
  }

  initForm() {
    this.modalForm = this.formBuilder.group({
      name: [{value: ''}, Validators.required],
      iso: [{value: '', disabled: true}, Validators.required],
      currency_iso: [{value: '', disabled: true}, Validators.required],
      currency_name: [{value: '', disabled: true}, Validators.required],
    });
  }

  openCreateModal() {
    this.openModal();
    this.modalFormType = EModalFormType.CREATE;
    this.modalConfig = {
      modalTitle: 'Ajouter un pays',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-info text-white',
      cancelButtonClass: 'btn-secondary',
      submitButtonClass: 'btn-primary text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.create(),
    };

    this.modalForm = this.formBuilder.group({
      name: ['', Validators.required],
      iso: ['', Validators.required],
      currency_iso: ['', Validators.required],
      currency_name: ['', Validators.required],
    });
  }

  openUpdateModal(event: ActionEventType) {
    this.openModal();
    this.modalConfig = {
      modalTitle: 'Modifier le pays',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-warning text-white',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-warning text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.update(),
    };
    this.modalFormType = EModalFormType.UPDATE;
    this.currentCountry = event.rowData;
    this.modalForm = this.formBuilder.group({
      name: [this.currentCountry?.name, Validators.required],
      iso: [this.currentCountry?.iso, Validators.required],
      currency_iso: [this.currentCountry?.currency?.iso, Validators.required],
      currency_name: [this.currentCountry?.currency?.name, Validators.required],
    });
  }

  async openModal() {
    return await this.modalComponent.open();
  }

  closeModal() {
    return this.modalComponent?.close();
  }

  selectPays() {
    const selectedCountryName: string = this.modalForm.value['name'];
    const selectedCountry: CountryCurrency | undefined = this.allCountries.find(country => country.name == selectedCountryName);
    const iso = selectedCountry?.iso ?? '';
    const currency_name = selectedCountry?.currency_name ?? '';
    const currency_iso = selectedCountry?.currency_iso ?? '';

    this.modalForm.controls['iso'].setValue(iso);
    this.modalForm.controls['currency_name'].setValue(currency_name);
    this.modalForm.controls['currency_iso'].setValue(currency_iso);
  }

  create() {    
    this.countryStore.addCountry(this.modalForm.value);
  }

  update() {
    this.countryStore.updateCountry(this.modalForm.value, this.currentCountry?.id ?? 0);
  }


  // import excel file
  showSpinner() {
    if (
      this.importingData
    )
      this.spinner.show();
    else this.spinner.hide();
  }

  openImportModal() {
    this.importCoordinateComponent.openImportModal();
  }

  importData(loadedData: any) {
    this.importingData = true;
    this.showSpinner();
    const payload = {
      coordinates: loadedData.map((item:any) => ({
        name: item.country,
        latitude: item.y_coord,
        longitude: item.x_coord,
      })),
    };
    this.countriesService.importCoordinatesData(payload).subscribe({
      next: () => {
        this.importingData = false;
        this.showSpinner();
        this.toastService.show('Importation des coordonnées du pays réussies.', {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 6000,
        });
        this.importCoordinateComponent.confirmModalComponent.close();
        this.importCoordinateComponent.modalComponent.close();
      },
      error: (error) => {
        if (error.includes("At least one country not found")) {
          this.error = `Au moins un pays n'a pas été trouvé`;
        } else {
          this.error = `Une erreur est survenue lors l'import des coordonnées des pays. Merci de réessayer !`;
        }
        this.importingData = false;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 6000,
        });
        this.showSpinner();
      },
    });
  }

  redirectToMap() {
    this.mapService.redirectToMap(this.router, 'countries');
  }
}
