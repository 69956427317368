import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EDropDownSearchFilterType, IDropDownSearchFilter } from 'src/app/components/inputs-search-filter-modal/inputs-search-filter-modal.component';
import { LogisticToursPaginationInterface, SearchFiltersLogisticToursDto } from 'src/app/interfaces/logistics-tours';
import { EVehicleType } from 'src/app/interfaces/vehicle-interface';
import { ELogisticTourStatus, LogisticTour } from 'src/app/models/logistic-tour';
import { PointOfDelivery } from 'src/app/models/point-of-delivery';
import { User } from 'src/app/models/user';
import { Vehicle } from 'src/app/models/vehicle';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToursService } from 'src/app/services/tours.service';
import { UsersService } from 'src/app/services/users.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { translateVehicleType } from 'src/app/utils';
import { getLastDeliveryPointVehicleAndUserActors, translateLogisticTourStatus } from 'src/app/utils/helpers';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  /* Global */
  error: string = '';
  message: string = '';
  currentUser!: User;
  loading: boolean = false;
  loadingUsers: boolean = false;
  loadingtruck: boolean = false;
  isDropDownFilterList: boolean = false;
  dropDownFilterListType!: EDropDownSearchFilterType;
  EDropDownSearchFilterType = EDropDownSearchFilterType;
  filterForm!: FormGroup;

  logisticTourStatus: ELogisticTourStatus[] = Object.values(ELogisticTourStatus);

  ELogisticToursStatus = ELogisticTourStatus;
  logisticTourList: LogisticTour[] = [];

  vehiclesList: Vehicle[] = [];
  filterdVehiclesList: Vehicle[] = [];
  selectedVehicleFilter?: Vehicle;
  carrierTruckVehiclesList: Vehicle[] = [];
  roadTractorVehiclesList: Vehicle[] = [];
  semiTrailerVehiclesList: Vehicle[] = [];

  valuesCarrierTruck: number[] = [];
  valuesTractorTruck: number[] = [];
  valuesTrailerTruck: number[] = [];

  semi_trailer = new FormControl();
  road_tractor = new FormControl();
  carrier_truck = new FormControl();

  VehicleType = EVehicleType;

  conveyorsUsers: User[] = [];
  driverUsers: User[] = [];

  plannings: LogisticTour[] = [];
  pagination: LogisticToursPaginationInterface = {
    page: 1,
    limit: 15,
  };
  constructor(
    private authentificationService: AuthenticationService,
    private toursService: ToursService,
    private usersService: UsersService,
    private vehicleService: VehicleService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authentificationService.currentUserValue;
    this.getFilteredListVehicles();
    this.getConveyorUsers();
    this.getDriverUsers();
    this.getLogisticToursList(this.pagination);
    this.initFilterForm();
    this.onCarrierTruckChange();
    this.onRoadTtractorTruckChange();
    this.onSemiTrailerTruckChange();
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      conveyor: '',
      driver: '',
      start_date: '',
      end_date: '',
      status: ''
    });
  }

  getConveyorUsers() {
    this.loadingUsers = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [6] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.conveyorsUsers = response.data;
        this.loadingUsers = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateur. Merci de réessayer`;
        this.loading = false;
        this.showSpinner();
      },
    });
  }

  getDriverUsers() {
    this.loadingUsers = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [15] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.driverUsers = response.data;
        this.loadingUsers = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateur. Merci de réessayer`;
        this.loading = false;
        this.showSpinner();
      },
    });
  }

  getFilteredListVehicles() {
    this.loadingtruck = true;
    this.showSpinner();
    this.error = '';
    this.vehicleService.getFilteredListTruck({ page: 1, limit: 200 }).subscribe({
      next: (response) => {
        this.vehiclesList = response.data;
        this.roadTractorVehiclesList = this.vehiclesList.filter((vehicle) => vehicle.vehicle_type === EVehicleType.ROAD_TRACTOR);
        this.carrierTruckVehiclesList = this.vehiclesList.filter((vehicle) => vehicle.vehicle_type === EVehicleType.CARRIER_TRUCK);
        this.semiTrailerVehiclesList = this.vehiclesList.filter((vehicle) => vehicle.vehicle_type === EVehicleType.SEMI_TRAILER);
        Object.assign(this.filterdVehiclesList, this.vehiclesList);
        this.loadingtruck = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des véhicules. Merci de réessayer`;
        this.loadingtruck = false;
        this.showSpinner();
      },
    });
  }

  getLastDeliveryPointVehicleAndUserActors(tour: LogisticTour){
    return getLastDeliveryPointVehicleAndUserActors(tour);
  }


  getLogisticToursList(pagination: LogisticToursPaginationInterface) {
    this.loading = true;
    this.showSpinner()
    this.toursService.getLogisticToursList(pagination).subscribe({
      next: (response) => {
        this.logisticTourList = response.logistic_tours
        this.pagination = {
          ...this.pagination,
          next: response.metadata.next,
          last: response.metadata.last,
          pages: response.metadata.pages,
        };
        this.loading = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des tournées. Merci de réessayer`;
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  getFormControlFor(control: string) {
    return this.filterForm.get(control)!;
  }

  resetDateFilter() {
    this.getFormControlFor('start_date').patchValue('');
    this.getFormControlFor('end_date').patchValue('')
  }

  dropDownFilterList(values: IDropDownSearchFilter) {
    this.isDropDownFilterList = values.isDropDown;
    this.dropDownFilterListType = values.filter_type;
  }

  getListVehiclesFilteredBySearch(searchTerm: string, vehiclesList: Vehicle[]) {
    return vehiclesList.filter((v) => v.immatriculation.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  searchTermFilter(searchTerm: string) {
    if (searchTerm.trim() !== "") {
      this.filterdVehiclesList = this.getListVehiclesFilteredBySearch(searchTerm, this.vehiclesList);
    } else {
      Object.assign(this.filterdVehiclesList, this.vehiclesList);
    }
  }

  filterListByVehicle(vehicle: Vehicle) {
    this.isDropDownFilterList = false;
    this.selectedVehicleFilter = vehicle;
    this.setValueVehicleSelectInListSearch(this.selectedVehicleFilter);
    this.pagination = {
      ...this.pagination,
      carrier_truck: this.valuesCarrierTruck,
      road_tractor: this.valuesTractorTruck,
      semi_trailer: this.valuesTrailerTruck,
      page: 1
    }
    this.getLogisticToursList(this.pagination);
  }

  searchFilter() {
    this.isDropDownFilterList = false;
    let filter = this.filterForm.value;
    const filterPayload: SearchFiltersLogisticToursDto = {
      ...filter,
      status: filter.status.length ? filter.status : [],
      conveyor: filter.conveyor.length ? filter.conveyor : [],
      semi_trailer: this.valuesTrailerTruck.length ? this.valuesTrailerTruck : [],
      carrier_truck: this.valuesCarrierTruck.length ? this.valuesCarrierTruck : [],
      road_tractor: this.valuesTractorTruck.length ? this.valuesTractorTruck : [],
      ...(filter.start_date !== "" && filter.end_date !== "" && {
        interval_of_date: {
          start_date: filter.start_date,
          end_date: filter.end_date
        }
      })
    }
    this.pagination = { ...filterPayload, page: 1, limit: 15 };
    this.getLogisticToursList(this.pagination);
  }



  paginate(pagination: any) {
    this.pagination = {
      ...this.pagination,
      page: pagination.page,
      limit: pagination.limit,
    };
    this.getLogisticToursList(this.pagination);
  }

  showSpinner() {
    if (this.loading) this.spinner.show();
    else this.spinner.hide();
  }

  translateLogisticTourStatus(status: ELogisticTourStatus) {
    return translateLogisticTourStatus(status)
  }

  translateVehicleType(vehicleType: EVehicleType) {
    return translateVehicleType(vehicleType)
  }

  isVehicleSelectedInListFilter(vehicle: Vehicle) {
    return [...this.valuesCarrierTruck, ...this.valuesTractorTruck, ...this.valuesTrailerTruck].includes(vehicle.id);
  }

  onCarrierTruckChange() {
    this.carrier_truck.valueChanges.subscribe((values: number[]) => {
      if (this.selectedVehicleFilter &&
        this.selectedVehicleFilter.vehicle_type === EVehicleType.CARRIER_TRUCK &&
        !values.includes(this.selectedVehicleFilter?.id)) {
        this.selectedVehicleFilter = undefined;
      }
      this.valuesCarrierTruck = values;
    })
  }

  onSemiTrailerTruckChange() {
    this.semi_trailer.valueChanges.subscribe((values: number[]) => {
      if (this.selectedVehicleFilter &&
        this.selectedVehicleFilter.vehicle_type === EVehicleType.SEMI_TRAILER &&
        !values.includes(this.selectedVehicleFilter?.id)) {
        this.selectedVehicleFilter = undefined;
      }
      this.valuesTrailerTruck = values;
    })
  }

  onRoadTtractorTruckChange() {
    this.road_tractor.valueChanges.subscribe((values: number[]) => {
      if (this.selectedVehicleFilter &&
        this.selectedVehicleFilter.vehicle_type === EVehicleType.ROAD_TRACTOR &&
        !values.includes(this.selectedVehicleFilter?.id)) {
        this.selectedVehicleFilter = undefined;
      }
      this.valuesTractorTruck = values;
    })
  }

  setValueVehicleSelectInListSearch(vehicle: Vehicle) {
    switch (vehicle.vehicle_type) {
      case EVehicleType.CARRIER_TRUCK:
        if (this.valuesCarrierTruck.includes(vehicle.id)) {
          this.valuesCarrierTruck = this.removeIdFromArray(vehicle.id, this.valuesCarrierTruck);
        } else this.carrier_truck.patchValue([...this.valuesCarrierTruck, vehicle.id]);
        break;

      case EVehicleType.ROAD_TRACTOR:
        if (this.valuesTractorTruck.includes(vehicle.id)) {
          this.valuesTractorTruck = this.removeIdFromArray(vehicle.id, this.valuesTractorTruck);
        } else this.road_tractor.patchValue([...this.valuesTractorTruck, vehicle.id]);

        break;

      case EVehicleType.SEMI_TRAILER:
        if (this.valuesTrailerTruck.includes(vehicle.id)) {
          this.valuesTrailerTruck = this.removeIdFromArray(vehicle.id, this.valuesTrailerTruck);
        } else this.semi_trailer.patchValue([...this.valuesTrailerTruck, vehicle.id]);

        break;

      default:
        break;
    }
  }

  removeIdFromArray(id: number, array: number[]) {
    return array.filter((id_array) => id != id_array);
  }

  addIdTOArray(id: number, array: number[]) {
    array.push(id);
  }
  getFullName(objetValue: Partial<{ first_name: string, last_name: string }>) {
    return `${objetValue.first_name} ${objetValue.last_name}`
  }
}
