import {
  Month,
  months,
  Year,
  formatDate,
  isInThePast,
} from './../../../utils/helpers';
import { SectorService } from './../../../services/sector.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Sector } from './../../../models/sector';
import { Component, OnInit } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from 'src/app/utils/datepicker-adapter';
import { PerformanceService } from 'src/app/services/performance.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GoalService } from 'src/app/services/goal.service';
import { Goal } from 'src/app/models/goal';

@Component({
  selector: 'app-global-performances',
  templateUrl: './global-performances.component.html',
  styleUrls: ['./global-performances.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class GlobalPerformancesComponent implements OnInit {
  sectors: Sector[] = [];
  sectorOne: undefined | number;
  sectorTwo: undefined | number;
  loading: boolean = false;
  startDate!: string;
  endDate!: string;
  month!: number;
  months: Month[] = months;
  year!: number;
  years: Year[] = [];
  performances: any = [];
  remainingDays!: number;
  currentDate!: number;
  monthLength?: number;
  selectedSectors: number[] = [];
  sectorsGoals: Goal[] = [];

  constructor(
    private route: ActivatedRoute,
    private performanceService: PerformanceService,
    private router: Router,
    private sectorService: SectorService,
    private spinner: NgxSpinnerService,
    private goalService: GoalService
  ) {}

  ngOnInit(): void {
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();
    this.startDate = formatDate(new Date(this.year, this.month - 1, 1));
    this.endDate = formatDate(new Date(this.year, this.month, 0));
    this.remainingDays =
      new Date(this.year, this.month, 0).getDate() - new Date().getDate();
    this.currentDate = new Date().getDate();
    this.monthLength = new Date(this.year, this.month, 0).getDate();
    this.getSectors();
    this.getYears();

    this.getPerformances();
  }

  getSectors(): void {
    this.sectorService.getSectors().subscribe((sectors) => {
      this.sectors = sectors;
      if (this.route.snapshot.queryParamMap.get('sector_one_id')) {
        this.sectorOne = Number(
          this.route.snapshot.queryParamMap.get('sector_one_id')
        );
        this.sectorTwo = Number(
          this.route.snapshot.queryParamMap.get('sector_two_id')
        );
      }
    });
  }

  getYears(): void {
    this.performanceService.getYears().subscribe((years) => {
      this.years = years;
      this.year = new Date().getFullYear();
    });
  }

  onDateChange(date: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        start_date: this.startDate,
        end_date: this.endDate,
        sector_one_id: this.sectorOne,
        sector_two_id: this.sectorTwo,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  onSectorChange(sectorId: any): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        start_date: this.startDate,
        end_date: this.endDate,
        sector_one_id: this.sectorOne,
        sector_two_id: this.sectorTwo,
      },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  onMonthChange(month: any): void {
    this.month = month;
    this.remainingDays =
      new Date(this.year, month, 0).getDate() - new Date().getDate();
    this.currentDate = new Date().getDate();
    this.monthLength = new Date(this.year, this.month, 0).getDate();
    this.startDate = formatDate(new Date(this.year, month - 1, 1));
    this.endDate = formatDate(new Date(this.year, month, 0));
    this.getPerformances();
  }

  onYearChange(year: any): void {
    this.year = year;
    this.startDate = formatDate(new Date(this.year, this.month - 1, 1));
    this.endDate = formatDate(new Date(this.year, this.month, 0));
    this.getPerformances();
  }

  filterSectors(sectorId: any): any {
    return this.sectors.filter((sector) => sector.id !== sectorId);
  }

  getPerformances() {
    this.spinner.show();
    const params: any = {};

    if (this.startDate) {
      params['start_date'] = this.startDate;
    }

    if (this.endDate) {
      params['end_date'] = this.endDate;
    }

    if (this.selectedSectors) {
      if (this.selectedSectors.length > 0) {
        params['sectors'] = this.selectedSectors;
      }
    }

    this.loading = true;
    this.performanceService
      .getSectorsPerformances(
        Object.keys(params).length > 0 ? params : undefined
      )
      .subscribe({
        next: (pointOfSalesPerformances) => {
          this.getGoals();
          this.loading = false;
          this.spinner.hide();
          this.performances = pointOfSalesPerformances;
        },
        error: (e: any) => {
          this.loading = false;
          this.spinner.hide();
        },
      });
  }

  getCA(sector_id: number) {
    const perf = this.performances.find(
      (perf: { sector_id: number }) => perf.sector_id == sector_id
    );
    return perf !== undefined ? perf!.sale_total_amount : 0;
  }

  getCATotal() {
    const caTotal = this.performances.reduce(
      (a: any, b: any) => a + b.sale_total_amount,
      0
    );
    return caTotal;
  }

  getReturns(sector_id: number): number {
    const perf = this.performances.find(
      (perf: { sector_id: number }) => perf.sector_id == sector_id
    );
    return perf?.return_total_amount !== undefined
      ? perf?.return_total_amount
      : 0;
  }

  getTotalReturns(): number {
    const perf = this.performances.reduce(
      (a: any, b: { return_total_amount: any }) => a + b.return_total_amount,
      0
    );
    return perf || 0;
  }

  getTotalSales(): number {
    const perf = this.performances.reduce(
      (a: any, b: { sale_total_amount: any }) => a + b.sale_total_amount,
      0
    );
    return perf || 0;
  }

  getReturnPercentage(sector_id: number): number | string {
    const perf = this.performances.find(
      (perf: { sector_id: number }) => perf.sector_id == sector_id
    );
    return perf !== undefined
      ? perf!.sale_total_amount !== 0
        ? ((perf!.return_total_amount / perf!.sale_total_amount) * 100).toFixed(
            2
          )
        : 0.0
      : 0.0;
  }

  getReturnPercentageTotal(): number | string {
    return (
      ((this.getTotalReturns() / this.getTotalSales()) * 100).toFixed(2) || 0
    );
  }

  getProjection(sector_id: number): number {
    return (this.getCA(sector_id) / this.currentDate) * this.monthLength! || 0;
  }

  getProjectionTotal(): number {
    let perf = this.getCATotal();
    if (!isInThePast(new Date(this.year, this.month, 0))) {
      perf = (this.getCATotal() / this.currentDate) * this.monthLength! || 0;
    }

    return Math.round(perf) || 0;
  }

  getProjectionRatePercentage(sector_id: number): number | string {
    return (
      (this.getCA(sector_id) / this.getProjection(sector_id)) *
      100
    ).toFixed(2);
  }

  onSelectedSector() {
    this.getPerformances();
  }

  getSectorLabel(sector_id: number) {
    const perf = this.sectors.find((sector) => sector.id == sector_id);
    return perf?.label;
  }

  getGoals() {
    this.goalService
      .getGoals({ year: this.year, month: this.month })
      .subscribe((goals) => {
        this.sectorsGoals = goals;
      });
  }

  getGoalCA(sector_id: number): number {
    const goal = this.sectorsGoals.find(
      (goal) => goal.goalable_id == sector_id
    );
    return goal?.ca || 0;
  }

  getGoalCAClass(sector_id: number, ca: number): String {
    const goal = this.sectorsGoals.find(
      (goal) => goal.goalable_id == sector_id
    );

    if (goal?.ca && ca < goal?.ca) {
      return 'text-danger';
    }

    if (goal?.ca && ca > goal?.ca) {
      return 'text-primary';
    }

    return '';
  }

  isGoalCAReached(sector_id: number, ca: number): boolean {
    const goal = this.sectorsGoals.find(
      (goal) => goal.goalable_id == sector_id
    );

    if (goal?.ca && ca < goal?.ca) {
      return false;
    } else {
      return true;
    }
  }

  getGoalReturnRateClass(sector_id: number, return_rate: number): string {
    const goal = this.sectorsGoals.find(
      (goal) => goal.goalable_id == sector_id
    );

    if (goal?.return_rate && return_rate < goal?.return_rate) {
      return 'text-danger';
    }

    if (goal?.return_rate && return_rate > goal?.return_rate) {
      return 'text-primary';
    }

    return '';
  }
}
