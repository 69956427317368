import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsSearchFilterModalComponent } from './inputs-search-filter-modal.component';
import { NgxBootstrapIconsModule, filter, xCircle } from 'ngx-bootstrap-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const icons = {
  filter,
  xCircle
};

@NgModule({
  declarations: [
    InputsSearchFilterModalComponent
  ],
  exports:[InputsSearchFilterModalComponent],
  imports: [
    CommonModule,
    NgxBootstrapIconsModule.pick(icons),
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class InputsSearchFilterModalModule { }
