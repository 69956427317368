import { CommonModule } from '@angular/common';
import {
  Component,
  Injectable,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from 'src/app/types';

@Component({
  standalone: true,
  selector: 'modal',
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
@Injectable()
export class ModalComponent implements OnInit {
  @Input() public modalConfig!: ModalConfig;
  @ViewChild('modal') private modalContent!: TemplateRef<ModalComponent>;
  private modalRef!: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        centered: true,
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  openSm(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        centered: true,
        size: 'sm'
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  openLg(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        centered: true,
        size: 'lg'
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  openXl(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        centered: true,
        size: 'xl'
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async submit(): Promise<void> {
    if (
      this.modalConfig.shouldSubmit === undefined ||
      (await this.modalConfig.shouldSubmit())
    ) {
      const result =
        this.modalConfig.onSubmit === undefined ||
        (await this.modalConfig.onSubmit());
      // this.modalRef.close(result);
    }
  }

  async cancel(): Promise<void> {
    if (
      this.modalConfig.shouldCancel === undefined ||
      (await this.modalConfig.shouldCancel())
    ) {
      const result =
        this.modalConfig.onCancel === undefined ||
        (await this.modalConfig.onCancel());
      this.modalRef.dismiss(result);
    }
  }

  close() {
    this.modalRef.close();
  }
}
