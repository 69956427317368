import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Config from '../utils/config';
import { Observable } from 'rxjs';
import { OtherTypeListDTO } from '../dtos/other-type.dtos';

@Injectable({
  providedIn: 'root',
})
export class OtherTypeService {
  constructor(private http: HttpClient) {}

  getOtherTypes(params: any): Observable<OtherTypeListDTO> {
    return this.http.get<OtherTypeListDTO>(`${Config.baseUrlV2}/other_types`, {
      params: params,
    });
  }

  addOtherType(other: any) {
    return this.http.post<any>(`${Config.baseUrlV2}/other_types`, {
      other_types: other,
    });
  }

  updateOtherType(other: any) {
    return this.http.put<any>(
      `${Config.baseUrlV2}/other_types/${other['id']}`,
      {
        other_types: other,
      }
    );
  }

  deleteOtherType(other: any) {
    return this.http.delete<any>(
      `${Config.baseUrlV2}/other_types/${other['id']}`
    );
  }
}
