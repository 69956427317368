<modal #nGmodal [modalConfig]="modalConfig" class="modal-dialog-centered">
     <div class="d-flex justify-content-end align-items-center row" test>
       <div
         class="input-file d-flex justify-content-between"
         (click)="fileUpload.click()"
       >
   
         <div class="">
           <span class="field-file-name">{{
             fileName || "Importer le fichier"
           }}</span>
         </div>
   
         <div><i-bs style="font-weight: bold" name="arrow-up"></i-bs></div>
   
       </div>
       <input
         type="file"
         class="form-control hidden-file-input"
         (change)="handleFileInput($event)"
         #fileUpload
       />
     </div>
   
     <div class="col-12">
       <div class="d-flex justify-content-between align-items-center">
         <div *ngIf="invalidData.length" class="text-danger fw-bold">
           Attention, il y a {{invalidData.length}} coordonnée(s) invalides !
         </div>
       </div>
     </div>
     <div
       *ngIf="
         !loadedDataToDisplay.length &&
         fileName &&
         !isLoadingFileData &&
         !loadedData.length
       "
       class="text-center"
     >
       Pas de données à importer
     </div>
     <div class="d-flex justify-content-center align-items-center">
        <div
          *ngIf="isLoadingFileData"
          class="spinner-border text-info"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    
     <div *ngIf="loadedDataToDisplay.length" class="d-flex flex-column mt-3">
       <div class="import-table-row import-table-header">
        <ng-container *ngIf="entity =='Secteur'">
          <div class="name-column">Secteur</div>
        </ng-container>
        <ng-container *ngIf="entity =='Zone_superviseur'">
          <div class="name-column">Zone Superviseur</div>
        </ng-container>
        <ng-container *ngIf="entity =='COUNTRY'">
          <div class="name-column">Pays</div>
        </ng-container>
        <ng-container *ngIf="entity =='Zone'">
          <div class="name-column">Zone</div>
        </ng-container>
        <ng-container *ngIf="entity =='Region'">
          <div class="name-column">Région commerciale</div>
        </ng-container>
         <div class="pe-4">Latitude</div>
         <div class="pe-4">Longitude</div>
       </div>
       <div style="max-height: 250px; overflow: auto">
         <ng-template ngFor let-item [ngForOf]="loadedDataToDisplay">
           <div
             class="import-table-row custom-table-row mt-2"
             [ngClass]="{ error: invalidData.includes(item[entity.toLowerCase()]), 'table-danger': !item.isValid }"
           >
             <div class="name-column">{{ item[entity.toLowerCase()]  }}</div>
             <div class="coordinate-column">{{ item.y_coord }}</div>
             <div class="coordinate-column">{{ item.x_coord }}</div>
           </div>
         </ng-template>
       </div>
     </div>
   </modal>
   <modal
     #confirmModal
     [modalConfig]="confirmModalConfig"
     class="modal-dialog-centered"
   >
     <div>
       Etes-vous sûr de vouloir importer ces coordonnées ?
     </div>
</modal>
   
