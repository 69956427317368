import { Other, OtherState } from '../types';
import { Store } from './store';
import { OtherService } from '../services/others.service';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { OtherListDTO } from '../dtos/other.dtos';
import { PaginationInterface } from '../interfaces/pagination-interface';

const initialState: OtherState = {
  loading: false,
  others: [],
  error: '',
  modalState: 'idle',
  pagination: {
    page: 1,
    limit: 15,
    next: 1,
    last: 1,
    pages: 0,
  },
};

@Injectable({ providedIn: 'root' })
export class OtherStore extends Store<OtherState> {
  private loaded = false;

  constructor(
    private otherService: OtherService,
    private toastService: ToastService
  ) {
    super(initialState);
  }

  getOthers(params: any) {
    if (!this.loaded) {
      this.loaded = true;
      this.setState((state) => ({ ...state, loading: true }));
      this.otherService.getOthers(params).subscribe({
        next: (response: OtherListDTO) => {
          this.setState((state) => ({
            loading: false,
            others: response.data,
            pagination: response.metadata,
          }));
        },
        error: (error: any) => {
          this.setState((state) => ({ loading: false, error }));
        },
      });
    }
  }

  addOther(other: any) {
    this.setState((state) => ({ ...state, loading: true }));
    const payload = {
      ...other,
      libelle: other.libelle.charAt(0).toUpperCase() + other.libelle.slice(1),
    };
    this.otherService.addOther(payload).subscribe({
      next: (other: any) => {
        this.setState((state) => ({
          modalState: 'closed',
          loading: false,
          others: [other, ...state.others],
        }));
        this.toastService.show('Autre produit créé', {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
      },
      error: (error: any) => {
        const errorMessage = error.includes('has already been taken')
          ? 'Cet autre produit existe déjà.'
          : "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer";
        this.setState((state) => ({ loading: false, error }));
        this.toastService.show(errorMessage, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });
      },
    });
  }

  updateOther(other: any) {
    this.setState((state) => ({ ...state, loading: true }));
    const payload = {
      ...other,
      libelle:
        other.libelle.charAt(0).toUpperCase() +
        other.libelle.slice(1).toLowerCase(),
    };
    this.otherService.updateOther(other).subscribe({
      next: (other: any) => {
        this.setState((state) => ({
          modalState: 'closed',
          loading: false,
          others: state.others.map((o) => (o.id === other.id ? other : o)),
        }));
        this.toastService.show("L'autre produit a été modifié avec succès", {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        const errorMessage = error.includes('has already been taken')
          ? 'Cet autre produit existe déjà.'
          : "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer";
        this.toastService.show(errorMessage, {
          classname: 'bg-danger text-light toast-container',
          delay: 5000,
        });
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  deleteOther(other: Other) {
    this.setState((state) => ({ ...state, loading: true }));
    this.otherService.deleteOther(other).subscribe({
      next: (o: any) => {
        this.setState((state) => ({
          loading: false,
          others: state.others.filter((o) => o.id !== other.id),
        }));
        this.toastService.show("L'autre produit a été supprimé avec succès", {
          header: 'Succès',
          classname: 'bg-success text-light toast-container',
          delay: 5000,
        });
      },
      error: (error: any) => {
        this.toastService.show(
          "Une erreur est survenu lors de la création de l'autre produit. Merci de réessayer",
          {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container',
            delay: 5000,
          }
        );
        this.setState((state) => ({ loading: false, error }));
      },
    });
  }

  setInitial() {
    this.loaded = false;
  }
}
