import { Pagination, PaginationInterface } from "../interfaces/pagination-interface";
import { IIntervalOfDate } from "../interfaces/stock-movement-interface";
import { EMotifNoSale } from "../utils";
import { PointOfSale } from "./point-of-sale";
import { Sector } from "./sector";
import { User } from "./user";

export class Sales {
    id!: number;
    user_id!: number;
    point_of_sale_id!: number;
    date!: string;
    created_at!: string;
    updated_at!: string;
    tournee_id!: number;
    status!: ESaleStatus;
    statusFR!: string;
    unsolds_ca!: number;
    return_products_ca!: number;
    point_of_sale!: PointOfSale; 
    sector!: Sector;
    sector_id?: number;
    price_total?: number;
    start_time?: string;
    end_time?: string;
    sale_duration_in_minute?: string;
    uuid?: string;
    statusColor?: string;
    paiement_mode?: string;
    sale_products_items!: ISaleProducts[]
}

export interface INoSales{
    id: number;
    sector: Sector;
    seller: User;
    point_of_sale: PointOfSale;
    date: string;
    motifs_no_sale: EMotifNoSale;
    created_at?: string;
    updated_at?: string;
    qrcode_is_messing?: false,
    tournee_id: number;
    sale_id: number;
}

export interface ISaleProducts {
    id: number;
    sale_id: number;
    quantity: number;
    product_id: number;
    final_stock: number;
    rotation: number;
    remaning: number;
    price_total: number;
    min: number;
    max: number;
    rotation_today: number;
    tournee_id: number;
    created_at: string
    updated_at: string
}

export interface ISalesInfosAndPagineNeighbor { 
    sale: Sales;
    no_sale: INoSales;
    pagine: any; 
    sale_return_products: any; 
    sale_unsold_products: any; 
    sale_initial_stock: any; 
}

export class SalesPaginationDTO implements PaginationInterface {
    page: number = 1;
    limit: number = 15;
    next?: number | null | undefined;
    items?: number | undefined;
    last?: number | null | undefined;
    pages?: number | null | undefined;
    country_id?: number | undefined;
    sector_ids?: number[];
    status?: ESaleStatus[];
    searchTerm?: string | undefined;
    zone_supervisor_id?: number | undefined;
    region_commercial_id?: number | undefined;
    start_date?: string | undefined; 
    interval_of_date?: IIntervalOfDateSales;
}

export interface IIntervalOfDateSales {
    start_date: string;
    end_date: string;
}

export interface ISalePagine {
    previous_sale: ISaleNeighbor;
    next_sale: ISaleNeighbor;
}

export interface ISaleNeighbor{ 
    id: number; 
    created_at: string;
}

export interface IRecapSaleData{
    key: string;
    title: string;
    data: any;
    color: string;
    bgColor: string;
    icon?: string;
}


export enum ESaleStatus {
    DONE = "DONE",
    IN_PROGRESS = "IN_PROGRESS",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
}

export enum ESaleStatusFR {
    DONE = "Terminée",
    IN_PROGRESS = "En cours",
    CANCELED = "Annulée",
    FAILED = "Echouée",
}

export enum EPagineSale {
    PREVIOUS = "previous_sale",
    NEXT = "next_sale"
}

export enum ESaleProductRecapType{
    SALE_PRODUCT = "sale",
    INITIAL_STOCK = "sale_initial_stock",
    RETURN_PRODUCTS = "sale_return_products",
    UNSOLD_PRODUCTS = "sale_unsold_products"
}