import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClustersRoutingModule } from './clusters-routing.module';
import { ClustersComponent } from './clusters.component';
import { GoBackModule } from 'src/app/components/go-back/go-back.module';
import { ListComponent } from './list/list.component';
import { TableModule } from '../../components/table/table.module';
import { EmptyCardModule } from 'src/app/components/empty-card/empty-card.module';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { ModalComponent } from '../../components/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IsGuardedModule } from 'src/app/components/is-guarded/is-guarded.module';

@NgModule({
  declarations: [ClustersComponent, ListComponent],
  providers: [],
  imports: [
    CommonModule,
    ClustersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GoBackModule,
    TableModule,
    EmptyCardModule,
    PaginationModule,
    ModalComponent,
    IsGuardedModule,
  ],
})
export class ClustersModule {}
