import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EActionTourLogistic, IActionDropDown } from 'src/app/interfaces/logistics-tours';
import { EVehicleType } from 'src/app/interfaces/vehicle-interface';
import { Depot } from 'src/app/models/depot';
import { ELogisticTourStatus, LogisticTour } from 'src/app/models/logistic-tour';
import { EPointOfDeliveryStatus, ILogisticDeliveredProducts, PointOfDelivery, StartLogisticTourDto, UpdatePointOfDeliveryDto } from 'src/app/models/point-of-delivery';
import { User } from 'src/app/models/user';
import { Vehicle } from 'src/app/models/vehicle';
import { DepotService } from 'src/app/services/depot.service';
import { LogisticTourItemService } from 'src/app/services/logistic-tour-item.service';
import { PointOfDeliveryService } from 'src/app/services/point-of-delivery.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToursService } from 'src/app/services/tours.service';
import { UsersService } from 'src/app/services/users.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ModalConfig } from 'src/app/types';
import { EModalFrameDeliveryPoint, askedValidatorFactory, calculatePercentage, dateAdapter, isDateTime1AfterDateTime2, sortArray, translateVehicleType } from 'src/app/utils';
import { extractDateAndTime, calculPallet, translateLogisticTourStatus, translatePointOfDeliveryStatus, getLastDeliveryPointVehicleAndUserActors, isCarrierTruckChanged, isRoadTractorChanged, isNoChange, isInfosTourItemNotNull, checkIfVehivuleAndConveyorsPresentInPointOfDeliveries } from 'src/app/utils/helpers';
import Swal from 'sweetalert2';

export enum EPointOfDEliveryModal {
  UPDATE_NEW = 'UPDATE_NEW',
  START_TOUR = 'START_TOUR',
  UPDATE_SENDED = 'UPDATE_SENDED',
  CANCEL = 'CANCEL',
}
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss', '../custom-table.scss']
})
export class DetailComponent implements OnInit {
  @ViewChild('confirmModal') private modalComponent!: ModalComponent;
  @ViewChild('modalUpdateLogisticTourItem') private modalUpdateLogisticTourItem!: ModalComponent;

  /* Global */
  error: string = '';
  message: string = '';
  currentUser!: User;
  loading: boolean = false;
  loadingUpdateTourItem: boolean = false;
  loadingtruck: boolean = false;
  loadingUsers: boolean = false;
  loadingDepots: boolean = false;
  today = new Date();
  dateMin!: string;

  // modal confirmation
  modalConfig: ModalConfig = {
    modalTitle: 'Démarrage de la tournée',
  }

  modalConfigLogisticTourItem: ModalConfig = {
    modalTitle: ''
  }
  open_tooltip: boolean = false;
  isOpenModal: boolean = false;
  isOpenModalStarTour: boolean = false;
  isEmptyListProductPointFilter: boolean = false;
  checkChangeFromInfosTourItem: boolean = false;

  formInfosTourLogisticItem!: FormGroup;
  formPointOfDelivery!: FormGroup;
  modalFormType!: EPointOfDEliveryModal;
  selectedPointOfDelivery!: PointOfDelivery;
  indexSelectedPointOfDelivery!: number;
  EPointOfDeliveryStatus = EPointOfDeliveryStatus;
  EModalFrameDeliveryPoint = EModalFrameDeliveryPoint
  modalFrameSelected: EModalFrameDeliveryPoint = EModalFrameDeliveryPoint.FRAME_INFOS;

  actions: IActionDropDown[] = [
    {
      label: 'Démarrer',
      action: EActionTourLogistic.START_TOUR,
    },
    {
      label: 'Modifier',
      action: EActionTourLogistic.MODIFY,
    },
    {
      label: 'Annuler',
      action: EActionTourLogistic.CANCEL
    }
  ];
  EActionTourLogistic = EActionTourLogistic;

  //depots
  depotsList: Depot[] = [];
  searchProduct: FormControl<string> = new FormControl();



  //Pallet
  loaderPallets!: number;
  vehiclePallets!: number;

  othersVehiclesList: Vehicle[] = [];
  semiTrailerVehiclesList: Vehicle[] = [];
  vehicleChoiceFirst?: Vehicle;
  vehicleChoiceSecond?: Vehicle;
  VehicleType = EVehicleType;
  conveyorsUsers: User[] = [];
  driverUsers: User[] = [];


  //tours
  logisticTour!: LogisticTour;
  logistic_tour_id!: number;
  ELogisticTourStatus = ELogisticTourStatus;

  constructor(
    private logisticsToursService: ToursService,
    private pointOfDeliveryServide: PointOfDeliveryService,
    private depotService: DepotService,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private logisticTourItemService: LogisticTourItemService,
    private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastService: ToastService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.logistic_tour_id = params['id'];
      this.getLogisticTour(this.logistic_tour_id);
    });
    this.dateMin = dateAdapter(this.today);
    this.getDepotList();
    this.getConveyorUsers();
    this.getDriverUsers();
    this.getFilteredListVehicles();
    this.initFormLoadProducts();
    this.onSearchFilterProduct();
  }

  getLogisticTour(id: number) {
    this.loading = true;
    this.showSpinner()
    this.logisticsToursService.getLogisticTourById(id).subscribe({
      next: (response) => {
        this.logisticTour = response.logistic_tour;
        this.checkChangeFromInfosTourItem = false;
        this.logisticTour.point_of_deliveries = sortArray(this.logisticTour.point_of_deliveries, 'created_at');
        this.getLastVehiclePallet(this.logisticTour);
        this.loaderPallets = this.getLoadedPalletQuantity(this.logisticTour.point_of_deliveries, this.logisticTour.status);
        this.displayActionsSwitchingStatusOfInventory();
        this.loading = false;
        this.showSpinner();
      },
      error: (error) => {
        if (error.includes("Couldn't find LogisticTour with 'id'=")) {
          this.error = `Tournée avec l'id ${id} introuvable`;
          this.router.navigateByUrl('/list-tour-logistic');
          this.toastService.show(this.error, {
            header: 'Erreur',
            classname: 'bg-danger text-light toast-container p-1',
            delay: 5000,
          });
        } else {
          this.error = `Une erreur est survenue lors de la récupération de la tournée logistique. Merci de réessayer`;
        }
        this.loading = false;
        this.showSpinner();
      },
    })
  }


  getLastVehiclePallet(tour: LogisticTour) {
    const lastLogisticTourItem = this.getLastDeliveryPointVehicleAndUserActors(tour);
    if (lastLogisticTourItem.carrier_truck !== null) {
      this.vehiclePallets = lastLogisticTourItem.carrier_truck.pallet;
    }
    else if (lastLogisticTourItem.semi_trailer !== null) {
      this.vehiclePallets = lastLogisticTourItem.semi_trailer.pallet;
    }
    else {
      this.vehiclePallets = 0;
    }
  }

  getFilteredListVehicles() {
    this.loadingtruck = true;
    this.showSpinner();
    this.error = '';
    this.vehicleService.getFilteredListTruck({ page: 1, limit: 50 }).subscribe({
      next: (response) => {
        this.othersVehiclesList = response.data.filter((vehicle) => vehicle.vehicle_type !== EVehicleType.SEMI_TRAILER);
        this.semiTrailerVehiclesList = response.data.filter((vehicle) => vehicle.vehicle_type === EVehicleType.SEMI_TRAILER);
        this.getConveyorUsers();
        this.loadingtruck = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des véhicules. Merci de réessayer`;
        this.loadingtruck = false;
        this.showSpinner();
      },
    });
  }

  getConveyorUsers() {
    this.loadingUsers = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [6] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.conveyorsUsers = response.data;
        this.getDriverUsers();
        this.loadingUsers = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateurs. Merci de réessayer`;
        this.loadingUsers = false;
        this.showSpinner();
      },
    });
  }

  getDriverUsers() {
    this.loadingUsers = true;
    this.showSpinner();
    const params = { page: 1, limit: 100, roles: [15] };
    this.usersService.getUsers(params).subscribe({
      next: (response) => {
        this.driverUsers = response.data;
        this.loadingUsers = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la récupération des utilisateurs. Merci de réessayer`;
        this.loadingUsers = false;
        this.showSpinner();
      },
    });
  }

  startLogisticTour(point_of_deliveries: StartLogisticTourDto[]) {
    let payload = { point_of_deliveries }
    this.loading = true;
    this.showSpinner();
    this.logisticsToursService.startLogisticTour(this.logistic_tour_id, payload).subscribe({
      next: (response) => {
        this.loading = false;
        this.message = response.message;
        this.showSpinner();
        if (this.message.length) this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
        this.isOpenModalStarTour = false;
        this.getLogisticTour(this.logistic_tour_id);

      },
      error: (error) => {
        this.error = `Une erreur est survenue lors du démarrage de la tournée logistique. Merci de réessayer`;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        })
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  cancelLogisticTour() {
    this.loading = true;
    this.showSpinner();
    this.logisticsToursService.cancel(this.logistic_tour_id).subscribe({
      next: (response) => {
        this.loading = false;
        this.message = 'La tournée a été annulée avec succès';
        this.showSpinner();
        this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
        this.closeModalConfirm()
        this.getLogisticTour(this.logistic_tour_id)
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de l'annulation de la tournée logistique. Merci de réessayer`;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        })
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  updatePointOfDelivery(id: number, payload: UpdatePointOfDeliveryDto) {
    this.loading = true;
    this.showSpinner()
    this.pointOfDeliveryServide.update(id, payload).subscribe({
      next: (response) => {
        this.loading = false;
        this.message = 'La tournée a été modifiée avec succès';
        this.showSpinner();
        this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
        this.getLogisticTour(this.logistic_tour_id)
        this.isOpenModal = false;
      },
      error: (error) => {
        if (error.includes("is out of range for ActiveModel::Type::Integer with limit 4 bytes")) {
          this.error = `La valeur à chargé est trop grande. Cette valeur ne peut être prise en charge pour le champ demandé`;
        } else {
          this.error = `Une erreur est survenue lors de la modification du point de livraison. Merci de réessayer`;
        }
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        })
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  cancelPointOfDelivery(id: number) {
    this.loading = true;
    this.showSpinner()
    this.pointOfDeliveryServide.update(id, { status: EPointOfDeliveryStatus.CANCELED }).subscribe({
      next: (response) => {
        this.loading = false;
        this.message = "Point de livraison annulé avec succès";
        this.showSpinner();
        if (this.message.length) this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
        this.getLogisticTour(this.logistic_tour_id)
        this.isOpenModal = false;
        this.closeModalConfirm()
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de l'annulation du point de livraison. Merci de réessayer`;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        })
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  deletePointOfDelivery(id: number, isLastPoint: boolean = false) {
    this.loading = true;
    this.showSpinner()
    this.pointOfDeliveryServide.delete(id).subscribe({
      next: (response) => {
        this.loading = false;
        this.message = "Point de livraison supprimé avec succès";
        this.showSpinner();
        this.toastService.show(this.message, {
          header: 'Succès',
          classname: 'bg-primary text-light toast-container p-1',
          delay: 5000,
        });
        this.closeModalConfirm();
        this.isOpenModal = false;
        if (isLastPoint) {
          this.router.navigateByUrl('/list-tour-logistic');
        } else {
          this.getLogisticTour(this.logistic_tour_id)
        }
      },
      error: (error) => {
        this.error = `Une erreur est survenue lors de la suppression du point de livraison. Merci de réessayer`;
        this.toastService.show(this.error, {
          header: 'Erreur',
          classname: 'bg-danger text-light toast-container p-1',
          delay: 5000,
        })
        this.loading = false;
        this.showSpinner();
      },
    })
  }

  flowStartingTour() {
    this.closeModalConfirm();
    this.isOpenModalStarTour = true;
  }

  confirmStartTourLogistic() {
    if (!(checkIfVehivuleAndConveyorsPresentInPointOfDeliveries(this.logisticTour))) {
      Swal.fire({
        title: 'Erreur!',
        text: "Impossible de démarrer la tournée si l'une de ces informations n'est pas renseignée (chauffeur, convoyeur ou véhicules)",
        icon: 'error',
      });
    } else if (!this.canStartLogisticTour(this.logisticTour.start_date)) {
      this.modalConfig = {
        modalTitle: 'Démarrage de la tournée',
        submitButtonLabel: 'Oui',
        cancelButtonLabel: 'Non',
        headerClass: 'bg-info d-none text-white',
        cancelButtonClass: 'btn-danger ',
        submitButtonClass: 'btn-primary text-white',
        contentText: 'Etes-vous sûr de vouloir démarrer la tournée ?',
        onSubmit: () => this.flowStartingTour(),
      };
      this.openModal();
    }
    else Swal.fire({
      title: 'Erreur!',
      text: "Impossible de démarrer la tournée si la date de démarrage est dépassée ou pas encore arrivée",
      icon: 'error',
    });
  }

  confirmCancelTourLogistic() {
    this.modalConfig = {
      modalTitle: 'Annulation de la tournée',
      submitButtonLabel: 'Oui',
      cancelButtonLabel: 'Non',
      headerClass: 'bg-info d-none text-white',
      cancelButtonClass: 'btn-danger ',
      submitButtonClass: 'btn-primary text-white',
      contentText: 'Etes-vous sûr de vouloir annuler cette tournée ?',
      onSubmit: () => this.cancelLogisticTour()
    };
    this.openModal();
  }

  confirmDeletePointOfDelivery() {
    this.modalConfig = {
      modalTitle: 'Suppression du point de livraison',
      submitButtonLabel: 'Oui',
      cancelButtonLabel: 'Non',
      headerClass: 'bg-info d-none text-white',
      cancelButtonClass: 'btn-danger ',
      submitButtonClass: 'btn-primary text-white',
    };
    if (this.indexSelectedPointOfDelivery === 0 && this.indexSelectedPointOfDelivery === this.logisticTour.point_of_deliveries.length - 1) {
      this.modalConfig.contentText = "Etes-vous sûre de vouloir supprimer ce dépôt de la tournée, cette action supprimera la tournée?"
      this.modalConfig.onSubmit = () => this.deletePointOfDelivery(this.selectedPointOfDelivery.id, true);
    } else {
      this.modalConfig.contentText = "Etes-vous sûre de vouloir supprimer ce dépôt de la tournée"
      this.modalConfig.onSubmit = () => this.deletePointOfDelivery(this.selectedPointOfDelivery.id);
    }
    this.openModal();
  }

  confirmCancelPointOfDelivery() {
    this.modalConfig = {
      modalTitle: 'Annulation de la livraison',
      submitButtonLabel: 'Oui',
      cancelButtonLabel: 'Non',
      headerClass: 'bg-info d-none text-white',
      cancelButtonClass: 'btn-danger ',
      submitButtonClass: 'btn-primary text-white',
      contentText: 'Etes-vous sûre de vouloir annuler la livraison de ce dépôt ?',
      onSubmit: () => this.cancelPointOfDelivery(this.selectedPointOfDelivery.id),
    };
    this.openModal();
  }


  updatePointOfDeliveryNew() {
    const payload = {
      ...(this.formPointOfDelivery.value.driver && {driver: Number(this.formPointOfDelivery.value.driver)}),
      conveyor: Number(this.formPointOfDelivery.value.conveyor),
      ...(this.vehicleChoiceFirst?.vehicle_type === EVehicleType.CARRIER_TRUCK && {
        carrier_truck: this.formPointOfDelivery.value.truck_id
      }),
      ...(this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.CARRIER_TRUCK && {
        road_tractor: this.formPointOfDelivery.value.truck_id,
        semi_trailer: this.formPointOfDelivery.value.truck_id_two,
      }),
      delivered_products: this.formPointOfDelivery.value.delivered_products.map((product: any) => ({
        product_id: product.product_id,
        asked: Number(product.asked),
      })),
      planned_departure: `${this.formPointOfDelivery.value.planned_departure} ${this.formPointOfDelivery.value.planned_departure_time}:00`,
      planned_arrival: `${this.formPointOfDelivery.value.planned_arrival} ${this.formPointOfDelivery.value.planned_arrival_time}:00`,
      update_conveyor_data_from_other_points: true
    }
    const previousPoint = this.indexSelectedPointOfDelivery > 0 ? this.logisticTour.point_of_deliveries[this.indexSelectedPointOfDelivery - 1] : null;
    const nextPoint = this.indexSelectedPointOfDelivery < this.logisticTour.point_of_deliveries.length ? this.logisticTour.point_of_deliveries[this.indexSelectedPointOfDelivery + 1] : null;
    if (!isDateTime1AfterDateTime2(payload.planned_departure, this.logisticTour.start_date)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être inférieure à la date démarrage de la tournée",
        icon: 'error',
      });
    }
    else if (!isDateTime1AfterDateTime2(payload.planned_arrival, payload.planned_departure)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être inférieure à la date départ",
        icon: 'error',
      });
    }
    else if (nextPoint && !isDateTime1AfterDateTime2(nextPoint.planned_departure, payload.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date d'arrivée ne doit pas être supérieur à la date départ du point suivant",
        icon: 'error',
      });
    }
    else if (previousPoint && !isDateTime1AfterDateTime2(payload.planned_departure, previousPoint.planned_arrival)) {
      Swal.fire({
        title: 'Erreur!',
        text: "La date départ ne doit pas être inférieure à la date d'arrivée du point précédent",
        icon: 'error',
      });
    } else {
      this.updatePointOfDelivery(this.selectedPointOfDelivery.id, payload);
    }

  }

  updatePointOfDeliveryInProgress() {
    const payload: UpdatePointOfDeliveryDto = {
      planned_departure: `${this.formPointOfDelivery.value.planned_departure} ${this.formPointOfDelivery.value.planned_departure_time}:00`,
      planned_arrival: `${this.formPointOfDelivery.value.planned_arrival} ${this.formPointOfDelivery.value.planned_arrival_time}:00`,
      delivered_products: this.formPointOfDelivery.value.delivered_products.map((product: any) => ({
        product_id: product.product_id,
        loaded: Number(product.loaded),
      })),
      ...(this.formPointOfDelivery.value.driver && {driver: Number(this.formPointOfDelivery.value.driver)}),
      conveyor: Number(this.formPointOfDelivery.value.conveyor),
      ...(this.vehicleChoiceFirst?.vehicle_type === EVehicleType.CARRIER_TRUCK && {
        carrier_truck: this.formPointOfDelivery.value.truck_id
      }),
      ...(this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.CARRIER_TRUCK && {
        road_tractor: this.formPointOfDelivery.value.truck_id,
        semi_trailer: this.formPointOfDelivery.value.truck_id_two,
      }),
      update_conveyor_data_from_other_points: this.formPointOfDelivery.value.update_conveyor_data_from_other_points
    }
    this.updatePointOfDelivery(this.selectedPointOfDelivery.id, payload);
  }

  getDepotList() {
    this.loadingDepots = true;
    this.showSpinner();
    this.depotService.getDepots({ page: 1, limit: 25 }).subscribe({
      next: (response) => {
        this.depotsList = response.depots;
        this.loadingDepots = false;
        this.showSpinner();
      },
      error: (error) => {
        this.error = `Une erreur est survenu lors de la récupération des dépôts. Merci de réessayer`;
        this.loadingDepots = false;
        this.showSpinner();
      },
    })
  }

  closeModal(event: boolean) {
    this.isOpenModal = event;
  }

  closeModalStartTour(event: boolean) {
    this.isOpenModalStarTour = event;
  }

  roundPositivePallet(value: number) {
    return Math.ceil(value);
  }

  onActionItemClick(itemAction: any) {
    let action = itemAction.action;
    if (action === EActionTourLogistic.MODIFY) {
      const urlUpdateLogisticTour = `/list-tour-logistic/${this.logistic_tour_id}/update`
      this.router.navigateByUrl(urlUpdateLogisticTour)
    }
    if (action === EActionTourLogistic.START_TOUR) {
      this.confirmStartTourLogistic();
    }
    if (action === EActionTourLogistic.CANCEL) {
      this.confirmCancelTourLogistic();
    }
  }

  displayActionsSwitchingStatusOfInventory() {
    // Filtrer les actions en fonction du statut
    if (this.logisticTour.status !== ELogisticTourStatus.SCHEDULED) {
      this.actions = this.actions.filter(
        (action) => action.action === EActionTourLogistic.CANCEL
      );
    }
  }

  isDeliveredProductLoaded(point_of_delivery_items: ILogisticDeliveredProducts[]) {
    return point_of_delivery_items.some((item) => item.loaded > 0);
  }

  getLoadProductsValues() {
    return this.formPointOfDelivery.get('delivered_products') as FormArray;
  }

  getInputFormLoadProductFor(control: string) {
    return this.formPointOfDelivery.get(control);
  }

  initFormLoadProducts() {
    this.formPointOfDelivery = this.formBuilder.group({
      planned_departure: ['', Validators.required],
      planned_departure_time: ['', Validators.required],
      departure_point: ['', Validators.required],
      arrival_point: ['', Validators.required],
      planned_arrival: ['', Validators.required],
      planned_arrival_time: ['', Validators.required],
      delivered_products: this.formBuilder.array([]),
      truck_id: ['', [Validators.required]],
      truck_id_two: ['', [Validators.required]],
      driver: ['', [Validators.required]],
      conveyor: ['', [Validators.required]],
    });
  }

  onVehicleChoiceFirstChange(value: number) {
    this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === Number(value));
    if (this.vehicleChoiceFirst?.vehicle_type !== EVehicleType.ROAD_TRACTOR) {
      this.resetInputValueAndValidators('truck_id_two');
    }
    else {
      this.getInputFormLoadProductFor('truck_id_two')?.setValidators(Validators.required);
    }
  }

  onVehicleChoiceSecondChange(value: number) {
    const vehicleSelect = this.getInputFormLoadProductFor('truck_id_two')?.value;
    if (vehicleSelect !== "") {
      this.vehicleChoiceSecond = this.semiTrailerVehiclesList.find((vehicle) => vehicle.id === parseInt(vehicleSelect)) || undefined;
      this.vehiclePallets = this.vehicleChoiceSecond ? this.vehicleChoiceSecond.pallet : this.vehicleChoiceFirst!.pallet;
    }
  }

  onSearchFilterProduct() {
    this.searchProduct.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      const deliveredProductsArray = this.getLoadProductsValues();
      if (searchTerm != "") {
        deliveredProductsArray.controls.forEach((control) => {
          const productName = control.get('product_name')?.value as string;
          const shouldShow = productName.toLowerCase().includes(searchTerm.toLowerCase());
          shouldShow ? control.get('isIncludeSearchTerm')?.setValue(false) : control.get('isIncludeSearchTerm')?.setValue(true);
        });
        this.isEmptyListProductPointFilter = deliveredProductsArray.controls.every((control) => control.get('isIncludeSearchTerm')?.value === true);
      } else if (searchTerm || searchTerm.trim() === '') {
        deliveredProductsArray.controls.map(control => control.get('isIncludeSearchTerm')?.setValue(false))
        this.isEmptyListProductPointFilter = deliveredProductsArray.controls.every((control) => control.get('isIncludeSearchTerm')?.value === true);
      } else {
      }
    });
  }

  //time line cursor click
  openModalDetailPoint(values: { point_of_delivery: PointOfDelivery, index: number }) {
    this.indexSelectedPointOfDelivery = values.index;
    this.selectedPointOfDelivery = values.point_of_delivery;
    let status = this.selectedPointOfDelivery.status;
    this.searchProduct.patchValue("");
    const dateAndTimeDeparture = this.getDateAndTimeFromLongDate(this.selectedPointOfDelivery.planned_departure);
    const dateAndTimeArrival = this.getDateAndTimeFromLongDate(this.selectedPointOfDelivery.planned_arrival);
    this.modalFormType = EPointOfDEliveryModal.UPDATE_NEW;
    this.formPointOfDelivery = this.formBuilder.group({
      planned_departure: [dateAndTimeDeparture.date, Validators.required],
      planned_departure_time: [dateAndTimeDeparture.time, Validators.required],
      departure_point: [values.point_of_delivery.departure_point.id, Validators.required],
      arrival_point: [this.selectedPointOfDelivery.arrival_point.id, Validators.required],
      planned_arrival: [dateAndTimeArrival.date, Validators.required],
      planned_arrival_time: [dateAndTimeArrival.time, Validators.required],
      arrival: [""],
      arrival_time: [""],
      departure: [""],
      departure_time: [""],
      delivered_products: this.formBuilder.array([]),
      truck_id: ['', [Validators.required]],
      truck_id_two: ['', [Validators.required]],
      driver: [this.selectedPointOfDelivery.driver !== null ? this.selectedPointOfDelivery.driver.id : ''],
      conveyor: [this.selectedPointOfDelivery.conveyor !== null ? this.selectedPointOfDelivery.conveyor.id : '', [Validators.required]],
      update_conveyor_data_from_other_points: [''],
    });
    this.checkIfValueInfoTourItemFormChange();
    if (this.selectedPointOfDelivery.carrier_truck !== null) {
      this.resetInputValueAndValidators('truck_id_two');
      this.getInputFormLoadProductFor('truck_id')?.patchValue(this.selectedPointOfDelivery.carrier_truck.id)
      this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === this.selectedPointOfDelivery.carrier_truck.id);
    }
    else if (this.selectedPointOfDelivery.road_tractor !== null) {
      this.vehicleChoiceFirst = this.othersVehiclesList.find((vehicle) => vehicle.id === this.selectedPointOfDelivery.road_tractor.id);
      this.getInputFormLoadProductFor('truck_id')?.patchValue(this.selectedPointOfDelivery.road_tractor.id)
      this.getInputFormLoadProductFor('truck_id_two')?.patchValue(this.selectedPointOfDelivery.semi_trailer.id)
    } else { }
    const delivered_products = this.getLoadProductsValues()
    for (const product of this.selectedPointOfDelivery.point_of_delivery_items) {
      let value: FormGroup = this.formBuilder.group({
        product_name: new FormControl(product.product.sku_sage, Validators.required),
        product_id: new FormControl(product.product.id, Validators.required),
        asked: new FormControl(product.asked, [Validators.required, Validators.min(1), askedValidatorFactory()]),
        ...((this.selectedPointOfDelivery.status !== EPointOfDeliveryStatus.TO_SEND) && {
          loaded: new FormControl(product.loaded || 0, [Validators.required, Validators.min(1), askedValidatorFactory()])
        }),
        pallet: new FormControl(status === EPointOfDeliveryStatus.SENDED ? calculPallet(product.loaded, product.product.pallet)
          : status === EPointOfDeliveryStatus.RECEIVED ? calculPallet(product!.delivered!, product.product.pallet)
            : calculPallet(product.asked, product.product.pallet), [Validators.min(0)]),
        delivered: new FormControl(product.delivered, [Validators.required, Validators.min(0)]),
        isIncludeSearchTerm: new FormControl(false),
      });
      delivered_products.push(value);
    }
    this.isOpenModal = true;
  }

  sortArrayBy(array: PointOfDelivery[], prop: string, isAsc = false) {
    return sortArray(array, prop, isAsc)
  }

  getPalletCountForProduct(productLoad: any) {
    let product = this.selectedPointOfDelivery.point_of_delivery_items.find((p) => p.product.id === Number(productLoad.value.product_id)) || undefined;
    let pallet = calculPallet(productLoad.value.asked, product ? product.product.pallet : 0);
    productLoad.controls['pallet'].setValue(pallet)
  }

  getPalletCountForLoadProduct(productLoad: any) {
    let product = this.selectedPointOfDelivery.point_of_delivery_items.find((p) => p.product.id === Number(productLoad.value.product_id)) || undefined;
    let pallet = calculPallet(productLoad.value.loaded, product ? product.product.pallet : 0);
    productLoad.controls['pallet'].setValue(pallet)
  }

  getLoadedPalletQuantity(point_of_deliveries: PointOfDelivery[], statusTour: ELogisticTourStatus) {
    let totalPallet: number = 0;
    for (let i = 0; i < point_of_deliveries.length; i++) {
      const element = point_of_deliveries[i];
      let palletQuantity = statusTour === ELogisticTourStatus.SCHEDULED ? element.point_of_delivery_items?.reduce((sum, product) => sum + calculPallet(product.asked, product.product.pallet), 0) || 0
        : element.point_of_delivery_items?.reduce((sum, product) => sum + calculPallet(product.loaded || product.asked, product.product.pallet), 0) || 0;
      totalPallet = totalPallet + palletQuantity
    }
    return Math.round(totalPallet * 10) / 10;
  }

  changeModalFrame(frame: EModalFrameDeliveryPoint) {
    this.modalFrameSelected = frame;
  }

  resetInputValueAndValidators(controlName: string) {
    this.getInputFormLoadProductFor(controlName)?.setValue('');
    this.getInputFormLoadProductFor(controlName)?.clearValidators();
    this.getInputFormLoadProductFor(controlName)?.updateValueAndValidity();
  }

  // utils
  canStartLogisticTour(start_date: string) {
    const startDate = new Date(start_date).setHours(0, 0, 0, 0);
    const currentDate = this.today.setHours(0, 0, 0, 0);
    return startDate !== currentDate;
  }

  getLastDeliveryPointVehicleAndUserActors(tour: LogisticTour) {
    return getLastDeliveryPointVehicleAndUserActors(tour);
  }

  showSpinner() {
    if (this.loading || this.loadingDepots || this.loadingUsers || this.loadingtruck || this.loadingUpdateTourItem) this.spinner.show();
    else this.spinner.hide();
  }

  getDateAndTimeFromLongDate(dateTime: string) {
    return extractDateAndTime(dateTime);
  }

  translatePointOfDeliveryStatus(status: EPointOfDeliveryStatus) {
    return translatePointOfDeliveryStatus(status)
  }

  translateLogisticTourStatus(status: ELogisticTourStatus) {
    return translateLogisticTourStatus(status)
  }

  translateVehicleType(type: EVehicleType) {
    return translateVehicleType(type);
  }

  onShowOrCloseTootltip() {
    this.open_tooltip = !this.open_tooltip;
  }

  calculatePercentagePalletLoaded(loaderPallets: number, vehiclePallets: number) {
    return calculatePercentage(loaderPallets, vehiclePallets);
  }

  checkIfValueInfoTourItemFormChange() {
    this.formPointOfDelivery.valueChanges.subscribe((values) => {
      if (this.selectedPointOfDelivery.carrier_truck != null && isCarrierTruckChanged(values, this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = true;
      } else if (this.selectedPointOfDelivery.road_tractor != null && isRoadTractorChanged(values, this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = true;
      } else if (isNoChange(values, this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = false;
      } else if (!isInfosTourItemNotNull(this.selectedPointOfDelivery)) {
        this.checkChangeFromInfosTourItem = true;
      } else {
      }
    });
  }

  async openModal() {
    this.message = "";
    this.error = "";
    return await this.modalComponent.open();
  }

  async closeModalConfirm() {
    return await this.modalComponent.close()
  }
}
