import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { AuthenticationGuardService } from 'src/app/services/authentication-guard.service';
import { GoalsComponent } from './goals.component';
import { RcGoalsListComponent } from './rc-goals-list/rc-goals-list.component';
import { SecGoalsListComponent } from './sec-goals-list/sec-goals-list.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'goals',
        component: GoalsComponent,
        canActivate: [AuthenticationGuardService],
        children: [
          { path: '', component: RcGoalsListComponent },
          {
            path: ':id',
            component: SecGoalsListComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class GoalsRoutingModule {}
