import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITransactionFilter } from '../interfaces/transaction-interface';
import { Transaction } from '../models/transaction';
import { buildParams, formattedDateForCSV } from '../utils';
import Config from '../utils/config';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private http: HttpClient) {}

  getTransactions(params?: ITransactionFilter) {
    const url = 'transactions' + buildParams(params);
    return this.http.get<{ items: Transaction[]; page_info: any }>(
      `${Config.baseUrlV2}/${url}`
    );
  }

  getBalance() {
    return this.http.get<any>(`${Config.baseUrlV2}/transactions/balance`);
  }

  generateCsv() {}

  ConvertToCSV(objArray: any[]) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (const accountingEntry of array) {
      let headerDEC = this.generateAccountingEntriesHeaders(
        accountingEntry,
        'DEC',
        accountingEntry.libelle_fee
      );
      let d1DEC = this.generateFeeOne(accountingEntry);
      let d2DEC = this.generateFeeTwo(accountingEntry);
      let ADEC = this.generateFeeThree(accountingEntry);
      let d3DEC = this.generateFeeFour(accountingEntry);
      let headerENC = this.generateAccountingEntriesHeaders(
        accountingEntry,
        'ENC',
        accountingEntry.libelle_versement
      );
      let d1ENC = this.generateVersementOne(accountingEntry);
      let d2ENC = this.generateVersementTwo(accountingEntry);

      str += `${headerDEC}\r\n${d1DEC}\r\n${d2DEC}\r\n${ADEC}\r\n${d3DEC}\r\n${headerENC}\r\n${d1ENC}\r\n${d2ENC}\r\n`;
    }
    return str;
  }

  generateAccountingEntriesHeaders(
    accountingEntry: any,
    headerType: string,
    libelle: string
  ) {
    return `G;${headerType};;SFLDB;LDB01;ODWA;${formattedDateForCSV(
      accountingEntry.date
    )};${formattedDateForCSV(accountingEntry.date)};WAVE;${
      accountingEntry.currency
    };1;STDCO;;${libelle};`;
  }

  generateFeeOne(accountingEntry: any) {
    return `D;1;1;1;SFLDB;;63180000;;${accountingEntry.libelle_fee};1;${accountingEntry.fee};S18;;;`;
  }

  generateFeeTwo(accountingEntry: any) {
    return `D;1;2;1;SFLDB;;63183008;;${accountingEntry.libelle_fee};1;${accountingEntry.fee};;;;`;
  }

  generateFeeThree(accountingEntry: any) {
    return `A;1;CCT;081310;0;${accountingEntry.fee};${accountingEntry.fee};PRJ;;0;${accountingEntry.fee};${accountingEntry.fee};;;`;
  }

  generateFeeFour(accountingEntry: any) {
    return `D;2;1;2;SFLDB;;55420000;;${accountingEntry.libelle_fee};-1;${accountingEntry.fee};;;;`;
  }

  generateVersementOne(accountingEntry: any) {
    return `D;1;1;1;SFLDB;CL4;41111400;${accountingEntry.sage_x3};${accountingEntry.libelle_versement};-1;${accountingEntry.amount};;;;`;
  }

  generateVersementTwo(accountingEntry: any) {
    return `D;2;1;2;SFLDB;;55420000;;${accountingEntry.libelle_versement};1;${accountingEntry.amount};;;;`;
  }

  downloadFile(data: any, filename = 'ec_wave', date: Date) {
    let csvData = this.ConvertToCSV(data);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    const formattedDate = formattedDateForCSV(date);
    dwldLink.setAttribute(
      'download',
      `${filename}_${formattedDate}_v1.2` + '.csv'
    );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
