import { Injectable } from '@angular/core';
import { ToastInfo } from 'src/app/types';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(type: string, header: string, body: string) {
    this.toasts.push({ type, header, body });
  }

  showSuccess(body: string) {
    this.show('bg-success text-light', 'Succès', body);
  }

  showDanger(body: string) {
    this.show('bg-danger text-light', 'Erreur', body);
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }
}
