import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-is-guarded',
  templateUrl: './is-guarded.component.html',
  styleUrls: ['./is-guarded.component.scss'],
})
export class IsGuardedComponent implements OnInit {
  @Input() permissions?: string | string[] = [];

  constructor() {}

  ngOnInit(): void {}
}
