import { Component, Input, OnInit } from '@angular/core';
import { ELogisticTourStatus, LogisticTour } from 'src/app/models/logistic-tour';
import { getLastDeliveryPointVehicleAndUserActors, translateLogisticTourStatus } from 'src/app/utils/helpers';

@Component({
  selector: 'app-card-daily-planning',
  templateUrl: './card-daily-planning.component.html',
  styleUrls: ['./card-daily-planning.component.scss']
})
export class CardDailyPlanningComponent implements OnInit {
  @Input() planning!: LogisticTour;

  EPlanningStatus = ELogisticTourStatus;
  constructor() { }

  ngOnInit(): void {
  }

  translatELogisticTourStatus(status: ELogisticTourStatus){
    return translateLogisticTourStatus(status)
  }

  getLastDeliveryPointVehicleAndUserActors(tour: LogisticTour){
    return getLastDeliveryPointVehicleAndUserActors(tour);
  }
}
