import { Depot } from "./depot";
import { PointOfDelivery } from "./point-of-delivery";
import { User } from "./user";
import { Vehicle } from "./vehicle";

export class LogisticTour {
  constructor(
    public id: number,
    public code: string,
    public status: ELogisticTourStatus,
    public is_deleted: boolean,
    public start_date: string,
    public end_date: string,
    public created_by: User,
    public last_updated_by: User,
    public point_of_deliveries: PointOfDelivery[],
    public created_at: string,
    public updated_at: string,
    public origin: Depot,
    public week: number,
    public year: number,
    public day: number,
    public month?: number | null,

  ) {}
}


export enum  ELogisticTourStatus {
  CANCELED= "CANCELED",
  FINISH= "FINISH",
  IN_PROGRESS= "IN_PROGRESS",
  SCHEDULED= "SCHEDULED",
}

export enum  ELogisticTourStatusFR {
  CANCELED = "Annulée",
  FINISH = "Terminée",
  IN_PROGRESS = "En cours",
  SCHEDULED = "En attente",
}
