import { NgModule } from "@angular/core";
import { GoBackComponent } from "./go-back.component";
import { NgxBootstrapIconsModule, chevronLeft  } from 'ngx-bootstrap-icons';

const icons = {
    chevronLeft,
}

@NgModule({
    declarations: [
        GoBackComponent,
    ],
    imports: [
        NgxBootstrapIconsModule.pick(icons),
    ],
    exports: [
        GoBackComponent,
    ]
})
export class GoBackModule {}