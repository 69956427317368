import { GlobalPerformancesComponent } from './global-performances/global-performances.component';
import { ViewPerformancesComponent } from './view-performances/view-performances.component';
import { LayoutComponent } from './../../layout/layout.component';
import { HomePerformancesComponent } from './home-performances/home-performances.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from 'src/app/services/authentication-guard.service';
import { PerformancesComponent } from './performances.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'performances',
        component: PerformancesComponent,
        canActivate: [AuthenticationGuardService],
        children: [
          { path: '', component: GlobalPerformancesComponent },
          { path: 'point-of-sales', component: HomePerformancesComponent },
          {
            path: 'point-of-sale/:point-of-sale-id',
            component: ViewPerformancesComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class PerformancesRoutingModule {}
