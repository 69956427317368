import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Config from '../utils/config';
import { VehiclePaginationInterface } from '../interfaces/vehicle-interface';
import { CreateVehicleDto, UpdateVehicleDto } from '../models/vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private http: HttpClient) {}
  getFilteredListTruck(payload: VehiclePaginationInterface) {
    return this.http.post<{ data: any[]; metadata: any }>(
      `${Config.baseUrlV2}/vehicles/list`,
      payload
    );
  }

  createTruck(payload: CreateVehicleDto) {
    return this.http.post<any>(
      `${Config.baseUrlV2}/vehicles`,
      payload
    );
  }

  updateTruck(id: number, payload: UpdateVehicleDto) {
    return this.http.patch<any>(
      `${Config.baseUrlV2}/vehicles/${id}`,
      payload
    );
  }
}
