import { ClusterPaginationInterface } from '../interfaces/clusters-interface';
import { Pagination, PaginationInterface } from '../interfaces/pagination-interface';
import { ClusterZoneSupervisor } from '../models/cluster';
import { Cluster } from '../types';
import { ECategoryCluster, EClusterType, ERefrigeratorType } from '../utils';

export interface ClusterListDTO {
  data: Cluster[];
  metadata: PaginationInterface;
}


export interface NewClusterDTO {
  country_id: number;
  label: string;
  cluster: EClusterType;
  category_cluster: ECategoryCluster;
  passage: number;
  refrigerator_type: ERefrigeratorType;
  rate: number;
  recommended_range_products: IRecommandedRangeProduct[];
  zone_supervisor_ids: number[];
  min_ca?: number;
  max_ca?: number;
}

export interface UpdateClusterDTO{
  label?: string;
  passage?: number;
  refrigerator_type?: ERefrigeratorType;
  rate?: number;
  recommended_range_products?: IRecommandedRangeProduct[];
  min_ca?: number;
  max_ca?: number;
  status?: boolean;
}   

export interface ClusterZoneSupervisorDTO{
  data: ClusterZoneSupervisor[];
  metadata: ClusterPaginationInterface;
}

export interface ClusterZoneSupervisorPaginationDTO {
  limit: number;
  page: number;
}

export interface IRecommandedRangeProduct {
  product_id: number
}