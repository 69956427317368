import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]',
})
export class CurrencyFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input')
  onInput() {
    const inputElement = this.el.nativeElement;
    const value = inputElement.value;

    // Remove non-numeric characters except decimal point
    const cleanValue = value.replace(/[^0-9.]/g, '');

    // Format the value as currency
    const formattedValue = this.formatCurrency(cleanValue);

    // Update the input value
    inputElement.value = formattedValue;
  }

  formatCurrency(value: string): string {
    const numberString = value.toString();
    const parts = numberString.split('.');
    const wholeNumberPart = parts[0];
    const decimalPart = parts[1] ? '.' + parts[1] : '';

    let formattedWholeNumber = wholeNumberPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ' '
    );

    if (wholeNumberPart.length > 6) {
      // Insert space separator for millions
      formattedWholeNumber = formattedWholeNumber.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ' '
      );
    }

    return formattedWholeNumber + decimalPart;
  }
}
