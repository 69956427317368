import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss'],
})
export class GoBackComponent implements OnInit {
  @Input() marginClass: string = '';
  @Input() redirectUrl: string = '';
  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {}

  back(): void {
    if (this.redirectUrl) this.router.navigate([this.redirectUrl]);
    else this.location.back();
  }
}
