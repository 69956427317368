<div
  class="d-flex justify-content-between flex-column pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <!-- <app-go-back [marginClass]="''"></app-go-back> -->
    <h1 class="h2 text-info">Ecritures comptable sage X3</h1>
  </div>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-around justify-content-start b-4">
      <div class="row-cols-sm-auto form-group">
        <label for="inputEmail3" class="mx-2 col-form-label fw-bold text-info"
          >Rechercher par téléphone, employé, ...
        </label>
        <div class="col">
          <div class="input-group">
            <input
              type="text"
              class="form-control search-input"
              [formControl]="searchFormControl"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="row-cols-sm-auto form-group mx-3">
        <label for="inputEmail3" class="mx-2 col-form-label fw-bold text-info"
          >Choix de la date
        </label>
        <div class="col">
          <div class="input-group">
            <input
              ngb-dp-today
              class="form-control form-control-sm rounded-left"
              placeholder="dd/mm/yyyy"
              [formControl]="dateFilterControl"
              ngbDatepicker
              #d="ngbDatepicker"
            />
            <button
              class="btn btn-info btn-sm btn-outline-secondary calendar text-white"
              (click)="d.toggle()"
              type="button"
            >
              <i-bs name="calendar3"></i-bs>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-is-guarded [permissions]="['CAN_GENERATE_ACCOUNT_BILLING_LETTER']">
  <div class="d-flex justify-content-end mb-3">
    <button
      class="btn create-user-button mx-3"
      (click)="generateAccountingEntries()"
    >
      Générer les écriture comptable
    </button>
  </div>
</app-is-guarded>
<app-table
  *ngIf="!loadingAccountEntries && groupedTransactionBySellerToDisplay?.length"
  [columnDefs]="columnDefs"
  [tableData]="sortArray(groupedTransactionBySellerToDisplay, 'libelle')"
></app-table>
